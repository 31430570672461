import styled from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import Icon from '../Icon';
import Select from '../Select';

import { Props } from './types';

export const Root = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const SelectorsWrapper = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
  gap: 8px;
`;
export const StyledSelect = styled(Select)`
  flex-grow: 1;
` as typeof Select;
export const Delimiter = styled.span`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
`;
export const Label = styled.label`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-bottom: 8px;
  letter-spacing: 0.5px;
`;
export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;
