import React, { FC } from 'react';

export const AlertCircle: FC = () => (
  <svg viewBox="0 0 16 16" fill="currentColor">
    <path
      d="M11.334 2.2267C12.3395 2.80727 13.1759 3.64038 13.7605 4.64356C14.3451 5.64675 14.6576 6.78521 14.6669 7.94626C14.6763 9.1073 14.3823 10.2507 13.814 11.2632C13.2457 12.2757 12.4228 13.1222 11.4268 13.7189C10.4308 14.3156 9.29623 14.6419 8.13538 14.6654C6.97454 14.6889 5.82768 14.4088 4.80835 13.8529C3.78902 13.2969 2.93257 12.4844 2.32376 11.4957C1.71496 10.507 1.37492 9.3765 1.33732 8.21603L1.33398 8.00003L1.33732 7.78403C1.37465 6.63269 1.70968 5.51067 2.30974 4.52736C2.90981 3.54405 3.75442 2.733 4.76125 2.17329C5.76807 1.61357 6.90275 1.32429 8.05466 1.33366C9.20656 1.34302 10.3364 1.6507 11.334 2.2267ZM8.00732 10L7.92265 10.0047C7.76062 10.024 7.61128 10.102 7.50293 10.224C7.39457 10.346 7.33473 10.5035 7.33473 10.6667C7.33473 10.8299 7.39457 10.9874 7.50293 11.1094C7.61128 11.2314 7.76062 11.3094 7.92265 11.3287L8.00065 11.3334L8.08532 11.3287C8.24735 11.3094 8.39669 11.2314 8.50504 11.1094C8.6134 10.9874 8.67324 10.8299 8.67324 10.6667C8.67324 10.5035 8.6134 10.346 8.50504 10.224C8.39669 10.102 8.24735 10.024 8.08532 10.0047L8.00732 10ZM8.00065 4.6667C7.83736 4.66672 7.67976 4.72667 7.55774 4.83517C7.43571 4.94368 7.35776 5.0932 7.33865 5.25536L7.33398 5.33336V8.00003L7.33865 8.07803C7.35792 8.24006 7.43596 8.3894 7.55796 8.49775C7.67997 8.60611 7.83748 8.66595 8.00065 8.66595C8.16383 8.66595 8.32133 8.60611 8.44334 8.49775C8.56535 8.3894 8.64338 8.24006 8.66265 8.07803L8.66732 8.00003V5.33336L8.66265 5.25536C8.64355 5.0932 8.56559 4.94368 8.44357 4.83517C8.32154 4.72667 8.16394 4.66672 8.00065 4.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertCircle;
