import styled, { css } from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  height: 100%;
  width: 100%;
`;
export const TabsContainer = styled.div`
  ${flexStyle({ direction: 'row', justify: 'flex-start' })};
  margin-bottom: 24px;
`;
export const Tab = styled.div<{ active?: boolean; disabled?: boolean }>`
  ${flexStyle({ align: 'center', justify: 'center' })};
  ${textStyle({ color: 'textTertiary', lineHeight: 1.2, size: '12px', textAlign: 'left', weight: 500 })};
  position: relative;
  letter-spacing: 0.1px;
  padding: 12px 16px;
  margin: 12px 12px 12px 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;

      &:hover {
        color: ${({ theme }) => theme.colors.textTertiary};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.textPrimary};

      &:hover {
        color: ${({ theme }) => theme.colors.textPrimary};
      }
    `}
`;
export const ActiveTabBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 50%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 2px;
`;
