import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader, { Modes as LoaderModes } from 'components/Loader';
import Tree from 'components/Tree';
import { TreeItem } from 'components/Tree/types';
import { convertToTreeItems } from 'components/Tree/utils';

import { ProjectTreeItemTypes } from 'models/ProjectTree/enums';
import { isDef } from 'utils/def';

import { fetchProjectTree } from '../../store/actions';
import { selectProjectTree, selectProjectTreeLoading } from '../../store/selectors';

import FlowNode from './components/FlowNode';
import FlowVariablesNode from './components/FlowVariablesNode';
import InitiativeNode from './components/InitiativeNode';
import InitiativeVariablesNode from './components/InitiativeVariablesNode';
import ProjectNode from './components/ProjectNode';
import { LoaderContainer, Root } from './styles';
import { Props } from './types';

const nodeTypes = {
  [ProjectTreeItemTypes.project]: ProjectNode,
  [ProjectTreeItemTypes.initiative]: InitiativeNode,
  [ProjectTreeItemTypes.flow]: FlowNode,
  [ProjectTreeItemTypes.bpVariables]: InitiativeVariablesNode,
  [ProjectTreeItemTypes.flowVariables]: FlowVariablesNode,
};

const ProjectExplorer: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const { projectName, sphereName } = useParams();
  const loading = useSelector(selectProjectTreeLoading);
  const projectTree = useSelector(selectProjectTree);
  useEffect(() => {
    if (isDef(projectName) && isDef(sphereName)) {
      dispatch(fetchProjectTree.request({ projectName, sphereName }));
    }
  }, [dispatch, projectName, sphereName]);
  const nodes: TreeItem[] = useMemo(
    () =>
      convertToTreeItems(
        isDef(projectTree) ? [projectTree] : undefined,
        (item, parentIndex) => (isDef(parentIndex) ? `${parentIndex}_${item.name}` : item.name),
        (item) => item.name,
        (item) => item.type,
        (item) => item.children
      ),
    [projectTree]
  );

  return (
    <Root className={className}>
      {loading && (
        <LoaderContainer>
          <Loader mode={LoaderModes.default} />
        </LoaderContainer>
      )}
      {!loading && <Tree nodeTypes={nodeTypes} nodes={nodes} />}
    </Root>
  );
};

export default ProjectExplorer;
