import { useCallback, useRef } from 'react';

const useDynamicCallback = <T extends Function>(callback: T) => {
  const ref = useRef<T>(callback);
  ref.current = callback;
  return useCallback(
    // must be a `function` to correctly pass `this`
    function (this: unknown, ...args: unknown[]) {
      return ref.current.apply(this, args);
    },
    []
  );
};

export default useDynamicCallback;
