import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from 'components/Modal';

import useDeepCallback from 'hooks/useDeepCallback';
import { Initiative } from 'models/Initiative';
import { modalOpen } from 'store/modal/actions';
import { isDefAndNotNull } from 'utils/def';

import { InitiativeFormFields, ViewModes } from '../../../components/InitiativeForm';

import { DELETE_INITIATIVE_CONFIRM_MODAL_ID } from '../../constant';
import { deleteInitiative, updateInitiative } from '../../store/actions';
import { selectDeleteInitiativeLoading, selectInitiative, selectUpdateInitiativeLoading } from '../../store/selectors';

import { DateCreated, InitiativeInfo, NameCreated, Root, StyledInitiativeForm } from './styles';
import { Props } from './types';

const InitiativeFormUpdate: FC<Props> = ({ className, onCancel }) => {
  const dispatch = useDispatch();
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const updateInitiativeLoading = useSelector(selectUpdateInitiativeLoading);
  const initiative = useSelector(selectInitiative);
  const deleteInitiativeLoading = useSelector(selectDeleteInitiativeLoading);

  const handleDeleteInitiative = useCallback(() => {
    if (sphereName && projectName && initiativeName)
      dispatch(deleteInitiative.request({ initiative: initiativeName, project: projectName, sphere: sphereName }));
  }, [dispatch, sphereName, projectName, initiativeName]);
  const handleEditInitiative = useDeepCallback((oldName: Initiative['name'], data: InitiativeFormFields) => {
    if (isDefAndNotNull(sphereName) && isDefAndNotNull(projectName))
      dispatch(
        updateInitiative.request({
          data: {
            data: { description: data.description, name: data.name?.trim(), oldName: oldName, type: data.type!.name },
            flow: flowName,
            project: projectName,
            sphere: sphereName,
          },
        })
      );
  });
  const handleOpenDeleteInitiativeConfirmModal = useCallback(() => {
    dispatch(modalOpen({ id: DELETE_INITIATIVE_CONFIRM_MODAL_ID }));
  }, [dispatch]);

  return (
    <Root className={className}>
      <InitiativeInfo>
        <NameCreated>{initiative?.createdBy ? initiative?.createdBy : 'Created by Test User'}</NameCreated>
        <DateCreated>{initiative?.createdAt ? initiative?.createdAt : '01/01/2024'}</DateCreated>
      </InitiativeInfo>
      <StyledInitiativeForm
        model={initiative}
        onSubmit={handleEditInitiative(initiativeName!)}
        onCancel={onCancel}
        onDelete={handleOpenDeleteInitiativeConfirmModal}
        mode={ViewModes.edit}
        updateLoading={updateInitiativeLoading}
      />
      <Modal
        id={DELETE_INITIATIVE_CONFIRM_MODAL_ID}
        confirm
        renderTitle={() => `Delete ${initiativeName}`}
        renderChildren={() => 'Are you sure you want to delete this initiative?'}
        confirmButtonProps={() => ({ label: 'Delete', loading: deleteInitiativeLoading, onClick: handleDeleteInitiative })}
      />
    </Root>
  );
};

export type { Props };

export default memo(InitiativeFormUpdate);
