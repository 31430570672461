import React, { FC } from 'react';

const Moon: FC = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M19.0017 10.9999H21.0017M20.0017 9.99994V11.9999M12.0017 2.99994C12.1337 2.99994 12.2647 2.99994 12.3947 2.99994C11.1101 4.19365 10.2843 5.79979 10.061 7.53916C9.83772 9.27854 10.231 11.0412 11.1725 12.5207C12.1139 14.0002 13.5441 15.103 15.2143 15.6374C16.8846 16.1718 18.6893 16.1041 20.3147 15.4459C19.6895 16.9504 18.6675 18.257 17.3579 19.2262C16.0483 20.1954 14.5002 20.791 12.8786 20.9493C11.2571 21.1077 9.623 20.8229 8.15062 20.1253C6.67825 19.4278 5.42285 18.3436 4.51832 16.9885C3.6138 15.6334 3.09409 14.0582 3.01462 12.4309C2.93515 10.8036 3.29892 9.18524 4.06711 7.74846C4.8353 6.31167 5.9791 5.11037 7.3765 4.27268C8.7739 3.43499 10.3725 2.99234 12.0017 2.99194V2.99994ZM17.0017 3.99994C17.0017 4.53038 17.2125 5.03908 17.5875 5.41416C17.9626 5.78923 18.4713 5.99994 19.0017 5.99994C18.4713 5.99994 17.9626 6.21066 17.5875 6.58573C17.2125 6.9608 17.0017 7.46951 17.0017 7.99994C17.0017 7.46951 16.791 6.9608 16.416 6.58573C16.0409 6.21066 15.5322 5.99994 15.0017 5.99994C15.5322 5.99994 16.0409 5.78923 16.416 5.41416C16.791 5.03908 17.0017 4.53038 17.0017 3.99994Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Moon;
