import React, { FC, useCallback } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';

import { isDefAndNotNull } from 'utils/def';

import { REQUIRED_ERROR_TEXT } from '../FormField';
import { ICONS_TYPES } from '../Icon';

import { FILE_MAX_SIZE_DEFAULT, FILE_MIN_SIZE_DEFAULT } from './constants';
import {
  AttentionIcon,
  DropContainer,
  DropLabelDescription,
  DropLabelTitle,
  ErrorMessage,
  ErrorMessageContainer,
  FileIcon,
  FileItemInput,
  FileItemsContainer,
  InputLabel,
  RemoveIcon,
  RequiredMark,
  Root,
  UploadIcon,
} from './styles';
import { Props } from './types';

const FileInput: FC<Props> = ({
  accept,
  className,
  clearError,
  disabled,
  error,
  fileNameInputDisabled,
  fileNameInputPlaceholder = 'Type new file name',
  label = 'Select a file',
  maxSize = FILE_MAX_SIZE_DEFAULT,
  minSize = FILE_MIN_SIZE_DEFAULT,
  multi,
  name,
  onBlur,
  onChange,
  required,
  setError,
  value,
}) => {
  const handleDrop = useCallback(
    (files: File[]) => {
      onChange(
        files.map((file) => {
          const fileNameSplit = file.name.split('.');
          const nameWithoutExtension = fileNameSplit.slice(0, fileNameSplit.length - 1).join('.');

          return { data: file, name: nameWithoutExtension };
        })
      );
    },
    [onChange]
  );
  const handleDropRejection = useCallback(
    (fileRejections: FileRejection[]) => {
      const [
        {
          errors: [{ code, message }],
        },
      ] = fileRejections;
      let messageFormatted = message;

      if (code === ErrorCode.FileInvalidType) {
        messageFormatted = message.replace(/,/g, ', ');
      }

      setError?.(messageFormatted);
    },
    [setError]
  );
  const handleDropAccepted = useCallback(() => {
    clearError?.();
  }, [clearError]);
  const handleFileNameChange = useCallback(
    (name: string) => {
      onChange(value!.map((file) => ({ ...file, name })));

      if (!name) {
        setError?.(REQUIRED_ERROR_TEXT);
      } else if (isDefAndNotNull(error)) {
        clearError?.();
      }
    },
    [value, error, onChange, setError, clearError]
  );
  const handleRemoveItem = useCallback(
    (index: number) => () => {
      onChange(value!.filter((_, fileIndex) => fileIndex !== index));
    },
    [value, onChange]
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept,
    disabled,
    maxSize,
    minSize,
    multiple: multi,
    onDrop: handleDrop,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejection,
    onFileDialogCancel: onBlur,
  });

  return (
    <Root className={className}>
      {label && (
        <InputLabel>
          {required ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </InputLabel>
      )}
      {isDefAndNotNull(value) && value?.length !== 0 ? (
        <FileItemsContainer>
          {value?.map((file, index) => {
            const inputValue = isDefAndNotNull(file.name) ? file.name : file.data?.name;

            return (
              <FileItemInput
                name="fileItemName"
                value={inputValue}
                onChange={handleFileNameChange}
                key={index}
                placeholder={fileNameInputPlaceholder}
                showClear={false}
                prefix={<FileIcon icon={ICONS_TYPES.File} size={20} />}
                postfix={<RemoveIcon onClick={handleRemoveItem(index)} icon={ICONS_TYPES.TrashBin} size={20} />}
                touched
                error={error}
                showErrorText={false}
                disabled={fileNameInputDisabled}
              />
            );
          })}
        </FileItemsContainer>
      ) : (
        <DropContainer {...getRootProps()} disabled={disabled} error={error} isDragActive={isDragActive} htmlFor={name}>
          <input {...getInputProps({ onBlur })} />
          <UploadIcon icon={ICONS_TYPES.Upload} size={32} />
          <DropLabelTitle>{label}</DropLabelTitle>
          <DropLabelDescription>Drag and drop file here</DropLabelDescription>
        </DropContainer>
      )}
      {error && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export type { Props };

export default FileInput;
