import { createAction, createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Variable, VariableType } from 'models/Variable';

export type FetchGlobalVariablesRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchGlobalVariablesSuccessPayload = FetchGlobalVariablesRequestPayload & {
  variables: Variable[];
};
export const tryFetchGlobalVariablesActionName = 'variables/tryFetchGlobalVariables';
export const tryFetchGlobalVariables = createAction(tryFetchGlobalVariablesActionName)<FetchGlobalVariablesRequestPayload>();
export const fetchGlobalVariables = createAsyncAction(
  'variables/fetchGlobalVariables/request',
  'variables/fetchGlobalVariables/success',
  'variables/fetchGlobalVariables/failure'
)<FetchGlobalVariablesRequestPayload, FetchGlobalVariablesSuccessPayload, undefined>();

export type FetchContextVariablesRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchContextVariablesSuccessPayload = FetchContextVariablesRequestPayload & {
  variables: Variable[];
};
export const tryFetchContextVariablesActionName = 'variables/tryFetchContextVariables';
export const tryFetchContextVariables = createAction(tryFetchContextVariablesActionName)<FetchContextVariablesRequestPayload>();
export const fetchContextVariables = createAsyncAction(
  'variables/fetchContextVariables/request',
  'variables/fetchContextVariables/success',
  'variables/fetchContextVariables/failure'
)<FetchContextVariablesRequestPayload, FetchContextVariablesSuccessPayload, undefined>();

export type FetchInitiativeVariablesRequestPayload = {
  bp: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchInitiativeVariablesSuccessPayload = FetchInitiativeVariablesRequestPayload & {
  variables: Variable[];
};
export const tryFetchInitiativeVariablesActionName = 'variables/tryFetchInitiativeVariables';
export const tryFetchInitiativeVariables = createAction(
  tryFetchInitiativeVariablesActionName
)<FetchInitiativeVariablesRequestPayload>();
export const fetchInitiativeVariables = createAsyncAction(
  'variables/fetchInitiativeVariables/request',
  'variables/fetchInitiativeVariables/success',
  'variables/fetchInitiativeVariables/failure'
)<FetchInitiativeVariablesRequestPayload, FetchInitiativeVariablesSuccessPayload, undefined>();

export type FetchFlowVariablesRequestPayload = {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchFlowVariablesSuccessPayload = FetchFlowVariablesRequestPayload & {
  variables: Variable[];
};
export const tryFetchFlowVariablesActionName = 'variables/tryFetchFlowVariables';
export const tryFetchFlowVariables = createAction(tryFetchFlowVariablesActionName)<FetchFlowVariablesRequestPayload>();
export const fetchFlowVariables = createAsyncAction(
  'variables/fetchFlowVariables/request',
  'variables/fetchFlowVariables/success',
  'variables/fetchFlowVariables/failure'
)<FetchFlowVariablesRequestPayload, FetchFlowVariablesSuccessPayload, undefined>();

export type CreateVariablesRequestPayload = {
  bp: Initiative['name'];
  data: Variable;
  flow: Flow['name'];
  modalId?: string;
  project: Project['name'];
  sphere: Sphere['name'];
  variableType: VariableType;
};
export const createVariable = createAsyncAction(
  'variables/createVariable/request',
  'variables/createVariable/success',
  'variables/createVariable/failure'
)<CreateVariablesRequestPayload, undefined, undefined>();

export type UpdateVariablesRequestPayload = {
  bp: Initiative['name'];
  data: Variable;
  flow: Flow['name'];
  modalId?: string;
  name: Variable['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  variableType: VariableType;
};
export const updateVariable = createAsyncAction(
  'variables/updateVariable/request',
  'variables/updateVariable/success',
  'variables/updateVariable/failure'
)<UpdateVariablesRequestPayload, undefined, undefined>();

export type DeleteVariablesRequestPayload = {
  bp: Initiative['name'];
  flow: Flow['name'];
  name: Variable['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  variableType: VariableType;
};
export const deleteVariable = createAsyncAction(
  'variables/deleteVariable/request',
  'variables/deleteVariable/success',
  'variables/deleteVariable/failure'
)<DeleteVariablesRequestPayload, undefined, undefined>();

export type FetchVariablesRequestPayload = {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  variableType: VariableType;
};
