import { AxiosResponse } from 'axios';

import { Schema } from 'models/Schema';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'schemas';

export const fetchSchemas = (params?: RequestOptions['params']): Promise<AxiosResponse<APIResponse<{ schemas: Schema[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });
