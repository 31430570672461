import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { OptionProps, SingleValue } from 'react-select';

import { ServiceType } from 'models/ServiceType';
import { selectServiceTypes } from 'store/app/selectors';
import { isDefAndNotNull } from 'utils/def';

import ServiceForm from '../ServiceForm';

import { OptionValueContainer, OptionValueIcon, Root, StyledSelect } from './styles';
import { Props } from './types';

const CreateServiceForm: FC<Props> = ({ createLoading, existingServicesNames, filterOptions, onCancel, onSubmit }) => {
  const { list: serviceTypes } = useSelector(selectServiceTypes);
  const serviceTypesFiltered = useMemo(
    () => (isDefAndNotNull(filterOptions) ? serviceTypes.filter((option) => filterOptions(option)) : serviceTypes),
    [filterOptions, serviceTypes]
  );
  const onlySingleOption = useMemo(() => serviceTypesFiltered.length === 1, [serviceTypesFiltered.length]);
  const [selectedServiceType, setSelectedServiceType] = useState<SingleValue<ServiceType> | undefined>(
    onlySingleOption ? serviceTypesFiltered[0] : undefined
  );
  const selectedService = useMemo(
    () => (selectedServiceType ? { data: {}, isValidated: undefined, name: '', type: selectedServiceType.type } : undefined),
    [selectedServiceType]
  );
  const Option = (props: OptionProps<ServiceType, false>) => {
    const { data } = props;
    return (
      <OptionValueContainer {...props}>
        <OptionValueIcon model={data.icon} />
        {data.type}
      </OptionValueContainer>
    );
  };

  return (
    <Root>
      <StyledSelect<ServiceType, false>
        name="serviceTypes"
        onChange={setSelectedServiceType}
        disabled={onlySingleOption}
        value={selectedServiceType}
        placeholder="Choose a service you want to add"
        options={serviceTypesFiltered}
        isSearchable={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect
        getOptionLabel={(option) => option.type}
        getOptionValue={(option) => option.type}
        components={{
          Option,
        }}
        styles={{ option: () => ({ whiteSpace: 'normal' }) }}
      />
      {selectedServiceType && selectedService && (
        <ServiceForm
          type={selectedServiceType}
          model={selectedService}
          serviceNameListToValidate={existingServicesNames}
          onSubmit={onSubmit}
          onCancel={onCancel}
          createLoading={createLoading}
        />
      )}
    </Root>
  );
};

export default CreateServiceForm;
