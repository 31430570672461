import { ObjectSchema, Schema } from 'jsonSchema';

export const getProperty = <T>(schema: Schema, propName: string): T => {
  const objectSchema = schema as ObjectSchema;
  return objectSchema.properties[propName] as T;
};

export const removeProperty = (schema: Schema, propName: string) => {
  const objectSchema = schema as ObjectSchema;
  delete objectSchema.properties[propName];
};

export const addRequiredField = (schema: Schema, fieldName: string) => {
  const objectSchema = schema as ObjectSchema;
  objectSchema.required.push(fieldName);
};
