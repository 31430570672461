import { AxiosResponse } from 'axios';

import { TriggerType } from 'models/TriggerType';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'trigger-types';

export const fetchTriggerTypes = (
  params?: RequestOptions['params']
): Promise<AxiosResponse<APIResponse<{ triggers: TriggerType[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });
