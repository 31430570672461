import React, { FC, useCallback, useState } from 'react';

import { ICONS_TYPES } from '../Icon';

import { Sizes } from './enums';
import {
  ActionIcon,
  ContentContainer,
  ExpandableHeaderContainer,
  ExpandableViewIcon,
  Label,
  LabelContainer,
  Root,
} from './styles';
import { Props } from './types';

const ExpandableView: FC<Props> = ({
  action,
  actionIcon,
  children,
  className,
  defaultExpanded = false,
  label,
  labelSize = Sizes.default,
  showArrowIcon = true,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const handleToggleExpand = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  return (
    <Root className={className}>
      <ExpandableHeaderContainer expanded={expanded}>
        <LabelContainer onClick={handleToggleExpand}>
          {showArrowIcon && <ExpandableViewIcon rotate={expanded ? 0 : -90} icon={ICONS_TYPES.ChevronDown} size={14} />}
          <Label labelSize={labelSize}>{label}</Label>
        </LabelContainer>
        {actionIcon && action && <ActionIcon icon={actionIcon.type} size={actionIcon.size} onClick={action} />}
      </ExpandableHeaderContainer>
      <ContentContainer expanded={expanded}>{children}</ContentContainer>
    </Root>
  );
};

export { Sizes };
export type { Props };

export default ExpandableView;
