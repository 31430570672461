import { FormFields, extractValue } from 'jsonSchema';
import { Service } from 'models/Service';
import { SERVICE_API_METHOD_FIELD_NAME, SERVICE_PATHS_FIELD_NAME } from 'models/Service/constants';
import { isDef } from 'utils/def';

import { ServiceTypes } from './enums';

export const getServiceApiMethodFieldValue = (model: Service) => {
  if (model.type === ServiceTypes.REST && isDef(model.data)) {
    const apiMethodFieldWrapper = model.data[SERVICE_API_METHOD_FIELD_NAME] as FormFields;
    const apiMethodField = apiMethodFieldWrapper[SERVICE_API_METHOD_FIELD_NAME] as FormFields;
    return extractValue(apiMethodField)?.toString();
  }
  return undefined;
};

export const getServicePathsFieldValue = (model: Service) => {
  if (model.type === ServiceTypes.REST && isDef(model.data)) {
    const apiMethodField = model.data[SERVICE_PATHS_FIELD_NAME] as FormFields;
    return extractValue(apiMethodField);
  }
  return undefined;
};
