import { ReactNode } from 'react';

import { isDefAndNotNull } from 'utils/def';

export const buildDataCyValue = ({
  elementName,
  fieldName,
}: {
  elementName: string | undefined;
  fieldName: string | undefined | ReactNode;
}) => (isDefAndNotNull(fieldName) && isDefAndNotNull(elementName) ? `cy-${fieldName}-${elementName}` : undefined);
