import { ActionType, getType } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { isDef, isDefAndNotNull } from 'utils/def';

import * as flowTabsActions from './actions';

type FlowTabsActionType = ActionType<typeof flowTabsActions>;

export type FlowTabsState = {
  createFlowLoading: boolean;
  deleteFlowLoading: boolean;
  flows: Flow[];
  flowsLoading: boolean;
  tabsOpened: {
    [key: Initiative['name']]: Flow['name'][];
  };
};

const initialState: FlowTabsState = {
  createFlowLoading: false,
  deleteFlowLoading: false,
  flows: [],
  flowsLoading: true,
  tabsOpened: {},
};

const flowTabsReducer = (state: FlowTabsState = initialState, action: FlowTabsActionType): FlowTabsState => {
  switch (action.type) {
    case getType(flowTabsActions.fetchFlows.success):
      return {
        ...state,
        flows: action.payload.flows.map((flow) => {
          const existingFlow = state.flows.find((item) => item.name === flow.name);

          if (isDefAndNotNull(existingFlow) && isDefAndNotNull(existingFlow.previewObjectUrl)) {
            return {
              ...flow,
              previewObjectUrl: existingFlow.previewObjectUrl,
            };
          }

          return flow;
        }),
        flowsLoading: false,
      };
    case getType(flowTabsActions.fetchFlows.request):
      return {
        ...state,
        flowsLoading: true,
      };
    case getType(flowTabsActions.fetchFlows.failure):
      return {
        ...state,
        flowsLoading: false,
      };

    case getType(flowTabsActions.createFlow.success):
    case getType(flowTabsActions.createFlow.failure):
      return {
        ...state,
        createFlowLoading: false,
      };
    case getType(flowTabsActions.createFlow.request):
      return {
        ...state,
        createFlowLoading: true,
      };

    case getType(flowTabsActions.deleteFlow.success):
    case getType(flowTabsActions.deleteFlow.failure):
      return {
        ...state,
        deleteFlowLoading: false,
      };
    case getType(flowTabsActions.deleteFlow.request):
      return {
        ...state,
        deleteFlowLoading: true,
      };

    case getType(flowTabsActions.tabOpen):
      return {
        ...state,
        tabsOpened: {
          ...state.tabsOpened,
          [action.payload.initiativeName]: isDef(state.tabsOpened[action.payload.initiativeName])
            ? [...state.tabsOpened[action.payload.initiativeName], action.payload.flowName]
            : [action.payload.flowName],
        },
      };
    case getType(flowTabsActions.tabClose.success):
      return {
        ...state,
        tabsOpened: {
          ...state.tabsOpened,
          [action.payload.bp]: state.tabsOpened[action.payload.bp].filter((tab) => tab !== action.payload.flow),
        },
      };

    case getType(flowTabsActions.setFlowsPreviewUrl):
      return {
        ...state,
        flows: state.flows.map((flow) => {
          const flowToPatch = action.payload.find((item) => item.flow === flow.name);

          if (isDefAndNotNull(flowToPatch)) {
            return {
              ...flow,
              previewObjectUrl: flowToPatch.previewObjectUrl,
            };
          }

          return flow;
        }),
      };

    default:
      return state;
  }
};

export default flowTabsReducer;
