import styled, { css } from 'styled-components';

import { flexStyle, rgba, textStyle } from 'styles/mixins';

import Icon from '../Icon';

import { Sizes } from './enums';
import { Props } from './types';

export const Root = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  width: 100%;
`;
export const ExpandableHeaderContainer = styled.div<{ expanded: boolean }>`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
  width: 100%;
  margin-bottom: ${({ expanded }) => (expanded ? '24px' : undefined)};
`;
export const LabelContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;
export const Label = styled.h1<Partial<Props>>`
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ labelSize }) => {
    switch (labelSize) {
      case Sizes.small:
        return css`
          ${textStyle({ color: 'textPrimary', lineHeight: 1.3, size: '12px', textAlign: 'left', weight: 500 })};
          letter-spacing: 0.1px;
        `;
      default:
        return css`
          ${textStyle({ color: 'textPrimary', lineHeight: 1.3, size: '14px', textAlign: 'left', weight: 500 })};
          letter-spacing: 0.15px;
        `;
    }
  }}
`;
export const ExpandableViewIcon = styled(Icon)`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-right: 8px;
`;
export const ActionIcon = styled(Icon)`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  flex: 32px 0 0;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.textTertiary};

  &:hover {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 6)};
  }
`;
export const ContentContainer = styled.div<{ expanded: boolean }>`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  width: 100%;
  ${({ expanded }) =>
    !expanded &&
    css`
      display: none;
    `};
`;
