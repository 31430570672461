import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Tag } from 'models/Tag';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'tags';

export const createSphereTag = (data: { data: Tag; sphere: Sphere['name'] }): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const fetchSphereTags = (sphereName: Sphere['name']): Promise<AxiosResponse<APIResponse<{ tags: Tag[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params: { sphere: sphereName },
  });

export const updateSphereTag = (data: {
  data: Tag & { oldName: Tag['name'] };
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.put,
  });

export const deleteSphereTag = (
  params: Pick<Tag, 'name'> & { sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });

export const fetchProjectTags = (params: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ tags: Tag[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params: params,
  });

export const createProjectTag = (data: {
  data: Tag;
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateProjectTag = (data: {
  data: Tag & { oldName: Tag['name'] };
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.put,
  });

export const deleteProjectTag = (
  params: Pick<Tag, 'name'> & { project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });
