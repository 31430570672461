import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormField, FormFieldSubTypes, FormFieldValue, FormFields, SelectDatasourceSubTypeProps, extractValue } from 'jsonSchema';
import { isDef } from 'utils/def';

import { useGetSubTypeProps } from '../../FormArrayRenderer/hooks/useGetArraySubTypeProps';

export const useFieldSubTypeDependencyValues = (field: FormField): FormFields => {
  const fieldNamePrefix = useMemo(() => (field.name.indexOf('.') ? field.name.split('.')[0] : ''), [field.name]);
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const { formFields, valueFields } = useMemo(() => {
    const formFields: string[] = [],
      valueFields: string[] = [];
    if (isDef(subTypeProps)) {
      const { dependencies } = subTypeProps;
      if (isDef(dependencies)) {
        formFields.push(
          ...Object.keys(dependencies)
            .filter((key) => isDef(dependencies[key].fieldName))
            .map((key) => dependencies[key].fieldName || '')
        );
        valueFields.push(...Object.keys(dependencies).filter((key) => isDef(dependencies[key].value)));
      }
    }
    return { formFields, valueFields };
  }, [subTypeProps]);
  const formFieldNames = useMemo(() => formFields.map((key) => `${fieldNamePrefix}.${key}`), [fieldNamePrefix, formFields]);
  const formValues = useWatch({ name: formFieldNames }) as FormFields[];

  return useMemo(
    () =>
      [...formFields, ...valueFields].reduce((memo: FormFields, curr: string) => {
        let keyValue: FormFieldValue = undefined;
        if (isDef(subTypeProps)) {
          const { dependencies } = subTypeProps;
          if (isDef(dependencies) && isDef(dependencies[curr])) {
            const { fieldName, value } = dependencies[curr];
            if (isDef(fieldName)) {
              const fieldIndex = formFieldNames.findIndex((key) => key === `${fieldNamePrefix}.${fieldName}`);
              if (fieldIndex !== -1) {
                keyValue = extractValue(formValues[fieldIndex]) as FormFieldValue;
              }
            }
            if (valueFields.includes(curr)) {
              keyValue = value as FormFieldValue;
            }
          }
        }
        return {
          ...memo,
          [curr]: keyValue,
        };
      }, {} as FormFields),
    [formValues, valueFields, formFields, fieldNamePrefix, subTypeProps, formFieldNames]
  );
};
