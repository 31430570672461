import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { store } from 'store';

import { API_VERSION } from './constants';
import { Methods } from './enums';
import { stringifyEndpoint } from './utils';

export const makeRequest = (options: Partial<AxiosRequestConfig>) => {
  return axios({ validateStatus: () => true, ...options })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) throw response;
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export interface RequestOptions {
  data?: AxiosRequestConfig['data'];
  endpoint: string;
  headers?: AxiosRequestConfig['headers'];
  method: Methods;
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  params?: AxiosRequestConfig['params'];
  responseType?: AxiosRequestConfig['responseType'];
  timeout?: AxiosRequestConfig['timeout'];
}

const customParamsSerializer = (params: AxiosRequestConfig['params']) => qs.stringify(params);
export const request = ({
  data,
  endpoint,
  headers,
  method,
  onUploadProgress,
  params,
  responseType,
  timeout,
}: RequestOptions): Promise<AxiosResponse> => {
  const options: Partial<AxiosRequestConfig> = { headers, method, url: `/api/v${API_VERSION}/${stringifyEndpoint(endpoint)}` };
  const state = store.getState();

  if (state.auth.clientID) options.headers = { ...options.headers, 'x-client-id': state.auth.clientID };
  if (data) options.data = data;
  if (params) {
    options.params = params;
    options.paramsSerializer = customParamsSerializer;
  }
  if (onUploadProgress) options.onUploadProgress = onUploadProgress;
  if (timeout) options.timeout = timeout;
  if (responseType) options.responseType = responseType;

  return makeRequest(options);
};
