import { ActionType, getType } from 'typesafe-actions';

import * as projectResourcesActions from './actions';

type ProjectResourcesActionType = ActionType<typeof projectResourcesActions>;

export type ProjectResourcesState = {
  createLoading: boolean;
  deleteLoading: boolean;
  list: projectResourcesActions.FetchResourcesSuccessPayload['list'];
  loading: boolean;
  updateLoading: boolean;
  validateLoading: boolean;
};

const initialState: ProjectResourcesState = {
  createLoading: false,
  deleteLoading: false,
  list: [],
  loading: false,
  updateLoading: false,
  validateLoading: false,
};

const projectResourcesReducer = (
  state: ProjectResourcesState = initialState,
  action: ProjectResourcesActionType
): ProjectResourcesState => {
  switch (action.type) {
    case getType(projectResourcesActions.fetchResources.request):
      return {
        ...state,
        loading: true,
      };
    case getType(projectResourcesActions.fetchResources.success):
      return {
        ...state,
        list: action.payload.list,
        loading: false,
      };
    case getType(projectResourcesActions.fetchResources.failure):
      return {
        ...state,
        loading: false,
      };

    case getType(projectResourcesActions.createResource.request):
      return {
        ...state,
        createLoading: true,
      };
    case getType(projectResourcesActions.createResource.success):
    case getType(projectResourcesActions.createResource.failure):
      return {
        ...state,
        createLoading: false,
      };

    case getType(projectResourcesActions.updateResource.request):
      return {
        ...state,
        updateLoading: true,
      };
    case getType(projectResourcesActions.updateResource.success):
    case getType(projectResourcesActions.updateResource.failure):
      return {
        ...state,
        updateLoading: false,
      };

    case getType(projectResourcesActions.validateResource.request):
      return {
        ...state,
        validateLoading: true,
      };
    case getType(projectResourcesActions.validateResource.success):
    case getType(projectResourcesActions.validateResource.failure):
      return {
        ...state,
        validateLoading: false,
      };

    case getType(projectResourcesActions.deleteResource.request):
      return {
        ...state,
        deleteLoading: true,
      };
    case getType(projectResourcesActions.deleteResource.success):
    case getType(projectResourcesActions.deleteResource.failure):
      return {
        ...state,
        deleteLoading: false,
      };

    default:
      return state;
  }
};

export default projectResourcesReducer;
