import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Variable } from 'models/Variable';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'variables/bp';

export const fetchBusinessProcessVariables = (params: {
  bp: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ variables: Variable[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const createVariable = (data: {
  bp: Initiative['name'];
  data: Variable;
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<Variable>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateVariable = (data: {
  bp: Initiative['name'];
  data: Variable;
  name: Variable['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<Variable>>> =>
  request({
    data: omit(data, ['name']),
    endpoint: `${endpoint}/${data.name}`,
    method: Methods.put,
  });

export const deleteVariable = (params: {
  bp: Initiative['name'];
  name: Variable['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });
