import { createSelector } from 'reselect';

import { RootState } from 'store/types';

import { FlowState } from './reducer';

const selectFlowState = (state: RootState) => state.pages.flow;

export const selectFlow = createSelector(selectFlowState, (flowState: FlowState) => flowState.flow);
export const selectFlowLoading = createSelector(selectFlowState, (flowState: FlowState) => flowState.flowLoading);
export const selectFlowPreview = createSelector(selectFlowState, (flowState: FlowState) => flowState.flowPreview);

export const selectTriggerCreating = createSelector(selectFlowState, (flowState: FlowState) => flowState.createTriggerLoading);
export const selectTriggerUpdating = createSelector(selectFlowState, (flowState: FlowState) => flowState.updateTriggerLoading);
export const selectTriggerDeleting = createSelector(selectFlowState, (flowState: FlowState) => flowState.removeTriggerLoading);
export const selectTriggerConfigUpdating = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.updateTriggerConfigLoading
);
export const selectFlowSelectedTrigger = createSelector(selectFlowState, (flowState: FlowState) =>
  flowState.flow.triggers.find((item) => item.name === flowState.selectedTriggerName)
);

export const selectFlowSelectedStep = createSelector(selectFlowState, (flowState: FlowState) => flowState.selectedStep);
export const selectStepCreating = createSelector(selectFlowState, (flowState: FlowState) => flowState.createStepLoading);
export const selectStepUpdating = createSelector(selectFlowState, (flowState: FlowState) => flowState.updateStepLoading);
export const selectStepConfigUpdating = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.updateStepConfigLoading
);
export const selectStepDataMapperConfigUpdating = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.updateStepDataMapperLoading
);
export const selectCreateBranchLoading = createSelector(selectFlowState, (flowState: FlowState) => flowState.createBranchLoading);
export const selectUpdateBranchTypeLoading = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.updateBranchTypeLoading
);
export const selectDeleteBranchLoading = createSelector(selectFlowState, (flowState: FlowState) => flowState.deleteBranchLoading);
export const selectDeleteBranchWaitNodeLoading = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.deleteBranchWaitNodeLoading
);
export const selectUpdateBranchWaitNodeLoading = createSelector(
  selectFlowState,
  (flowState: FlowState) => flowState.updateBranchWaitNodeLoading
);
export const selectStepDeleting = createSelector(selectFlowState, (flowState: FlowState) => flowState.removeStepLoading);

export const selectBuildLoading = createSelector(selectFlowState, (flowState: FlowState) => flowState.buildLoading);
export const selectDeployLoading = createSelector(selectFlowState, (flowState: FlowState) => flowState.deployLoading);

export const selectGoTo = createSelector(selectFlowState, (flowState: FlowState) => flowState.goTo);
