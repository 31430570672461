import React, { FC } from 'react';

const File: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M11.668 2.5V5.83333C11.668 6.05435 11.7558 6.26631 11.912 6.42259C12.0683 6.57887 12.2803 6.66667 12.5013 6.66667H15.8346M11.668 2.5H5.83464C5.39261 2.5 4.96868 2.67559 4.65612 2.98816C4.34356 3.30072 4.16797 3.72464 4.16797 4.16667V15.8333C4.16797 16.2754 4.34356 16.6993 4.65612 17.0118C4.96868 17.3244 5.39261 17.5 5.83464 17.5H14.168C14.61 17.5 15.0339 17.3244 15.3465 17.0118C15.659 16.6993 15.8346 16.2754 15.8346 15.8333V6.66667M11.668 2.5L15.8346 6.66667M7.5013 14.1667H12.5013M7.5013 10.8333H12.5013"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default File;
