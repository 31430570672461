import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import DraggableItem from 'components/DraggableItem';
import { Modes } from 'components/PaginatedContainer';

import { StepTypes } from 'models/Step';
import { StepType } from 'models/StepType';
import { selectStepTypes } from 'store/app/selectors';

import StepTypeCard, { ViewModes } from '../StepTypeCard';
import { StyledDragOverlay } from '../TriggerExplorer/styles';

import { STEP_EXPLORER_DND_TYPE } from './constants';
import { NoMatch, Root, StepTypesContainer, StyledSearch } from './styles';
import { Props } from './types';

const StepExplorer: FC<Props> = ({ className }) => {
  const [searchStr, setSearchStr] = useState('');
  const { list: stepTypes } = useSelector(selectStepTypes);
  const filteredStepTypes = useMemo(
    () => stepTypes.filter((t) => t.type.toLowerCase().includes(searchStr) && t.type !== StepTypes.goTo),
    [stepTypes, searchStr]
  );
  const handleSearchChanged = useCallback((value: string) => setSearchStr(value.toLowerCase()), [setSearchStr]);
  const [draggableItem, setDraggableItem] = useState<StepType | undefined>(undefined);
  const setIsDraggable = useCallback(
    (isDraggable: boolean, id: string) => {
      setDraggableItem(filteredStepTypes.find((t) => isDraggable && t.type === id));
    },
    [filteredStepTypes, setDraggableItem]
  );

  return (
    <Root className={className}>
      <StyledSearch
        backgroundColor="surfacePrimary"
        name="stepTypeSearch"
        isSearch
        placeholder="Search"
        value={searchStr}
        onChange={handleSearchChanged}
      />
      <StepTypesContainer
        gridGap={8}
        mode={Modes.grid}
        gridColumns={1}
        list={filteredStepTypes}
        getListItemId={(stepType) => stepType.type}
        renderListItem={(stepType) => (
          <DraggableItem id={stepType.type} setIsDraggable={setIsDraggable} type={STEP_EXPLORER_DND_TYPE} data={stepType}>
            <StepTypeCard model={stepType} viewMode={ViewModes.filled} />
          </DraggableItem>
        )}
      />
      {!filteredStepTypes.length && <NoMatch>No match found</NoMatch>}
      {createPortal(
        <StyledDragOverlay dropAnimation={null}>
          {draggableItem && <StepTypeCard model={draggableItem} viewMode={ViewModes.filled} />}
        </StyledDragOverlay>,
        document.body
      )}
    </Root>
  );
};

export default memo(StepExplorer);
