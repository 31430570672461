import { Placement, Side } from '@floating-ui/react';

const PLACEMENT_TOP: Placement = 'top';
const PLACEMENT_RIGHT: Placement = 'right';
const PLACEMENT_BOTTOM: Placement = 'bottom';
const PLACEMENT_LEFT: Placement = 'left';

export const PLACEMENT_TYPES: Record<Side, Placement> = {
  [PLACEMENT_BOTTOM]: PLACEMENT_BOTTOM,
  [PLACEMENT_LEFT]: PLACEMENT_LEFT,
  [PLACEMENT_RIGHT]: PLACEMENT_RIGHT,
  [PLACEMENT_TOP]: PLACEMENT_TOP,
};

export const OFFSET = 9;
export const MAX_WIDTH = 250;
export const PADDING = 8;
