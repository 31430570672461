import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { buildDataCyValue } from 'utils/cy';
import { isDefAndNotNull } from 'utils/def';

import { ICONS_TYPES } from '../../../Icon';

import { selectSlideClosingClass, selectSlideIsOpen } from '../../store/selectors';

import { ActionsContainer, CloseIcon, Content, Header, Root, Slide, Title } from './styles';
import { Props } from './types';

const FlowNavSlider: FC<Props> = ({ actions, children, className, onCancel, sliderId, title }) => {
  const isOpen = useSelector(selectSlideIsOpen(sliderId));
  const closingClass = useSelector(selectSlideClosingClass(sliderId));

  const root = (
    <Root className={className} data-cy={buildDataCyValue({ elementName: 'window', fieldName: title })}>
      <Slide className={closingClass}>
        <Header>
          {isDefAndNotNull(title) && <Title>{title}</Title>}
          <ActionsContainer>
            {isDefAndNotNull(actions) && actions}
            <CloseIcon onClick={onCancel} icon={ICONS_TYPES.XMark} size={20} />
          </ActionsContainer>
        </Header>
        <Content>{children}</Content>
      </Slide>
    </Root>
  );
  return isOpen ? root : null;
};

export type { Props };

export default FlowNavSlider;
