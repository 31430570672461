import React, { FC } from 'react';

const Variable: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M4.58398 3.66699C2.29232 8.25033 2.29232 12.8337 4.58398 18.3337M17.4173 3.66699C19.709 8.25033 19.709 12.8337 17.4173 18.3337M8.25065 8.25033H9.16732C10.084 8.25033 10.084 9.16699 11.0153 11.4834C11.9173 13.7503 11.9173 14.667 12.834 14.667H13.7506M7.33398 14.667C8.70898 14.667 10.084 12.8337 11.0007 11.4587C11.9173 10.0837 13.2923 8.25033 14.6673 8.25033"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Variable;
