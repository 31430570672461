import React, { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button, { StyleTypes } from 'components/Button';
import FormField from 'components/FormField';

import { getValidationRulesFromASchema } from 'jsonSchema';
import { InitiativeTypes } from 'models/Initiative/enums';
import { selectInitiativeSchema } from 'store/app/selectors';
import { ValueOf } from 'utils/types';

import { INITIATIVE_NAME_UNIQ_ERROR_TEXT, INITIATIVE_TYPES_OPTIONS } from './constants';
import { ViewModes } from './enums';
import {
  ButtonsCreateContainer,
  ButtonsEditContainer,
  FormFieldsContainer,
  Root,
  SelectContainer,
  StyledInput,
  StyledSelect,
  SubmitButton,
} from './styles';
import { InitiativeFormFields, Props } from './types';

const InitiativeForm: FC<Props> = ({
  className,
  initiativesList,
  mode = ViewModes.create,
  model,
  onCancel,
  onDelete,
  onSubmit,
  updateLoading,
}) => {
  const { control, formState, handleSubmit } = useForm<InitiativeFormFields>({
    defaultValues: {
      description: model?.description,
      name: model?.name,
      type: model?.type
        ? Object.values(INITIATIVE_TYPES_OPTIONS).filter((type) => type.name == model.type)[0]
        : INITIATIVE_TYPES_OPTIONS[InitiativeTypes.integration],
    },
    mode: 'onTouched',
  });
  const initiativeSchema = useSelector(selectInitiativeSchema);
  const validationRules = useMemo(
    () =>
      initiativeSchema
        ? {
            description: getValidationRulesFromASchema('description', initiativeSchema),
            name: getValidationRulesFromASchema('name', initiativeSchema),
            type: getValidationRulesFromASchema('type', initiativeSchema),
          }
        : {},
    [initiativeSchema]
  );
  const isDisabled = useMemo(() => !formState.isValid || !formState.isDirty, [formState.isValid, formState.isDirty]);

  const handleNameValidate = useCallback(
    (value: InitiativeFormFields['name']) =>
      initiativesList
        ? initiativesList.some((initiative) => initiative.name === value)
          ? INITIATIVE_NAME_UNIQ_ERROR_TEXT
          : undefined
        : undefined,
    [initiativesList]
  );

  return (
    <Root className={className} onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsContainer>
        <FormField<InitiativeFormFields, 'name'>
          {...validationRules?.name}
          controllerProps={{
            control,
            name: 'name',
            rules: {
              validate: handleNameValidate,
            },
          }}
          render={(props) => <StyledInput {...props} label="Name" />}
        />
        <FormField<InitiativeFormFields, 'type'>
          {...validationRules?.type}
          controllerProps={{
            control,
            name: 'type',
          }}
          render={(props) => (
            <SelectContainer mode={mode}>
              <StyledSelect<ValueOf<typeof INITIATIVE_TYPES_OPTIONS>, false>
                {...props}
                label="Type"
                options={Object.values(INITIATIVE_TYPES_OPTIONS)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable={false}
              />
            </SelectContainer>
          )}
        />
        <FormField<InitiativeFormFields, 'description'>
          {...validationRules?.description}
          controllerProps={{
            control,
            name: 'description',
          }}
          render={(props) => <StyledInput {...props} multi label="Description" />}
        />
      </FormFieldsContainer>
      {mode === ViewModes.create ? (
        <ButtonsCreateContainer>
          <SubmitButton type="submit" disabled={isDisabled} loading={updateLoading} label="Create" />
          <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
        </ButtonsCreateContainer>
      ) : (
        <ButtonsEditContainer>
          <SubmitButton type="submit" disabled={isDisabled} loading={updateLoading} label="Save Changes" />
          <Button label="Delete Initiative" loading={updateLoading} onClick={onDelete} styleType={StyleTypes.danger} />
        </ButtonsEditContainer>
      )}
    </Root>
  );
};

export { ViewModes };

export type { InitiativeFormFields };

export default InitiativeForm;
