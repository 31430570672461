import React, { FC, MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import useDeepCallback from 'hooks/useDeepCallback';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { stopMouseEvents } from 'utils/events';

import { PlacementTypes, Sizes } from './enums';
import { PopoverContainer, Root } from './styles';
import { Props } from './types';

const Popover: FC<Props> = ({
  children,
  className,
  closeOnRootClick = true,
  content,
  defaultPlacement = [PlacementTypes.right, PlacementTypes.bottom],
  disabled,
  preventStopMouseEvents,
  showMenuByInitialization = false,
  size = Sizes.default,
}) => {
  const [showPopover, setShowPopover] = useState(showMenuByInitialization);
  const [placement, setPlacement] = useState(defaultPlacement);
  const rootRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleSetShowPopover = useCallback(
    (e: ReactMouseEvent) => {
      if (!preventStopMouseEvents) {
        stopMouseEvents(e);
      }
      setShowPopover(true);
    },
    [setShowPopover, preventStopMouseEvents]
  );
  const handleUnsetShowPopover = useDeepCallback((stopEvent: boolean, e: ReactMouseEvent | MouseEvent) => {
    if (stopEvent) {
      stopMouseEvents(e);
    }
    setShowPopover(false);
  });

  useOnClickOutside(rootRef, handleUnsetShowPopover(false));

  useEffect(() => {
    if (popoverRef && popoverRef.current) {
      const { height, top } = popoverRef.current.getBoundingClientRect();
      const [body] = document.querySelectorAll('body');
      const docEl = document.documentElement;
      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const clientTop = docEl.clientTop || body.clientTop || 0;
      const rootTop = top + scrollTop - clientTop;
      const { offsetTop: parentOffsetTop = 0 } = popoverRef.current.parentElement || {};

      if (parentOffsetTop - height >= 0 && rootTop + height > window.innerHeight && !placement.includes(PlacementTypes.top)) {
        setPlacement([defaultPlacement[0], PlacementTypes.top]);
      }
    }
  }, [showPopover, popoverRef, defaultPlacement, placement]);

  return (
    <Root
      ref={rootRef}
      className={className}
      onClick={!disabled ? (closeOnRootClick && showPopover ? handleUnsetShowPopover(true) : handleSetShowPopover) : undefined}
    >
      {children}
      {showPopover && (
        <PopoverContainer ref={popoverRef} placement={placement} size={size}>
          {content}
        </PopoverContainer>
      )}
    </Root>
  );
};

export * from './enums';
export * from './types';

export default Popover;
