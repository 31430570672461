import { createAction, createAsyncAction } from 'typesafe-actions';

import { Resource } from 'models/Resource';
import { Sphere } from 'models/Sphere';

import { CreateWizardSteps } from '../enums';

export type SetWizardStepPayload = {
  step: CreateWizardSteps;
};
export const setWizardStep = createAction('sphereCreate/setWizardStep')<SetWizardStepPayload>();
export const reset = createAction('sphereCreate/reset')<undefined>();

export type CreateSphereRequestPayload = {
  data: Pick<Sphere, 'name' | 'color' | 'access'> & { resources: Resource[] };
};
export const createSphere = createAsyncAction(
  'sphereCreate/createSphere/request',
  'sphereCreate/createSphere/success',
  'sphereCreate/createSphere/failure'
)<CreateSphereRequestPayload, undefined, undefined>();
