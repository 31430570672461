import { isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import SchemaArray from 'components/JsonSchemaRenderer/components/SchemaArrayRenderer';

import { FormFields, normalizeFields, schemaToFormConfig } from 'jsonSchema';

import { ViewModes } from '../../enums';

import { Root } from './styles';
import { Props } from './types';

const ParametersList: React.FC<Props> = ({ onValuesChange, schema, value, viewMode }) => {
  const memoServiceFormConfig = useMemo(() => schemaToFormConfig(schema), [schema]);
  const formDefaultValues = useMemo(() => normalizeFields(value, memoServiceFormConfig), [memoServiceFormConfig, value]);
  const methods = useForm<FormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const { control } = methods;
  const values = useWatch({ control });
  useEffect(() => {
    if (!isEqual(formDefaultValues, values)) {
      onValuesChange(values);
    }
  }, [values, onValuesChange, formDefaultValues, schema.title]);

  return (
    <Root>
      <FormProvider {...methods}>
        <SchemaArray
          schema={schema}
          hasSearch={false}
          hasRowAdd={viewMode !== ViewModes.VIEW}
          hasRowEdit={viewMode !== ViewModes.VIEW}
          hasRowDelete={viewMode !== ViewModes.VIEW}
        />
      </FormProvider>
    </Root>
  );
};

export default ParametersList;
