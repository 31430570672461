import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Log } from 'models/Log';
import { Resource } from 'models/Resource';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'spheres';

export const fetchSpheres = (
  params?: RequestOptions['params']
): Promise<AxiosResponse<APIResponse<{ spheres: Record<string, Sphere> }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const fetchSphere = (
  sphereName: Sphere['name']
): Promise<AxiosResponse<APIResponse<{ sphere: Sphere<true> & { resources: Resource[] } }>>> =>
  request({
    endpoint: `${endpoint}/${sphereName}`,
    method: Methods.get,
  });

export const createSphere = (
  data: Pick<Sphere, 'name' | 'color' | 'access'> & { resources: Resource[] }
): Promise<AxiosResponse<APIResponse<{ logs: Log['data']; sphere: Pick<Sphere, 'name' | 'color' | 'access'> }>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateSphere = (
  data: Pick<Sphere, 'name' | 'color' | 'access'> & { oldName: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'oldName'),
    endpoint: `${endpoint}/${data.oldName}`,
    method: Methods.put,
  });

export const deleteSphere = (name: Sphere['name']): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${name}`,
    method: Methods.delete,
  });
