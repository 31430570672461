import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export const DEFAULT_DEBOUNCE_TIME = 300;

const useWindowResize = (debounceTime = DEFAULT_DEBOUNCE_TIME) => {
  const [windowSize, setWindowSize] = useState({ height: 0, width: 0 });
  const debouncedSetSize = useRef(
    debounce(() => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, debounceTime)
  );
  const handleSetSize = useCallback(() => debouncedSetSize.current(), [debouncedSetSize]);

  useEffect(() => {
    handleSetSize();
    window.addEventListener('resize', handleSetSize);
    return () => window.removeEventListener('resize', handleSetSize);
  }, [handleSetSize]);

  return windowSize;
};

export default useWindowResize;
