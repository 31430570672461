import { all, call, put, takeEvery } from 'typed-redux-saga';

import API, { apiCallHandler } from 'api';
import { isDefAndNotNull } from 'utils/def';

import {
  fetchMapperTypes,
  fetchResourceTypes,
  fetchSchemas,
  fetchServiceTypes,
  fetchSpheres,
  fetchStepTypes,
  fetchTriggerTypes,
} from './actions';

function* onFetchSchemas(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchSchemas.failure());
    },
    errMessageFallback: 'Failed to fetch schemas',
    tryHandler: function* () {
      const {
        data: {
          data: { schemas },
        },
      } = yield* call(API.schemas.fetchSchemas);

      yield put(fetchSchemas.success({ list: schemas }));
    },
  });
}

export function* onFetchSpheres(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchSpheres.failure());
    },
    errMessageFallback: 'Failed to fetch spheres',
    tryHandler: function* () {
      const {
        data: {
          data: { spheres },
        },
      } = yield* call(API.spheres.fetchSpheres);
      const list = Object.values(spheres).map((sphere) => ({
        ...sphere,
        color: isDefAndNotNull(sphere.color) && !sphere.color.startsWith('#') ? `#${sphere.color}` : sphere.color,
      }));

      yield put(fetchSpheres.success({ list }));
    },
  });
}

function* onFetchResourceTypes(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchResourceTypes.failure());
    },
    errMessageFallback: 'Failed to fetch resources',
    tryHandler: function* () {
      const {
        data: {
          data: { resources },
        },
      } = yield* call(API.resourceTypes.fetchResourceTypes);

      yield put(
        fetchResourceTypes.success({
          list: resources,
        })
      );
    },
  });
}

function* onFetchTriggerTypes(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchTriggerTypes.failure());
    },
    errMessageFallback: 'Failed to fetch triggers',
    tryHandler: function* () {
      const {
        data: {
          data: { triggers },
        },
      } = yield* call(API.triggerTypes.fetchTriggerTypes);

      yield put(
        fetchTriggerTypes.success({
          list: triggers,
        })
      );
    },
  });
}

function* onFetchStepTypes(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchStepTypes.failure());
    },
    errMessageFallback: 'Failed to fetch resources',
    tryHandler: function* () {
      const {
        data: {
          data: { steps },
        },
      } = yield* call(API.stepTypes.fetchStepTypes);

      yield put(
        fetchStepTypes.success({
          list: steps,
        })
      );
    },
  });
}

function* onFetchMapperTypes(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchMapperTypes.failure());
    },
    errMessageFallback: 'Failed to fetch mapper types',
    tryHandler: function* () {
      const {
        data: {
          data: { mapTypes },
        },
      } = yield* call(API.mapperTypes.fetchMapperTypes);

      yield put(fetchMapperTypes.success({ list: mapTypes }));
    },
  });
}

function* onFetchServiceTypes(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchServiceTypes.failure());
    },
    errMessageFallback: 'Failed to fetch services',
    tryHandler: function* () {
      const {
        data: {
          data: { services },
        },
      } = yield* call(API.serviceTypes.fetchServiceTypes);

      yield put(
        fetchServiceTypes.success({
          list: services,
        })
      );
    },
  });
}

function* appSaga() {
  yield all([
    takeEvery(fetchSchemas.request, onFetchSchemas),
    takeEvery(fetchSpheres.request, onFetchSpheres),
    takeEvery(fetchResourceTypes.request, onFetchResourceTypes),
    takeEvery(fetchTriggerTypes.request, onFetchTriggerTypes),
    takeEvery(fetchStepTypes.request, onFetchStepTypes),
    takeEvery(fetchMapperTypes.request, onFetchMapperTypes),
    takeEvery(fetchServiceTypes.request, onFetchServiceTypes),
  ]);
}

export default appSaga;
