import { omit, pick } from 'lodash';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';

import API, { apiCallHandler } from 'api';
import { VariableType } from 'models/Variable';
import { isDef } from 'utils/def';

import { modalClose } from '../modal/actions';

import {
  FetchVariablesRequestPayload,
  createVariable,
  deleteVariable,
  fetchContextVariables,
  fetchFlowVariables,
  fetchGlobalVariables,
  fetchInitiativeVariables,
  tryFetchContextVariablesActionName,
  tryFetchFlowVariablesActionName,
  tryFetchGlobalVariablesActionName,
  tryFetchInitiativeVariablesActionName,
  updateVariable,
} from './actions';
import { selectContextVariables, selectFlowVariables, selectGlobalVariables, selectInitiativeVariables } from './selectors';

function* onTryFetchGlobalVariables({ payload }: ReturnType<typeof fetchGlobalVariables.request>): Generator {
  const variables = yield select(selectGlobalVariables(payload.sphere, payload.project));
  if (!isDef(variables)) {
    yield put(fetchGlobalVariables.request(payload));
  }
}

function* onTryFetchContextVariables({ payload }: ReturnType<typeof fetchGlobalVariables.request>): Generator {
  const variables = yield select(selectContextVariables(payload.sphere, payload.project));
  if (!isDef(variables)) {
    yield put(fetchContextVariables.request(payload));
  }
}

function* onTryFetchInitiativeVariables({ payload }: ReturnType<typeof fetchInitiativeVariables.request>): Generator {
  const variables = yield select(selectInitiativeVariables(payload.sphere, payload.project, payload.bp));
  if (!isDef(variables)) {
    yield put(fetchInitiativeVariables.request(payload));
  }
}

function* onTryFetchFlowVariables({ payload }: ReturnType<typeof fetchFlowVariables.request>): Generator {
  const variables = yield select(selectFlowVariables(payload.sphere, payload.project, payload.bp, payload.flow));
  if (!isDef(variables)) {
    yield put(fetchFlowVariables.request(payload));
  }
}

function* onFetchGlobalVariables({ payload }: ReturnType<typeof fetchGlobalVariables.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchGlobalVariables.failure());
    },
    errMessageFallback: 'Failed to fetch global variables',
    tryHandler: function* () {
      const {
        data: { data },
      } = yield* call(API.globalVariables.fetchGlobalVariables, payload);

      yield put(fetchGlobalVariables.success({ ...payload, variables: data.variables }));
    },
  });
}

function* onFetchContextVariables({ payload }: ReturnType<typeof fetchContextVariables.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchContextVariables.failure());
    },
    errMessageFallback: 'Failed to fetch context variables',
    tryHandler: function* () {
      const {
        data: { data },
      } = yield* call(API.contextVariables.fetchVariables, payload);

      yield put(fetchContextVariables.success({ ...payload, variables: data.variables }));
    },
  });
}

export function* onFetchInitiativeVariables({ payload }: ReturnType<typeof fetchInitiativeVariables.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchInitiativeVariables.failure());
    },
    errMessageFallback: 'Failed to fetch initiative variables',
    tryHandler: function* () {
      const {
        data: { data },
      } = yield* call(API.businessProcessVariables.fetchBusinessProcessVariables, payload);

      yield put(fetchInitiativeVariables.success({ ...payload, variables: data.variables }));
    },
  });
}

export function* onFetchFlowVariables({ payload }: ReturnType<typeof fetchFlowVariables.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchFlowVariables.failure());
    },
    errMessageFallback: 'Failed to fetch flow variables',
    tryHandler: function* () {
      const {
        data: { data },
      } = yield* call(API.flowVariables.fetchFlowVariables, payload);

      yield put(fetchFlowVariables.success({ ...payload, variables: data.variables }));
    },
  });
}

function* refetchVariables(payload: FetchVariablesRequestPayload): Generator {
  switch (payload.variableType) {
    case VariableType.Global: {
      yield put(fetchGlobalVariables.request(pick(payload, ['sphere', 'project'])));
      break;
    }
    case VariableType.Context: {
      yield put(fetchContextVariables.request(pick(payload, ['sphere', 'project'])));
      break;
    }
    case VariableType.Initiative: {
      yield put(fetchInitiativeVariables.request(pick(payload, ['sphere', 'project', 'bp'])));
      break;
    }
    case VariableType.Flow: {
      yield put(fetchFlowVariables.request(pick(payload, ['sphere', 'project', 'bp', 'flow'])));
      break;
    }
  }
}

function* onCreateVariables({ payload }: ReturnType<typeof createVariable.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createVariable.failure());
    },
    errMessageFallback: 'Failed to create variable',
    tryHandler: function* () {
      switch (payload.variableType) {
        case VariableType.Global: {
          yield* call(API.globalVariables.createVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Context: {
          yield* call(API.contextVariables.createVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Initiative: {
          yield* call(API.businessProcessVariables.createVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Flow: {
          yield* call(API.flowVariables.createVariable, omit(payload, 'modalId'));
          break;
        }
      }

      if (isDef(payload.modalId)) {
        yield put(modalClose.request({ id: payload.modalId }));
      }
      yield put(createVariable.success());
      yield call(refetchVariables, pick(payload, ['project', 'sphere', 'flow', 'bp', 'variableType']));
    },
  });
}

function* onUpdateVariables({ payload }: ReturnType<typeof updateVariable.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateVariable.failure());
    },
    errMessageFallback: 'Failed to update variable',
    tryHandler: function* () {
      switch (payload.variableType) {
        case VariableType.Global: {
          yield* call(API.globalVariables.updateVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Context: {
          yield* call(API.contextVariables.updateVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Initiative: {
          yield* call(API.businessProcessVariables.updateVariable, omit(payload, 'modalId'));
          break;
        }
        case VariableType.Flow: {
          yield* call(API.flowVariables.updateVariable, omit(payload, 'modalId'));
          break;
        }
      }

      if (isDef(payload.modalId)) {
        yield put(modalClose.request({ id: payload.modalId }));
      }
      yield put(updateVariable.success());
      yield call(refetchVariables, pick(payload, ['project', 'sphere', 'flow', 'bp', 'variableType']));
    },
  });
}

function* onDeleteVariables({ payload }: ReturnType<typeof deleteVariable.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteVariable.failure());
    },
    errMessageFallback: 'Failed to delete variable',
    tryHandler: function* () {
      switch (payload.variableType) {
        case VariableType.Global: {
          yield* call(API.globalVariables.deleteVariable, {
            name: payload.name,
            project: payload.project,
            sphere: payload.sphere,
          });
          break;
        }
        case VariableType.Context: {
          yield* call(API.contextVariables.deleteVariable, {
            name: payload.name,
            project: payload.project,
            sphere: payload.sphere,
          });
          break;
        }
        case VariableType.Initiative: {
          yield* call(API.businessProcessVariables.deleteVariable, {
            bp: payload.bp,
            name: payload.name,
            project: payload.project,
            sphere: payload.sphere,
          });
          break;
        }
        case VariableType.Flow: {
          yield* call(API.flowVariables.deleteVariable, {
            bp: payload.bp,
            flow: payload.flow,
            name: payload.name,
            project: payload.project,
            sphere: payload.sphere,
          });
          break;
        }
      }
      yield put(deleteVariable.success());
      yield call(refetchVariables, pick(payload, ['project', 'sphere', 'flow', 'bp', 'variableType']));
    },
  });
}

function* variablesSaga() {
  yield all([
    takeEvery(tryFetchGlobalVariablesActionName, onTryFetchGlobalVariables),
    takeEvery(tryFetchContextVariablesActionName, onTryFetchContextVariables),
    takeEvery(tryFetchInitiativeVariablesActionName, onTryFetchInitiativeVariables),
    takeEvery(tryFetchFlowVariablesActionName, onTryFetchFlowVariables),
    takeEvery(fetchGlobalVariables.request, onFetchGlobalVariables),
    takeEvery(fetchContextVariables.request, onFetchContextVariables),
    takeEvery(fetchInitiativeVariables.request, onFetchInitiativeVariables),
    takeEvery(fetchFlowVariables.request, onFetchFlowVariables),
    takeEvery(createVariable.request, onCreateVariables),
    takeEvery(deleteVariable.request, onDeleteVariables),
    takeEvery(updateVariable.request, onUpdateVariables),
  ]);
}

export default variablesSaga;
