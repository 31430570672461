import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import SchemaIcon from '../SchemaIcon';
import Select from '../Select';

export const StyledSelect = styled(Select)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
` as typeof Select;
export const ResourceOptionValueContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  white-space: normal;
`;
export const ServiceOptionValueContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  white-space: normal;
`;
export const ResourceOptionValueIcon = styled(SchemaIcon)`
  margin-right: 16px;
  width: 20px;
  height: 20px;
  font-size: 20px;
`;
export const ServiceOptionValueIcon = styled(SchemaIcon)`
  margin-right: 16px;
  width: 20px;
  height: 20px;
  font-size: 20px;
`;
