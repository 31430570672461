import React, { FC } from 'react';

const Plus: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      id="Vector"
      d="M10.0013 4.16663V15.8333M4.16797 9.99996H15.8346"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Plus;
