import { createGlobalStyle } from 'styled-components';

import { flexStyle, scrollStyle } from '../../mixins';

export const Base = createGlobalStyle`
  html,
  body {
    ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
    ${scrollStyle()};
    flex: 1;
    min-height: 100%;
    width: 100%;
    font-family: Inter, Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.background};
  }

  div[id="root"] {
    ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
    width: 100%;
    flex: 1;
  }

  img,
  svg {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  a {
    color: ${({ theme }) => theme.colors.textPrimary};
    text-decoration: none;
  }

  input,
  textarea {
    outline: none;
    border: 0;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 0;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none; /* for button */
    -moz-user-select: none;
    -ms-user-select: none;
  }

  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  strong {
    font-weight: 500;
  }

  @media screen and (max-width: 1140px) {
    div[id="root"] {
      width: 1140px;
      overflow: scroll;
    }
  }
`;

export default Base;
