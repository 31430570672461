import React, { FC } from 'react';

const Attention: FC = () => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M11.332 2.22664C12.3375 2.80721 13.174 3.64032 13.7586 4.6435C14.3432 5.64669 14.6556 6.78515 14.665 7.9462C14.6744 9.10724 14.3803 10.2506 13.812 11.2631C13.2437 12.2756 12.4209 13.1221 11.4249 13.7188C10.4289 14.3156 9.29428 14.6419 8.13343 14.6653C6.97259 14.6888 5.82573 14.4087 4.8064 13.8528C3.78707 13.2968 2.93061 12.4843 2.32181 11.4956C1.71301 10.507 1.37297 9.37644 1.33536 8.21597L1.33203 7.99997L1.33536 7.78397C1.3727 6.63263 1.70773 5.51061 2.30779 4.5273C2.90785 3.54399 3.75247 2.73294 4.75929 2.17322C5.76612 1.61351 6.9008 1.32423 8.0527 1.33359C9.20461 1.34295 10.3344 1.65063 11.332 2.22664ZM8.00536 9.99997L7.9207 10.0046C7.75867 10.0239 7.60933 10.1019 7.50097 10.2239C7.39262 10.346 7.33277 10.5035 7.33277 10.6666C7.33277 10.8298 7.39262 10.9873 7.50097 11.1093C7.60933 11.2313 7.75867 11.3094 7.9207 11.3286L7.9987 11.3333L8.08336 11.3286C8.2454 11.3094 8.39474 11.2313 8.50309 11.1093C8.61144 10.9873 8.67129 10.8298 8.67129 10.6666C8.67129 10.5035 8.61144 10.346 8.50309 10.2239C8.39474 10.1019 8.2454 10.0239 8.08336 10.0046L8.00536 9.99997ZM7.9987 4.66664C7.83541 4.66666 7.67781 4.72661 7.55578 4.83511C7.43376 4.94362 7.3558 5.09314 7.3367 5.2553L7.33203 5.3333V7.99997L7.3367 8.07797C7.35597 8.24 7.434 8.38934 7.55601 8.49769C7.67801 8.60605 7.83552 8.66589 7.9987 8.66589C8.16187 8.66589 8.31938 8.60605 8.44139 8.49769C8.56339 8.38934 8.64142 8.24 8.6607 8.07797L8.66536 7.99997V5.3333L8.6607 5.2553C8.64159 5.09314 8.56364 4.94362 8.44161 4.83511C8.31959 4.72661 8.16199 4.66666 7.9987 4.66664Z"
      fill="currentColor"
    />
  </svg>
);

export default Attention;
