import { isDef } from 'utils/def';

import { TreeItem, TreeItemIndex } from './types';

export const getParentForNode = (node: TreeItem | undefined, nodes: TreeItem[], parentNode?: TreeItem): TreeItem | undefined => {
  if (!isDef(node) || !isDef(nodes)) {
    return undefined;
  }
  for (const item of nodes) {
    if (node.index === item.index) {
      return parentNode;
    }
    if (item.children) {
      const result = getParentForNode(node, item.children, item);
      if (isDef(result)) {
        return result;
      }
    }
  }
  return undefined;
};

export const convertToTreeItems = <T>(
  items: T[] | undefined,
  getIndex: (item: T, parentIndex: TreeItemIndex | undefined) => TreeItemIndex,
  getName: (item: T) => TreeItem['name'],
  getType: (item: T) => TreeItem['type'],
  getChildren: (item: T) => T[] | undefined,
  parentIndex?: TreeItemIndex
): TreeItem[] => {
  return isDef(items)
    ? items.map((item) => {
        const index = getIndex(item, parentIndex);
        return {
          children: convertToTreeItems(getChildren(item), getIndex, getName, getType, getChildren, index),
          data: item,
          index,
          name: getName(item),
          type: getType(item),
        };
      })
    : [];
};
