import { Row } from '@tanstack/react-table';
import { isEqual, isObject, upperFirst } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Button, { StyleTypes } from 'components/Button';
import SchemaArray from 'components/JsonSchemaRenderer/components/SchemaArrayRenderer';
import Tabs from 'components/Tabs';

import { ArraySchema, FormConfig, FormFields, formatBeforeSubmit, normalizeFields, schemaToFormConfig } from 'jsonSchema';
import { SERVICE_PATHS_FIELD_NAME } from 'models/Service/constants';
import { modalClose } from 'store/modal/actions';

import ParametersList from './components/ParametersList';
import { PARAMETERS_LIST_ID } from './constants';
import { ParameterTypes, ViewModes } from './enums';
import { DataContainer, ParametersView, PathsView, Root, SubmitButton, SubmitButtonsContainer } from './styles';
import { Props } from './types';
import { PathParametersType } from './types';
import { findParameterSchema, getActualParameters, getArrayFromFields, getParameterType, getTabKey } from './utils';

const initialProperties = {
  [ParameterTypes.cookies]: [],
  [ParameterTypes.response]: [],
  [ParameterTypes.query]: [],
  [ParameterTypes.request]: [],
  [ParameterTypes.headers]: [],
};

const PathParametersList: React.FC<Props> = ({ onCloseClick, onSubmit, schema, service, viewMode }) => {
  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState<PathParametersType | undefined>();
  const [activeTabKey, setActiveTabKey] = useState<string>(ParameterTypes.query);
  const [updatedParameters, setUpdatedParameters] = useState<Record<string, PathParametersType>>({});
  const [isFormValuesDirty, setIsFormValuesDirty] = useState<boolean>(false);

  const memoServiceFormConfig = useMemo(() => schemaToFormConfig(schema), [schema]);
  const pathsSchema = useMemo(
    () => memoServiceFormConfig.find((item) => item.name === SERVICE_PATHS_FIELD_NAME)?.fieldSchema as ArraySchema,
    [memoServiceFormConfig]
  );
  const activeParametersConfig = useMemo(
    () => (pathsSchema ? (findParameterSchema(pathsSchema) as FormConfig) : undefined),
    [pathsSchema]
  );
  const pathsConfig = useMemo(() => schemaToFormConfig(pathsSchema), [pathsSchema]);
  const pathsField = useMemo(
    () => (pathsConfig?.[0] ? (service.data[SERVICE_PATHS_FIELD_NAME] as FormFields[]) : undefined),
    [pathsConfig, service.data]
  );
  const formDefaultValues = useMemo(() => {
    const formDefaultValues = pathsField ? normalizeFields(pathsField, pathsConfig) : undefined;
    if (formDefaultValues) {
      const fields = getArrayFromFields(formDefaultValues);
      if (fields) {
        return {
          [SERVICE_PATHS_FIELD_NAME]: fields.map((field) => ({
            ...field,
            customId: uuidv4(),
          })),
        } as FormFields;
      }
    }
  }, [pathsConfig, pathsField]);
  const methods = useForm<FormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const isParametersDataDirty = useMemo(() => !!Object.keys(updatedParameters).length, [updatedParameters]);
  const { formState, getValues, setValue } = methods;
  const isDisabled = useMemo(
    () => (!formState.isValid || !formState.isDirty) && !isParametersDataDirty && !isFormValuesDirty,
    [formState.isValid, formState.isDirty, isParametersDataDirty, isFormValuesDirty]
  );
  const handleParametersChange = useCallback(
    (values: Partial<PathParametersType['properties']>) => {
      if (activeField) {
        setUpdatedParameters((prev) => {
          const newProperties = { ...getActualParameters(activeField, prev).properties };
          const parameterType = getParameterType(activeTabKey);
          if (values[parameterType]) {
            newProperties[parameterType] = values[parameterType]!;
            const v = {
              ...(prev || {}),
              ...{ [activeField.customId]: { ...activeField, properties: newProperties } },
            };
            return v;
          }
          return prev;
        });
      }
    },
    [activeField, activeTabKey]
  );
  const tabsItems = useMemo(() => {
    return [
      ParameterTypes.query,
      ParameterTypes.headers,
      ParameterTypes.cookies,
      ParameterTypes.request,
      ParameterTypes.response,
    ].map((key) => {
      const activeParameterTypeData = activeField
        ? getActualParameters(activeField, updatedParameters)?.properties[key]
        : undefined;
      const activeParametersSchema = activeParametersConfig?.find((config) => config.name === key)?.fieldSchema as ArraySchema;
      return {
        key: getTabKey(key, activeField?.customId),
        label: upperFirst(key),
        view:
          activeParametersSchema && activeParameterTypeData ? (
            <ParametersList
              value={activeParameterTypeData}
              schema={activeParametersSchema}
              onValuesChange={handleParametersChange}
              viewMode={viewMode}
              key={`${getTabKey(key, activeField?.customId)}-view`}
            />
          ) : (
            <></>
          ),
      };
    });
  }, [activeField, activeParametersConfig, handleParametersChange, updatedParameters, viewMode]);
  const handleRowClick = useCallback(
    (row: Row<FormFields>) => {
      let value = row.original as PathParametersType;
      if (!isObject(value.properties)) {
        value = { ...value, properties: { ...initialProperties } };
      }
      setActiveField(value);
      if (activeTabKey) {
        const type = getParameterType(activeTabKey);
        setActiveTabKey(getTabKey(type, value.customId));
      }
    },
    [activeTabKey]
  );

  const handleSetFormValuesCheck = useCallback(
    (values: FormFields) => {
      const defaultValuesFormatted = formatBeforeSubmit(formState.defaultValues as FormFields, pathsConfig);
      const dataFormatted = formatBeforeSubmit(values as FormFields, pathsConfig);
      setIsFormValuesDirty(!isEqual(defaultValuesFormatted, dataFormatted));
    },
    [pathsConfig, formState.defaultValues]
  );
  const handleRowDelete = useCallback(
    (value: FormFields) => {
      const data = getValues();
      const values = getArrayFromFields(data);
      const valueIndex = values.findIndex((item) => item.customId === value.customId);
      const newValues = [...values.slice(0, valueIndex), ...values.slice(valueIndex + 1, values.length)];
      setValue(SERVICE_PATHS_FIELD_NAME, newValues);
      if (activeField?.customId === value.customId) {
        setActiveField(undefined);
        setActiveTabKey(ParameterTypes.query);
      }
      handleSetFormValuesCheck({ [SERVICE_PATHS_FIELD_NAME]: newValues });
    },
    [setValue, getValues, activeField, handleSetFormValuesCheck]
  );
  const handleRowSubmit = useCallback(
    (newValue: FormFields, prevValue: FormFields | undefined, modalId: string) => {
      const data = getValues();
      const values = getArrayFromFields(data);
      if (!prevValue) {
        newValue.customId = uuidv4();
        newValue.properties = { ...initialProperties };
        values.push(newValue);
      } else {
        const valueIndex = values.findIndex((item) => item.customId === prevValue.customId);
        values[valueIndex] = { ...newValue, customId: prevValue.customId };
      }
      setValue(SERVICE_PATHS_FIELD_NAME, values);
      dispatch(modalClose.request({ id: modalId }));
      handleSetFormValuesCheck(data);
    },
    [dispatch, setValue, getValues, handleSetFormValuesCheck]
  );
  const handleSubmit = useCallback(() => {
    const formFields = getValues();
    const extendedFields: FormFields[] = getArrayFromFields(formFields).map((field) =>
      getActualParameters(field as PathParametersType, updatedParameters)
    );
    const fields = formatBeforeSubmit({ [SERVICE_PATHS_FIELD_NAME]: extendedFields }, pathsConfig) as FormFields[];
    onSubmit(fields);
  }, [getValues, onSubmit, pathsConfig, updatedParameters]);

  return (
    <Root>
      <DataContainer>
        <PathsView viewMode={viewMode}>
          <FormProvider {...methods}>
            <SchemaArray
              schema={pathsSchema}
              skipPropNames={['properties']}
              onRowClick={handleRowClick}
              hasRowAdd={viewMode !== ViewModes.VIEW}
              hasRowEdit={viewMode !== ViewModes.VIEW}
              hasRowDelete={viewMode !== ViewModes.VIEW}
              onRowSubmit={handleRowSubmit}
              onRowDelete={handleRowDelete}
            />
          </FormProvider>
        </PathsView>
        <ParametersView>
          <Tabs
            id={PARAMETERS_LIST_ID}
            items={tabsItems}
            onTabChange={setActiveTabKey}
            defaultKey={activeTabKey}
            showCount={false}
          />
        </ParametersView>
      </DataContainer>
      <SubmitButtonsContainer>
        {viewMode === ViewModes.EDIT && (
          <SubmitButton onClick={handleSubmit} disabled={isDisabled} loading={false} label="Save" />
        )}
        <Button label="Cancel" onClick={onCloseClick} styleType={StyleTypes.link} />
      </SubmitButtonsContainer>
    </Root>
  );
};

export * from './enums';
export * from './constants';

export default PathParametersList;
