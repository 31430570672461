import React, { FC } from 'react';

const ArrowRight: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M4.16797 10H15.8346M15.8346 10L10.8346 15M15.8346 10L10.8346 5"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRight;
