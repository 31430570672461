import styled from 'styled-components';

import { flexStyle, scrollStyle } from 'styles/mixins';

export const Root = styled.div`
  ${scrollStyle()};
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  padding-top: 8px;
`;
export const LoaderContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'center' })};
  flex: 1;
`;
