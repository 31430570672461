import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { FormFieldFileValueType } from 'jsonSchema';
import { Branch } from 'models/Branch';
import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Step } from 'models/Step';
import { requestDataToFormData } from 'utils/converters';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'steps';

export const createStep = (data: {
  bp: Initiative['name'];
  branch?: Branch['name'];
  data: Pick<Step, 'name' | 'type' | 'config'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step?: Step['name']; // Parent Step
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateStep = (data: {
  bp: Initiative['name'];
  data: Step;
  flow: Flow['name'];
  ignoreValidation?: boolean;
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'step'),
    endpoint: `${endpoint}/${data.step}`,
    method: Methods.patch,
  });

export const updateStepConfig = (data: {
  bp: Initiative['name'];
  data: Pick<Step, 'config'>;
  flow: Flow['name'];
  ignoreValidation?: boolean;
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'step'),
    endpoint: `${endpoint}/${data.step}`,
    method: Methods.patch,
  });

export const updateStepDataMapperConfig = (data: {
  bp: Initiative['name'];
  data: Step;
  files?: FormFieldFileValueType[];
  flow: Flow['name'];
  ignoreValidation?: boolean;
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: omit(data, 'step') }, data.files),
    endpoint: `${endpoint}/${data.step}`,
    method: Methods.patch,
  });

export const removeStep = (data: {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Pick<Step, 'name' | 'type'>;
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${data.step.name}`,
    method: Methods.delete,
    params: omit(data, 'step'),
  });
