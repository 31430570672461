import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { StyleTypes } from 'components/Button';
import { ICONS_TYPES } from 'components/Icon';
import { Modes } from 'components/Modal';

import { Service } from 'models/Service';
import { modalOpen } from 'store/modal/actions';
import { createService } from 'store/projectServices/actions';
import { selectProjectServices } from 'store/projectServices/selectors';

import ServicesList from '../ServicesList';
import { CREATE_SERVICE_MODAL_ID } from '../ServicesList/constants';

import { ButtonContainer, Root } from './styles';
import { Props } from './types';

const ServicesSection: FC<Props> = ({ className, projectName, sphereName }) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector(selectProjectServices);

  const handleOpenServiceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: CREATE_SERVICE_MODAL_ID, mode: Modes.rightSlide }));
  }, [dispatch]);
  const handleCreateService = useCallback(
    (service: Service) => {
      dispatch(
        createService.request({
          data: {
            ...service,
            project: projectName,
            sphere: sphereName,
          },
        })
      );
    },
    [dispatch, sphereName, projectName]
  );

  return (
    <Root className={className}>
      <ServicesList onCreate={handleCreateService} projectName={projectName} sphereName={sphereName} />
      <ButtonContainer>
        <Button
          label="Add Service"
          leftIcon={{ size: 20, type: ICONS_TYPES.Plus }}
          loading={updateLoading}
          onClick={handleOpenServiceModal}
          styleType={StyleTypes.outlined}
        />
      </ButtonContainer>
    </Root>
  );
};

export default ServicesSection;
