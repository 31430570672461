import { all, fork } from 'typed-redux-saga';

import componentsSaga from 'components/componentsSaga';

import pagesSaga from 'pages/pagesSaga';

import appSaga from './app/saga';
import modalSaga from './modal/saga';
import projectResourcesSaga from './projectResources/saga';
import projectServicesSaga from './projectServices/saga';
import projectTagsSaga from './projectTags/saga';
import variablesSaga from './variables/saga';

function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(modalSaga),
    fork(pagesSaga),
    fork(projectResourcesSaga),
    fork(projectTagsSaga),
    fork(projectServicesSaga),
    fork(componentsSaga),
    fork(variablesSaga),
  ]);
}

export default rootSaga;
