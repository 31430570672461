import styled from 'styled-components';

import { textStyle } from 'styles/mixins';

export const Root = styled.div`
  h1 {
    ${textStyle({ color: 'red', lineHeight: 1.2, size: '22px', textAlign: 'left', weight: 400 })};
  }
`;
export const ErrorMessageContainer = styled.div`
  ${textStyle({ color: 'darkGrey', lineHeight: 1.2, size: '14px', textAlign: 'left', weight: 400 })};
  white-space: pre;
  padding: 0 20px;
`;
