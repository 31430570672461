import { isObject } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetFormFieldPrefix } from 'hooks/useGetFormFieldPrefix';
import { FormField, FormFieldSubTypes, FormFieldTypes, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef } from 'utils/def';

import { useGetSubTypeProps } from '../../FormArrayRenderer/hooks/useGetArraySubTypeProps';
import { FormFieldsType } from '../../FormFieldRenderer/types';

export const useSetValuesFromSubTypeSelection = <T extends FormFields>(field: FormField) => {
  const { clearErrors, setValue } = useFormContext<FormFieldsType>();
  const fieldNamePrefix = useGetFormFieldPrefix(field);
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const setFormValues = useCallback(
    (selection: T) => {
      if (isDef(subTypeProps) && isDef(subTypeProps?.outputMapping)) {
        Object.keys(subTypeProps.outputMapping || {}).forEach((key) => {
          if (isDef(field.subTypeProps) && isDef(subTypeProps.outputMapping) && isObject(subTypeProps.outputMapping)) {
            const { fieldName, type } = subTypeProps.outputMapping[key];
            const targetFieldName = fieldNamePrefix ? `${fieldNamePrefix}.${key}` : key;
            let value = selection[fieldName];
            switch (type) {
              case FormFieldTypes.select: {
                value = { label: value, value };
                break;
              }
            }
            clearErrors(targetFieldName);
            setValue(targetFieldName, value);
          }
        });
      }
    },
    [subTypeProps, field.subTypeProps, fieldNamePrefix, clearErrors, setValue]
  );

  return { setFormValues };
};
