import { toast } from 'react-toastify';
import { push, replace } from 'redux-first-history';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import { slideClose } from 'components/FlowNavMenu/store/actions';

import API, { apiCallHandler } from 'api';
import { modalClose } from 'store/modal/actions';

import { DELETE_INITIATIVE_CONFIRM_MODAL_ID, EDIT_INITIATIVE_MODAL_ID } from '../constant';

import { deleteInitiative, fetchInitiative, updateInitiative } from './actions';

function* onFetchInitiative({ payload: { data } }: ReturnType<typeof fetchInitiative.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchInitiative.failure());
    },
    errMessageFallback: 'Failed to fetch businessProcess',
    tryHandler: function* () {
      const {
        data: {
          data: { businessProcess },
        },
      } = yield* call(API.businessProcesses.fetchInitiative, data);

      yield put(fetchInitiative.success({ businessProcess }));
    },
  });
}

function* onUpdateInitiative({ payload: { data } }: ReturnType<typeof updateInitiative.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateInitiative.failure());
    },
    errMessageFallback: 'Failed to update initiative',
    tryHandler: function* () {
      yield* call(API.businessProcesses.updateInitiative, data);
      yield* onFetchInitiative({
        payload: { data: { initiative: data.data.name, project: data.project, sphere: data.sphere } },
        type: getType(fetchInitiative.request),
      });
      yield put(updateInitiative.success());

      yield put(slideClose.request({ id: EDIT_INITIATIVE_MODAL_ID }));
      yield put(
        replace(
          `/spheres/${data.sphere}/projects/${data.project}/initiative/${data.data.name}${data.flow ? `/flow/${data.flow}` : ''}`
        )
      );
      yield call(toast.success, `The initiative '${data.data.oldName}' was successfully updated`);
    },
  });
}

function* onDeleteInitiative({ payload }: ReturnType<typeof deleteInitiative.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteInitiative.failure());
    },
    errMessageFallback: 'Failed to delete initiative',
    tryHandler: function* () {
      yield* call(API.businessProcesses.deleteInitiative, payload);

      yield put(deleteInitiative.success());
      yield put(slideClose.request({ id: EDIT_INITIATIVE_MODAL_ID }));
      yield put(modalClose.request({ id: DELETE_INITIATIVE_CONFIRM_MODAL_ID }));
      yield put(push(`/spheres/${payload.sphere}/projects/${payload.project}`));
      yield call(toast.success, `The initiative '${payload.initiative}' was successfully deleted`);
    },
  });
}

function* initiativeSaga() {
  yield all([
    takeEvery(fetchInitiative.request, onFetchInitiative),
    takeEvery(updateInitiative.request, onUpdateInitiative),
    takeEvery(deleteInitiative.request, onDeleteInitiative),
  ]);
}

export default initiativeSaga;
