import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { modalClose } from 'store/modal/actions';
import {
  selectModalClosingClass,
  selectModalData,
  selectModalIsAnimated,
  selectModalIsAnyOpen,
  selectModalIsOpen,
  selectModalMode,
} from 'store/modal/selectors';
import { buildDataCyValue } from 'utils/cy';
import { isDefAndNotNull } from 'utils/def';

import Button, { StyleTypes } from '../Button';
import { ICONS_TYPES } from '../Icon';

import ModalContext from './context';
import { Modes, Sizes } from './enums';
import {
  ActionsContainer,
  CloseIcon,
  ConfirmButton,
  ConfirmButtonsContainer,
  ConfirmContainer,
  ConfirmText,
  Content,
  Header,
  Root,
  Title,
} from './styles';
import { ModalDataType, Props } from './types';

const Modal = <TData extends ModalDataType>({
  className = '',
  confirm,
  confirmButtonProps,
  hideConfirmButton,
  hideXMark = false,
  id,
  onClose,
  overflowY = 'auto',
  renderActions,
  renderChildren,
  renderTitle,
  size = Sizes.small,
}: Props<TData>) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const isOpen = useSelector(selectModalIsOpen(id));
  const data = useSelector(selectModalData(id)) as TData;
  const mode = useSelector(selectModalMode(id));
  const animated = useSelector(selectModalIsAnimated(id));
  const closingClass = useSelector(selectModalClosingClass(id));
  const isAnyOpen = useSelector(selectModalIsAnyOpen);
  const [startClosing, setStartClosing] = useState(false);

  const handleCloseModal = useCallback(() => {
    dispatch(modalClose.request({ callBack: onClose, id }));
  }, [dispatch, id, onClose]);
  const handleClickOutside = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target === event.currentTarget && startClosing) {
        handleCloseModal();
      }
    },
    [handleCloseModal, startClosing]
  );
  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setStartClosing(event.target === event.currentTarget);
    },
    [setStartClosing]
  );

  useEffect(() => {
    document.body.style.overflow = isAnyOpen ? 'hidden' : 'visible';
  }, [isAnyOpen]);

  if (!isOpen) return null;

  const root = (
    <Root
      animated={animated}
      className={`${className}${closingClass}`}
      mode={mode}
      onMouseDown={handleMouseDown}
      onClick={handleClickOutside}
    >
      <Content
        animated={animated}
        className={closingClass}
        ref={contentRef}
        size={size}
        mode={mode}
        overflowY={overflowY}
        data-cy={buildDataCyValue({ elementName: 'window', fieldName: 'modal' })}
      >
        <ModalContext.Provider value={{ modalContentRootRef: contentRef }}>
          {(!hideXMark || isDefAndNotNull(renderTitle)) && (
            <Header>
              {isDefAndNotNull(renderTitle) && <Title>{renderTitle(data)}</Title>}
              <ActionsContainer>
                {isDefAndNotNull(renderActions) && renderActions(data)}
                {!hideXMark && <CloseIcon onClick={handleCloseModal} icon={ICONS_TYPES.XMark} size={20} />}
              </ActionsContainer>
            </Header>
          )}
          {confirm ? (
            <ConfirmContainer>
              <ConfirmText>{renderChildren(data)}</ConfirmText>
              <ConfirmButtonsContainer>
                <Button label="Cancel" onClick={handleCloseModal} styleType={StyleTypes.link} />
                {!hideConfirmButton && <ConfirmButton {...confirmButtonProps?.(data)} />}
              </ConfirmButtonsContainer>
            </ConfirmContainer>
          ) : (
            renderChildren(data)
          )}
        </ModalContext.Provider>
      </Content>
    </Root>
  );

  return ReactDOM.createPortal(root, document.body);
};

export type { Props };
export { Sizes, Modes };
export * from './constants';

export default Modal;
