import styled from 'styled-components';

import Icon from 'components/Icon';
import SchemaIcon from 'components/SchemaIcon';
import Tooltip from 'components/Tooltip';

import { flexStyle, textStyle } from 'styles/mixins';
import { isDefAndNotNull } from 'utils/def';

import { Props } from './types';

export const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
  display: none;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;
export const Root = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'center', justify: 'space-between', wrap: 'nowrap' })};
  height: 50px;
  background-color: ${({ theme }) => theme.colors.surfacePrimary};
  border-radius: 4px;
  padding: 8px 8px;
  border-right: 4px solid
    ${({ model, theme }) => {
      if (isDefAndNotNull(model?.isValidated)) {
        return model?.isValidated ? theme.colors.green : theme.colors.red;
      }

      return theme.colors.surfacePrimary;
    }};

  &:hover > ${EditIcon} {
    display: initial;
  }
`;
export const ServiceContent = styled.div`
  ${flexStyle({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
  max-width: 80%;
`;
export const ServiceIcon = styled(SchemaIcon)`
  margin-right: 16px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  flex-shrink: 0;
`;
export const ServiceName = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const RemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
`;
export const RemoveIconTooltip = styled(Tooltip)`
  margin-right: 16px;
`;
