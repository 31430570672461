import { RowSelectionState } from '@tanstack/table-core/src/features/RowSelection';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFieldArray, FormFieldSubTypes, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from '../../../../FormArrayRenderer/hooks/useGetArraySubTypeProps';

export const useGetTableSelection = (field: FormFieldArray) => {
  const { getValues } = useFormContext<FormFields>();
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const key = useMemo(() => subTypeProps?.dataSource?.key, [subTypeProps]);
  const getRowKeyName = useCallback(
    (row: FormFields): string => {
      if (isDef(field.items)) {
        let keyName = JSON.stringify(row);
        if (isDef(key)) {
          const property = row[key];
          if (isDefAndNotNull(property)) {
            keyName = property.toString();
          }
        }
        return keyName;
      } else {
        return row.toString();
      }
    },
    [key, field]
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(() => {
    const fieldValue = (getValues(field.name) || []) as FormFields[];
    return (Array.isArray(fieldValue) ? fieldValue : [fieldValue]).reduce((memo: RowSelectionState, curr: FormFields) => {
      const key = getRowKeyName(curr);
      return {
        ...memo,
        [key]: true,
      };
    }, {});
  });

  return { rowSelection, setRowSelection };
};
