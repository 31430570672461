import { createAction, createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

export type FetchFlowsRequestPayload = {
  bp: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchFlowsSuccessPayload = {
  flows: Flow[];
};
export const fetchFlows = createAsyncAction(
  'flowTabs/fetchFlows/request',
  'flowTabs/fetchFlows/success',
  'flowTabs/fetchFlows/failure'
)<FetchFlowsRequestPayload, FetchFlowsSuccessPayload, undefined>();

export type CreateFlowRequestPayload = {
  bp: Initiative['name'];
  data: Pick<Flow, 'name' | 'description'>;
  project: Project['name'];
  sphere: Sphere['name'];
};
export const createFlow = createAsyncAction(
  'flowTabs/CreateFlow/request',
  'flowTabs/CreateFlow/success',
  'flowTabs/CreateFlow/failure'
)<CreateFlowRequestPayload, undefined, undefined>();

export type DeleteFlowRequestPayload = {
  bp: Initiative['name'];
  currentUrlFlowName: Flow['name'] | undefined;
  name: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const deleteFlow = createAsyncAction(
  'flowTabs/deleteFlow/request',
  'flowTabs/deleteFlow/success',
  'flowTabs/deleteFlow/failure'
)<DeleteFlowRequestPayload, undefined, undefined>();

export type TabOpenPayload = {
  flowName: Flow['name'];
  initiativeName: Initiative['name'];
};
export const tabOpen = createAction('flowTabs/tabOpen')<TabOpenPayload>();

export type TabClosePayload = {
  bp: Initiative['name'];
  currentUrlFlowName: Flow['name'] | undefined;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const tabClose = createAsyncAction('flowTabs/close/request', 'flowTabs/close/success', 'flowTabs/close/failure')<
  TabClosePayload,
  TabClosePayload,
  TabClosePayload
>();

export type SetFlowsPreviewUrl = {
  flow: Flow['name'];
  previewObjectUrl: string;
}[];
export const setFlowsPreviewUrl = createAction('flowTabs/setFlowsPreviewUrl')<SetFlowsPreviewUrl>();
