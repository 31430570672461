import SuccessDarkImg from 'assets/images/successDark.gif';
import SuccessLightImg from 'assets/images/successLight.gif';

import { Theme } from './types';

export const commonColors: Record<string, string> = {
  black: `#000`,
  blue: '#6296E3',
  canvasHeader: '#33333d',
  green: '#2ab571',
  lightBlue: '#a7e8ff',
  orange: '#ff800b',
  pink: '#ff83ac',
  red: '#f1536e',
  secondaryBackground: '#f2f3f3',
  white: '#fff',
};

const theme: Theme = {
  dark: {
    colors: {
      background: '#242424',
      divider: '#2c2c2c',
      green: '#2ab571',
      grey: '#444346',
      primary: '#6e62e3',
      primaryDark: '#4235bf',
      primaryLight: '#6e62e31a',

      surfacePrimary: '#1c1c1c',
      surfaceSecondary: '#363538',
      textPrimary: '#fff',
      textSecondary: '#8c8b90',
      textTertiary: '#68686b',

      ...commonColors,
    },
    images: {
      success: SuccessDarkImg,
    },
  },
  light: {
    colors: {
      background: '#fff',
      divider: '#e4e4e4',
      grey: '#b7b7b7',
      primary: '#1c2134',
      primaryDark: '#090b13',
      primaryLight: '#493c931a',

      surfacePrimary: '#f7f8fA',
      surfaceSecondary: '#e8e8e8',
      textPrimary: '#1c1c1c',
      textSecondary: '#4e4e53',
      textTertiary: '#8f8f95',

      ...commonColors,
    },
    images: {
      success: SuccessLightImg,
    },
  },
};

export default theme;
