import styled from 'styled-components';

import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';

import { flexStyle } from 'styles/mixins';

import { ViewModes } from './enums';

export const Root = styled.form`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
`;
export const FormFieldsContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  margin-bottom: 48px;
`;
export const StyledInput = styled(Input)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const StyledSelect = styled(Select)<{ mode?: ViewModes }>`
  width: 100%;
` as typeof Select;
export const SelectContainer = styled.div<{ mode?: ViewModes }>`
  ${flexStyle({ justify: 'space-between', wrap: 'nowrap' })};
  margin-bottom: ${({ mode }) => {
    if (mode === ViewModes.create) {
      return '40px';
    }
    return '24px';
  }};
`;
export const ButtonsCreateContainer = styled.div`
  ${flexStyle({ justify: 'space-between', wrap: 'nowrap' })};
  flex-grow: 1;
`;
export const ButtonsEditContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-end', wrap: 'nowrap' })};
  flex-grow: 1;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
  margin-bottom: 16px;
  width: 100%;
`;
