import { ActionType, getType } from 'typesafe-actions';

import { THEME_MODE_LS_KEY } from 'mainConstants';
import { ThemeModes } from 'styles/enums';

import * as appActions from './actions';

type AppActionType = ActionType<typeof appActions>;

export type AppState = {
  mapperTypes: {
    list: appActions.FetchMapperTypesSuccessPayload['list'];
    loading: boolean;
  };
  resourceTypes: {
    list: appActions.FetchResourceTypesSuccessPayload['list'];
    loading: boolean;
  };
  schemas: {
    list: appActions.FetchSchemasSuccessPayload['list'];
    loading: boolean;
  };
  serviceTypes: {
    list: appActions.FetchServiceTypesSuccessPayload['list'];
    loading: boolean;
  };
  spheres: {
    list: appActions.FetchSpheresSuccessPayload['list'];
    loading: boolean;
  };
  stepTypes: {
    list: appActions.FetchStepTypesSuccessPayload['list'];
    loading: boolean;
  };
  themeMode: ThemeModes;
  triggerTypes: {
    list: appActions.FetchTriggerTypesSuccessPayload['list'];
    loading: boolean;
  };
};

const initialState: AppState = {
  mapperTypes: {
    list: {
      destination: [],
      source: [],
    },
    loading: false,
  },
  resourceTypes: {
    list: [],
    loading: false,
  },
  schemas: {
    list: [],
    loading: false,
  },
  serviceTypes: {
    list: [],
    loading: false,
  },
  spheres: {
    list: [],
    loading: true,
  },
  stepTypes: {
    list: [],
    loading: false,
  },
  themeMode: (window.localStorage.getItem(THEME_MODE_LS_KEY) as ThemeModes) || ThemeModes.light,
  triggerTypes: {
    list: [],
    loading: false,
  },
};

const appReducer = (state: AppState = initialState, action: AppActionType): AppState => {
  switch (action.type) {
    case getType(appActions.setThemeMode):
      return {
        ...state,
        themeMode: action.payload.mode,
      };

    case getType(appActions.fetchSchemas.request):
      return {
        ...state,
        schemas: {
          ...state.schemas,
          loading: true,
        },
      };
    case getType(appActions.fetchSchemas.success):
      return {
        ...state,
        schemas: {
          ...state.schemas,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchSchemas.failure):
      return {
        ...state,
        schemas: {
          ...state.schemas,
          loading: false,
        },
      };

    case getType(appActions.fetchSpheres.request):
      return {
        ...state,
        spheres: {
          ...state.spheres,
          loading: true,
        },
      };
    case getType(appActions.fetchSpheres.success):
      return {
        ...state,
        spheres: {
          ...state.spheres,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchSpheres.failure):
      return {
        ...state,
        spheres: {
          ...state.spheres,
          loading: false,
        },
      };

    case getType(appActions.fetchResourceTypes.request):
      return {
        ...state,
        resourceTypes: {
          ...state.resourceTypes,
          loading: true,
        },
      };
    case getType(appActions.fetchResourceTypes.success):
      return {
        ...state,
        resourceTypes: {
          ...state.resourceTypes,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchResourceTypes.failure):
      return {
        ...state,
        resourceTypes: {
          ...state.resourceTypes,
          loading: false,
        },
      };

    case getType(appActions.fetchTriggerTypes.request):
      return {
        ...state,
        triggerTypes: {
          ...state.triggerTypes,
          loading: true,
        },
      };
    case getType(appActions.fetchTriggerTypes.success):
      return {
        ...state,
        triggerTypes: {
          ...state.triggerTypes,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchTriggerTypes.failure):
      return {
        ...state,
        triggerTypes: {
          ...state.triggerTypes,
          loading: false,
        },
      };

    case getType(appActions.fetchStepTypes.request):
      return {
        ...state,
        stepTypes: {
          ...state.stepTypes,
          loading: true,
        },
      };
    case getType(appActions.fetchStepTypes.success):
      return {
        ...state,
        stepTypes: {
          ...state.stepTypes,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchStepTypes.failure):
      return {
        ...state,
        stepTypes: {
          ...state.stepTypes,
          loading: false,
        },
      };

    case getType(appActions.fetchMapperTypes.request):
      return {
        ...state,
        mapperTypes: {
          ...state.mapperTypes,
          loading: true,
        },
      };
    case getType(appActions.fetchMapperTypes.success):
      return {
        ...state,
        mapperTypes: {
          ...state.mapperTypes,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchMapperTypes.failure):
      return {
        ...state,
        mapperTypes: {
          ...state.mapperTypes,
          loading: false,
        },
      };

    case getType(appActions.fetchServiceTypes.request):
      return {
        ...state,
        serviceTypes: {
          ...state.serviceTypes,
          loading: true,
        },
      };
    case getType(appActions.fetchServiceTypes.success):
      return {
        ...state,
        serviceTypes: {
          ...state.serviceTypes,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(appActions.fetchServiceTypes.failure):
      return {
        ...state,
        serviceTypes: {
          ...state.serviceTypes,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default appReducer;
