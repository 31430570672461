import { toast } from 'react-toastify';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import API, { apiCallHandler } from 'api';
import { DELETE_TAG_CONFIRM_MODAL_ID, TAG_MODAL_ID } from 'pages/components/TagCard/constants';
import { CREATE_TAG_MODAL_ID } from 'pages/project/constants';
import { modalClose } from 'store/modal/actions';

import { createTag, deleteTag, fetchTags, updateTag } from './actions';

function* onFetchProjectTags({ payload: { project, sphere } }: ReturnType<typeof fetchTags.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchTags.failure());
    },
    errMessageFallback: 'Failed to fetch tags',
    tryHandler: function* () {
      const {
        data: {
          data: { tags },
        },
      } = yield* call(API.tags.fetchProjectTags, { project, sphere });

      yield put(fetchTags.success({ list: tags }));
    },
  });
}

function* onCreateProjectTag({ payload: { data } }: ReturnType<typeof createTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createTag.failure());
    },
    errMessageFallback: 'Failed to create tag',
    tryHandler: function* () {
      yield* call(API.tags.createProjectTag, data);
      yield onFetchProjectTags({ payload: { project: data.project, sphere: data.sphere }, type: getType(fetchTags.request) });

      yield put(createTag.success());
      yield put(modalClose.request({ id: CREATE_TAG_MODAL_ID }));
      yield call(toast.success, `The Tag '${data.data.name}' was successfully created`);
    },
  });
}

function* onUpdateProjectTag({ payload: { data } }: ReturnType<typeof updateTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateTag.failure());
    },
    errMessageFallback: 'Failed to update tag',
    tryHandler: function* () {
      yield* call(API.tags.updateProjectTag, data);
      yield onFetchProjectTags({ payload: { project: data.project, sphere: data.sphere }, type: getType(fetchTags.request) });

      yield put(updateTag.success());
      const modalId = `${TAG_MODAL_ID}_${data.data.oldName}`;
      yield put(modalClose.request({ id: modalId }));
      yield call(toast.success, `The Tag '${data.data.oldName}' was successfully updated`);
    },
  });
}

function* onDeleteProjectTag({ payload: { data } }: ReturnType<typeof deleteTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteTag.failure());
    },
    errMessageFallback: 'Failed to delete tag',
    tryHandler: function* () {
      yield* call(API.tags.deleteProjectTag, { name: data.name, project: data.project, sphere: data.sphere });
      yield onFetchProjectTags({ payload: { project: data.project, sphere: data.sphere }, type: getType(fetchTags.request) });

      yield put(deleteTag.success());
      const modalId = `${TAG_MODAL_ID}_${data.name}`;
      const deleteModalId = `${DELETE_TAG_CONFIRM_MODAL_ID}_${data.name}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(modalClose.request({ id: deleteModalId }));
      yield call(toast.success, `The Tag '${data.name}' was successfully deleted`);
    },
  });
}

function* projectTagsSaga() {
  yield all([
    takeEvery(fetchTags.request, onFetchProjectTags),
    takeEvery(createTag.request, onCreateProjectTag),
    takeEvery(updateTag.request, onUpdateProjectTag),
    takeEvery(deleteTag.request, onDeleteProjectTag),
  ]);
}

export default projectTagsSaga;
