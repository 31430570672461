import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
  height: 100%;
  padding: 16px;
`;
export const ButtonContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
  margin-top: 16px;
`;
