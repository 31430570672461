import { combineReducers } from 'redux';

import devTestFormReducer from './DevTestForm/store/reducer';
import flowNavReducer from './FlowNavMenu/store/reducer';
import flowTabsReducer from './FlowTabs/store/reducer';
import expressionFormReducer from './FormInputRenderer/components/FormExpressionModal/components/ExpressionForm/store/reducer';
import formSourceArrayReducer from './FormInputRenderer/components/FormSourceArrayTable/store/reducer';

const componentsReducer = combineReducers({
  devTestForm: devTestFormReducer,
  expressionForm: expressionFormReducer,
  flowNav: flowNavReducer,
  flowTabs: flowTabsReducer,
  formSourceArray: formSourceArrayReducer,
});

export default componentsReducer;
