import { ActionType, getType } from 'typesafe-actions';

import * as servicesActions from './actions';

type ServicesActionType = ActionType<typeof servicesActions>;

export type ServicesState = {
  actionLoading: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  list: servicesActions.FetchServicesSuccessPayload['list'];
  loading: boolean;
  updateLoading: boolean;
};

const initialState: ServicesState = {
  actionLoading: false,
  createLoading: false,
  deleteLoading: false,
  list: [],
  loading: false,
  updateLoading: false,
};

const projectServicesReducer = (state: ServicesState = initialState, action: ServicesActionType): ServicesState => {
  switch (action.type) {
    case getType(servicesActions.fetchServices.request):
      return {
        ...state,
        loading: true,
      };
    case getType(servicesActions.fetchServices.success):
      return {
        ...state,
        list: action.payload.list,
        loading: false,
      };
    case getType(servicesActions.fetchServices.failure):
      return {
        ...state,
        loading: false,
      };

    case getType(servicesActions.createService.request):
      return {
        ...state,
        createLoading: true,
      };
    case getType(servicesActions.createService.success):
    case getType(servicesActions.createService.failure):
      return {
        ...state,
        createLoading: false,
      };

    case getType(servicesActions.updateService.request):
      return {
        ...state,
        updateLoading: true,
      };
    case getType(servicesActions.updateService.success):
    case getType(servicesActions.updateService.failure):
      return {
        ...state,
        updateLoading: false,
      };

    case getType(servicesActions.deleteService.request):
      return {
        ...state,
        deleteLoading: true,
      };
    case getType(servicesActions.deleteService.success):
    case getType(servicesActions.deleteService.failure):
      return {
        ...state,
        deleteLoading: false,
      };

    case getType(servicesActions.callServiceAction.request):
      return {
        ...state,
        actionLoading: true,
      };
    case getType(servicesActions.callServiceAction.success):
    case getType(servicesActions.callServiceAction.failure):
      return {
        ...state,
        actionLoading: false,
      };

    default:
      return state;
  }
};

export default projectServicesReducer;
