import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button, { StyleTypes } from '../../../../../Button';
import FormField from '../../../../../FormField';

import { reset, validateExpression } from './store/actions';
import { selectExpressionFormState } from './store/selectors';
import { ButtonsContainer, Root, StyledInput, SubmitButton, SubmitButtonsContainer } from './styles';
import { ExpressionFormFields, Props } from './types';

const FormExpressionForm: FC<Props> = ({ expressionType, initialValue, onCancel, onSubmit }) => {
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const dispatch = useDispatch();
  const { validateLoading, validated } = useSelector(selectExpressionFormState);
  const { control, formState, getValues, handleSubmit } = useForm<ExpressionFormFields>({
    defaultValues: {
      expression: initialValue,
    },
    mode: 'onTouched',
  });
  const isDisabled = useMemo(() => !formState.isValid || !validated, [validated, formState.isValid]);
  const handleValidate = useCallback(() => {
    dispatch(
      validateExpression.request({
        bp: initiativeName!,
        data: {
          expression: getValues('expression'),
          expressionType,
        },
        flow: flowName!,
        project: projectName!,
        sphere: sphereName!,
      })
    );
  }, [dispatch, initiativeName, getValues, expressionType, flowName, projectName, sphereName]);
  const expressionValue = useWatch({ name: 'expression' });
  const onFormSubmit = useCallback(
    ({ expression }: ExpressionFormFields) => {
      onSubmit(expression);
    },
    [onSubmit]
  );
  useEffect(() => {
    dispatch(reset());
  }, [dispatch, expressionValue]);
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Root onSubmit={handleSubmit(onFormSubmit)}>
      <FormField<ExpressionFormFields, 'expression'>
        controllerProps={{
          control,
          name: 'expression',
        }}
        required
        render={(props) => <StyledInput {...props} multi rows={5} />}
      />
      <ButtonsContainer>
        <Button label="Validate" onClick={handleValidate} loading={validateLoading} styleType={StyleTypes.outlined} />
        <SubmitButtonsContainer>
          <SubmitButton type="submit" disabled={isDisabled} label="Save" />
          <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
        </SubmitButtonsContainer>
      </ButtonsContainer>
    </Root>
  );
};

export default FormExpressionForm;
