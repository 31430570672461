import React, { FC, MouseEvent, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useDeepCallback from 'hooks/useDeepCallback';
import { Flow } from 'models/Flow';
import { getLastFlowNumber } from 'models/Flow/utils';
import { Initiative } from 'models/Initiative';
import { fetchInitiative } from 'pages/initiative/store/actions';
import { buildDataCyValue } from 'utils/cy';
import { isDefAndNotNull } from 'utils/def';
import { stopMouseEvents } from 'utils/events';

import { Colors } from '../ColorPicker';
import { ICONS_TYPES } from '../Icon';
import Loader, { Modes as LoaderModes } from '../Loader';
import { PlacementTypes } from '../Popover';
import PopoverMenu, { MenuItem } from '../PopoverMenu';

import FlowTab, { Modes } from './components/FlowTab';
import useGetHiddenFlowTabs from './hooks/useGetHiddenFlowTabs';
import { createFlow, fetchFlows, tabClose } from './store/actions';
import {
  selectCreateFlowLoading,
  selectDeleteFlowLoading,
  selectFlowTabs,
  selectFlows,
  selectFlowsLoading,
} from './store/selectors';
import {
  BackButton,
  BackIcon,
  CloseIcon,
  DeleteIcon,
  FlowTabContainer,
  FlowTabIcon,
  FlowTabsContainer,
  InitiativeIcon,
  LabelContainer,
  LinkText,
  LogoContainer,
  LogoLabel,
  MoreIcon,
  PlusIcon,
  Root,
} from './styles';
import { Props } from './types';

const FlowTabs: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const flows = useSelector(selectFlows);
  const createFlowLoading = useSelector(selectCreateFlowLoading);
  const flowsLoading = useSelector(selectFlowsLoading);
  const deleteFlowLoading = useSelector(selectDeleteFlowLoading);
  const tabsOpened = useSelector(selectFlowTabs(initiativeName));
  const loading = useMemo(
    () => flowsLoading || createFlowLoading || deleteFlowLoading,
    [flowsLoading, createFlowLoading, deleteFlowLoading]
  );

  const handleCreateFlow = useCallback(() => {
    const lastFlowNumber = getLastFlowNumber(flows);
    if (isDefAndNotNull(sphereName) && isDefAndNotNull(projectName) && isDefAndNotNull(initiativeName)) {
      dispatch(
        createFlow.request({
          bp: initiativeName,
          data: {
            description: undefined,
            name: `Flow ${lastFlowNumber + 1}`,
          },
          project: projectName,
          sphere: sphereName,
        })
      );
    }
  }, [flows, sphereName, projectName, initiativeName, dispatch]);
  const handleCloseTab = useDeepCallback(({ bp, flow }: { bp: Initiative['name']; flow: Flow['name'] }, e: MouseEvent) => {
    dispatch(
      tabClose.request({
        bp,
        currentUrlFlowName: flowName,
        flow,
        project: projectName!,
        sphere: sphereName!,
      })
    );
    stopMouseEvents(e);
  });

  useEffect(() => {
    if (isDefAndNotNull(sphereName) && isDefAndNotNull(projectName) && isDefAndNotNull(initiativeName)) {
      dispatch(fetchInitiative.request({ data: { initiative: initiativeName, project: projectName, sphere: sphereName } }));
      dispatch(fetchFlows.request({ bp: initiativeName, project: projectName, sphere: sphereName }));
    }
  }, [dispatch, sphereName, projectName, initiativeName, flowName]);

  const handleOpenFlow = useDeepCallback((flowName: Flow['name']) => {
    navigate(`/spheres/${sphereName}/projects/${projectName}/initiative/${initiativeName}/flow/${flowName}`);
  });

  const { hiddenTabs, tabsRef } = useGetHiddenFlowTabs(tabsOpened, flowName!);
  const hiddenTabItems: MenuItem[] = useMemo(() => {
    return hiddenTabs.map((label) => ({
      icon: ICONS_TYPES.SiteMap,
      iconBackgroundColor: 'pink',
      iconColor: 'white',
      iconSize: 16,
      label: (
        <LabelContainer>
          {label}
          <CloseIcon size={16} icon={ICONS_TYPES.XMark} onClick={handleCloseTab({ bp: initiativeName!, flow: label })} />
        </LabelContainer>
      ),
      onClick: handleOpenFlow(label),
    }));
  }, [hiddenTabs, initiativeName, handleCloseTab, handleOpenFlow]);

  return (
    <Root className={className} data-cy={buildDataCyValue({ elementName: 'header', fieldName: 'flow' })}>
      <FlowTabsContainer>
        <LogoContainer>
          <LogoLabel>xpi</LogoLabel>
          <BackButton to={`/spheres/${sphereName}/projects/${projectName}`}>
            <BackIcon icon={ICONS_TYPES.ArrowRight} size={24} />
          </BackButton>
        </LogoContainer>
        <FlowTab to={`/spheres/${sphereName}/projects/${projectName}/initiative/${initiativeName}`} mode={Modes.gray} end>
          <InitiativeIcon icon={ICONS_TYPES.EmptyFile} size={24} />
        </FlowTab>
        {tabsOpened.map((tabName, index) => (
          <FlowTabContainer hide={hiddenTabs.includes(tabName)} key={tabName} ref={(el) => (tabsRef.current[index] = el)}>
            <FlowTab to={`/spheres/${sphereName}/projects/${projectName}/initiative/${initiativeName}/flow/${tabName}`}>
              <>
                <FlowTabIcon color={Colors.pink} icon={ICONS_TYPES.SiteMap} size={16} />
                <LinkText>{tabName}</LinkText>
                <DeleteIcon icon={ICONS_TYPES.XMark} size={16} onClick={handleCloseTab({ bp: initiativeName!, flow: tabName })} />
              </>
            </FlowTab>
          </FlowTabContainer>
        ))}
        {loading ? (
          <FlowTab mode={Modes.gray}>
            <Loader size={24} />
          </FlowTab>
        ) : (
          <>
            {hiddenTabItems.length > 0 && (
              <PopoverMenu
                className={className}
                items={hiddenTabItems}
                popoverProps={{ defaultPlacement: [PlacementTypes.right, PlacementTypes.bottom] }}
              >
                <FlowTab mode={Modes.gray}>
                  <MoreIcon icon={ICONS_TYPES.ChevronDown} size={24} />
                </FlowTab>
              </PopoverMenu>
            )}
            <FlowTab mode={Modes.gray} onClick={handleCreateFlow}>
              <PlusIcon icon={ICONS_TYPES.Plus} size={24} />
            </FlowTab>
          </>
        )}
      </FlowTabsContainer>
      {createFlowLoading && <Loader mode={LoaderModes.fullScreen} />}
    </Root>
  );
};

export type { Props };

export default memo(FlowTabs);
