import React from 'react';

import { Route } from './types';

const SphereCreatePage = React.lazy(() => import('pages/sphereCreate'));

const SpherePage = React.lazy(() => import('pages/sphere'));
const SpherePageBreadCrumb = React.lazy(() => import('pages/sphere/components/BreadCrumb'));
const SpherePageActions = React.lazy(() => import('pages/sphere/components/Actions'));

const ProjectPage = React.lazy(() => import('pages/project'));
const ProjectPageBreadCrumb = React.lazy(() => import('pages/project/components/BreadCrumb'));
const ProjectPageActions = React.lazy(() => import('pages/project/components/Actions'));

const InitiativePage = React.lazy(() => import('pages/initiative'));
const InitiativePageBreadCrumb = React.lazy(() => import('pages/initiative/components/BreadCrumb'));
const InitiativePageActions = React.lazy(() => import('pages/initiative/components/Actions'));

const FlowPage = React.lazy(() => import('pages/flow'));
const FlowPageBreadCrumb = React.lazy(() => import('pages/flow/components/BreadCrumb'));
const FlowPageActions = React.lazy(() => import('pages/flow/components/Actions'));

const RecentsPage = React.lazy(() => import('pages/recents'));
const SchemaTestPage = React.lazy(() => import('pages/schemaTest'));

const NotFoundPage = React.lazy(() => import('pages/notFound'));

export const routes: Route = {
  drafts: {
    breadCrumbs: [],
    canvas: false,
    component: <NotFoundPage />,
    headerVisible: false,
    path: '/drafts',
  },
  flow: {
    actions: <FlowPageActions />,
    breadCrumbComponent: <FlowPageBreadCrumb />,
    breadCrumbs: ['initiative', 'flow'],
    canvas: true,
    component: <FlowPage />,
    headerVisible: true,
    path: '/spheres/:sphereName/projects/:projectName/initiative/:initiativeName/flow/:flowName',
  },
  initiative: {
    actions: <InitiativePageActions />,
    breadCrumbComponent: <InitiativePageBreadCrumb />,
    breadCrumbs: ['sphere', 'project', 'initiative'],
    canvas: true,
    component: <InitiativePage />,
    headerVisible: true,
    path: '/spheres/:sphereName/projects/:projectName/initiative/:initiativeName',
  },
  project: {
    actions: <ProjectPageActions />,
    breadCrumbComponent: <ProjectPageBreadCrumb />,
    breadCrumbs: ['sphere', 'project'],
    canvas: false,
    component: <ProjectPage />,
    headerVisible: true,
    path: '/spheres/:sphereName/projects/:projectName',
  },
  recents: {
    breadCrumbComponent: 'Recents',
    breadCrumbs: ['recents'],
    canvas: false,
    component: <RecentsPage />,
    headerVisible: true,
    path: '/recents',
  },
  schemaTest: {
    breadCrumbComponent: 'JSON schema test',
    breadCrumbs: ['schemaTest'],
    canvas: true,
    component: <SchemaTestPage />,
    headerVisible: true,
    path: '/spheres/:sphereName/projects/:projectName/initiative/:initiativeName/flow/:flowName/schema-test',
  },
  sphere: {
    actions: <SpherePageActions />,
    breadCrumbComponent: <SpherePageBreadCrumb />,
    breadCrumbs: ['sphere'],
    canvas: false,
    component: <SpherePage />,
    headerVisible: true,
    path: '/spheres/:sphereName',
  },
  start: {
    breadCrumbs: [],
    canvas: false,
    component: <SphereCreatePage />,
    headerVisible: false,
    path: '/start',
  },
};
