import { useMemo } from 'react';

import useDynamicCallback from './useDynamicCallback';

const useDeepCallback = <T = unknown, K extends string | number | symbol | boolean | object | Function = number>(
  handler: (key: K, data: T, ...args: never[]) => void
) => {
  const innerHandler = useDynamicCallback(handler);
  return useMemo(() => {
    const map: Record<
      string,
      {
        (): void;
        (data: T, ...args: never[]): void;
      }
    > = {};
    return (key: K) => {
      if (!map[JSON.stringify(key)]) {
        map[JSON.stringify(key)] = innerHandler.bind(this, key);
      }
      return map[JSON.stringify(key)];
    };
  }, [innerHandler]);
};

export default useDeepCallback;
