import { MarkerType } from 'reactflow';

import { Branch } from 'models/Branch';
import { Step, StepTypes } from 'models/Step';
import { commonColors } from 'styles/theme';

import {
  DEFAULT_STEP_NODE_ID,
  GO_TO_EDGE_PREFIX,
  GO_TO_TARGET_LEFT_HANDLE_ID,
  SOURCE_HANDLE_DEFAULT_ID,
  TARGET_HANDLE_DEFAULT_ID,
} from '../constants';
import { EdgeTypes } from '../enums';
import { BranchEdgeData, StepEdgeData, XpiStudioEdges } from '../types';

export const connectSteps = (data: Step[]): XpiStudioEdges => {
  const edges: XpiStudioEdges = [];

  const createStepEdge = (source: string, target: string, data: StepEdgeData): void => {
    edges.push({
      data,
      id: `${source}_${target}`,
      markerEnd: { height: 20, type: MarkerType.Arrow, width: 20 },
      source,
      sourceHandle: SOURCE_HANDLE_DEFAULT_ID,
      target,
      targetHandle: TARGET_HANDLE_DEFAULT_ID,
      type: EdgeTypes.step,
    });
  };
  const createBranchEdge = (source: string, target: string, data: BranchEdgeData): void => {
    edges.push({
      data,
      id: `${source}_${target}`,
      markerEnd: { height: 20, type: MarkerType.Arrow, width: 20 },
      source,
      sourceHandle: SOURCE_HANDLE_DEFAULT_ID,
      target,
      targetHandle: TARGET_HANDLE_DEFAULT_ID,
      type: EdgeTypes.branch,
    });
  };
  const createGoToEdge = (source: string, target: string): void => {
    edges.push({
      id: `${GO_TO_EDGE_PREFIX}_${source}_${target}`,
      markerEnd: { height: 20, type: MarkerType.Arrow, width: 20 },
      source,
      sourceHandle: SOURCE_HANDLE_DEFAULT_ID,
      style: {
        stroke: commonColors.orange,
        strokeDasharray: 3,
      },
      target,
      targetHandle: GO_TO_TARGET_LEFT_HANDLE_ID,
    });
  };

  const proceedBranches = (branches: Branch[], parentStep: Step) => {
    branches.forEach((branch) => {
      if (branch.steps.length === 0) {
        createBranchEdge(parentStep.name, `${parentStep.name}_${branch.name}_${DEFAULT_STEP_NODE_ID}`, {
          branch,
          parentStep,
        });
      } else {
        branch.steps.forEach((nestedStep, nestedStepIndex) => {
          if (nestedStep.type === StepTypes.goTo && nestedStep?.config?.destination) {
            createGoToEdge(nestedStep.name, nestedStep.config.destination as string);
          }

          if (nestedStepIndex === 0) {
            createBranchEdge(parentStep.name, nestedStep.name, {
              branch,
              parentStep,
            });
          }

          if (branch.steps[nestedStepIndex + 1]) {
            createStepEdge(nestedStep.name, branch.steps[nestedStepIndex + 1].name, {
              isLast: false,
              parentStep: nestedStep,
              targetStep: branch.steps[nestedStepIndex + 1],
            });
          }

          if (Array.isArray(nestedStep.branches) && nestedStep.branches.length !== 0) {
            proceedBranches(nestedStep.branches, nestedStep);
          } else if (nestedStepIndex === branch.steps.length - 1) {
            createStepEdge(nestedStep.name, `${nestedStep.name}_${branch.name}_${DEFAULT_STEP_NODE_ID}`, {
              isLast: true,
              parentStep: nestedStep,
            });
          }
        });
      }
    });
  };

  const proceedSteps = (steps: Step[]) => {
    steps.forEach((step, index) => {
      if (step.type === StepTypes.goTo && step?.config?.destination) {
        createGoToEdge(step.name, step.config.destination as string);
      }

      if (Array.isArray(step.branches) && step.branches.length !== 0) {
        proceedBranches(step.branches, step);
      } else if (steps[index + 1]) {
        createStepEdge(step.name, steps[index + 1].name, { isLast: false, parentStep: step, targetStep: steps[index + 1] });
      } else {
        createStepEdge(step.name, `${step.name}_${DEFAULT_STEP_NODE_ID}`, { isLast: true, parentStep: step });
      }
    });
  };

  proceedSteps(data);
  return edges;
};

export const isBChildOfA = (arr: (Step | Branch)[], nameA: string, nameB: string): boolean => {
  const findNodeIndex = (nodes: (Step | Branch)[], name: string): number | null => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if (node.name === name) return i;

      if ('branches' in node && node.branches) {
        const found = findNodeIndex(node.branches, name);

        if (found !== null) return found;
      }

      if ('steps' in node && Array.isArray(node.steps)) {
        const found = findNodeIndex(node.steps, name);

        if (found !== null) return found;
      }
    }
    return null;
  };
  const nodeAIndex = findNodeIndex(arr, nameA);

  if (nodeAIndex === null) return false;

  const checkChild = (nodes: (Step | Branch)[], parentIndex: number, childName: string): boolean => {
    // Check if the next node after parentIndex is the child
    if (nodes[parentIndex + 1] && nodes[parentIndex + 1].name === childName) {
      return true;
    }

    // Recursively check steps and branches for the child
    const checkStepsAndBranches = (steps: (Step | Branch)[]): boolean => {
      for (const step of steps) {
        if (step.name === childName) return true;

        if ('branches' in step && step.branches && checkStepsAndBranches(step.branches)) return true;

        if ('steps' in step && Array.isArray(step.steps) && checkStepsAndBranches(step.steps)) return true;
      }
      return false;
    };
    const parentNode = nodes[parentIndex];

    if ('branches' in parentNode && parentNode.branches && checkStepsAndBranches(parentNode.branches)) {
      return true;
    }

    return 'steps' in parentNode && Array.isArray(parentNode.steps) && checkStepsAndBranches(parentNode.steps);
  };

  return checkChild(arr, nodeAIndex, nameB);
};
