import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import { DeleteIcon } from '../../styles';

import { Modes } from './enums';

export const StyledBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
export const Root = styled.div<{ mode?: Modes }>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  height: 44px;
  padding: 8px 16px 16px 16px;
  margin: 0 8px;
  letter-spacing: 0.5px;
  & img {
    object-fit: fill;
  }
  &:hover ${StyledBackground} {
    opacity: 0.1;
  }
  &:hover ${DeleteIcon} {
    opacity: 1;
  }
  &.active {
    color: ${({ theme }) => theme.colors.canvasHeader};
  }
  &.active ${StyledBackground} {
    opacity: ${({ mode }) => (mode === Modes.white ? 1 : 0.1)};
  }
  &.active ${DeleteIcon} {
    opacity: 1;
  }
`;
export const RootButton = styled(Root)<{ mode?: Modes }>`
  cursor: pointer;
`;
