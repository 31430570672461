import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormFieldFileValueType } from 'jsonSchema';
import { Branch } from 'models/Branch';
import { DataMapperTreeNode } from 'models/DataMapperNode';
import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Step } from 'models/Step';
import { Trigger } from 'models/Trigger';

import { XpiStudioNodes } from '../types';

export type FetchFlowRequestPayload = {
  bp: Initiative['name'];
  name: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchFlowSuccessPayload = {
  flow: Flow;
};
export const fetchFlow = createAsyncAction('flow/fetchFlow/request', 'flow/fetchFlow/success', 'flow/fetchFlow/failure')<
  FetchFlowRequestPayload,
  FetchFlowSuccessPayload,
  undefined
>();

export type SetSelectedTriggerPayload = {
  selectedTriggerName: string;
};
export const setSelectedTrigger = createAction('flow/setSelectedTrigger')<SetSelectedTriggerPayload>();

export const resetSelectedTrigger = createAction('flow/resetSelectedTrigger')();

export type CreateTriggerRequestPayload = {
  bp: Initiative['name'];
  data: Pick<Trigger, 'name' | 'type'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const createTrigger = createAsyncAction(
  'flow/createTrigger/request',
  'flow/createTrigger/success',
  'flow/createTrigger/failure'
)<CreateTriggerRequestPayload, undefined, undefined>();

export type UpdateTriggerRequestPayload = {
  bp: Initiative['name'];
  data: Trigger;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Trigger['name'];
};
export const updateTrigger = createAsyncAction(
  'flow/updateTrigger/request',
  'flow/updateTrigger/success',
  'flow/updateTrigger/failure'
)<UpdateTriggerRequestPayload, undefined, undefined>();

export type UpdateTriggerConfigRequestPayload = {
  bp: Initiative['name'];
  data: Pick<Trigger, 'type' | 'config'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Trigger['name'];
};
export const updateTriggerConfig = createAsyncAction(
  'flow/updateTriggerConfig/request',
  'flow/updateTriggerConfig/success',
  'flow/updateTriggerConfig/failure'
)<UpdateTriggerConfigRequestPayload, undefined, undefined>();

export type RemoveTriggerRequestPayload = {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Pick<Trigger, 'name' | 'type'>;
};
export const removeTrigger = createAsyncAction(
  'flow/removeTrigger/request',
  'flow/removeTrigger/success',
  'flow/removeTrigger/failure'
)<RemoveTriggerRequestPayload, undefined, undefined>();

export const reset = createAction('flow/reset')();

export type SetSelectedStepPayload = {
  step: Step;
};
export const setSelectedStep = createAction('flow/setSelectedStep')<SetSelectedStepPayload>();

export const resetSelectedStep = createAction('flow/resetSelectedStep')();

export type CreateStepRequestPayload = {
  bp: Initiative['name'];
  branch?: Branch['name'];
  data: Pick<Step, 'name' | 'type' | 'config'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step?: Sphere['name'];
};
export const createStep = createAsyncAction('flow/createStep/request', 'flow/createStep/success', 'flow/createStep/failure')<
  CreateStepRequestPayload,
  undefined,
  undefined
>();

export type UpdateStepRequestPayload = {
  bp: Initiative['name'];
  data: Step;
  flow: Flow['name'];
  ignoreValidation?: boolean;
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const updateStep = createAsyncAction('flow/updateStep/request', 'flow/updateStep/success', 'flow/updateStep/failure')<
  UpdateStepRequestPayload,
  undefined,
  undefined
>();

export type UpdateStepConfigRequestPayload = {
  bp: Initiative['name'];
  data: Pick<Step, 'type' | 'config'>;
  flow: Flow['name'];
  ignoreValidation?: boolean;
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const updateStepConfig = createAsyncAction(
  'flow/updateStepConfig/request',
  'flow/updateStepConfig/success',
  'flow/updateStepConfig/failure'
)<UpdateStepConfigRequestPayload, undefined, undefined>();

export type UpdateStepDataMapperRequestPayload = {
  bp: Initiative['name'];
  data: Step;
  files?: FormFieldFileValueType[];
  flow: Flow['name'];
  ignoreValidation?: boolean;
  path?: DataMapperTreeNode['path'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const updateStepDataMapperConfig = createAsyncAction(
  'flow/updateStepDataMapperConfig/request',
  'flow/updateStepDataMapperConfig/success',
  'flow/updateStepDataMapperConfig/failure'
)<UpdateStepDataMapperRequestPayload, undefined, undefined>();

export type CreateBranchRequestPayload = {
  bp: Initiative['name'];
  data: Pick<Branch, 'type' | 'name'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const createBranch = createAsyncAction(
  'flow/createBranch/request',
  'flow/createBranch/success',
  'flow/createBranch/failure'
)<CreateBranchRequestPayload, undefined, undefined>();

export type UpdateBranchTypeRequestPayload = {
  bp: Initiative['name'];
  branch: Branch['name'];
  data: Pick<Branch, 'type'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const updateBranchType = createAsyncAction(
  'flow/updateBranchType/request',
  'flow/updateBranchType/success',
  'flow/updateBranchType/failure'
)<UpdateBranchTypeRequestPayload, undefined, undefined>();

export type DeleteBranchRequestPayload = {
  bp: Initiative['name'];
  branch: Branch['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const deleteBranch = createAsyncAction(
  'flow/deleteBranch/request',
  'flow/deleteBranch/success',
  'flow/deleteBranch/failure'
)<DeleteBranchRequestPayload, undefined, undefined>();

export type UpdateBranchWaitNodeRequestPayload = {
  bp: Initiative['name'];
  branch: Branch['name'];
  data: Pick<Branch, 'wait'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const updateBranchWaitNode = createAsyncAction(
  'flow/updateBranchWaitNode/request',
  'flow/updateBranchWaitNode/success',
  'flow/updateBranchWaitNode/failure'
)<UpdateBranchWaitNodeRequestPayload, undefined, undefined>();

export type DeleteBranchWaitNodeRequestPayload = {
  bp: Initiative['name'];
  branch: Branch['name'];
  data: Pick<Branch, 'wait'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
};
export const deleteBranchWaitNode = createAsyncAction(
  'flow/deleteBranchWaitNode/request',
  'flow/deleteBranchWaitNode/success',
  'flow/deleteBranchWaitNode/failure'
)<DeleteBranchWaitNodeRequestPayload, undefined, undefined>();

export type RemoveStepRequestPayload = {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Pick<Step, 'name' | 'type'>;
};
export const removeStep = createAsyncAction('flow/removeStep/request', 'flow/removeStep/success', 'flow/removeStep/failure')<
  RemoveStepRequestPayload,
  undefined,
  undefined
>();

export type RunBuildRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export const runBuild = createAsyncAction('flow/runBuild/request', 'flow/runBuild/success', 'flow/runBuild/failure')<
  RunBuildRequestPayload,
  undefined,
  undefined
>();

export type RunDeployRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export const runDeploy = createAsyncAction('flow/runDeploy/request', 'flow/runDeploy/success', 'flow/runDeploy/failure')<
  RunDeployRequestPayload,
  undefined,
  undefined
>();

export type SetFlowPreviewUrlRequestPayload = {
  nodes: XpiStudioNodes;
};
export type SetFlowPreviewUrlSuccessPayload = {
  objectURL: string | undefined;
};
export const setFlowPreviewUrl = createAsyncAction(
  'flow/setFlowPreviewUrl/request',
  'flow/setFlowPreviewUrl/success',
  'flow/setFlowPreviewUrl/failure'
)<SetFlowPreviewUrlRequestPayload, SetFlowPreviewUrlSuccessPayload, undefined>();

export type UploadFlowPreviewRequestPayload = {
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const uploadFlowPreview = createAsyncAction(
  'flow/uploadFlowPreview/request',
  'flow/uploadFlowPreview/success',
  'flow/uploadFlowPreview/failure'
)<UploadFlowPreviewRequestPayload, undefined, undefined>();

export type StartGoToPayload = { source: Step['name']; targets: Step['name'][] };
export const startGoTo = createAction('flow/startGoTo')<StartGoToPayload>();

export const finishGoTo = createAction('flow/finishGoTo')();
