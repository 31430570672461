export const isDef = <T>(val: T | undefined): val is T => val !== undefined;

export const isNotNull = <T>(val: T | null): val is T => val !== null;

export const isDefAndNotNull = <T>(val: T | null | undefined): val is T => val !== undefined && val !== null;

export const isEveryItemDefAndNotNull = <T>(val: Array<T | null | undefined>): val is Array<T> => val.every(isDefAndNotNull);

export const areAllFieldsDefAndNotNull = <T extends object>(
  arr: Array<Partial<Record<keyof T, T[keyof T] | null | undefined>>>
): arr is Array<Record<keyof T, T[keyof T]>> => {
  return arr.every((item) => {
    if (!isDefAndNotNull(item)) {
      return false;
    }
    return isEveryItemDefAndNotNull(Object.values(item));
  });
};
