import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import { FLOW_NAV_HEIGHT } from '../FlowTabs/constant';

export const Root = styled.main`
  ${flexStyle({ direction: 'column', wrap: 'nowrap' })};
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;
export const ContentWrapper = styled.div<{ canvas: boolean | undefined }>`
  ${flexStyle({ wrap: 'nowrap' })};
  flex: 1;
  height: ${({ canvas }) => (canvas ? `calc(100% - ${FLOW_NAV_HEIGHT}px)` : '100%')};
`;
export const Content = styled.div`
  ${flexStyle({ direction: 'column', wrap: 'nowrap' })};
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surfacePrimary};
`;
