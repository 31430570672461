import { createSelector } from 'reselect';

import { RootState } from '../types';

import { TabsState } from './reducer';

const selectTabsState = (state: RootState) => state.tabs;

export const selectTabs = (id: keyof TabsState) => createSelector(selectTabsState, (tabsState): TabsState['id'] => tabsState[id]);
export const selectActiveTab = (id: keyof TabsState) => createSelector(selectTabs(id), (tabsState) => tabsState?.activeTab);
