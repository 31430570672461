import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import { Modes } from 'components/Modal';

import API, { apiCallHandler } from 'api';
import { Log, LogTypes } from 'models/Log';
import { getResourceIdentifier } from 'models/Resource/utils';
import { onFetchSpheres } from 'store/app/saga';
import { addLog } from 'store/logs/actions';
import { modalClose, modalOpen } from 'store/modal/actions';
import { isDefAndNotNull } from 'utils/def';

import { DELETE_RESOURCE_CONFIRM_MODAL_ID, RESOURCE_MODAL_ID } from '../../components/ResourceCard/constants';
import { DELETE_TAG_CONFIRM_MODAL_ID, TAG_MODAL_ID } from '../../components/TagCard/constants';

import {
  CREATE_PROJECT_MODAL_ID,
  CREATE_RESOURCE_MODAL_ID,
  CREATE_TAG_MODAL_ID,
  DELETE_SPHERE_CONFIRM_MODAL_ID,
  IMPORT_PROJECT_MODAL_ID,
  UPDATE_SPHERE_MODAL_ID,
} from '../constants';

import {
  createProject,
  createResource,
  createTag,
  deleteResource,
  deleteSphere,
  deleteTag,
  fetchResources,
  fetchSphere,
  fetchTags,
  importProject,
  updateResource,
  updateSphere,
  updateTag,
  validateResource,
} from './actions';

function* onFetchSphere({ payload: { sphereName } }: ReturnType<typeof fetchSphere.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchSphere.failure());
    },
    errMessageFallback: 'Failed to fetch sphere',
    tryHandler: function* () {
      const {
        data: {
          data: { sphere },
        },
      } = yield* call(API.spheres.fetchSphere, sphereName);

      yield put(
        fetchSphere.success({
          sphere: {
            ...sphere,
            color: isDefAndNotNull(sphere.color) && !sphere.color.startsWith('#') ? `#${sphere.color}` : sphere.color,
          },
        })
      );
    },
  });
}

function* onUpdateSphere({ payload: { data } }: ReturnType<typeof updateSphere.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateSphere.failure());
    },
    errMessageFallback: 'Failed to update sphere',
    tryHandler: function* () {
      yield* call(API.spheres.updateSphere, data);
      yield onFetchSpheres();

      yield put(updateSphere.success());
      yield put(push(`/spheres/${data.name}`));
      yield put(modalClose.request({ id: UPDATE_SPHERE_MODAL_ID }));
    },
  });
}

function* onDeleteSphere({ payload: { sphereName } }: ReturnType<typeof deleteSphere.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteSphere.failure());
    },
    errMessageFallback: 'Failed to delete sphere',
    tryHandler: function* () {
      yield* call(API.spheres.deleteSphere, sphereName);
      yield onFetchSpheres();

      yield put(deleteSphere.success());
      yield put(modalClose.request({ id: DELETE_SPHERE_CONFIRM_MODAL_ID }));
      yield put(push('/'));
      yield call(toast.success, `The Sphere '${sphereName}' was successfully deleted`);
    },
  });
}

function* onImportProject({ payload: { data } }: ReturnType<typeof importProject.request>): Generator {
  yield apiCallHandler<{ logs: Log['data'] }>({
    catchHandler: function* ({
      data: {
        data: { logs },
      },
    }) {
      yield put(importProject.failure());
      yield put(
        addLog({
          log: {
            data: logs,
            name: `Project: ${data.sphere} / ${data.name} • failed to import`,
            new: true,
            type: LogTypes.projectImport,
          },
        })
      );
    },
    errMessageFallback: 'Failed to import project',
    tryHandler: function* () {
      const {
        data: {
          data: { logs, project },
        },
      } = yield* call(API.projects.importProject, data);

      yield put(importProject.success());
      yield put(
        addLog({
          log: { data: logs, name: `Project: ${data.sphere} / ${data.name} • imported`, new: true, type: LogTypes.projectImport },
        })
      );
      yield put(modalClose.request({ id: IMPORT_PROJECT_MODAL_ID }));
      yield put(push(`/spheres/${data.sphere}/projects/${project.name}`));
      yield call(toast.success, `The Project '${project.name}' was successfully imported`);
    },
  });
}

function* onCreateProject({ payload: { data } }: ReturnType<typeof createProject.request>): Generator {
  yield apiCallHandler<{ logs: Log['data'] }>({
    catchHandler: function* ({
      data: {
        data: { logs },
      },
    }) {
      yield put(createProject.failure());
      yield put(
        addLog({
          log: {
            data: logs,
            name: `Project: ${data.sphere} / ${data.data.name} • failed to create`,
            new: true,
            type: LogTypes.projectCreate,
          },
        })
      );
    },
    errMessageFallback: 'Failed to create project',
    tryHandler: function* () {
      const {
        data: {
          data: { logs },
        },
      } = yield* call(API.projects.createProject, data);

      yield put(createProject.success());
      yield put(
        addLog({
          log: {
            data: logs,
            name: `Project: ${data.sphere} / ${data.data.name} • created`,
            new: true,
            type: LogTypes.projectCreate,
          },
        })
      );
      yield put(modalClose.request({ id: CREATE_PROJECT_MODAL_ID }));
      yield put(push(`/spheres/${data.sphere}/projects/${data.data.name}`));
      yield call(toast.success, `The Project '${data.data.name}' was successfully created`);
    },
  });
}

function* onFetchSphereResources({ payload: { sphereName } }: ReturnType<typeof fetchResources.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchResources.failure());
    },
    errMessageFallback: 'Failed to fetch resources',
    tryHandler: function* () {
      const {
        data: {
          data: { resources },
        },
      } = yield* call(API.resources.fetchSphereResources, { sphere: sphereName });

      yield put(fetchResources.success({ list: resources }));
    },
  });
}

function* onCreateSphereResource({ payload: { data } }: ReturnType<typeof createResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createResource.failure());
    },
    errMessageFallback: 'Failed to create resource',
    tryHandler: function* () {
      yield* call(API.resources.createSphereResource, data);
      yield onFetchSphereResources({ payload: { sphereName: data.sphere }, type: getType(fetchResources.request) });

      yield put(createResource.success());
      yield put(modalClose.request({ animated: false, id: CREATE_RESOURCE_MODAL_ID }));
      yield put(
        modalOpen({
          animated: false,
          id: `${RESOURCE_MODAL_ID}_${getResourceIdentifier({ name: data.name, type: data.type })}`,
          mode: Modes.rightSlide,
        })
      );
      yield call(toast.success, `The Resource '${data.name}' was successfully created`);
    },
  });
}

function* onUpdateSphereResource({ payload: { data } }: ReturnType<typeof updateResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateResource.failure());
    },
    errMessageFallback: 'Failed to update resource',
    tryHandler: function* () {
      yield* call(API.resources.updateSphereResource, data);
      yield onFetchSphereResources({ payload: { sphereName: data.sphere }, type: getType(fetchResources.request) });

      yield put(updateResource.success());
      const modalId = `${RESOURCE_MODAL_ID}_${getResourceIdentifier({ name: data.oldName, type: data.type })}`;
      yield put(modalClose.request({ id: modalId }));
      yield call(toast.success, `The Resource '${data.oldName}' was successfully updated`);
    },
  });
}

function* onValidateSphereResource({ payload: { data } }: ReturnType<typeof validateResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(validateResource.failure());
    },
    errMessageFallback: 'Failed to validate resource',
    tryHandler: function* () {
      yield* call(API.resources.validateSphereResource, data);

      yield put(validateResource.success());
      yield call(toast.success, `The Resource '${data.oldName}' was successfully validated`);
    },
  });
}

function* onDeleteSphereResource({ payload: { data } }: ReturnType<typeof deleteResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteResource.failure());
    },
    errMessageFallback: 'Failed to delete resource',
    tryHandler: function* () {
      yield* call(API.resources.deleteSphereResource, data);
      yield onFetchSphereResources({ payload: { sphereName: data.sphere }, type: getType(fetchResources.request) });

      yield put(deleteResource.success());
      const modalId = `${RESOURCE_MODAL_ID}_${getResourceIdentifier(data)}`;
      const deleteModalId = `${DELETE_RESOURCE_CONFIRM_MODAL_ID}_${getResourceIdentifier(data)}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(modalClose.request({ id: deleteModalId }));
      yield call(toast.success, `The Resource '${data.name}' was successfully deleted`);
    },
  });
}

function* onFetchSphereTags({ payload: { sphereName } }: ReturnType<typeof fetchTags.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchTags.failure());
    },
    errMessageFallback: 'Failed to fetch tags',
    tryHandler: function* () {
      const {
        data: {
          data: { tags },
        },
      } = yield* call(API.tags.fetchSphereTags, sphereName);

      yield put(fetchTags.success({ list: tags }));
    },
  });
}

function* onCreateSphereTag({ payload: { data } }: ReturnType<typeof createTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createTag.failure());
    },
    errMessageFallback: 'Failed to create tag',
    tryHandler: function* () {
      yield* call(API.tags.createSphereTag, data);
      yield onFetchSphereTags({ payload: { sphereName: data.sphere }, type: getType(fetchTags.request) });

      yield put(createTag.success());
      yield put(modalClose.request({ id: CREATE_TAG_MODAL_ID }));
      yield call(toast.success, `The Tag '${data.data.name}' was successfully created`);
    },
  });
}

function* onUpdateSphereTag({ payload: { data } }: ReturnType<typeof updateTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateTag.failure());
    },
    errMessageFallback: 'Failed to update tag',
    tryHandler: function* () {
      yield* call(API.tags.updateSphereTag, data);
      yield onFetchSphereTags({ payload: { sphereName: data.sphere }, type: getType(fetchTags.request) });

      yield put(updateTag.success());
      const modalId = `${TAG_MODAL_ID}_${data.data.oldName}`;
      yield put(modalClose.request({ id: modalId }));
      yield call(toast.success, `The Tag '${data.data.oldName}' was successfully updated`);
    },
  });
}

function* onDeleteSphereTag({ payload: { data } }: ReturnType<typeof deleteTag.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteTag.failure());
    },
    errMessageFallback: 'Failed to delete tag',
    tryHandler: function* () {
      yield* call(API.tags.deleteSphereTag, { name: data.name, sphere: data.sphere });
      yield onFetchSphereTags({ payload: { sphereName: data.sphere }, type: getType(fetchTags.request) });

      yield put(deleteTag.success());
      const modalId = `${TAG_MODAL_ID}_${data.name}`;
      const deleteModalId = `${DELETE_TAG_CONFIRM_MODAL_ID}_${data.name}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(modalClose.request({ id: deleteModalId }));
      yield call(toast.success, `The Tag '${data.name}' was successfully deleted`);
    },
  });
}

function* sphereSaga() {
  yield all([
    takeEvery(fetchSphere.request, onFetchSphere),
    takeEvery(deleteSphere.request, onDeleteSphere),
    takeEvery(updateSphere.request, onUpdateSphere),
    takeEvery(importProject.request, onImportProject),
    takeEvery(createProject.request, onCreateProject),
    takeEvery(fetchResources.request, onFetchSphereResources),
    takeEvery(createResource.request, onCreateSphereResource),
    takeEvery(updateResource.request, onUpdateSphereResource),
    takeEvery(validateResource.request, onValidateSphereResource),
    takeEvery(deleteResource.request, onDeleteSphereResource),
    takeEvery(createTag.request, onCreateSphereTag),
    takeEvery(fetchTags.request, onFetchSphereTags),
    takeEvery(updateTag.request, onUpdateSphereTag),
    takeEvery(deleteTag.request, onDeleteSphereTag),
  ]);
}

export default sphereSaga;
