import styled from 'styled-components';

import SchemaIcon from 'components/SchemaIcon';

import { flexStyle, iconStyle, rgba, textStyle } from 'styles/mixins';

export const RootOutlined = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between' })};
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.background};
  cursor: pointer;
  overflow: hidden;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
export const RootFilled = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between' })};
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.surfacePrimary};
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.surfacePrimary};
  overflow: hidden;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
export const StepContainer = styled.div`
  ${flexStyle({ align: 'center' })};
  flex-wrap: nowrap;
  width: 100%;
  gap: 16px;
`;
export const StepIconContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'center' })};
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => rgba(theme.colors.blue, 10)};
  border-radius: 3px;
  flex-shrink: 0;
`;
export const StepIcon = styled(SchemaIcon)`
  ${flexStyle({ align: 'center', justify: 'center' })};
  ${({ theme }) => iconStyle({ color: theme.colors.blue })}
  width: 24px;
  height: 24px;
`;
export const StepName = styled.span`
  ${textStyle({ color: 'textPrimary', size: '12px', weight: 400 })};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
