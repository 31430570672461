import { DEFAULT_STEP_NODE_ID } from '../constants';
import { NodeTypes } from '../enums';
import { XpiStudioEdges, XpiStudioNodes } from '../types';

export const toggleHideNodes = (isGoToActive: boolean) => (nodes: XpiStudioNodes) => {
  const isHidden = nodes.some((n) => n.hidden);

  if (isHidden && !isGoToActive) {
    return nodes.map((n) => (n.type === NodeTypes.stepDefault ? { ...n, hidden: false } : n));
  }

  if (!isHidden && isGoToActive) {
    return nodes.map((n) => (n.type === NodeTypes.stepDefault ? { ...n, hidden: true } : n));
  }

  // remove unnecessary renders;
  return nodes;
};

export const toggleHideEdges = (isGoToActive: boolean) => (edges: XpiStudioEdges) => {
  const isHidden = edges.some((e) => e.hidden);

  if (isHidden && !isGoToActive) {
    return edges.map((e) => (e.target.includes(DEFAULT_STEP_NODE_ID) ? { ...e, hidden: false } : e));
  }

  if (!isHidden && isGoToActive) {
    return edges.map((e) => (e.target.includes(DEFAULT_STEP_NODE_ID) ? { ...e, hidden: true } : e));
  }

  // remove unnecessary renders;
  return edges;
};
