import React, { ChangeEvent, FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { FormFieldInputNumberTypes } from 'jsonSchema';
import { buildDataCyValue } from 'utils/cy';
import { isDef, isDefAndNotNull } from 'utils/def';

import { ICONS_TYPES } from '../Icon';
import Loader from '../Loader';

import {
  AttentionIcon,
  ClearIcon,
  ErrorMessage,
  ErrorMessageContainer,
  InputContainer,
  InputWrapper,
  Label,
  RequiredMark,
  Root,
  SearchIcon,
  StyledInput,
  StyledTextArea,
  TextAreaWrapper,
} from './styles';
import { Props } from './types';

const Input: FC<Props> = ({
  backgroundColor,
  className,
  dataCy = {},
  disabled,
  error,
  isSearch,
  label,
  loading,
  multi,
  name,
  numberType,
  onBlur,
  onChange,
  onClick,
  placeholder,
  postfix,
  prefix,
  readOnly = false,
  required,
  rows = 2,
  secure,
  showClear = true,
  showErrorText = true,
  touched,
  value,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selection, setSelection] = useState<(number | null)[]>([null, null]);
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const disabledOrLoading = disabled || loading;
  const showClearButton = useMemo(
    () => !!value?.length && showClear && !readOnly && !disabled,
    [value, showClear, readOnly, disabled]
  );

  useLayoutEffect(() => {
    if (selection[0] && selection[1] && inputRef.current) {
      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = selection;
    }
  }, [selection]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      if (isDef(onChange)) {
        let valueFormatted = e.target.value.replace(/^\s+/, '');

        if (numberType === FormFieldInputNumberTypes.integer) {
          valueFormatted = valueFormatted.replace(/[^0-9]/g, '');
        } else if (numberType === FormFieldInputNumberTypes.number) {
          valueFormatted = valueFormatted.replace(/[^(0-9)|\\.]/g, '');
        }

        onChange(valueFormatted);
      }
      setSelection([e.target.selectionStart, e.target.selectionEnd]);
    },
    [onChange, numberType]
  );
  const handleClear = useCallback(() => {
    if (isDef(onChange)) {
      onChange('');
    }
    setSelection([null, null]);
  }, [onChange]);
  const handleFocus = useCallback(() => {
    setIsActive(true);
  }, []);
  const handleBlur = useCallback(() => {
    setIsActive(false);
    onBlur?.();
  }, [onBlur]);

  const getDataCy = useCallback(
    (elementName: keyof typeof dataCy) => dataCy?.[elementName] || buildDataCyValue({ elementName, fieldName: name }),
    [dataCy, name]
  );

  return (
    <Root className={className} data-cy={getDataCy('root')}>
      {label && (
        <Label htmlFor={name} data-cy={getDataCy('label')}>
          {required && !disabledOrLoading ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <InputContainer
        $active={isActive}
        disabled={disabled}
        readOnly={readOnly}
        error={error}
        touched={touched}
        backgroundColor={backgroundColor}
        onClick={onClick}
      >
        {!multi ? (
          <InputWrapper data-cy={getDataCy('inputWrapper')}>
            {isSearch && <SearchIcon icon={ICONS_TYPES.Search} size={20} />}
            {prefix}
            <StyledInput
              data-cy={getDataCy('input')}
              {...{
                backgroundColor,
                disabled: disabledOrLoading,
                onBlur: handleBlur,
                onChange: handleChange,
                onFocus: handleFocus,
                placeholder,
                readOnly,
                ref: inputRef,
                type: secure ? 'password' : 'text',
                value: isDefAndNotNull(value) ? value : '',
              }}
            />
            {postfix}
            {showClearButton && <ClearIcon icon={ICONS_TYPES.XMark} size={20} onClick={handleClear} />}
            {loading && <Loader size={20} />}
          </InputWrapper>
        ) : (
          <TextAreaWrapper>
            <StyledTextArea
              {...{
                backgroundColor,
                disabled: disabledOrLoading,
                onBlur: handleBlur,
                onChange: handleChange,
                onFocus: handleFocus,
                placeholder,
                ref: inputRef,
                rows,
                value: isDefAndNotNull(value) ? value : '',
              }}
            />
            {showClearButton && <ClearIcon icon={ICONS_TYPES.XMark} size={20} onClick={handleClear} />}
          </TextAreaWrapper>
        )}
      </InputContainer>
      {error && touched && showErrorText && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export type { Props };

export default Input;
