import { FormFields, extractValue } from 'jsonSchema';

export const checkIfFormFieldsContainString = (value: FormFields, searchStr: string, skipFields: string[]): boolean => {
  const lowerCaseStr = searchStr.toLowerCase();
  return Object.keys(value).some((key) => {
    const extractedValue = extractValue(value[key]);
    return (
      lowerCaseStr?.length === 0 ||
      (extractedValue?.toString()?.toLowerCase()?.includes(lowerCaseStr) && !skipFields.includes(key))
    );
  });
};
