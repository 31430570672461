import { FormConfig, FormFields, ObjectSchema, Schema, isFormFieldArray, schemaToFormConfig } from 'jsonSchema';
import { SERVICE_PATHS_FIELD_NAME } from 'models/Service/constants';

import { ParameterTypes } from './enums';
import { PathParametersType } from './types';

export const getTabKey = (parameterType: string, pathFieldId?: string) => {
  return `${parameterType}${pathFieldId ? `_${pathFieldId}` : ''}`;
};

export const getParameterType = (tabKey: string): ParameterTypes => {
  if (!tabKey.includes('_')) {
    return tabKey as ParameterTypes;
  }
  return tabKey.split('_')[0] as ParameterTypes;
};

export const findParameterSchema = (schema: Schema): FormConfig | undefined => {
  const config = schemaToFormConfig(schema)?.[0];
  if (isFormFieldArray(config)) {
    const item = config.items?.find((item) => item.name === 'properties');
    if (item?.fieldSchema) {
      const parameterConfig = schemaToFormConfig({ ...item.fieldSchema, required: [] } as ObjectSchema);
      return parameterConfig;
    }
  }
  return undefined;
};

export const getArrayFromFields = (fields: FormFields, fieldName?: string) =>
  fields[fieldName || SERVICE_PATHS_FIELD_NAME] as FormFields[];

export const getActualParameters = (field: PathParametersType, dictionary: Record<string, PathParametersType>) => {
  let activeParameterType: PathParametersType | undefined = dictionary[field?.customId || ''];
  if (!activeParameterType) {
    activeParameterType = field;
  }
  return activeParameterType;
};
