import styled, { css } from 'styled-components';

import { textStyle } from 'styles/mixins';

import { PADDING } from './constans';

export const Root = styled.div<{ disabled?: boolean }>`
  flex-shrink: 0;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;
export const TooltipContainer = styled.div`
  ${textStyle({ color: 'background', lineHeight: 1.2, size: '12px', textAlign: 'center', weight: 400 })};
  background-color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.4px;
  white-space: pre-wrap;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;

  z-index: 100;
  padding: ${PADDING}px;
  border-radius: 4px;
`;
export const TooltipArrow = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.colors.primary};
`;
