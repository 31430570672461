import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

export const endpoint = 'flows';

export const fetchFlows = (params: {
  bp: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ flows: Flow[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const fetchFlow = (params: {
  bp: Initiative['name'];
  name: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ flow: Flow }>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.get,
    params: omit(params, 'name'),
  });

export const createFlow = (data: {
  bp: Initiative['name'];
  data: Pick<Flow, 'name' | 'description'>;
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const deleteFlow = (params: {
  bp: Initiative['name'];
  name: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });

export const uploadFlowPreview = (
  params: {
    bp: Initiative['name'];
    flow: Flow['name'];
    project: Project['name'];
    sphere: Sphere['name'];
  },
  file: File
): Promise<AxiosResponse<APIResponse<object>>> => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return request({
    data: formData,
    endpoint: `${endpoint}/${params.flow}/thumbnail`,
    method: Methods.put,
    params: omit(params, 'flow'),
  });
};
