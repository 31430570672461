import styled from 'styled-components';

import Button from 'components/Button';
import Input from 'components/Input';

import { flexStyle } from 'styles/mixins';

export const Root = styled.div``;
export const TopContainer = styled.div<{ isEndAligned?: boolean }>`
  ${({ isEndAligned }) => flexStyle({ justify: isEndAligned ? 'flex-end' : 'space-between' })}
  margin-bottom: 24px;
  grid-gap: 12px;
`;
export const StyledSearch = styled(Input)`
  width: 370px;
`;
export const CellWrapper = styled.div`
  ${flexStyle({ align: 'flex-start', justify: 'space-between', wrap: 'nowrap' })}
  grid-gap: 8px;
`;
export const CellValue = styled.span``;
export const CellActions = styled.div`
  ${flexStyle({ align: 'flex-end', wrap: 'nowrap' })}
  grid-gap: 4px;
  margin-top: -8px;
`;
export const StyledButton = styled(Button)`
  width: 30px;
  height: 30px;
`;
