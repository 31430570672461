import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ICONS_TYPES } from 'components/Icon';
import Modal, { Sizes } from 'components/Modal';
import { TreeNodeProps } from 'components/Tree/types';

import { modalClose, modalOpen } from 'store/modal/actions';

import GlobalVariableList from './components/GlobalVariableList';
import { GLOBAL_VARIABLES_MODAL_ID } from './constants';
import { FolderIcon, FolderIconContainer, Root, TitleContainer } from './styles';

const GlobalVariablesNode: FC<TreeNodeProps> = ({ className, node }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(modalOpen({ id: GLOBAL_VARIABLES_MODAL_ID }));
  }, [dispatch]);
  const handleCancel = useCallback(() => {
    dispatch(modalClose.request({ id: GLOBAL_VARIABLES_MODAL_ID }));
  }, [dispatch]);

  return (
    <>
      <Root className={className} onClick={handleClick}>
        <FolderIconContainer>
          <FolderIcon icon={ICONS_TYPES.Files} size={14} />
        </FolderIconContainer>
        <TitleContainer>{node.name}</TitleContainer>
      </Root>
      <Modal
        id={GLOBAL_VARIABLES_MODAL_ID}
        size={Sizes.medium}
        renderTitle={() => 'Global variables'}
        renderChildren={() => <GlobalVariableList onCancel={handleCancel} />}
      />
    </>
  );
};

export default GlobalVariablesNode;
