import React, { FC } from 'react';
import ReactDOM from 'react-dom';

import LoaderGif from 'assets/images/loader.gif';

import { LOADER_DEFAULT_SIZE } from './constants';
import { Modes } from './enums';
import { Root, RootFullScreen } from './styles';
import { Props } from './types';

const Loader: FC<Props> = ({ center = false, className, mode = Modes.default, size = LOADER_DEFAULT_SIZE }) => {
  if (mode === Modes.fullScreen) {
    return ReactDOM.createPortal(
      <RootFullScreen>
        <img src={LoaderGif} alt="loading" />
      </RootFullScreen>,
      document.body
    );
  }

  return <Root className={className} center={center} size={size} />;
};

export * from './constants';
export { Modes };
export type { Props };
export default Loader;
