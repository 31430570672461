import React, { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button, { StyleTypes } from 'components/Button';
import FormField from 'components/FormField';

import { isDefAndNotNull } from '../../utils/def';

import { postDev } from './store/actions';
import { selectPostLoading } from './store/selectors';
import { ButtonsContainer, FormFieldsContainer, Root, StyledInput, SubmitButton } from './styles';
import { DevTestFormFields, Props } from './types';

const DevTestForm: FC<Props> = ({ className, onCancel }) => {
  const dispatch = useDispatch();
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const { control, formState, handleSubmit } = useForm<DevTestFormFields>({
    mode: 'onTouched',
  });
  const isDisabled = useMemo(() => !formState.isValid || !formState.isDirty, [formState.isValid, formState.isDirty]);
  const postLoading = useSelector(selectPostLoading);
  const onSubmit = useCallback(
    (data: DevTestFormFields) => {
      if (
        isDefAndNotNull(sphereName) &&
        isDefAndNotNull(projectName) &&
        isDefAndNotNull(initiativeName) &&
        isDefAndNotNull(flowName)
      ) {
        dispatch(
          postDev.request({
            bp: initiativeName,
            data: {
              text: data.text,
            },
            flow: flowName,
            project: projectName,
            sphere: sphereName,
          })
        );
      }
    },
    [sphereName, projectName, initiativeName, flowName, dispatch]
  );

  return (
    <Root className={className} onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsContainer>
        <FormField<DevTestFormFields, 'text'>
          required
          controllerProps={{
            control,
            name: 'text',
          }}
          render={(props) => <StyledInput {...props} label="Text" multi />}
        />
      </FormFieldsContainer>
      <ButtonsContainer>
        <SubmitButton type="submit" disabled={isDisabled} label="Save" loading={postLoading} />
        <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
      </ButtonsContainer>
    </Root>
  );
};

export default DevTestForm;
