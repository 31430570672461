import { AxiosResponse } from 'axios';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'dev';

export const postDev = (data: {
  bp: Initiative['name'];
  data: {
    text: string;
  };
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint: endpoint,
    method: Methods.post,
  });
