import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectSpheres } from 'store/app/selectors';

import { Sizes } from '../ExpandableView';
import { ICONS_TYPES } from '../Icon';
import Loader from '../Loader';

import {
  Divider,
  ExpandableContentContainer,
  LinkIcon,
  LinkText,
  LinksContainer,
  LogoText,
  NavMenuContentBottom,
  NavMenuContentScroll,
  NavMenuContentTop,
  Root,
  SphereIcon,
  StyledDummyText,
  StyledExpandableView,
  StyledLink,
  StyledSearch,
  StyledThemeSwitcher,
} from './styles';
import { Props } from './types';

const NavMenu: FC<Props> = ({ className }) => {
  const spheres = useSelector(selectSpheres);
  const { list: spheresList, loading: spheresLoading } = spheres;

  return (
    <Root className={className}>
      <NavMenuContentTop>
        <LogoText>Magic® xpi</LogoText>
        <StyledSearch
          backgroundColor="surfacePrimary"
          name="navMenuSearch"
          isSearch
          placeholder="Search"
          value=""
          onChange={console.log}
        />
      </NavMenuContentTop>
      <NavMenuContentScroll>
        <LinksContainer>
          <StyledLink to="/recents">
            <LinkIcon icon={ICONS_TYPES.Clock} size={20} />
            Recents
          </StyledLink>
          <StyledLink to="/drafts">
            <LinkIcon icon={ICONS_TYPES.Document} size={20} />
            Drafts
          </StyledLink>
        </LinksContainer>
        <StyledDummyText>Favorite files</StyledDummyText>
        <Divider />
        {spheresLoading ? (
          <Loader size={24} />
        ) : (
          <StyledExpandableView label="Spheres" defaultExpanded showArrowIcon={false} labelSize={Sizes.small}>
            <ExpandableContentContainer>
              {spheresList.map((sphere) => (
                <StyledLink key={sphere.name} to={encodeURI(`/spheres/${sphere.name}`)}>
                  <SphereIcon color={sphere.color} icon={ICONS_TYPES.Sphere} size={18} />
                  <LinkText>{sphere.name}</LinkText>
                </StyledLink>
              ))}
              <StyledLink to="/start">
                <LinkIcon icon={ICONS_TYPES.Plus} size={20} />
                Create new sphere
              </StyledLink>
            </ExpandableContentContainer>
          </StyledExpandableView>
        )}
      </NavMenuContentScroll>
      <NavMenuContentBottom>
        <StyledThemeSwitcher />
      </NavMenuContentBottom>
    </Root>
  );
};

export type { Props };

export default NavMenu;
