import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ICONS_TYPES } from 'components/Icon';
import Modal, { Sizes } from 'components/Modal';
import { TreeNodeProps } from 'components/Tree/types';

import { modalClose, modalOpen } from 'store/modal/actions';

import InitiativeVariableList from './components/InitiativeVariableList';
import { INITIATIVE_VARIABLES_MODAL_ID } from './constants';
import { FolderIcon, FolderIconContainer, Root, TitleContainer } from './styles';

const InitiativeVariablesNode: FC<TreeNodeProps> = ({ className, getParentNode, node }) => {
  const dispatch = useDispatch();
  const initiativeNode = useMemo(() => getParentNode(node), [getParentNode, node]);
  const projectNode = useMemo(() => getParentNode(initiativeNode), [getParentNode, initiativeNode]);
  const modalId = useMemo(
    () => `${INITIATIVE_VARIABLES_MODAL_ID}_${projectNode?.name}_${initiativeNode?.name}`,
    [initiativeNode, projectNode]
  );
  const handleClick = useCallback(() => {
    dispatch(modalOpen({ id: modalId }));
  }, [modalId, dispatch]);
  const handleCancel = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);

  return (
    <>
      <Root className={className} onClick={handleClick}>
        <FolderIconContainer>
          <FolderIcon icon={ICONS_TYPES.Files} size={14} />
        </FolderIconContainer>
        <TitleContainer>{node.name}</TitleContainer>
      </Root>
      <Modal
        id={modalId}
        size={Sizes.medium}
        renderTitle={() => `${initiativeNode?.name} variables`}
        renderChildren={() => <InitiativeVariableList initiativeName={initiativeNode?.name || ''} onCancel={handleCancel} />}
      />
    </>
  );
};

export default InitiativeVariablesNode;
