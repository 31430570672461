import styled, { css } from 'styled-components';

import { CLOSING_TIMEOUT_MS } from 'store/modal/constants';
import { flexStyle, scrollStyle, textStyle } from 'styles/mixins';

import Button from '../Button';
import Icon from '../Icon';

import { CLOSING_CLASSNAME } from './constants';
import { Modes, Sizes } from './enums';
import { Props } from './types';

export const Root = styled.div<Partial<Props<unknown> & { animated?: boolean }>>`
  ${({ mode }) => flexStyle(mode === Modes.default ? { align: 'center', justify: 'center' } : { justify: 'flex-end' })};
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;

  ${({ animated, mode }) =>
    mode === Modes.rightSlide &&
    animated &&
    css`
      @keyframes fadeIn {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }
        100% {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      @keyframes fadeOut {
        0% {
          background-color: rgba(0, 0, 0, 0.5);
        }
        100% {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      animation: fadeIn ${CLOSING_TIMEOUT_MS}ms ease-in;

      &.${CLOSING_CLASSNAME} {
        animation: fadeOut ${CLOSING_TIMEOUT_MS}ms ease-out;
      }
    `}
`;
export const Content = styled.div<Partial<Props<unknown> & { animated?: boolean }>>`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  ${scrollStyle()};
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ mode }) => `${mode === Modes.default ? 32 : 40}px`};
  border-radius: ${({ mode }) => `${mode === Modes.default ? 4 : 0}px`};
  overflow-y: ${({ overflowY }) => overflowY};

  ${({ animated, mode, size }) => {
    switch (mode) {
      case Modes.rightSlide:
        return css`
          width: 550px;
          height: 100vh;
          position: relative;

          ${animated &&
          css`
            @keyframes openSlideFromRight {
              0% {
                right: -550px;
              }
              100% {
                right: 0;
              }
            }
            @keyframes closeSlideToRight {
              0% {
                right: 0;
              }
              100% {
                right: -550px;
              }
            }

            animation: openSlideFromRight ${CLOSING_TIMEOUT_MS}ms ease-in;

            &.${CLOSING_CLASSNAME} {
              animation: closeSlideToRight ${CLOSING_TIMEOUT_MS}ms ease-out;
            }
          `}
        `;
      default: // default
        return css`
          position: absolute;
          top: 15%;
          max-height: calc(100% - 25%);

          ${() => {
            switch (size) {
              case Sizes.small:
                return css`
                  width: 500px;
                `;
              case Sizes.large:
                return css`
                  width: 80%;
                `;
              default: // medium
                return css`
                  width: 50%;
                `;
            }
          }}
        `;
    }
  }}
`;
export const Header = styled.div`
  ${flexStyle({ align: 'flex-start', justify: 'space-between', wrap: 'nowrap' })};
  margin-bottom: 30px;
`;
export const Title = styled.h1`
  ${textStyle({ color: 'textPrimary', lineHeight: 1.2, size: '18px', textAlign: 'left', weight: 500 })};
  letter-spacing: 0.15px;
  max-width: calc(100% - 20px);
  word-wrap: break-word;
`;
export const ActionsContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  cursor: pointer;
`;
export const ConfirmContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const ConfirmText = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1.4, size: '14px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.25px;
  margin-bottom: 50px;
`;
export const ConfirmButtonsContainer = styled.div`
  ${flexStyle({ justify: 'space-between', wrap: 'nowrap' })};
`;
export const ConfirmButton = styled(Button)`
  margin-left: 16px;
  flex: 1;
`;
