import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { APIResponse } from 'api';

export type ErrorType<ErrorData extends object = object> = AxiosResponse<APIResponse<ErrorData>>;
export type ErrMessageFallbackFn<ErrorData extends object = object> = (err: ErrorType<ErrorData>) => string;
export interface APICallHandler<ErrorData extends object = object> {
  catchHandler?: (err: ErrorType<ErrorData>) => Generator;
  errMessageFallback?: string | ErrMessageFallbackFn<ErrorData>;
  tryHandler: () => Generator;
}

export const isErrFallbackFn = <ErrorData extends object = object>(
  errMessageFallback: APICallHandler<ErrorData>['errMessageFallback']
): errMessageFallback is ErrMessageFallbackFn<ErrorData> => typeof errMessageFallback === 'function';

export function* apiCallHandler<ErrorData extends object = object>({
  catchHandler,
  errMessageFallback,
  tryHandler,
}: APICallHandler<ErrorData>): Generator {
  try {
    yield tryHandler();
  } catch (err: unknown) {
    const typedErr = err as ErrorType<ErrorData>;
    const {
      data: { result },
    } = typedErr;
    const errMessage = isErrFallbackFn(errMessageFallback) ? errMessageFallback(typedErr) : errMessageFallback;
    toast.error(errMessage || result.msg);
    yield catchHandler?.(typedErr);
  }
}
