export const NODE_DEFAULT_X = 0;
export const NODE_DEFAULT_Y = 0;
export const NODE_DEFAULT_POSITION = { x: NODE_DEFAULT_X, y: NODE_DEFAULT_Y };
export const EDGE_DEFAULT_LENGTH_PX = 80;
export const BRANCH_MARGIN_X = 130;
export const ROOT_NODE_DEFAULT_OFFSET = 30;

export const ROOT_NODE_ID = 'trigger-root';
export const DEFAULT_STEP_NODE_ID = 'default-step';
export const TARGET_HANDLE_DEFAULT_ID = 'destination-default';
export const SOURCE_HANDLE_DEFAULT_ID = 'source-default';
export const GO_TO_EDGE_PREFIX = 'go-to';
export const GO_TO_TARGET_LEFT_HANDLE_ID = 'go-to-destination_left';
export const STEP_TYPE_FILLER = 'STEP_FILLER';

export const FLOW_PREVIEW_GENERATE_DEBOUNCE_TIMER = 5000;
export const FLOW_PREVIEW_UPLOAD_INTERVAL_TIMER = 30000;
