import styled from 'styled-components';

import Button from 'components/Button';
import Input from 'components/Input';

import { flexStyle } from 'styles/mixins';

export const Root = styled.form`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
`;
export const FormFieldsContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  margin-bottom: 48px;
`;
export const StyledInput = styled(Input)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ justify: 'space-between', wrap: 'nowrap' })};
  flex-grow: 1;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
  width: 100%;
`;
