import React, { FC } from 'react';

import TablerIcon from 'components/TablerIcon';

import { Root, SvgIcon } from './styles';
import { Props } from './types';

const SchemaIcon: FC<Props> = ({ className, model }) => {
  return (
    <Root className={className}>
      {model?.svg && <SvgIcon dangerouslySetInnerHTML={{ __html: model.svg }} />}
      {!model?.svg && model?.tabler && <TablerIcon icon={model.tabler} />}
    </Root>
  );
};

export default SchemaIcon;
