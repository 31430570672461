import { ActionType, getType } from 'typesafe-actions';

import { Project } from 'models/Project';
import { ProjectTree } from 'models/ProjectTree';

import * as projectActions from './actions';

type ProjectActionType = ActionType<typeof projectActions>;

export type ProjectState = {
  deleteProjectLoading: boolean;
  initiatives: {
    createLoading: boolean;
    list: projectActions.FetchInitiativesSuccessPayload['businessProcesses'];
    loading: boolean;
  };
  project: Project | undefined;
  projectLoading: boolean;
  projectTree: ProjectTree | undefined;
  projectTreeLoading: boolean;
  updateProjectLoading: boolean;
};

const initialState: ProjectState = {
  deleteProjectLoading: false,
  initiatives: {
    createLoading: false,
    list: [],
    loading: false,
  },
  project: undefined,
  projectLoading: true,
  projectTree: undefined,
  projectTreeLoading: false,
  updateProjectLoading: false,
};

const projectReducer = (state: ProjectState = initialState, action: ProjectActionType): ProjectState => {
  switch (action.type) {
    case getType(projectActions.fetchProject.request):
      return {
        ...state,
        projectLoading: true,
      };
    case getType(projectActions.fetchProject.success):
      return {
        ...state,
        project: action.payload.project,
        projectLoading: false,
      };
    case getType(projectActions.fetchProject.failure):
      return {
        ...state,
        projectLoading: false,
      };

    case getType(projectActions.updateProject.request):
      return {
        ...state,
        updateProjectLoading: true,
      };
    case getType(projectActions.updateProject.success):
    case getType(projectActions.updateProject.failure):
      return {
        ...state,
        updateProjectLoading: false,
      };

    case getType(projectActions.deleteProject.request):
      return {
        ...state,
        deleteProjectLoading: true,
      };
    case getType(projectActions.deleteProject.success):
    case getType(projectActions.deleteProject.failure):
      return {
        ...state,
        deleteProjectLoading: false,
      };

    case getType(projectActions.fetchInitiatives.request):
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          loading: true,
        },
      };
    case getType(projectActions.fetchInitiatives.success):
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          list: action.payload.businessProcesses,
          loading: false,
        },
      };
    case getType(projectActions.fetchInitiatives.failure):
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          loading: false,
        },
      };

    case getType(projectActions.createInitiative.request):
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          createLoading: true,
        },
      };
    case getType(projectActions.createInitiative.success):
    case getType(projectActions.createInitiative.failure):
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          createLoading: false,
        },
      };

    case getType(projectActions.fetchProjectTree.request):
      return {
        ...state,
        projectTreeLoading: true,
      };
    case getType(projectActions.fetchProjectTree.success):
      return {
        ...state,
        projectTree: action.payload.project,
        projectTreeLoading: false,
      };
    case getType(projectActions.fetchProjectTree.failure):
      return {
        ...state,
        projectTreeLoading: false,
      };

    case getType(projectActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default projectReducer;
