import { ActionType, getType } from 'typesafe-actions';

import * as recentsActions from './actions';

type RecentsActionType = ActionType<typeof recentsActions>;

export type RecentsState = {
  recents: {
    businessProcesses: recentsActions.FetchRecentsSuccessPayload['businessProcesses'];
    projects: recentsActions.FetchRecentsSuccessPayload['projects'];
    spheres: recentsActions.FetchRecentsSuccessPayload['spheres'];
  };
  recentsLoading: boolean;
};

const initialState: RecentsState = {
  recents: {
    businessProcesses: [],
    projects: [],
    spheres: [],
  },
  recentsLoading: true,
};

const recentsReducer = (state: RecentsState = initialState, action: RecentsActionType): RecentsState => {
  switch (action.type) {
    case getType(recentsActions.fetchRecents.success):
      return {
        ...state,
        recents: action.payload,
        recentsLoading: false,
      };
    case getType(recentsActions.fetchRecents.request):
      return {
        ...state,
        recentsLoading: true,
      };
    case getType(recentsActions.fetchRecents.failure):
      return {
        ...state,
        recentsLoading: false,
      };

    case getType(recentsActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default recentsReducer;
