import { createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Service } from 'models/Service';
import { Sphere } from 'models/Sphere';

export type FetchServicesRequestPayload = {
  projectName: Project['name'];
  sphereName: Sphere['name'];
};
export type FetchServicesSuccessPayload = {
  list: Service[];
};
export const fetchServices = createAsyncAction(
  'projectServices/fetchServices/request',
  'projectServices/fetchServices/success',
  'projectServices/fetchServices/failure'
)<FetchServicesRequestPayload, FetchServicesSuccessPayload, undefined>();

export type CreateServiceRequestPayload = {
  data: Service & { project: Project['name']; sphere: Sphere['name'] };
  onSuccess?: (name: Service['name']) => void;
};
export const createService = createAsyncAction(
  'projectServices/createService/request',
  'projectServices/createService/success',
  'projectServices/createService/failure'
)<CreateServiceRequestPayload, undefined, undefined>();

export type UpdateServiceRequestPayload = {
  bp?: Initiative['name'];
  data: Service & { oldName: Service['name']; project: Project['name']; sphere: Sphere['name'] };
  flow?: Flow['name'];
};
export const updateService = createAsyncAction(
  'projectServices/updateService/request',
  'projectServices/updateService/success',
  'projectServices/updateService/failure'
)<UpdateServiceRequestPayload, undefined, undefined>();

export type CallServiceActionRequestPayload = {
  data: Service & { action: string; oldName: Service['name']; project: Project['name']; sphere: Sphere['name'] };
};
export const callServiceAction = createAsyncAction(
  'projectServices/callServiceAction/request',
  'projectServices/callServiceAction/success',
  'projectServices/callServiceAction/failure'
)<CallServiceActionRequestPayload, undefined, undefined>();

export type DeleteServiceRequestPayload = {
  data: Pick<Service, 'name' | 'type'> & { project: Project['name']; sphere: Sphere['name'] };
};
export const deleteService = createAsyncAction(
  'projectServices/deleteService/request',
  'projectServices/deleteService/success',
  'projectServices/deleteService/failure'
)<DeleteServiceRequestPayload, undefined, undefined>();
