import { Row } from '@tanstack/react-table';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button, { StyleTypes } from 'components/Button';
import SchemaArray from 'components/JsonSchemaRenderer/components/SchemaArrayRenderer';
import Loader from 'components/Loader';

import { FormConfig, FormFields, normalizeFields, schemaToFormConfig } from 'jsonSchema';
import { Variable } from 'models/Variable';
import { selectVariableSchemaByType } from 'store/app/selectors';
import { createVariable, deleteVariable, updateVariable } from 'store/variables/actions';
import { selectVariableCreating, selectVariableUpdating } from 'store/variables/selectors';
import { isDef } from 'utils/def';

import { SYSTEM_VARIABLE_EXPRESSION } from './constants';
import { ButtonsContainer, FormFieldsContainer, Root } from './styles';
import { Props } from './types';
import { buildVariableSchema } from './utils';

const VariableList: FC<Props> = ({ className, flowName, initiativeName, onCancel, variableLoading, variableType, variables }) => {
  const dispatch = useDispatch();
  const schema = useSelector(selectVariableSchemaByType(variableType));
  const { projectName, sphereName } = useParams();
  const variableCreating = useSelector(selectVariableCreating);
  const variableUpdating = useSelector(selectVariableUpdating);
  const arraySchema = useMemo(() => buildVariableSchema(schema), [schema]);
  const memoFormConfig = useMemo(() => (arraySchema ? schemaToFormConfig(arraySchema) : ({} as FormConfig)), [arraySchema]);
  const formDefaultValues = useMemo(() => normalizeFields(variables || [], memoFormConfig), [memoFormConfig, variables]);
  const methods = useForm<FormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  useEffect(() => {
    methods.reset(formDefaultValues);
  }, [formDefaultValues, methods]);
  const handleRowSubmit = useCallback(
    (newValue: FormFields, prevValue: FormFields | undefined, modalId: string) => {
      if (isDef(prevValue)) {
        const prev = prevValue as Variable;
        dispatch(
          updateVariable.request({
            bp: initiativeName!,
            data: newValue as Variable,
            flow: flowName!,
            modalId,
            name: prev.name,
            project: projectName!,
            sphere: sphereName!,
            variableType,
          })
        );
      } else {
        dispatch(
          createVariable.request({
            bp: initiativeName!,
            data: newValue as Variable,
            flow: flowName!,
            modalId,
            project: projectName!,
            sphere: sphereName!,
            variableType,
          })
        );
      }
    },
    [dispatch, projectName, sphereName, variableType, initiativeName, flowName]
  );
  const handleRowDelete = useCallback(
    (value: FormFields) => {
      const variable = value as Variable;
      dispatch(
        deleteVariable.request({
          bp: initiativeName!,
          flow: flowName!,
          name: variable.name,
          project: projectName!,
          sphere: sphereName!,
          variableType,
        })
      );
    },
    [dispatch, projectName, sphereName, variableType, initiativeName, flowName]
  );
  const handleRowReadonlyState = useCallback((row: Row<FormFields>) => {
    const variable = row.original as Variable;
    return SYSTEM_VARIABLE_EXPRESSION.test(variable.name);
  }, []);

  return (
    <Root className={className}>
      {variableLoading && <Loader />}
      {!variableLoading && (
        <>
          {arraySchema && (
            <FormFieldsContainer>
              <FormProvider {...methods}>
                <SchemaArray
                  schema={arraySchema}
                  onRowSubmit={handleRowSubmit}
                  onRowDelete={handleRowDelete}
                  rowSubmitting={variableCreating || variableUpdating}
                  getRowReadonlyState={handleRowReadonlyState}
                />
              </FormProvider>
            </FormFieldsContainer>
          )}
        </>
      )}
      <ButtonsContainer>
        <Button label="Close" onClick={onCancel} styleType={StyleTypes.link} />
      </ButtonsContainer>
    </Root>
  );
};

export default VariableList;
