import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { OptionProps, SingleValue } from 'react-select';

import { ResourceType } from 'models/ResourceType';
import { selectResourceTypes } from 'store/app/selectors';
import { isDefAndNotNull } from 'utils/def';

import ResourceForm from '../ResourceForm';

import { OptionValueContainer, OptionValueIcon, Root, StyledSelect } from './styles';
import { Props } from './types';

const CreateResourceForm: FC<Props> = ({ createLoading, existingResourcesNames, filterOptions, onCancel, onSubmit }) => {
  const { list: resourceTypes } = useSelector(selectResourceTypes);
  const resourceTypesFiltered = useMemo(
    () => (isDefAndNotNull(filterOptions) ? resourceTypes.filter((option) => filterOptions(option)) : resourceTypes),
    [filterOptions, resourceTypes]
  );
  const onlySingleOption = useMemo(() => resourceTypesFiltered.length === 1, [resourceTypesFiltered.length]);
  const [selectedResourceType, setSelectedResourceType] = useState<SingleValue<ResourceType> | undefined>(
    onlySingleOption ? resourceTypesFiltered[0] : undefined
  );
  const selectedResource = useMemo(
    () => (selectedResourceType ? { data: {}, isValidated: undefined, name: '', type: selectedResourceType.type } : undefined),
    [selectedResourceType]
  );
  const Option = (props: OptionProps<ResourceType, false>) => {
    const { data } = props;
    return (
      <OptionValueContainer>
        <OptionValueIcon model={data.icon} />
        {data.type}
      </OptionValueContainer>
    );
  };

  return (
    <Root>
      <StyledSelect<ResourceType, false>
        name="resourceTypes"
        onChange={setSelectedResourceType}
        value={selectedResourceType}
        disabled={onlySingleOption}
        placeholder="Choose a resource you want to add"
        options={resourceTypesFiltered}
        isSearchable={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect
        getOptionLabel={(option) => option.type}
        getOptionValue={(option) => option.type}
        components={{
          Option,
        }}
        styles={{ option: () => ({ whiteSpace: 'normal' }) }}
      />
      {selectedResourceType && selectedResource && (
        <ResourceForm
          type={selectedResourceType}
          model={selectedResource}
          resourceNameListToValidate={existingResourcesNames}
          onSubmit={onSubmit}
          onCancel={onCancel}
          createLoading={createLoading}
        />
      )}
    </Root>
  );
};

export default CreateResourceForm;
