import styled from 'styled-components';

import { flexStyle, rgba } from 'styles/mixins';

import { Props } from './types';

export const Root = styled.div<Props>`
  width: ${({ size }) => (size ? `${size}px` : '100%')};
  height: ${({ size }) => (size ? `${size}px` : '100%')};
  position: relative;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: ${({ theme }) => theme.colors.primary};
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  animation: spin 1s linear infinite;
  margin: ${({ center }) => (center ? 'auto' : 'none')};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const RootFullScreen = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'center', justify: 'center' })};
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: ${({ theme }) => rgba(theme.colors.background, 70)};

  @keyframes fadeIn {
    0% {
      background-color: ${({ theme }) => rgba(theme.colors.background, 0)};
    }
    100% {
      background-color: ${({ theme }) => rgba(theme.colors.background, 70)};
    }
  }

  animation: fadeIn 300ms ease-in;
`;
