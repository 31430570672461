export enum FormFieldSubTypes {
  array = 'array',
  dateInput = 'dateInput',
  expressionInput = 'expressionInput',
  fileInput = 'fileInput',
  resourcesSelect = 'resourcesSelect',
  selectDatasource = 'selectDatasource',
  servicesSelect = 'servicesSelect',
  timeInput = 'timeInput',
}
