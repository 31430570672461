import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import Button from '../Button';
import Input from '../Input';

export const Root = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const StyledInput = styled(Input)`
  width: 100%;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ justify: 'flex-end', wrap: 'nowrap' })};
  margin-top: 24px;
`;
export const StyledButton = styled(Button)`
  margin-top: -8px;
  margin-bottom: -8px;
  margin-right: -6px;
`;
