import React, { FC, useCallback, useMemo } from 'react';
import { SingleValue } from 'react-select';

import { isDefAndNotNull } from 'utils/def';

import { ICONS_TYPES } from '../Icon';

import {
  AttentionIcon,
  Delimiter,
  ErrorMessage,
  ErrorMessageContainer,
  Label,
  RequiredMark,
  Root,
  SelectorsWrapper,
  StyledSelect,
} from './styles';
import { OptionType, Props } from './types';
import { convertToOptionType } from './utils';

const TimePicker: FC<Props> = ({
  className,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  readonly,
  required,
  touched,
  value,
}) => {
  const parseValue = useCallback((value: string | undefined) => {
    const splitValues = isDefAndNotNull(value) ? value.split(':') : undefined;

    if (!splitValues || splitValues.length != 3) {
      return {
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
      };
    }

    const hours = splitValues[0];
    const minutes = splitValues[1];
    const seconds = splitValues[2];

    return {
      hours: convertToOptionType(hours),
      minutes: convertToOptionType(minutes),
      seconds: convertToOptionType(seconds),
    };
  }, []);
  const buildValue = useCallback(
    (hours: OptionType | undefined, minutes: OptionType | undefined, seconds: OptionType | undefined) => {
      return `${hours ? hours.value : '00'}:${minutes ? minutes.value : '00'}:${seconds ? seconds.value : '00'}`;
    },
    []
  );

  const minutesSecondsOptions: OptionType[] = useMemo(
    () =>
      Array(60)
        .fill(0)
        .map((el, i) => convertToOptionType(i < 10 ? '0' + i : i.toString())),
    []
  );
  const hoursOptions: OptionType[] = useMemo(() => minutesSecondsOptions.slice(0, 24), [minutesSecondsOptions]);
  const parsedValue = useMemo(() => parseValue(value), [parseValue, value]);
  const selectProps = useMemo(
    () => ({
      disabled: disabled,
      error: error,
      getOptionLabel: (option: OptionType) => option.value,
      getOptionValue: (option: OptionType) => option.value,
      isSearchable: false,
      onBlur: onBlur,
      placeholder: placeholder,
      readonly: readonly,
      showErrorText: false,
      touched: touched,
    }),
    [disabled, error, onBlur, placeholder, readonly, touched]
  );

  const handleChangeHours = useCallback(
    (value: SingleValue<OptionType>) => {
      onChange(buildValue(value!, parsedValue.minutes, parsedValue.seconds));
    },
    [buildValue, onChange, parsedValue.minutes, parsedValue.seconds]
  );
  const handleChangeMinutes = useCallback(
    (value: SingleValue<OptionType>) => {
      onChange(buildValue(parsedValue.hours, value!, parsedValue.seconds));
    },
    [buildValue, onChange, parsedValue.hours, parsedValue.seconds]
  );
  const handleChangeSeconds = useCallback(
    (value: SingleValue<OptionType>) => {
      onChange(buildValue(parsedValue.hours, parsedValue.minutes, value!));
    },
    [buildValue, onChange, parsedValue.hours, parsedValue.minutes]
  );

  return (
    <Root className={className}>
      {label && (
        <Label htmlFor={name}>
          {required && !disabled ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <SelectorsWrapper>
        <StyledSelect<OptionType, false>
          {...selectProps}
          options={hoursOptions}
          name={`${name}Hours`}
          onChange={handleChangeHours}
          value={parsedValue.hours}
        />
        <Delimiter>:</Delimiter>
        <StyledSelect<OptionType, false>
          {...selectProps}
          options={minutesSecondsOptions}
          name={`${name}Minutes`}
          onChange={handleChangeMinutes}
          value={parsedValue.minutes}
        />
        <Delimiter>:</Delimiter>
        <StyledSelect<OptionType, false>
          {...selectProps}
          options={minutesSecondsOptions}
          name={`${name}Seconds`}
          onChange={handleChangeSeconds}
          value={parsedValue.seconds}
        />
      </SelectorsWrapper>
      {error && touched && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export type { Props };

export default TimePicker;
