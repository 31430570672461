import React, { FC } from 'react';

const Bolt: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M11.9173 2.75V9.16667H17.4173L10.084 19.25V12.8333H4.58398L11.9173 2.75Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bolt;
