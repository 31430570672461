import { DndContext } from '@dnd-kit/core';
import React, { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchResources } from 'store/projectResources/actions';
import { fetchServices } from 'store/projectServices/actions';
import { fetchTags } from 'store/projectTags/actions';
import {
  tryFetchContextVariables,
  tryFetchFlowVariables,
  tryFetchGlobalVariables,
  tryFetchInitiativeVariables,
} from 'store/variables/actions';
import { isDef } from 'utils/def';

import ErrorFallback from '../ErrorFallback';
import FlowNavMenu from '../FlowNavMenu';
import FlowTabs from '../FlowTabs';
import Header from '../Header';
import NavMenu from '../NavMenu';

import { Content, ContentWrapper, Root } from './styles';
import { Props } from './types';

const BasePageLayout: FC<Props> = ({ children, settings }) => {
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDef(projectName) && isDef(sphereName)) {
      dispatch(tryFetchGlobalVariables({ project: projectName, sphere: sphereName }));
      dispatch(tryFetchContextVariables({ project: projectName, sphere: sphereName }));
      dispatch(fetchResources.request({ projectName: projectName, sphereName: sphereName }));
      dispatch(fetchTags.request({ project: projectName, sphere: sphereName }));
      dispatch(fetchServices.request({ projectName: projectName, sphereName: sphereName }));
    }
  }, [dispatch, sphereName, projectName]);
  useEffect(() => {
    if (isDef(projectName) && isDef(sphereName) && isDef(initiativeName)) {
      dispatch(tryFetchInitiativeVariables({ bp: initiativeName, project: projectName, sphere: sphereName }));
    }
  }, [dispatch, sphereName, projectName, initiativeName]);
  useEffect(() => {
    if (isDef(projectName) && isDef(sphereName) && isDef(initiativeName) && isDef(flowName)) {
      dispatch(tryFetchFlowVariables({ bp: initiativeName, flow: flowName, project: projectName, sphere: sphereName }));
    }
  }, [dispatch, sphereName, projectName, initiativeName, flowName]);

  return (
    <Root>
      {settings?.canvas && <FlowTabs />}
      <ContentWrapper canvas={settings?.canvas}>
        <DndContext>
          {settings?.canvas ? <FlowNavMenu /> : <NavMenu />}
          <Content>
            {settings?.headerVisible && <Header settings={settings}></Header>}
            <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
          </Content>
        </DndContext>
      </ContentWrapper>
    </Root>
  );
};

export type { Props };

export default BasePageLayout;
