import React, { FC } from 'react';

const Array: FC = () => (
  <svg viewBox="0 0 14 14" fill="none">
    <path
      d="M9.91667 9.91634V11.083C9.91667 11.3924 9.79375 11.6892 9.57496 11.908C9.35616 12.1268 9.05942 12.2497 8.75 12.2497H2.91667C2.60725 12.2497 2.3105 12.1268 2.09171 11.908C1.87292 11.6892 1.75 11.3924 1.75 11.083V5.83301C1.75 5.52359 1.87292 5.22684 2.09171 5.00805C2.3105 4.78926 2.60725 4.66634 2.91667 4.66634H4.08333M5.25 2.33301H7L8.16667 3.49967H11.0833C11.3928 3.49967 11.6895 3.62259 11.9083 3.84138C12.1271 4.06018 12.25 4.35692 12.25 4.66634V8.74967C12.25 9.05909 12.1271 9.35584 11.9083 9.57463C11.6895 9.79343 11.3928 9.91634 11.0833 9.91634H5.25C4.94058 9.91634 4.64383 9.79343 4.42504 9.57463C4.20625 9.35584 4.08333 9.05909 4.08333 8.74967V3.49967C4.08333 3.19026 4.20625 2.89351 4.42504 2.67472C4.64383 2.45592 4.94058 2.33301 5.25 2.33301Z"
      stroke="currentColor"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Array;
