import styled from 'styled-components';

import Button from 'components/Button';

import { flexStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
`;
export const FormFieldsContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  margin-bottom: 40px;
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ justify: 'flex-end', wrap: 'nowrap' })};
  margin-top: auto;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
`;
