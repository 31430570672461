import moment from 'moment/moment';

import { DayType } from './types';

export const convertToDayType = (
  value: number,
  disabled: boolean,
  formatSchema: string,
  selectedDate: moment.Moment,
  month: moment.Moment,
  isValidDate: boolean
): DayType => {
  const today = moment();
  const date = moment([month.year(), month.month() + 1, value], formatSchema).format(formatSchema);
  return {
    date,
    disabled,
    isToday: today.isSame(moment([month.year(), month.month() + 1, value], formatSchema), 'day'),
    selected: isValidDate && selectedDate.isSame(moment([month.year(), month.month() + 1, value], formatSchema), 'day'),
    value,
  };
};
