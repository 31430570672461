import { createSelector } from 'reselect';

import { Initiative } from 'models/Initiative';
import { RootState } from 'store/types';
import { isDef } from 'utils/def';

import { FlowTabsState } from './reducer';

const selectFlowTabsState = (state: RootState) => state.components.flowTabs;

export const selectFlows = createSelector(selectFlowTabsState, (flowTabsState: FlowTabsState) => flowTabsState.flows);
export const selectFlowsLoading = createSelector(
  selectFlowTabsState,
  (flowTabsState: FlowTabsState) => flowTabsState.flowsLoading
);
export const selectDeleteFlowLoading = createSelector(
  selectFlowTabsState,
  (flowTabsState: FlowTabsState) => flowTabsState.deleteFlowLoading
);
export const selectCreateFlowLoading = createSelector(
  selectFlowTabsState,
  (flowTabsState: FlowTabsState) => flowTabsState.createFlowLoading
);
export const selectFlowTabs = (initiativeName: Initiative['name'] | undefined) =>
  createSelector(selectFlowTabsState, (flowTabsState: FlowTabsState) =>
    isDef(initiativeName) && isDef(flowTabsState.tabsOpened[initiativeName]) ? flowTabsState.tabsOpened[initiativeName] : []
  );
