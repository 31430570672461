import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Trigger } from 'models/Trigger';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'triggers';

export const createTrigger = (data: {
  bp: Initiative['name'];
  data: Pick<Trigger, 'name' | 'type'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateTrigger = (data: {
  bp: Initiative['name'];
  data: Trigger;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Trigger['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'trigger'),
    endpoint: `${endpoint}/${data.trigger}`,
    method: Methods.patch,
  });

export const updateTriggerConfig = (data: {
  bp: Initiative['name'];
  data: Pick<Trigger, 'config'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Trigger['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'step'),
    endpoint: `${endpoint}/${data.trigger}`,
    method: Methods.patch,
  });

export const removeTrigger = (data: {
  bp: Initiative['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  trigger: Pick<Trigger, 'name' | 'type'>;
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${data.trigger.name}`,
    method: Methods.delete,
    params: omit(data, 'trigger'),
  });
