import { AxiosResponse } from 'axios';

import { ServiceType } from 'models/ServiceType';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'service-types';

export const fetchServiceTypes = (
  params?: RequestOptions['params']
): Promise<AxiosResponse<APIResponse<{ services: ServiceType[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });
