export enum JsonSchemaTypes {
  bpVar = 'bpVar',
  'business process' = 'business process',
  contextVar = 'contextVar',
  flowVar = 'flowVar',
  globalVar = 'globalVar',
  project = 'project',
  sphere = 'sphere',
  tag = 'tag',
}
