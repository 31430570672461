import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import Icon from '../Icon';

import { FLOW_NAV_HEIGHT } from './constant';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  background-color: ${({ theme }) => theme.colors.canvasHeader};
`;
export const FlowTabsContainer = styled.div`
  ${flexStyle({ align: 'flex-end', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const BackButton = styled(NavLink)`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  position: relative;
  display: none;
  width: 100%;
`;
export const BackIcon = styled(Icon)`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 0 8px;
  transform: rotate(180deg);
`;
export const LogoLabel = styled.label`
  ${textStyle({ color: 'white', lineHeight: 1, size: '22px', textAlign: 'center', weight: 400 })};
  ${flexStyle({ align: 'center', direction: 'column', justify: 'center', wrap: 'nowrap' })};
  width: 100%;
  padding: 0 8px;
  letter-spacing: 0.15px;
`;
export const LogoContainer = styled.div`
  ${flexStyle({ direction: 'row', justify: 'flex-start' })};
  width: 58px;
  height: ${FLOW_NAV_HEIGHT}px;
  cursor: pointer;
  &:hover > ${BackButton} {
    display: inherit;
  }
  &:hover > ${LogoLabel} {
    display: none;
  }
`;
export const InitiativeIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
`;
export const FlowTabContainer = styled.div<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    css`
      position: absolute;
      z-index: -1;
    `}
`;
export const FlowTabIcon = styled(Icon)<{ color?: string }>`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};
  border-radius: 3px;
  position: relative;
`;
export const DeleteIcon = styled(Icon)<{ color?: string }>`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.textTertiary};
  opacity: 0;
  position: relative;
`;
export const LinkText = styled.span`
  ${textStyle({ lineHeight: 1, size: '12px', textAlign: 'left', weight: 500 })};
  position: relative;
  white-space: nowrap;
  letter-spacing: 0.5px;
  padding: 0 8px;
`;
export const PlusIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
`;
export const MoreIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
`;
export const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-left: 20px;
`;
export const LabelContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between', wrap: 'nowrap' })};
  flex: 1;
`;
