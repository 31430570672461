import { FormFieldValue, FormFields } from 'jsonSchema/types';

import { Branch } from '../Branch';
import { DataMapperMapping } from '../DataMapperMapping';
import { DataMapperNode } from '../DataMapperNode';

export type StepConfig = {
  destinations?: DataMapperNode[];
  mapping?: DataMapperMapping[];
  sources?: DataMapperNode[];
};

export type Step = {
  [key: string]: FormFieldValue | Branch[];
  _store?: Record<string, string[]>;
  branches?: Branch[];
  condition?: string;
  config?: StepConfig & FormFields;
  name: string;
  type: string;
};

export * from './enums';
export * from './utils';
