import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { buildDataCyValue } from 'utils/cy';

import { Sizes, StyleTypes } from './enums';
import { Label, LeftIcon, RightIcon, Root, StyledLoader } from './styles';
import { Props } from './types';

const Button: FC<Props> = ({
  className,
  disabled,
  label,
  leftIcon,
  loading,
  onClick,
  rightIcon,
  size = Sizes.large,
  styleType = StyleTypes.filled,
  to,
  type = 'button',
}) => {
  const getDataCy = useCallback((elementName: string) => buildDataCyValue({ elementName, fieldName: label }), [label]);

  const content = loading ? (
    <StyledLoader />
  ) : (
    <>
      {leftIcon && <LeftIcon size={leftIcon.size} icon={leftIcon.type} $hasLabel={!!label} $buttonDisabled={disabled} />}
      {label && <Label>{label}</Label>}
      {rightIcon && <RightIcon size={rightIcon.size} icon={rightIcon.type} $hasLabel={!!label} $buttonDisabled={disabled} />}
    </>
  );

  if (to) {
    return (
      <Link className={className} to={to} onClick={onClick}>
        <Root disabled={disabled} styleType={styleType} size={size} $hasLabel={!!label}>
          {content}
        </Root>
      </Link>
    );
  }

  return (
    <Root
      className={className}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      styleType={styleType}
      size={size}
      $hasLabel={!!label}
      data-cy={getDataCy('button')}
    >
      {content}
    </Root>
  );
};

export { StyleTypes, Sizes };
export type { Props };

export default Button;
