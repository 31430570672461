import styled, { css } from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import Icon from '../Icon';

import { Modes } from './enums';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const ListContainer = styled.div<{ gridColumns: number; gridGap: number; mode: Modes }>`
  ${({ gridColumns, gridGap, mode }) => {
    switch (mode) {
      case Modes.grid:
        return css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(calc(${100 / gridColumns}% - 16px), 1fr));
          gap: ${gridGap}px;
        `;
      default: // flex
        return css`
          ${flexStyle({ align: 'flex-start', justify: 'flex-start' })};
          gap: ${gridGap}px;
        `;
    }
  }}

  width: 100%;
`;
export const ListItemWrapper = styled.div``;
export const PaginationContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between' })};
  margin-top: 30px;
`;
export const PaginationTotalMessage = styled.span`
  ${textStyle({ color: 'textTertiary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
`;
export const PaginationControlContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'flex-start' })};
`;
export const PaginationControlIcon = styled(Icon)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.textTertiary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.grey};

      &:hover {
        color: ${({ theme }) => theme.colors.grey};
      }
    `}
`;
export const PaginationControlText = styled.span`
  ${textStyle({ color: 'textTertiary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin: 0 24px;
`;
export const PaginationControlTextActive = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
`;
export const PaginationControlTextSlash = styled.span`
  margin: 0 8px;
`;
