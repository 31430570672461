import React, { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormExpressionInputNames } from 'jsonSchema/constants';
import { FormExpressionInputTypes, FormFieldSubTypes } from 'jsonSchema/enums';

import { useGetSubTypeProps } from '../../../FormArrayRenderer/hooks/useGetArraySubTypeProps';
import Modal, { Sizes } from '../../../Modal';

import ExpressionForm from './components/ExpressionForm';
import { Props } from './types';

const FormExpressionModal: FC<Props> = ({ field, onCancel, onSubmit }) => {
  const value = useWatch({ name: field.name });
  const subTypeProps = useGetSubTypeProps<FormExpressionInputTypes>(field, FormFieldSubTypes.expressionInput);
  const subTypeModalId = useMemo(() => `${field.name}_${field.subType}`, [field]);
  const expressionType = useMemo(() => subTypeProps || FormExpressionInputTypes.expression, [subTypeProps]);

  return (
    <Modal
      id={subTypeModalId}
      size={Sizes.medium}
      onClose={onCancel}
      renderTitle={() => FormExpressionInputNames[expressionType]}
      renderChildren={() => (
        <ExpressionForm expressionType={expressionType} onSubmit={onSubmit} onCancel={onCancel} initialValue={value} />
      )}
    />
  );
};

export default FormExpressionModal;
