import { createSelector } from 'reselect';

import { RootState } from 'store/types';

import { ProjectState } from './reducer';

const selectProjectState = (state: RootState) => state.pages.project;

export const selectProject = createSelector(selectProjectState, (projectState: ProjectState) => projectState.project);

export const selectProjectTree = createSelector(selectProjectState, (projectState: ProjectState) => projectState.projectTree);

export const selectProjectTreeLoading = createSelector(
  selectProjectState,
  (projectState: ProjectState) => projectState.projectTreeLoading
);
export const selectProjectLoading = createSelector(
  selectProjectState,
  (projectState: ProjectState) => projectState.projectLoading
);
export const selectUpdateProjectLoading = createSelector(
  selectProjectState,
  (projectState: ProjectState) => projectState.updateProjectLoading
);
export const selectDeleteProjectLoading = createSelector(
  selectProjectState,
  (sphereState: ProjectState) => sphereState.deleteProjectLoading
);
export const selectInitiatives = createSelector(selectProjectState, (projectState: ProjectState) => projectState.initiatives);
export const selectInitiativeList = createSelector(
  selectProjectState,
  (projectState: ProjectState) => projectState.initiatives.list
);
