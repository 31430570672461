import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Branch } from 'models/Branch';
import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Step } from 'models/Step';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'branches';

export const createBranch = (data: {
  bp: Initiative['name'];
  data: Pick<Branch, 'name' | 'type'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const deleteBranch = (data: {
  bp: Initiative['name'];
  branch: Branch['name'];
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${data.branch}`,
    method: Methods.delete,
    params: omit(data, 'branch'),
  });

export const updateBranch = (data: {
  bp: Initiative['name'];
  branch: Branch['name'];
  data: Pick<Branch, 'type'> | Pick<Branch, 'wait'>;
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  step: Step['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: omit(data, 'branch'),
    endpoint: `${endpoint}/${data.branch}`,
    method: Methods.patch,
  });
