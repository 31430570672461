import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import { HEADER_HEIGHT } from './constant';

export const Root = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  padding: 0 24px;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;
