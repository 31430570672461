import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { StyleTypes } from 'components/Button';
import { ICONS_TYPES } from 'components/Icon';
import { Modes } from 'components/Modal';

import { Resource } from 'models/Resource';
import { modalOpen } from 'store/modal/actions';
import { createResource } from 'store/projectResources/actions';
import { selectProjectResources } from 'store/projectResources/selectors';

import ProjectResourcesList from '../ProjectResourcesList';
import { CREATE_RESOURCE_MODAL_ID } from '../ProjectResourcesList/constant';

import { ButtonContainer, Root } from './styles';
import { Props } from './types';

const ProjectResourcesSection: FC<Props> = ({ bpName, className, flowName, projectName, sphereName }) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector(selectProjectResources);

  const handleOpenAddResourceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: CREATE_RESOURCE_MODAL_ID, mode: Modes.rightSlide }));
  }, [dispatch]);
  const handleCreateResource = useCallback(
    (resource: Resource) => {
      dispatch(
        createResource.request({
          data: {
            ...resource,
            project: projectName,
            sphere: sphereName,
          },
        })
      );
    },
    [dispatch, sphereName, projectName]
  );

  return (
    <Root className={className}>
      <ProjectResourcesList
        onCreate={handleCreateResource}
        projectName={projectName}
        sphereName={sphereName}
        bpName={bpName}
        flowName={flowName}
      />
      <ButtonContainer>
        <Button
          label="Add Resource"
          leftIcon={{ size: 20, type: ICONS_TYPES.Plus }}
          loading={updateLoading}
          onClick={handleOpenAddResourceModal}
          styleType={StyleTypes.outlined}
        />
      </ButtonContainer>
    </Root>
  );
};

export default ProjectResourcesSection;
