import React, { FC, useMemo } from 'react';

import Tree from 'components/Tree';
import { TreeItem } from 'components/Tree/types';

import ContextVariablesNode from './components/ContextVariablesNode';
import GlobalVariablesNode from './components/GlobalVariablesNode';
import RepositoryNode from './components/RepositoryNode';
import { RepositoryNodeType } from './enums';
import { Root } from './styles';
import { Props } from './types';

const NODE_TYPES = {
  [RepositoryNodeType.Repository]: RepositoryNode,
  [RepositoryNodeType.GlobalVariables]: GlobalVariablesNode,
  [RepositoryNodeType.ContextVariables]: ContextVariablesNode,
};

const Repository: FC<Props> = ({ className }) => {
  const nodes: TreeItem[] = useMemo(
    () => [
      {
        children: [
          {
            data: {},
            index: 'contextVariables',
            name: 'Context variables',
            type: RepositoryNodeType.ContextVariables,
          },
          {
            data: {},
            index: 'globalVariables',
            name: 'Global variables',
            type: RepositoryNodeType.GlobalVariables,
          },
        ],
        data: {},
        index: 'repository',
        name: 'Repository',
        type: RepositoryNodeType.Repository,
      },
    ],
    []
  );

  return (
    <Root className={className}>
      <Tree nodeTypes={NODE_TYPES} nodes={nodes} />
    </Root>
  );
};

export default Repository;
