import styled from 'styled-components';

import { flexStyle, gridStyle, textStyle } from 'styles/mixins';

import Icon from '../../../Icon';

export const Root = styled.div`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  cursor: auto;
`;
export const CalendarHeader = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 12px 16px;
  width: 100%;
`;
export const HeaderDate = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
`;
export const CalendarContent = styled.div`
  ${gridStyle({ columnGap: 8, columns: 7, rowGap: 0, rows: 4 })};
  padding: 0 30px;
  flex: 1;
`;
export const Month = styled.span`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 0.5, size: '14px', textAlign: 'left', weight: 500 })};
`;
export const YearPicker = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  margin-left: 16px;
`;
export const YearButton = styled.span`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const YearArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  padding: 4px;
  cursor: pointer;
`;
export const HeaderButtons = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const ControlButton = styled.span`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const ArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  padding: 4px;
  margin-left: 8px;
  cursor: pointer;
`;
export const WeekItem = styled.span`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textSecondary', lineHeight: 0.5, size: '12px', textAlign: 'left', weight: 400 })};
  width: 42px;
  height: 42px;
  gap: 10px;
  cursor: default;
`;
export const DayItem = styled.span<{ disabled: boolean; isToday: boolean; selected: boolean }>`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 0.5, size: '12px', textAlign: 'left', weight: 400 })};
  background-color: ${({ isToday, selected, theme }) =>
    selected ? theme.colors.primary : isToday ? theme.colors.primaryLight : 'transparent'};
  color: ${({ selected, theme }) => (selected ? theme.colors.background : theme.colors.textPrimary)};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  border-radius: 6px;
  width: 42px;
  height: 42px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ disabled, selected, theme }) => !disabled && !selected && theme.colors.primaryLight};
  }
`;
