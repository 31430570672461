import styled, { css } from 'styled-components';

import { flexStyle, rgba, textStyle } from 'styles/mixins';

import Icon from '../Icon';

import { Props } from './types';

export const Root = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const Label = styled.label`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-bottom: 8px;
  letter-spacing: 0.5px;
`;
export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
export const ColorsContainer = styled.div<Partial<Props>>`
  ${flexStyle({ justify: 'space-between', wrap: 'nowrap' })};
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;
export const ColorOuterBox = styled.div<{ $color: string; $selected: boolean }>`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  border-radius: 4px;
  background-color: ${({ $color }) => rgba($color, 10)};
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ $color }) => $color};
  }

  ${({ $color, $selected }) =>
    $selected &&
    css`
      background-color: ${$color};
    `}
`;
export const ColorInnerBox = styled.div<{ $color: string }>`
  display: block;
  border-radius: 2px;
  background-color: ${({ $color }) => $color};
  width: 16px;
  height: 16px;
`;
export const CheckIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.background};
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;
