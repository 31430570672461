import { getFontEmbedCSS, toPng } from 'html-to-image';
import { getNodesBounds, getViewportForBounds } from 'reactflow';

import { isDefAndNotNull } from 'utils/def';

import { XpiStudioNodes } from '../types';

const imageWidth = 720;
const imageHeight = 480;
const minZoom = 0.5;
const maxZoom = 2;
const imageBackgroundColor = '#f9f9f9';

let fontEmbedCSS: string | undefined = undefined;

export const generateBase64Url = async (nodes: XpiStudioNodes) => {
  const nodesBounds = getNodesBounds(nodes);
  const { x, y, zoom } = getViewportForBounds(nodesBounds, imageWidth, imageHeight, minZoom, maxZoom);
  const reactFlowElement = document.querySelector('.react-flow__viewport') as HTMLElement;

  if (isDefAndNotNull(reactFlowElement)) {
    if (!fontEmbedCSS) {
      fontEmbedCSS = await getFontEmbedCSS(reactFlowElement);
    }

    return await toPng(reactFlowElement, {
      backgroundColor: imageBackgroundColor,
      cacheBust: true,
      fontEmbedCSS,
      height: imageHeight,
      style: {
        height: `${imageHeight}px`,
        transform: `translate(${x}px, ${y}px) scale(${zoom})`,
        width: `${imageWidth}px`,
      },
      width: imageWidth,
    });
  }
};
