import styled, { css } from 'styled-components';

import { flexStyle } from 'styles/mixins';
import { isDefAndNotNull } from 'utils/def';

import { Props } from './types';

export const Root = styled.div`
  ${flexStyle({ align: 'flex-start', wrap: 'nowrap' })};
`;

export const SvgWrapper = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  ${({ rotate, size }) => css`
    width: ${isDefAndNotNull(size) ? `${size}px` : '100%'};
    height: ${isDefAndNotNull(size) ? `${size}px` : '100%'};
    transform: ${isDefAndNotNull(rotate) ? `rotate(${rotate}deg)` : 'none'};
  `}
  flex-shrink: 0;

  svg {
    width: 100%;
  }
`;
