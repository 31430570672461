import { createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Resource } from 'models/Resource';
import { Sphere } from 'models/Sphere';

export type FetchResourcesRequestPayload = {
  projectName: Project['name'];
  sphereName: Sphere['name'];
};
export type FetchResourcesSuccessPayload = {
  list: Resource[];
};
export const fetchResources = createAsyncAction(
  'projectResources/fetchResources/request',
  'projectResources/fetchResources/success',
  'projectResources/fetchResources/failure'
)<FetchResourcesRequestPayload, FetchResourcesSuccessPayload, undefined>();

export type CreateResourceRequestPayload = {
  data: Resource & { project: Project['name']; sphere: Sphere['name'] };
  onSuccess?: (name: Resource['name']) => void;
};
export const createResource = createAsyncAction(
  'projectResources/createResource/request',
  'projectResources/createResource/success',
  'projectResources/createResource/failure'
)<CreateResourceRequestPayload, undefined, undefined>();

export type ValidateResourceRequestPayload = {
  bp?: Initiative['name'];
  data: Resource & {
    oldName: Resource['name'];
    project: Project['name'];
    sphere: Sphere['name'];
  };
  flow?: Flow['name'];
};
export const validateResource = createAsyncAction(
  'projectResources/validateResource/request',
  'projectResources/validateResource/success',
  'projectResources/validateResource/failure'
)<ValidateResourceRequestPayload, undefined, undefined>();

export type UpdateResourceRequestPayload = {
  data: Resource & { oldName: Resource['name']; project: Project['name']; sphere: Sphere['name'] };
};
export const updateResource = createAsyncAction(
  'projectResources/updateResource/request',
  'projectResources/updateResource/success',
  'projectResources/updateResource/failure'
)<UpdateResourceRequestPayload, undefined, undefined>();

export type DeleteResourceRequestPayload = {
  data: Pick<Resource, 'name' | 'type'> & { project: Project['name']; sphere: Sphere['name'] };
};
export const deleteResource = createAsyncAction(
  'projectResources/deleteResource/request',
  'projectResources/deleteResource/success',
  'projectResources/deleteResource/failure'
)<DeleteResourceRequestPayload, undefined, undefined>();
