import { isEmpty } from 'lodash';
import React, { FC, useMemo } from 'react';
import { Accept } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { FileInputValue } from 'components/FileInput/types';
import FormField, { REQUIRED_ERROR_TEXT } from 'components/FormField';
import { FormFieldsType } from 'components/FormFieldRenderer/types';

import useDeepCallback from 'hooks/useDeepCallback';
import { FormFieldFileValueType, getValidationRulesFromAField } from 'jsonSchema';

import { Root, StyledFileInput } from './styles';
import { Props } from './types';

const FormFileRenderer: FC<Props> = ({ field }) => {
  const { clearErrors, control, setError } = useFormContext<FormFieldsType>();
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);

  const handleChange = useDeepCallback((onChange: (file: FormFieldFileValueType) => void, data: FileInputValue[]) => {
    const file = data?.[0]?.data;
    const value = (data?.length ? { file, id: uuidv4(), name: file?.name } : {}) as FormFieldFileValueType;
    onChange(value);
  });
  const handleSetError = useDeepCallback((name: keyof FormFieldsType, message: string) => {
    setError(name as string, { message });
  });

  return (
    <Root>
      <FormField
        {...validationRules}
        controllerProps={{
          control,
          name: field.name,
          rules: {
            validate: (value) => (validationRules.required && isEmpty(value) ? REQUIRED_ERROR_TEXT : undefined),
          },
        }}
        required={field.required}
        render={(props) => {
          const value = props.value as FormFieldFileValueType;
          return (
            <StyledFileInput
              {...props}
              label={field.label}
              accept={(field.subTypeProps?.fileInput?.accept as Accept) || {}}
              fileNameInputPlaceholder={field.placeholder}
              multi={false}
              onChange={handleChange(props.onChange)}
              setError={handleSetError(field.name)}
              clearError={clearErrors}
              fileNameInputDisabled
              value={value?.name ? [{ data: value.file, name: value.name }] : []}
            />
          );
        }}
      />
    </Root>
  );
};

export default FormFileRenderer;
