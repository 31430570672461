import styled from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import InitiativeForm from '../../../components/InitiativeForm';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  height: 100%;
`;
export const InitiativeInfo = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
  min-height: 64px;
  padding: 16px;
  border-bottom: 1px solid gray;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const NameCreated = styled.div`
  ${flexStyle({ direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '11px', textAlign: 'center', weight: 400 })};
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;
export const DateCreated = styled.div`
  ${flexStyle({ direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
  ${textStyle({ color: 'textTertiary', lineHeight: 1, size: '11px', textAlign: 'center', weight: 400 })};
  letter-spacing: 0.4px;
`;
export const StyledInitiativeForm = styled(InitiativeForm)`
  padding: 16px;
`;
