import { Row as RowObject, flexRender } from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core/src/types';
import React from 'react';

import useDeepCallback from 'hooks/useDeepCallback';

import EmptyData from './components/EmptyData';
import { Body, Cell, Head, HeadCell, HeadRow, Root, Row, TableRoot } from './styles';
import { Props } from './types';

const Table = <T extends RowData>({ actionButton, bordered, className, data, editable, onRowClick }: Props<T>) => {
  const rows = data.getRowModel().rows;
  const handleRowClick = useDeepCallback(({ index, row }: { index: number; row: RowObject<T> }, event: unknown) => {
    if (!editable) {
      row.getToggleSelectedHandler()(event);
      onRowClick?.(row, index);
    }
  });

  return (
    <Root className={className} bordered={bordered}>
      <TableRoot>
        <Head>
          {data.getHeaderGroups().map((headerGroup) => (
            <HeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <HeadCell key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </HeadCell>
              ))}
            </HeadRow>
          ))}
        </Head>
        <Body>
          {rows.map((row, index) => (
            <Row key={row.id} onClick={handleRowClick({ index, row })} isSelected={row.getIsSelected()}>
              {row.getVisibleCells().map((cell) => (
                <Cell key={cell.id} width={cell.column.columnDef.size} editable={editable}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Cell>
              ))}
            </Row>
          ))}
        </Body>
      </TableRoot>
      {!rows.length && <EmptyData>{actionButton}</EmptyData>}
    </Root>
  );
};

export default Table;
