import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button, { StyleTypes } from 'components/Button';
import FormField from 'components/FormField';
import FormFieldRenderer from 'components/FormFieldRenderer';

import { FormFields, extractFiles, formatBeforeSubmit, normalizeFields, schemaToFormConfig } from 'jsonSchema';
import { SERVICE_PATHS_FIELD_NAME } from 'models/Service/constants';
import { ServiceTypes, getServicePathsFieldValue } from 'models/ServiceType';
import { isDefAndNotNull } from 'utils/def';

import { SERVICE_NAME_UNIQ_ERROR_TEXT } from './constants';
import {
  ActionButtonsContainer,
  ButtonsContainer,
  FormFieldsContainer,
  Root,
  ServiceDescription,
  ServiceIcon,
  ServiceInfoContainer,
  ServiceName,
  ServiceTextContainer,
  StyledInput,
  SubmitButton,
  SubmitButtonsContainer,
} from './styles';
import { Props, ServiceFormFields } from './types';

const ServiceForm: FC<Props> = ({
  cancelOnSubmit,
  className,
  createLoading,
  model,
  onCancel,
  onSubmit,
  pathsData,
  renderActions,
  serviceNameListToValidate,
  type,
  updateLoading,
}) => {
  const memoServiceFullFormConfig = useMemo(() => schemaToFormConfig(type.schema), [type.schema]);
  const memoServiceFormConfig = useMemo(() => schemaToFormConfig(type.schema, [SERVICE_PATHS_FIELD_NAME]), [type.schema]);
  const formDefaultValues = useMemo(
    () =>
      ({
        name: model.name,
        ...normalizeFields(model.data, memoServiceFormConfig),
      }) as FormFields,
    [memoServiceFormConfig, model]
  );
  const methods = useForm<ServiceFormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const { control, formState, getValues } = methods;
  const isFormDirty = useMemo(() => formState.isDirty || isDefAndNotNull(pathsData), [pathsData, formState.isDirty]);
  const isDisabled = useMemo(
    () => (!formState.isValid || !formState.isDirty) && !isDefAndNotNull(pathsData),
    [formState.isValid, formState.isDirty, pathsData]
  );
  const loading = useMemo(() => createLoading || updateLoading, [createLoading, updateLoading]);

  const handleSubmit = useCallback(() => {
    const { name, ...formFields } = getValues();
    let fields = { ...formatBeforeSubmit(formFields, memoServiceFullFormConfig) };
    if (model.type === ServiceTypes.REST) {
      fields = {
        ...fields,
        [SERVICE_PATHS_FIELD_NAME]: pathsData || getServicePathsFieldValue(model) || [],
      };
    }
    const { fields: fieldsWithoutFiles, files } = extractFiles(fields as FormFields);
    onSubmit({
      data: fieldsWithoutFiles,
      files,
      isValidated: undefined,
      name,
      type: model.type,
    });

    if (cancelOnSubmit) {
      onCancel();
    }
  }, [cancelOnSubmit, getValues, memoServiceFullFormConfig, model, onCancel, onSubmit, pathsData]);

  const handleNameValidate = useCallback(
    (value: ServiceFormFields['name']) =>
      serviceNameListToValidate.some((rName) => rName === value?.trim()) ? SERVICE_NAME_UNIQ_ERROR_TEXT : undefined,
    [serviceNameListToValidate]
  );

  useEffect(() => {
    methods.reset(formDefaultValues);
  }, [type, methods, formDefaultValues]);

  return (
    <Root className={className}>
      <ServiceInfoContainer>
        <ServiceIcon model={type.icon} />
        <ServiceTextContainer>
          <ServiceName>{model.name || model.type}</ServiceName>
          <ServiceDescription>{type.schema.description}</ServiceDescription>
        </ServiceTextContainer>
      </ServiceInfoContainer>
      <FormFieldsContainer>
        <FormField<ServiceFormFields, 'name'>
          required
          maxLength={50}
          controllerProps={{
            control,
            name: 'name',
            rules: {
              validate: handleNameValidate,
            },
          }}
          render={(props) => <StyledInput {...props} label="Name" />}
        />
        <FormProvider {...methods}>
          {memoServiceFormConfig.map((field) => {
            return <FormFieldRenderer key={field.name} field={field} />;
          })}
        </FormProvider>
      </FormFieldsContainer>
      <ButtonsContainer>
        {isDefAndNotNull(renderActions) && <ActionButtonsContainer>{renderActions?.(isFormDirty)}</ActionButtonsContainer>}
        <SubmitButtonsContainer>
          <SubmitButton onClick={handleSubmit} disabled={isDisabled} loading={loading} label="Save Service" />
          <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
        </SubmitButtonsContainer>
      </ButtonsContainer>
    </Root>
  );
};

export * from './constants';

export default ServiceForm;
