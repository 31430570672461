import styled from 'styled-components';

import { flexStyle, rgba } from 'styles/mixins';

import Icon from '../../../Icon';

export const Root = styled.li``;
export const ChildContainer = styled.ul``;
export const ArrowContainer = styled.div`
  width: 16px;
  padding: 4px;
  cursor: pointer;
`;
export const NodeContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  flex: 1;
  overflow: hidden;
`;
export const ArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textPrimary};
`;
export const TitleContainer = styled.div<{ level: number }>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  grid-gap: 6px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding-left: ${({ level }) => level * 16 + 'px'};
  cursor: pointer;
  overflow: hidden;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 10)};
  }
`;
