import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  FormArrayDatasourceTypes,
  FormFieldOption,
  FormFieldSelect,
  FormFieldSubTypes,
  FormFieldTypes,
  SelectDatasourceSubTypeProps,
} from 'jsonSchema';
import { selectFlowSelectedStep } from 'pages/flow/store/selectors';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from '../../FormArrayRenderer/hooks/useGetArraySubTypeProps';

import { SingleSelectOptions, UseGetSelectOptionsReturn } from '../types';

import { useGetResourcesOptions } from './useGetResourcesOptions';
import { useGetServicesOptions } from './useGetServicesOptions';

export const useGetSelectOptions = (field: FormFieldSelect): UseGetSelectOptionsReturn => {
  const selectedStep = useSelector(selectFlowSelectedStep);
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const optionFields: FormFieldOption[] = useMemo(
    () => (field.dependantFields || []).filter((p) => p.type === FormFieldTypes.option) as FormFieldOption[],
    [field.dependantFields]
  );
  const defaultOptions: SingleSelectOptions = useMemo(() => {
    return optionFields.length ? optionFields.map((p) => ({ label: p.label || '', value: p.value })) : field.options;
  }, [optionFields, field]);
  const {
    OptionComponent: ResourcesOptionComponent,
    filterOption: resourcesFilterOption,
    onAdd: resourcesOnAdd,
    onAddComponent: resourcesOnAddComponent,
    options: resourcesOptions,
  } = useGetResourcesOptions(field);
  const {
    OptionComponent: ServicesOptionComponent,
    filterOption: servicesFilterOption,
    onAdd: servicesOnAdd,
    onAddComponent: servicesOnAddComponent,
    options: servicesOptions,
  } = useGetServicesOptions(field);

  return useMemo(() => {
    switch (field.subType) {
      case FormFieldSubTypes.resourcesSelect:
        return {
          OptionComponent: ResourcesOptionComponent,
          filterOption: resourcesFilterOption,
          onAdd: resourcesOnAdd,
          onAddComponent: resourcesOnAddComponent,
          optionFields,
          options: resourcesOptions,
        };
      case FormFieldSubTypes.servicesSelect:
        return {
          OptionComponent: ServicesOptionComponent,
          filterOption: servicesFilterOption,
          onAdd: servicesOnAdd,
          onAddComponent: servicesOnAddComponent,
          optionFields,
          options: servicesOptions,
        };
      default: {
        let filterOption: UseGetSelectOptionsReturn['filterOption'] | undefined = undefined;

        if (isDef(subTypeProps) && isDef(subTypeProps.dataSource)) {
          const dataSourceName = subTypeProps.dataSource.name;
          const dataSourceStoreKey = subTypeProps.dataSource.storeKey;

          if (dataSourceName == FormArrayDatasourceTypes.selectStore) {
            const stepStore = selectedStep?._store;

            if (isDefAndNotNull(stepStore) && isDefAndNotNull(dataSourceStoreKey)) {
              filterOption = (option) => stepStore[dataSourceStoreKey]?.includes(option.value);
            }
          }
        }

        return { filterOption, optionFields, options: defaultOptions };
      }
    }
  }, [
    field.subType,
    ResourcesOptionComponent,
    resourcesFilterOption,
    resourcesOnAdd,
    resourcesOnAddComponent,
    optionFields,
    resourcesOptions,
    ServicesOptionComponent,
    servicesFilterOption,
    servicesOnAdd,
    servicesOnAddComponent,
    servicesOptions,
    subTypeProps,
    defaultOptions,
    selectedStep?._store,
  ]);
};
