import isPropValid from '@emotion/is-prop-valid';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import ResetStyles from 'styles/components/Reset';

import '@tabler/icons-webfont/dist/tabler-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/fonts.css';

// exception import order
import { selectThemeMode } from 'store/app/selectors';
import BaseStyles from 'styles/components/Base';
import ToastStyles from 'styles/components/Toast';
import theme from 'styles/theme';

import App from '../App';

const StyledApp: FC = () => {
  const themeMode = useSelector(selectThemeMode);

  return (
    <ThemeProvider theme={theme[themeMode]}>
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={(propName, elementToBeRendered) =>
          typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
        }
      >
        <ResetStyles />
        <BaseStyles />
        <ToastStyles />
        <App />
      </StyleSheetManager>
    </ThemeProvider>
  );
};

export default StyledApp;
