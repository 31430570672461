import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

export const getGlobalVariablesKey = (sphere: Sphere['name'], project: Project['name']) => {
  return `global_${sphere}_${project}`;
};

export const getContextVariablesKey = (sphere: Sphere['name'], project: Project['name']) => {
  return `context_${sphere}_${project}`;
};

export const getInitiativeVariablesKey = (sphere: Sphere['name'], project: Project['name'], initiative: Initiative['name']) => {
  return `${sphere}_${project}_${initiative}`;
};

export const getFlowVariablesKey = (
  sphere: Sphere['name'],
  project: Project['name'],
  initiative: Initiative['name'],
  flow: Flow['name']
) => {
  return `${sphere}_${project}_${initiative}_${flow}`;
};
