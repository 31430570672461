import { ActionType, getType } from 'typesafe-actions';

import { Step } from 'models/Step';
import { isDef } from 'utils/def';

import { flattenSteps } from '../utils';

import * as flowActions from './actions';

type FlowActionType = ActionType<typeof flowActions>;

export type FlowState = {
  buildLoading: boolean;
  createBranchLoading: boolean;
  createStepLoading: boolean;
  createTriggerLoading: boolean;
  deleteBranchLoading: boolean;
  deleteBranchWaitNodeLoading: boolean;
  deployLoading: boolean;
  flow: flowActions.FetchFlowSuccessPayload['flow'];
  flowLoading: boolean;
  flowPreview: {
    generating: boolean;
    objectURL?: string;
    uploading: boolean;
  };
  goTo: {
    source: Step['name'] | undefined;
    targets: Step['name'][];
  };
  removeStepLoading: boolean;
  removeTriggerLoading: boolean;
  selectedStep: Step | undefined;
  selectedTriggerName: string;
  updateBranchTypeLoading: boolean;
  updateBranchWaitNodeLoading: boolean;
  updateStepConfigLoading: boolean;
  updateStepDataMapperLoading: boolean;
  updateStepLoading: boolean;
  updateTriggerConfigLoading: boolean;
  updateTriggerLoading: boolean;
};

const initialState: FlowState = {
  buildLoading: false,
  createBranchLoading: false,
  createStepLoading: false,
  createTriggerLoading: false,
  deleteBranchLoading: false,
  deleteBranchWaitNodeLoading: false,
  deployLoading: false,
  flow: { goto: [], name: '', steps: [], triggers: [] },
  flowLoading: true,
  flowPreview: {
    generating: false,
    objectURL: undefined,
    uploading: false,
  },
  goTo: {
    source: undefined,
    targets: [],
  },
  removeStepLoading: false,
  removeTriggerLoading: false,
  selectedStep: undefined,
  selectedTriggerName: '',
  updateBranchTypeLoading: false,
  updateBranchWaitNodeLoading: false,
  updateStepConfigLoading: false,
  updateStepDataMapperLoading: false,
  updateStepLoading: false,
  updateTriggerConfigLoading: false,
  updateTriggerLoading: false,
};

const flowReducer = (state: FlowState = initialState, action: FlowActionType): FlowState => {
  switch (action.type) {
    case getType(flowActions.fetchFlow.request):
      return {
        ...state,
        flowLoading: true,
      };
    case getType(flowActions.fetchFlow.success): {
      const selectedStepUpdated = flattenSteps(action.payload.flow.steps).find((s) => s.name === state.selectedStep?.name);

      return {
        ...state,
        flow: action.payload.flow,
        flowLoading: false,
        selectedStep: isDef(selectedStepUpdated) ? selectedStepUpdated : state.selectedStep,
      };
    }
    case getType(flowActions.fetchFlow.failure):
      return {
        ...state,
        flowLoading: false,
      };

    case getType(flowActions.setSelectedTrigger):
      return {
        ...state,
        selectedTriggerName: action.payload.selectedTriggerName,
      };

    case getType(flowActions.resetSelectedTrigger):
      return {
        ...state,
        selectedTriggerName: '',
      };

    case getType(flowActions.createTrigger.request):
      return {
        ...state,
        createTriggerLoading: true,
      };
    case getType(flowActions.createTrigger.success):
    case getType(flowActions.createTrigger.failure):
      return {
        ...state,
        createTriggerLoading: false,
      };

    case getType(flowActions.updateTrigger.request):
      return {
        ...state,
        updateTriggerLoading: true,
      };
    case getType(flowActions.updateTrigger.success):
    case getType(flowActions.updateTrigger.failure):
      return {
        ...state,
        updateTriggerLoading: false,
      };

    case getType(flowActions.updateTriggerConfig.request):
      return {
        ...state,
        updateTriggerConfigLoading: true,
      };
    case getType(flowActions.updateTriggerConfig.success):
    case getType(flowActions.updateTriggerConfig.failure):
      return {
        ...state,
        updateTriggerConfigLoading: false,
      };

    case getType(flowActions.removeTrigger.request):
      return {
        ...state,
        removeTriggerLoading: true,
      };
    case getType(flowActions.removeTrigger.success):
    case getType(flowActions.removeTrigger.failure):
      return {
        ...state,
        removeTriggerLoading: false,
      };

    case getType(flowActions.reset):
      return initialState;

    case getType(flowActions.setSelectedStep):
      return {
        ...state,
        selectedStep: action.payload.step,
      };

    case getType(flowActions.resetSelectedStep):
      return {
        ...state,
        selectedStep: undefined,
      };

    case getType(flowActions.createStep.request):
      return {
        ...state,
        createStepLoading: true,
      };
    case getType(flowActions.createStep.success):
    case getType(flowActions.createStep.failure):
      return {
        ...state,
        createStepLoading: false,
      };

    case getType(flowActions.updateStep.request):
      return {
        ...state,
        updateStepLoading: true,
      };
    case getType(flowActions.updateStep.success):
    case getType(flowActions.updateStep.failure):
      return {
        ...state,
        updateStepLoading: false,
      };

    case getType(flowActions.updateStepConfig.request):
      return {
        ...state,
        updateStepConfigLoading: true,
      };
    case getType(flowActions.updateStepConfig.success):
    case getType(flowActions.updateStepConfig.failure):
      return {
        ...state,
        updateStepConfigLoading: false,
      };

    case getType(flowActions.updateStepDataMapperConfig.request):
      return {
        ...state,
        updateStepDataMapperLoading: true,
      };
    case getType(flowActions.updateStepDataMapperConfig.success):
    case getType(flowActions.updateStepDataMapperConfig.failure):
      return {
        ...state,
        updateStepDataMapperLoading: false,
      };

    case getType(flowActions.createBranch.request):
      return {
        ...state,
        createBranchLoading: true,
      };
    case getType(flowActions.createBranch.success):
    case getType(flowActions.createBranch.failure):
      return {
        ...state,
        createBranchLoading: false,
      };

    case getType(flowActions.updateBranchType.request):
      return {
        ...state,
        updateBranchTypeLoading: true,
      };
    case getType(flowActions.updateBranchType.success):
    case getType(flowActions.updateBranchType.failure):
      return {
        ...state,
        updateBranchTypeLoading: false,
      };

    case getType(flowActions.updateBranchWaitNode.request):
      return {
        ...state,
        updateBranchWaitNodeLoading: true,
      };
    case getType(flowActions.updateBranchWaitNode.success):
    case getType(flowActions.updateBranchWaitNode.failure):
      return {
        ...state,
        updateBranchWaitNodeLoading: false,
      };

    case getType(flowActions.deleteBranch.request):
      return {
        ...state,
        deleteBranchLoading: true,
      };
    case getType(flowActions.deleteBranch.success):
    case getType(flowActions.deleteBranch.failure):
      return {
        ...state,
        deleteBranchLoading: false,
      };

    case getType(flowActions.deleteBranchWaitNode.request):
      return {
        ...state,
        deleteBranchWaitNodeLoading: true,
      };
    case getType(flowActions.deleteBranchWaitNode.success):
    case getType(flowActions.deleteBranchWaitNode.failure):
      return {
        ...state,
        deleteBranchWaitNodeLoading: false,
      };

    case getType(flowActions.removeStep.request):
      return {
        ...state,
        removeStepLoading: true,
      };
    case getType(flowActions.removeStep.success):
    case getType(flowActions.removeStep.failure):
      return {
        ...state,
        removeStepLoading: false,
      };

    case getType(flowActions.runBuild.request):
      return {
        ...state,
        buildLoading: true,
      };
    case getType(flowActions.runBuild.success):
    case getType(flowActions.runBuild.failure):
      return {
        ...state,
        buildLoading: false,
      };

    case getType(flowActions.runDeploy.request):
      return {
        ...state,
        deployLoading: true,
      };
    case getType(flowActions.runDeploy.success):
    case getType(flowActions.runDeploy.failure):
      return {
        ...state,
        deployLoading: false,
      };

    case getType(flowActions.setFlowPreviewUrl.request):
      return {
        ...state,
        flowPreview: {
          ...state.flowPreview,
          generating: true,
        },
      };
    case getType(flowActions.setFlowPreviewUrl.success):
      return {
        ...state,
        flowPreview: {
          ...state.flowPreview,
          generating: false,
          objectURL: action.payload.objectURL,
        },
      };
    case getType(flowActions.setFlowPreviewUrl.failure):
      return {
        ...state,
        flowPreview: {
          ...state.flowPreview,
          generating: false,
        },
      };

    case getType(flowActions.uploadFlowPreview.request):
      return {
        ...state,
        flowPreview: {
          ...state.flowPreview,
          uploading: true,
        },
      };
    case getType(flowActions.uploadFlowPreview.success):
    case getType(flowActions.uploadFlowPreview.failure):
      return {
        ...state,
        flowPreview: {
          ...state.flowPreview,
          uploading: false,
        },
      };

    case getType(flowActions.startGoTo):
      return {
        ...state,
        goTo: {
          source: action.payload.source,
          targets: action.payload.targets,
        },
      };
    case getType(flowActions.finishGoTo):
      return {
        ...state,
        goTo: initialState.goTo,
      };

    default:
      return state;
  }
};

export default flowReducer;
