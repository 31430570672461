import { createAction, createAsyncAction } from 'typesafe-actions';

import { MapperTypes } from 'models/MapperType';
import { ResourceType } from 'models/ResourceType';
import { Schema } from 'models/Schema';
import { ServiceType } from 'models/ServiceType';
import { Sphere } from 'models/Sphere';
import { StepType } from 'models/StepType';
import { TriggerType } from 'models/TriggerType';
import { ThemeModes } from 'styles/enums';

export type SetThemeModePayload = {
  mode: ThemeModes;
};
export const setThemeMode = createAction('app/setThemeMode')<SetThemeModePayload>();

export type FetchSchemasSuccessPayload = {
  list: Schema[];
};
export const fetchSchemas = createAsyncAction('app/fetchSchemas/request', 'app/fetchSchemas/success', 'app/fetchSchemas/failure')<
  undefined,
  FetchSchemasSuccessPayload,
  undefined
>();

export type FetchSpheresSuccessPayload = {
  list: Sphere[];
};
export const fetchSpheres = createAsyncAction('app/fetchSpheres/request', 'app/fetchSpheres/success', 'app/fetchSpheres/failure')<
  undefined,
  FetchSpheresSuccessPayload,
  undefined
>();

export type FetchResourceTypesSuccessPayload = {
  list: ResourceType[];
};
export const fetchResourceTypes = createAsyncAction(
  'app/fetchResourceTypes/request',
  'app/fetchResourceTypes/success',
  'app/fetchResourceTypes/failure'
)<undefined, FetchResourceTypesSuccessPayload, undefined>();

export type FetchTriggerTypesSuccessPayload = {
  list: TriggerType[];
};
export const fetchTriggerTypes = createAsyncAction(
  'app/fetchTriggerTypes/request',
  'app/fetchTriggerTypes/success',
  'app/fetchTriggerTypes/failure'
)<undefined, FetchTriggerTypesSuccessPayload, undefined>();

export type FetchStepTypesSuccessPayload = {
  list: StepType[];
};
export const fetchStepTypes = createAsyncAction(
  'app/fetchStepTypes/request',
  'app/fetchStepTypes/success',
  'app/fetchStepTypes/failure'
)<undefined, FetchStepTypesSuccessPayload, undefined>();

export type FetchMapperTypesSuccessPayload = {
  list: MapperTypes;
};
export const fetchMapperTypes = createAsyncAction(
  'app/fetchMapperTypes/request',
  'app/fetchMapperTypes/success',
  'app/fetchMapperTypes/failure'
)<undefined, FetchMapperTypesSuccessPayload, undefined>();
export type FetchServiceTypesSuccessPayload = {
  list: ServiceType[];
};
export const fetchServiceTypes = createAsyncAction(
  'app/fetchServiceTypes/request',
  'app/fetchServiceTypes/success',
  'app/fetchServiceTypes/failure'
)<undefined, FetchServiceTypesSuccessPayload, undefined>();
