import { all, fork } from 'redux-saga/effects';

import devTestFormSaga from './DevTestForm/store/saga';
import flowNavSaga from './FlowNavMenu/store/saga';
import flowTabsSaga from './FlowTabs/store/saga';
import expressionFormSaga from './FormInputRenderer/components/FormExpressionModal/components/ExpressionForm/store/saga';
import formSourceArraySaga from './FormInputRenderer/components/FormSourceArrayTable/store/saga';

function* componentsSaga() {
  yield all([fork(devTestFormSaga), fork(flowTabsSaga), fork(flowNavSaga), fork(formSourceArraySaga), fork(expressionFormSaga)]);
}

export default componentsSaga;
