import { ImportFrom } from './enums';

export const CREATE_SUCCESS_MODAL_ID: string = 'CREATE_SUCCESS_MODAL_ID';
export const IMPORT_PROJECT_MODAL_ID: string = 'IMPORT_PROJECT_MODAL_ID';
export const CREATE_PROJECT_MODAL_ID: string = 'CREATE_PROJECT_MODAL_ID';
export const CREATE_RESOURCE_MODAL_ID: string = 'CREATE_RESOURCE_MODAL_ID';
export const CREATE_TAG_MODAL_ID: string = 'CREATE_TAG_MODAL_ID';
export const DELETE_SPHERE_CONFIRM_MODAL_ID: string = 'DELETE_SPHERE_CONFIRM_MODAL_ID';
export const UPDATE_SPHERE_MODAL_ID: string = 'UPDATE_SPHERE_MODAL_ID';

export const IMPORT_FROM_OPTIONS = [
  { id: ImportFrom.file, name: 'Zip file' },
  { disabled: true, id: ImportFrom.git, name: 'Git repository' },
];
