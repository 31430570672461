import React, { FC } from 'react';

const Sparkles: FC = () => (
  <svg viewBox="0 0 23 22" fill="none">
    <path
      d="M15.1667 16.5C15.6529 16.5 16.1192 16.6931 16.463 17.0369C16.8068 17.3807 17 17.8471 17 18.3333C17 17.8471 17.1932 17.3807 17.537 17.0369C17.8808 16.6931 18.3471 16.5 18.8333 16.5C18.3471 16.5 17.8808 16.3068 17.537 15.963C17.1932 15.6192 17 15.1529 17 14.6666C17 15.1529 16.8068 15.6192 16.463 15.963C16.1192 16.3068 15.6529 16.5 15.1667 16.5ZM15.1667 5.49996C15.6529 5.49996 16.1192 5.69311 16.463 6.03693C16.8068 6.38075 17 6.84706 17 7.33329C17 6.84706 17.1932 6.38075 17.537 6.03693C17.8808 5.69311 18.3471 5.49996 18.8333 5.49996C18.3471 5.49996 17.8808 5.30681 17.537 4.96299C17.1932 4.61917 17 4.15286 17 3.66663C17 4.15286 16.8068 4.61917 16.463 4.96299C16.1192 5.30681 15.6529 5.49996 15.1667 5.49996ZM8.75 16.5C8.75 15.0413 9.32946 13.6423 10.3609 12.6109C11.3924 11.5794 12.7913 11 14.25 11C12.7913 11 11.3924 10.4205 10.3609 9.38905C9.32946 8.3576 8.75 6.95865 8.75 5.49996C8.75 6.95865 8.17054 8.3576 7.13909 9.38905C6.10764 10.4205 4.70869 11 3.25 11C4.70869 11 6.10764 11.5794 7.13909 12.6109C8.17054 13.6423 8.75 15.0413 8.75 16.5Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Sparkles;
