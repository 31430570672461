import { all, fork } from 'redux-saga/effects';

import flowSaga from './flow/store/saga';
import initiativeSaga from './initiative/store/saga';
import projectSaga from './project/store/saga';
import recentsSaga from './recents/store/saga';
import sphereSaga from './sphere/store/saga';
import sphereCreateSaga from './sphereCreate/store/saga';

function* pagesSaga() {
  yield all([
    fork(sphereCreateSaga),
    fork(sphereSaga),
    fork(projectSaga),
    fork(initiativeSaga),
    fork(flowSaga),
    fork(recentsSaga),
  ]);
}

export default pagesSaga;
