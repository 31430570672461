import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useGetFormFieldPrefix } from 'hooks/useGetFormFieldPrefix';
import { FormField, FormFieldSubTypes, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from '../../FormArrayRenderer/hooks/useGetArraySubTypeProps';

export const useGetFormSubTypeProps = (field: FormField): { formValues: FormFields[]; invalidFields: string[] } => {
  const fieldNamePrefix = useGetFormFieldPrefix(field);
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const formFields = useMemo(() => {
    const formFields: string[] = [];
    if (isDef(subTypeProps)) {
      const { dependencies } = subTypeProps;
      if (isDef(dependencies)) {
        formFields.push(
          ...Object.keys(dependencies)
            .filter((key) => isDef(dependencies[key].fieldName))
            .map((key) => dependencies[key].fieldName || '')
        );
      }
    }
    return formFields;
  }, [subTypeProps]);
  const formFieldNames = useMemo(() => formFields.map((key) => `${fieldNamePrefix}.${key}`), [fieldNamePrefix, formFields]);
  const formValues = useWatch({ name: formFieldNames }) as FormFields[];
  const invalidFields = useMemo(() => {
    return formFields.filter((key, index) => !isDefAndNotNull(formValues[index]));
  }, [formFields, formValues]);

  return { formValues, invalidFields };
};
