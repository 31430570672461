import { useMemo } from 'react';

import { ArraySubTypeProps, FormFieldArray, FormFieldSubTypes, FormFields } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from './useGetArraySubTypeProps';

export const useFormArrayDisplayValue = (field: FormFieldArray, fieldValue: FormFields[]) => {
  const subTypeProps = useGetSubTypeProps<ArraySubTypeProps>(field, FormFieldSubTypes.selectDatasource);

  return useMemo(() => {
    if (!isDefAndNotNull(fieldValue)) {
      return '';
    }
    if (fieldValue.length === 0) {
      return '';
    }
    if (isDef(field.items)) {
      if (isDef(subTypeProps?.displayField)) {
        return fieldValue.map((v) => v[subTypeProps?.displayField as string]).join(', ');
      } else {
        return `${fieldValue.length} item(s)`;
      }
    } else {
      return fieldValue.join(', ');
    }
  }, [fieldValue, field, subTypeProps]);
};
