import styled from 'styled-components';

import Icon from 'components/Icon';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ size: '12px' })};
  flex: 1;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
`;
export const FolderIconContainer = styled.div`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.pink};
  padding: 2px;
  margin-right: 8px;
`;
export const FolderIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.background};
`;
export const TitleContainer = styled.div`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
