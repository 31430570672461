import { createAction, createAsyncAction } from 'typesafe-actions';

import { RecentsInitiative } from 'models/RecentsInitiative';
import { RecentsProject } from 'models/RecentsProject';
import { Sphere } from 'models/Sphere';

export type FetchRecentsSuccessPayload = {
  businessProcesses: RecentsInitiative[];
  projects: RecentsProject[];
  spheres: Sphere[];
};
export const fetchRecents = createAsyncAction(
  'recents/fetchRecents/request',
  'recents/fetchRecents/success',
  'recents/fetchRecents/failure'
)<undefined, FetchRecentsSuccessPayload, undefined>();

export const reset = createAction('recents/reset')();
