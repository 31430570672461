import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';

import { ICONS_TYPES } from '../Icon';
import { PlacementTypes } from '../Popover';

import Calendar from './components/Calendar';
import { FORMAT_SCHEMA } from './constants';
import {
  AttentionIcon,
  ErrorMessage,
  ErrorMessageContainer,
  InputContainer,
  Label,
  RequiredMark,
  Root,
  StyledInput,
  StyledPopover,
} from './styles';
import { Props } from './types';

const DatePicker: FC<Props> = ({
  className,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  readOnly,
  required,
  touched,
  value,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const isValidDate: boolean = useMemo(() => moment(value, FORMAT_SCHEMA).isValid(), [value]);
  const selectedDate = useMemo(
    () => (isValidDate ? moment(value, FORMAT_SCHEMA).format(FORMAT_SCHEMA) : ''),
    [isValidDate, value]
  );
  const Input = useMemo(
    () => (
      <InputContainer disabled={disabled} readOnly={readOnly} error={error} touched={touched} $active={isActive}>
        <StyledInput value={selectedDate} readOnly placeholder={placeholder} disabled={disabled} />
      </InputContainer>
    ),
    [disabled, error, isActive, placeholder, readOnly, selectedDate, touched]
  );

  return (
    <Root className={className}>
      {label && (
        <Label htmlFor={name}>
          {required && !disabled ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <>
        {disabled || readOnly ? (
          { Input }
        ) : (
          <StyledPopover
            defaultPlacement={[PlacementTypes.left, PlacementTypes.bottom]}
            content={<Calendar value={value} onChange={onChange} setIsActive={setIsActive} onBlur={onBlur} />}
          >
            {Input}
          </StyledPopover>
        )}
      </>
      {error && touched && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export type { Props };

export default DatePicker;
