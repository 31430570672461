import { ActionType, getType } from 'typesafe-actions';

import { CreateWizardSteps } from '../enums';

import * as sphereCreateActions from './actions';

type SphereCreateActionType = ActionType<typeof sphereCreateActions>;

export type SphereCreateState = {
  createSphereLoading: boolean;
  wizardStep: CreateWizardSteps;
};

const initialState: SphereCreateState = {
  createSphereLoading: false,
  wizardStep: CreateWizardSteps.initial,
};

const sphereCreateReducer = (state: SphereCreateState = initialState, action: SphereCreateActionType): SphereCreateState => {
  switch (action.type) {
    case getType(sphereCreateActions.setWizardStep):
      return {
        ...state,
        wizardStep: action.payload.step,
      };

    case getType(sphereCreateActions.reset):
      return initialState;

    case getType(sphereCreateActions.createSphere.request):
      return {
        ...state,
        createSphereLoading: true,
      };
    case getType(sphereCreateActions.createSphere.success):
    case getType(sphereCreateActions.createSphere.failure):
      return {
        ...state,
        createSphereLoading: false,
      };

    default:
      return state;
  }
};

export default sphereCreateReducer;
