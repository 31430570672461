import styled, { css } from 'styled-components';

import { flexStyle, rgba } from 'styles/mixins';

import Icon from '../Icon';
import Loader from '../Loader';

import { Sizes, StyleTypes } from './enums';
import { Props } from './types';

export const StyledLoader = styled(Loader)``;
export const Root = styled.button<Partial<Props> & { $hasLabel: boolean }>`
  ${flexStyle({ align: 'center', justify: 'center' })}
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 200ms ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ styleType, theme }) => {
    switch (styleType) {
      case StyleTypes.outlined:
        return css`
          background-color: transparent;
          color: ${theme.colors.primary};
          border: 1px solid ${theme.colors.primary};

          & ${StyledLoader} {
            border-top-color: ${({ theme }) => theme.colors.primary};
            border-bottom-color: ${({ theme }) => theme.colors.primary};
          }

          &:hover:not(:disabled) {
            background-color: ${rgba(theme.colors.primary, 6)};
          }

          &:disabled {
            border: 1px solid ${theme.colors.divider};
            color: ${theme.colors.grey};
          }
        `;
      case StyleTypes.link:
        return css`
          background-color: ${rgba(theme.colors.primaryLight, 6)};
          color: ${theme.colors.primary};

          & ${StyledLoader} {
            border-top-color: ${({ theme }) => theme.colors.primary};
            border-bottom-color: ${({ theme }) => theme.colors.primary};
          }

          &:hover:not(:disabled) {
            background-color: ${rgba(theme.colors.primaryLight, 10)};
          }

          &:disabled {
            background-color: transparent;
            color: ${theme.colors.grey};
          }
        `;
      case StyleTypes.danger:
        return css`
          background-color: transparent;
          color: ${theme.colors.red};
          border: 1px solid ${theme.colors.red};

          & ${StyledLoader} {
            border-top-color: ${({ theme }) => theme.colors.red};
            border-bottom-color: ${({ theme }) => theme.colors.red};
          }

          &:hover:not(:disabled) {
            background-color: ${rgba(theme.colors.red, 10)};
          }

          &:disabled {
            background-color: ${theme.colors.surfaceSecondary};
            color: ${theme.colors.grey};
            border: 1px solid ${theme.colors.divider};
          }
        `;
      default: // filled
        return css`
          background-color: ${theme.colors.primary};
          color: ${theme.colors.background};

          & ${StyledLoader} {
            border-top-color: ${({ theme }) => theme.colors.background};
            border-bottom-color: ${({ theme }) => theme.colors.background};
          }

          &:hover:not(:disabled) {
            background-color: ${theme.colors.primaryDark};
          }

          &:disabled {
            background-color: ${theme.colors.surfaceSecondary};
            color: ${theme.colors.grey};
            border: 1px solid ${theme.colors.divider};
          }
        `;
    }
  }}

  ${({ $hasLabel, size }) => {
    switch (size) {
      case Sizes.small:
        return css`
          padding: ${$hasLabel ? '0 16px' : '0 6px'};
          height: 32px;
        `;
      default: // large
        return css`
          padding: ${$hasLabel ? '0 16px' : '0 10px'};
          height: 40px;
        `;
    }
  }}
`;

export const LeftIcon = styled(Icon)<{ $buttonDisabled?: boolean; $hasLabel: boolean }>`
  margin-right: ${({ $hasLabel }) => ($hasLabel ? '8px' : '0')};
  transition: scale 200ms ease-in-out;
  scale: 1;

  ${Root}:hover & {
    scale: 1.2;
  }
`;

export const RightIcon = styled(Icon)<{ $buttonDisabled?: boolean; $hasLabel: boolean }>`
  margin-left: ${({ $hasLabel }) => ($hasLabel ? '8px' : '0')};
  transition: scale 200ms ease-in-out;
  scale: 1;

  ${({ $buttonDisabled }) =>
    !$buttonDisabled &&
    css`
      ${Root}:hover & {
        scale: 1.2;
      }
    `}
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: normal;
  letter-spacing: 1.25px;
`;
