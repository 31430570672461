import { AxiosResponse } from 'axios';

import { RecentsInitiative } from 'models/RecentsInitiative';
import { RecentsProject } from 'models/RecentsProject';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'recents';

export const fetchRecents = (): Promise<
  AxiosResponse<
    APIResponse<{
      recents: {
        businessProcesses: RecentsInitiative[];
        projects: RecentsProject[];
        spheres: Sphere[];
      };
    }>
  >
> =>
  request({
    endpoint,
    method: Methods.get,
  });
