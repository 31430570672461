import { toast } from 'react-toastify';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import { slideClose } from 'components/FlowNavMenu/store/actions';
import { Modes } from 'components/Modal';

import API, { apiCallHandler } from 'api';
import { getResourceIdentifier } from 'models/Resource/utils';
import { CREATE_RESOURCE_MODAL_ID, PROJECT_RESOURCES_MODAL_ID } from 'pages/components/ProjectResourcesList/constant';
import { DELETE_RESOURCE_CONFIRM_MODAL_ID, RESOURCE_MODAL_ID } from 'pages/components/ResourceCard/constants';
import { fetchFlow } from 'pages/flow/store/actions';
import { isDefAndNotNull } from 'utils/def';

import { modalClose, modalOpen } from '../modal/actions';

import { createResource, deleteResource, fetchResources, updateResource, validateResource } from './actions';

function* onFetchProjectResources({
  payload: { projectName, sphereName },
}: ReturnType<typeof fetchResources.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchResources.failure());
    },
    errMessageFallback: 'Failed to fetch resources',
    tryHandler: function* () {
      const {
        data: {
          data: { resources },
        },
      } = yield* call(API.resources.fetchProjectResources, { project: projectName, sphere: sphereName });

      yield put(fetchResources.success({ list: resources }));
    },
  });
}

function* onCreateProjectResource({ payload: { data, onSuccess } }: ReturnType<typeof createResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createResource.failure());
    },
    errMessageFallback: 'Failed to create resource',
    tryHandler: function* () {
      yield* call(API.resources.createProjectResource, data);
      yield* onFetchProjectResources({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchResources.request),
      });

      yield put(createResource.success());
      yield put(modalClose.request({ animated: false, id: CREATE_RESOURCE_MODAL_ID }));
      yield put(
        modalOpen({
          animated: false,
          id: `${RESOURCE_MODAL_ID}_${getResourceIdentifier({ name: data.name, type: data.type })}`,
          mode: Modes.rightSlide,
        })
      );
      yield call(toast.success, `The Resource '${data.name}' was successfully created`);

      if (onSuccess) {
        onSuccess(data.name);
      }
    },
  });
}

function* onUpdateProjectResource({ payload: { data } }: ReturnType<typeof updateResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateResource.failure());
    },
    errMessageFallback: 'Failed to update resource',
    tryHandler: function* () {
      yield* call(API.resources.updateProjectResource, data);
      yield* onFetchProjectResources({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchResources.request),
      });

      yield put(updateResource.success());
      const modalId = `${RESOURCE_MODAL_ID}_${getResourceIdentifier({ name: data.oldName, type: data.type })}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(slideClose.request({ id: PROJECT_RESOURCES_MODAL_ID }));
      yield call(toast.success, `The Resource '${data.oldName}' was successfully updated`);
    },
  });
}

function* onValidateProjectResource({ payload: { bp, data, flow } }: ReturnType<typeof validateResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(validateResource.failure());
    },
    errMessageFallback: 'Failed to validate resource',
    tryHandler: function* () {
      yield* call(API.resources.validateProjectResource, data);
      yield put(validateResource.success());

      if (isDefAndNotNull(bp) && isDefAndNotNull(flow)) {
        yield put(
          fetchFlow.request({
            bp,
            name: flow,
            project: data.project,
            sphere: data.sphere,
          })
        );
      }

      yield call(toast.success, `The Resource '${data.oldName}' was successfully validated`);
    },
  });
}

function* onDeleteProjectResource({ payload: { data } }: ReturnType<typeof deleteResource.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteResource.failure());
    },
    errMessageFallback: 'Failed to delete resource',
    tryHandler: function* () {
      yield* call(API.resources.deleteProjectResource, data);
      yield* onFetchProjectResources({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchResources.request),
      });

      yield put(deleteResource.success());
      const modalId = `${RESOURCE_MODAL_ID}_${getResourceIdentifier(data)}`;
      const deleteModalId = `${DELETE_RESOURCE_CONFIRM_MODAL_ID}_${getResourceIdentifier(data)}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(modalClose.request({ id: deleteModalId }));
      yield put(slideClose.request({ id: PROJECT_RESOURCES_MODAL_ID }));
      yield call(toast.success, `The Resource '${data.name}' was successfully deleted`);
    },
  });
}

function* projectResourcesSaga() {
  yield all([
    takeEvery(fetchResources.request, onFetchProjectResources),
    takeEvery(createResource.request, onCreateProjectResource),
    takeEvery(updateResource.request, onUpdateProjectResource),
    takeEvery(validateResource.request, onValidateProjectResource),
    takeEvery(deleteResource.request, onDeleteProjectResource),
  ]);
}

export default projectResourcesSaga;
