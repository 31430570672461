import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { slideClose } from 'components/FlowNavMenu/store/actions';
import { ICONS_TYPES } from 'components/Icon';
import { TreeNodeProps } from 'components/Tree/types';

import { PROJECT_EXPLORER_MODAL_ID } from '../../constants';

import { TitleContainer } from '../ProjectNode/styles';

import { FileIcon, FileIconContainer, Root } from './styles';

const FlowNode: FC<TreeNodeProps> = ({ className, getParentNode, node }) => {
  const { sphereName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initiativeNode = useMemo(() => getParentNode(node), [getParentNode, node]);
  const projectNode = useMemo(() => getParentNode(initiativeNode), [getParentNode, initiativeNode]);
  const handleClick = useCallback(() => {
    dispatch(slideClose.request({ id: PROJECT_EXPLORER_MODAL_ID }));
    navigate(`/spheres/${sphereName}/projects/${projectNode?.name}/initiative/${initiativeNode?.name}/flow/${node.name}`);
  }, [node, sphereName, projectNode, initiativeNode, navigate, dispatch]);
  return (
    <Root className={className} onClick={handleClick}>
      <FileIconContainer>
        <FileIcon icon={ICONS_TYPES.SiteMap} size={14} />
      </FileIconContainer>
      <TitleContainer>{node.name}</TitleContainer>
    </Root>
  );
};

export default FlowNode;
