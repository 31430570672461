import { arrow, computePosition, flip, offset, shift, size } from '@floating-ui/react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { isDefAndNotNull } from 'utils/def';

import { MAX_WIDTH, OFFSET, PADDING, PLACEMENT_TYPES } from './constans';
import { Root, TooltipArrow, TooltipContainer } from './styles';
import { Props } from './types';

const Tooltip: FC<Props> = ({ children, className, content, disabled, placementType = PLACEMENT_TYPES.right }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleShow = useCallback(() => {
    setShowTooltip(true);
  }, []);
  const handleHide = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const setPositions = useCallback(() => {
    if (rootRef.current && containerRef.current && arrowRef.current) {
      computePosition(rootRef?.current, containerRef?.current, {
        middleware: [
          offset(OFFSET),
          size({
            apply({ elements }) {
              Object.assign(elements.floating.style, {
                maxWidth: `${MAX_WIDTH}px`,
              });
            },
          }),
          flip({ padding: PADDING }),
          shift({ padding: PADDING }),
          arrow({ element: arrowRef?.current }),
        ],
        placement: placementType,
      }).then(({ middlewareData, placement, x: containerX, y: containerY }) => {
        const { arrow } = middlewareData;
        const staticSide =
          {
            bottom: 'top',
            left: 'right',
            right: 'left',
            top: 'bottom',
          }[placement.split('-')[0]] || '';

        if (containerRef.current) {
          Object.assign(containerRef.current.style, {
            left: `${containerX}px`,
            top: `${containerY}px`,
          });
        }

        if (arrowRef.current && arrow) {
          Object.assign(arrowRef?.current?.style, {
            bottom: '',
            left: isDefAndNotNull(arrow.x) ? `${arrow.x}px` : '',
            right: '',
            top: isDefAndNotNull(arrow.y) ? `${arrow.y}px` : '',
            ...{ [staticSide]: '-4px' },
          });
        }
      });
    }
  }, [rootRef, containerRef, arrowRef, placementType]);

  useEffect(() => {
    setPositions();
  }, [setPositions, showTooltip]);

  return (
    <Root
      disabled={disabled}
      className={className}
      onMouseEnter={disabled ? undefined : handleShow}
      onMouseLeave={disabled ? undefined : handleHide}
      ref={rootRef}
    >
      {children}
      {showTooltip && (
        <TooltipContainer ref={containerRef}>
          <TooltipArrow ref={arrowRef} />
          {content}
        </TooltipContainer>
      )}
    </Root>
  );
};

export * from './constans';
export * from './types';

export default Tooltip;
