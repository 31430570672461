import React, { FC } from 'react';

import { ICONS_TYPES } from 'components/Icon';
import { TreeNodeProps } from 'components/Tree/types';

import { FolderIcon, FolderIconContainer, Root, TitleContainer } from './styles';

const RepositoryNode: FC<TreeNodeProps> = ({ className, node }) => {
  return (
    <Root className={className}>
      <FolderIconContainer>
        <FolderIcon icon={ICONS_TYPES.Folder} size={14} />
      </FolderIconContainer>
      <TitleContainer>{node.name}</TitleContainer>
    </Root>
  );
};

export default RepositoryNode;
