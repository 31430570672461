import { InitiativeTypeNames, InitiativeTypes } from 'models/Initiative/enums';

export const INITIATIVE_NAME_UNIQ_ERROR_TEXT = 'Initiative name already exists!';

export const INITIATIVE_TYPES_OPTIONS = {
  [InitiativeTypes.integration]: {
    id: InitiativeTypes.integration,
    name: InitiativeTypeNames.integration,
  },
  [InitiativeTypes.restApi]: {
    id: InitiativeTypes.restApi,
    name: InitiativeTypeNames.restApi,
  },
};
