import { intersection, isObject, upperFirst } from 'lodash';

import { isDef, isDefAndNotNull, isNotNull } from 'utils/def';

import { FormFieldInputNumberTypes, FormFieldSubTypes, FormFieldTypes } from './enums';
import {
  AnyOfSchema,
  ArraySchema,
  FormConfig,
  FormField,
  FormFieldArray,
  FormFieldFile,
  FormFieldFileValueType,
  FormFieldOption,
  FormFieldSelect,
  FormFieldUnion,
  FormFieldValidationRules,
  FormFieldValue,
  FormFields,
  ObjectSchema,
  OneOfProperty,
  PrimitiveProperty,
  Properties,
  Required,
  Schema,
  SchemaFieldArray,
  SchemaFieldObject,
  SchemaFieldString,
  SubTypeProps,
} from './types';
import {
  extractValue,
  isAnyOfSchema,
  isArrayProperty,
  isArraySchema,
  isBooleanProperty,
  isConstProperty,
  isDateProperty,
  isEnumProperty,
  isFileProperty,
  isFormFieldArray,
  isFormFieldCheckbox,
  isFormFieldHasDependantFields,
  isFormFieldInput,
  isFormFieldSelect,
  isFormFormFieldDate,
  isFormFormFieldTime,
  isIntegerProperty,
  isNumberProperty,
  isObjectSchema,
  isOneOfProperty,
  isSchemaFieldInteger,
  isSchemaFieldNumber,
  isSchemaFieldString,
  isStringProperty,
  isTimeProperty,
  isValueFile,
  isValueOption,
} from './utils';

const parseProperty = (propertyName: string, properties: Properties, required: Required): FormFieldUnion => {
  const property = properties[propertyName];
  const isRequired = required.includes(propertyName);
  const baseProps = {
    fieldSchema: property,
    name: propertyName,
    readonly: isConstProperty(property),
    required: isRequired,
    subType: property.behaviourHints?.subType as FormFieldSubTypes | undefined,
    subTypeProps: property.behaviourHints?.subTypeProps as SubTypeProps | undefined,
  };

  if (isOneOfProperty(property)) {
    return {
      type: FormFieldTypes.select,
      ...baseProps,
    };
  }

  const basePrimitiveProps = {
    defaultValue: isDefAndNotNull(property.default) ? property.default : property.const,
    label: upperFirst(property.title || propertyName),
    readonly: baseProps.readonly || (property.behaviourHints?.readOnly as boolean | undefined),
    secure: property.behaviourHints?.secure as boolean | undefined,
    submitOnChange: property.behaviourHints?.submitOnChange as boolean | undefined,
  };

  if (isBooleanProperty(property)) {
    return {
      disabled: property.disabled,
      type: FormFieldTypes.checkBox,
      ...baseProps,
      ...basePrimitiveProps,
    };
  }

  if (isIntegerProperty(property)) {
    return {
      disabled: property.disabled,
      numberType: FormFieldInputNumberTypes.integer,
      type: FormFieldTypes.input,
      ...baseProps,
      ...basePrimitiveProps,
      placeholder: property.description,
    };
  }

  if (isNumberProperty(property)) {
    return {
      disabled: property.disabled,
      numberType: FormFieldInputNumberTypes.number,
      type: FormFieldTypes.input,
      ...baseProps,
      ...basePrimitiveProps,
      placeholder: property.description,
    };
  }

  if (isEnumProperty(property)) {
    return {
      disabled: property.disabled,
      type: FormFieldTypes.select,
      ...baseProps,
      ...basePrimitiveProps,
      defaultValue: isDefAndNotNull(property.default) ? { label: property.default, value: property.default } : undefined,
      options: property.enum.map((p) => ({ label: p, value: p })),
      placeholder: property.description,
    };
  }

  if (isArrayProperty(property)) {
    return {
      type: FormFieldTypes.array,
      ...baseProps,
      ...basePrimitiveProps,
      defaultValue: [],
      fieldSchema: { ...baseProps.fieldSchema, title: propertyName },
      items: isObjectSchema(property.items) ? schemaToFormConfig(property.items) : undefined,
    };
  }

  if (isFileProperty(property)) {
    return {
      disabled: property.disabled,
      placeholder: property.description,
      type: FormFieldTypes.file,
      ...baseProps,
      ...basePrimitiveProps,
    } as FormFieldFile;
  }

  if (isDateProperty(property)) {
    return {
      disabled: property.disabled,
      placeholder: property.description,
      type: FormFieldTypes.date,
      ...baseProps,
      ...basePrimitiveProps,
    };
  }

  if (isTimeProperty(property)) {
    return {
      disabled: property.disabled,
      placeholder: property.description,
      type: FormFieldTypes.time,
      ...baseProps,
      ...basePrimitiveProps,
    };
  }

  const type = (() => {
    switch (property.behaviourHints?.subType) {
      case FormFieldSubTypes.resourcesSelect:
        return FormFieldTypes.select;
      case FormFieldSubTypes.servicesSelect:
        return FormFieldTypes.select;
      default:
        return FormFieldTypes.input;
    }
  })();

  return {
    disabled: property.disabled,
    placeholder: property.description,
    type,
    ...baseProps,
    ...basePrimitiveProps,
  };
};

const parseAnyOf = (property: AnyOfSchema): { selectFormConfigItem: FormFieldSelect } => {
  const { anyOf } = property;

  const formConfigItem: FormFieldSelect = {
    anyOfBehavior: true,
    fieldSchema: property,
    getOptionLabel: (option) => `${option.label}`,
    getOptionValue: (option) => `${option.value}`,
    name: '',
    placeholder: '',
    required: true,
    type: FormFieldTypes.select,
  };

  let defaultValue = undefined;
  const keyPropName = findKeyProperty(property);

  if (isDef(keyPropName)) {
    formConfigItem.dependantFields = anyOf.map((s) => {
      const schema = s as SchemaFieldObject;
      const formFieldOption: FormFieldOption = {
        fieldSchema: schema,
        name: s.title,
        type: FormFieldTypes.option,
        value: undefined,
      };

      const prop = schema.properties[keyPropName] as PrimitiveProperty;
      if (formConfigItem.name === '') {
        formConfigItem.name = keyPropName;
        formConfigItem.label = prop.title || property.title || keyPropName;
        formConfigItem.placeholder = prop.description || property.description;
      }
      formFieldOption.label = `${prop.const}`;
      formFieldOption.value = prop.const;

      if (isDef(prop.default)) {
        defaultValue = { label: `${prop.default}`, value: prop.default };
      }

      const dependantFields: FormConfig = [];
      schemaToFormConfig(s, [keyPropName]).forEach((property: FormFieldUnion) => {
        dependantFields.push({
          ...property,
        });
      });
      formFieldOption.dependantFields = dependantFields;

      return formFieldOption;
    });
  }
  formConfigItem.defaultValue = defaultValue;

  return { selectFormConfigItem: formConfigItem };
};

const parseOneOf = (
  propertyName: string,
  property: OneOfProperty,
  selectFormConfigItem: FormFieldSelect
): { selectFormConfigItem: FormFieldSelect } => {
  const formConfigItem: FormFieldSelect = { ...selectFormConfigItem };
  let defaultValue = undefined;
  let label = undefined;
  let placeholder: string | undefined = undefined;
  formConfigItem.getOptionLabel = (option) => `${option.value}`;
  formConfigItem.getOptionValue = (option) => `${option.label}`;
  formConfigItem.dependantFields = property.oneOf.map((p: SchemaFieldObject) => {
    const formOptionField: FormFieldOption = {
      fieldSchema: property,
      name: formConfigItem.name,
      type: FormFieldTypes.option,
      value: undefined,
    };
    if (isStringProperty(p) && isConstProperty(p)) {
      formOptionField.value = (p as SchemaFieldString).const;
      formOptionField.label = `${(p as SchemaFieldString).const}`;
    } else {
      const dependantFields: FormConfig = [];
      const oneOfProp = p.properties[propertyName];
      placeholder = placeholder || p.description;
      if (isConstProperty(oneOfProp)) {
        label = upperFirst(oneOfProp.title || propertyName);
        formOptionField.value = oneOfProp.const;
        formOptionField.label = `${oneOfProp.const}`;

        if (isDef(oneOfProp.default)) {
          defaultValue = { label: `${oneOfProp.default}`, value: oneOfProp.default };
        }
        const dependantProperties = { ...p.properties };
        delete dependantProperties[propertyName];
        const dependantRequired = p.required || [];
        for (const dependantPropertyName in dependantProperties) {
          if (isOneOfProperty(dependantProperties[dependantPropertyName])) {
            const dependantFormConfigItem = parseProperty(dependantPropertyName, dependantProperties, dependantRequired);
            const { selectFormConfigItem } = parseOneOf(
              dependantPropertyName,
              dependantProperties[dependantPropertyName] as OneOfProperty,
              dependantFormConfigItem as FormFieldSelect
            );

            dependantFields.push(selectFormConfigItem);
          } else {
            dependantFields.push({
              ...parseProperty(dependantPropertyName, dependantProperties, dependantRequired),
            });
          }
        }
      }
      formOptionField.dependantFields = dependantFields;
    }
    return formOptionField;
  });
  formConfigItem.label = label;
  formConfigItem.placeholder = formConfigItem.placeholder || placeholder;
  formConfigItem.defaultValue = defaultValue;
  return { selectFormConfigItem: formConfigItem };
};

const findKeyProperty = (property: AnyOfSchema): string | undefined => {
  const result = intersection(
    ...property.anyOf
      .filter((o) => isObjectSchema(o))
      .map((o) => o as ObjectSchema)
      .map((o: ObjectSchema) => Object.keys(o.properties).filter((key) => isConstProperty(o.properties[key])))
  );
  return result.length ? result[0] : undefined;
};

export const schemaToFormConfig = (schema: Schema, skipPropNames: string[] = []): FormConfig => {
  const formConfig: FormConfig = [];

  if (isAnyOfSchema(schema)) {
    const { selectFormConfigItem } = parseAnyOf(schema as AnyOfSchema);
    formConfig.push(selectFormConfigItem);
  }

  if (isArraySchema(schema)) {
    const { behaviourHints, items } = schema as ArraySchema;
    formConfig.push({
      fieldSchema: schema as SchemaFieldArray,
      items: isObjectSchema(items) || isAnyOfSchema(items) ? schemaToFormConfig(items) : undefined,
      label: schema.title,
      name: schema.title,
      subType: behaviourHints?.subType,
      subTypeProps: behaviourHints?.subTypeProps,
      type: FormFieldTypes.array,
    });
  }

  if (isObjectSchema(schema)) {
    const { properties, required } = schema as ObjectSchema;
    for (const propertyName in properties) {
      const property = properties[propertyName];

      if (skipPropNames.includes(propertyName)) {
        continue;
      }

      const formConfigItem = parseProperty(propertyName, properties, required);

      if (isOneOfProperty(property)) {
        const { selectFormConfigItem } = parseOneOf(propertyName, property, formConfigItem as FormFieldSelect);

        formConfig.push(selectFormConfigItem);
      } else {
        formConfig.push(formConfigItem);
      }
    }
  }

  return formConfig;
};

const iterateFormField = (formConfigItem: FormFieldUnion, formFields: FormFields): FormFields => {
  let result: FormFields = {};
  const propertyName = formConfigItem.name;
  const formField = formFields[propertyName];

  if (isDef(formField) && formConfigItem) {
    const isFieldNumber = isFormFieldInput(formConfigItem) && isDefAndNotNull(formConfigItem.numberType);
    const extractedValue = extractValue(formField);
    const extractedValueNonEmptyString = extractedValue === '' ? undefined : extractedValue;
    const value =
      isFieldNumber && isDefAndNotNull(extractedValueNonEmptyString)
        ? +extractedValueNonEmptyString
        : extractedValueNonEmptyString;

    if (isFormFieldHasDependantFields(formConfigItem)) {
      const selectedOption = formConfigItem.dependantFields
        ?.filter((f) => f.type === FormFieldTypes.option)
        ?.find((f) => (f as FormFieldOption).value === value);

      const dependantFields = (selectedOption?.dependantFields || []).reduce(
        (memo, field) => ({
          ...memo,
          ...iterateFormField(field, formFields),
        }),
        {}
      );

      if (formConfigItem.anyOfBehavior) {
        result = {
          [propertyName]: isNotNull(value) ? value : undefined,
          ...dependantFields,
        };
      } else {
        result[propertyName] = {
          [propertyName]: isNotNull(value) ? value : undefined,
          ...dependantFields,
        };
      }
    } else if (isFormFieldArray(formConfigItem) && Array.isArray(value)) {
      result[propertyName] = value.map((f) => {
        if (isDef(formConfigItem.items)) {
          return (formConfigItem.items || [])
            .map((formConfig) => iterateFormField(formConfig, f as FormFields))
            .reduce(
              (memo, curr) => ({
                ...memo,
                ...curr,
              }),
              {}
            );
        } else {
          return f;
        }
      });
    } else {
      result[propertyName] = isNotNull(value) ? (isFieldNumber && value === '' ? undefined : value) : undefined;
    }
  }

  return result;
};

export const formatBeforeSubmit = (formFields: FormFields, formConfig: FormConfig): FormFields | FormFields[] => {
  let result: FormFields | FormFields[] = {};

  for (const formConfigItem of formConfig) {
    result = {
      ...result,
      ...iterateFormField(formConfigItem, formFields),
    };
  }

  if (formConfig.length === 1 && formConfig[0].type === FormFieldTypes.array) {
    result = result[formConfig[0].name] as FormFields[];
  }

  return result;
};

export const extractFiles = (formFields: FormFields) => {
  const result: { fields: FormFields; files: FormFieldFileValueType[] } = { fields: {}, files: [] };

  for (const fieldKey in formFields) {
    const fieldValue = formFields[fieldKey];
    if (isDefAndNotNull(fieldValue)) {
      if (isValueFile(fieldValue)) {
        result.files.push({ ...fieldValue });
        result.fields = { ...result.fields, [fieldKey]: { id: fieldValue.id, name: fieldValue.name } };
      } else if (isObject(fieldValue) && !Array.isArray(fieldValue) && !isValueOption(fieldValue)) {
        const { fields, files } = extractFiles(fieldValue);
        result.fields = { ...result.fields, [fieldKey]: fields };
        result.files = [...result.files, ...files];
      } else {
        result.fields = { ...result.fields, [fieldKey]: fieldValue };
      }
    }
  }

  return result;
};

const normalizeFormField = (formConfigItem: FormFieldUnion, formFields: FormFields) => {
  let result: FormFields = {};
  const propertyName = formConfigItem.name;
  const formField = formFields[propertyName];
  if (formConfigItem) {
    const defaultValue = normalizeDefaultValue(formConfigItem);
    if (isFormFieldHasDependantFields(formConfigItem)) {
      let value: FormFieldValue | FormFieldValue[] | FormFields[];
      if (isDef(formField)) {
        const field = formField as FormFields;
        if (isDef(field[propertyName])) {
          value = extractValue(field[propertyName]);
        } else {
          value = extractValue(formField);
        }
      }

      const selectedOption = formConfigItem.dependantFields
        ?.filter((f) => f.type === FormFieldTypes.option)
        ?.find((f) => (f as FormFieldOption).value === value);

      result = {
        [propertyName]: isDefAndNotNull(value)
          ? {
              label: `${value}`,
              value,
            }
          : defaultValue,
        ...(selectedOption?.dependantFields || []).reduce(
          (memo, field) => ({
            ...memo,
            ...normalizeFormField(field, formConfigItem.anyOfBehavior ? formFields : (formField as FormFields)),
          }),
          {}
        ),
      };

      const dependantFieldsNested = formConfigItem.dependantFields?.filter((f) => isFormFieldHasDependantFields(f)) || [];
      for (const dependantField of dependantFieldsNested) {
        const dependantFieldsSelect = dependantField.dependantFields?.filter((f) => isFormFieldSelect(f));
        const dependantFieldsDefaultValues = dependantFieldsSelect
          ?.map((f) => normalizeFormField(f, formFields))
          .reduce(
            (res, f) => ({
              ...res,
              ...f,
            }),
            {}
          );
        result = { ...result, ...dependantFieldsDefaultValues };
      }
    } else {
      const value = extractValue(formField);
      if (isFormFieldSelect(formConfigItem)) {
        result[propertyName] = isDef(value) ? { label: value, value } : defaultValue;
      } else if (isFormFieldArray(formConfigItem) && Array.isArray(value)) {
        if (isDef(formConfigItem.items)) {
          result[propertyName] = value.map((f) => {
            return (formConfigItem.items || [])
              .map((formConfig) => {
                return normalizeFormField(formConfig, f as FormFields);
              })
              .reduce(
                (memo, curr) => ({
                  ...memo,
                  ...curr,
                }),
                {}
              );
          });
        } else {
          result[propertyName] = value;
        }
      } else {
        result[propertyName] = isDef(value) ? value : defaultValue;
      }
    }
  }

  return result;
};

export const normalizeFields = (formFields: FormFields | FormFields[], formConfig: FormConfig): FormFields => {
  let result: FormFields = {};
  let values: FormFields;

  if (formConfig.length === 1 && formConfig[0].type === FormFieldTypes.array) {
    values = {
      [formConfig[0].name]: formFields,
    };
  } else {
    values = formFields as FormFields;
  }

  for (const formConfigItem of formConfig) {
    result = {
      ...result,
      ...normalizeFormField(formConfigItem, values),
    };
  }

  return result;
};

const normalizeDefaultValue = (formConfigItem: FormFieldUnion) => {
  if (isDef(formConfigItem.defaultValue)) {
    return formConfigItem.defaultValue;
  }
  if (isFormFieldInput(formConfigItem)) {
    return '';
  }
  if (isFormFieldCheckbox(formConfigItem)) {
    return false;
  }
  if (isFormFormFieldDate(formConfigItem)) {
    return '';
  }
  if (isFormFormFieldTime(formConfigItem)) {
    return undefined;
  }
  return null;
};

export const getValidationRulesFromAField = (field?: FormFieldUnion): FormFieldValidationRules => {
  if (!field) {
    return {};
  }

  const { fieldSchema, required } = field;
  const baseRules: FormFieldValidationRules = {
    required,
  };

  if (isSchemaFieldString(fieldSchema)) {
    return {
      maxLength: fieldSchema.maxLength,
      minLength: fieldSchema.minLength,
      pattern: fieldSchema.pattern ? RegExp(fieldSchema.pattern) : undefined,
      ...baseRules,
    };
  }

  if (isSchemaFieldNumber(fieldSchema) || isSchemaFieldInteger(fieldSchema)) {
    return {
      max: fieldSchema.maximum,
      min: fieldSchema.minimum,
      pattern: RegExp(/^\d+(\.\d+)?$/),
      ...baseRules,
    };
  }

  return baseRules;
};

export const getValidationRulesFromASchema = (name: string, schema: Schema): FormFieldValidationRules => {
  if (isObjectSchema(schema)) {
    const { properties, required } = schema;
    const property = properties[name];
    const baseRules: FormFieldValidationRules = {
      required: required.includes(name),
    };

    if (isStringProperty(property)) {
      return {
        maxLength: property.maxLength,
        minLength: property.minLength,
        pattern: property.pattern ? RegExp(property.pattern) : undefined,
        ...baseRules,
      };
    }

    if (isIntegerProperty(property) || isNumberProperty(property)) {
      return {
        max: property.maximum,
        min: property.minimum,
        ...baseRules,
      };
    }

    return baseRules;
  } else {
    return {
      required: false,
    };
  }
};

export const findFormFieldByName = (
  name: string,
  formFields: FormFieldUnion[],
  formValues: FormFields
): FormField | undefined => {
  let result: FormField | undefined;
  for (const formField of formFields) {
    if (formField.name === name) {
      result = formField;
    }

    if (!result) {
      if (isFormFieldArray(formField)) {
        const field = formField as FormFieldArray;
        result = findFormFieldByName(name, field.items || [], formValues);
      }
    }

    if (!result) {
      if (isFormFieldHasDependantFields(formField)) {
        const optionFields = (formField.dependantFields || []).filter(
          (p) => p.type === FormFieldTypes.option
        ) as FormFieldOption[];
        const dependantFields: FormFieldUnion[] =
          optionFields.find((f) => f.value === extractValue(formValues[formField.name]))?.dependantFields || [];
        result = findFormFieldByName(name, dependantFields, formValues);
      }
    }

    if (result) {
      return result;
    }
  }

  return undefined;
};

export * from './types';
export * from './enums';
export * from './utils';
