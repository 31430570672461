import styled from 'styled-components';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import SchemaIcon from 'components/SchemaIcon';
import Select from 'components/Select';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
`;
export const ServiceInfoContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
  margin-bottom: 24px;
`;
export const ServiceIcon = styled(SchemaIcon)`
  margin-right: 32px;
  width: 60px;
  height: 60px;
  font-size: 60px;
`;
export const ServiceTextContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const ServiceName = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1.2, size: '12px', textAlign: 'left', weight: 500 })};
  letter-spacing: 0.1px;
`;
export const ServiceDescription = styled.span`
  ${textStyle({ color: 'textSecondary', lineHeight: 1.4, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
`;
export const FormFieldsContainer = styled.span`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  margin-bottom: 40px;
`;
export const StyledInput = styled(Input)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const StyledSelect = styled(Select)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
` as typeof Select;
export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 24px;
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ direction: 'column' })};
  margin-top: auto;
`;
export const ActionButtonsContainer = styled.div`
  ${flexStyle({ direction: 'row', wrap: 'wrap' })};
  :not(:last-child) {
    margin-right: 16px;
  }
`;
export const SubmitButtonsContainer = styled.div`
  ${flexStyle({ direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
  margin-top: 24px;
  width: '100%';
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
`;
