import { isDef } from 'utils/def';

import { ProjectTreeItemTypes } from './enums';
import { ProjectFlow, ProjectTree } from './index';

const iterateTree = (node: ProjectTree, callback: (node: ProjectTree) => void) => {
  callback(node);
  node.children?.forEach((child: ProjectTree) => {
    iterateTree(child, callback);
  });
};

export const getProjectFlowsFromTree = (tree: ProjectTree, bp?: string): ProjectFlow[] => {
  const flows: ProjectFlow[] = [];
  let currentBp: string = '';
  iterateTree(tree, (node: ProjectTree) => {
    if (node.type === ProjectTreeItemTypes.initiative) {
      currentBp = node.name;
    }
    if (node.type === ProjectTreeItemTypes.flow && (!isDef(bp) || bp === currentBp)) {
      flows.push({ bp: currentBp, name: node.name });
    }
  });
  return flows;
};
