import { compact, difference } from 'lodash';
import { useLayoutEffect, useRef, useState } from 'react';

import useWindowResize from 'hooks/useWindowResize';
import { isDef } from 'utils/def';

import { FLOW_TABS_GAP } from '../constant';

const useGetHiddenFlowTabs = (tabsOpened: string[], flowName: string | undefined) => {
  const tabsRef = useRef<(HTMLDivElement | null)[]>([]);
  const { width: windowWidth } = useWindowResize();
  const [hiddenTabs, setHiddenTabs] = useState<string[]>([]);

  useLayoutEffect(() => {
    if (tabsRef.current.length && tabsOpened.length) {
      const tabs = compact(tabsRef.current);
      const visibleTabs = [];

      if (tabs.length) {
        let width = 0;

        if (isDef(flowName)) {
          const index = tabsOpened.findIndex((x) => x === flowName);

          if (index >= 0) {
            const flowTab = tabs[index];

            if (isDef(flowTab)) {
              width += flowTab.clientWidth;
              visibleTabs.push(flowName);
            }
          }
        }

        for (let index = 0; index < tabsOpened.length; index++) {
          const tabName = tabsOpened[index];
          const tab = tabs[index];

          if (tab) {
            if (tabName !== flowName) {
              width += tab.clientWidth;

              if (windowWidth - width > FLOW_TABS_GAP) {
                visibleTabs.push(tabName);
              }
            }
          }
        }
      }

      setHiddenTabs(difference(tabsOpened, visibleTabs));
    }
  }, [tabsOpened, windowWidth, flowName]);

  return {
    hiddenTabs,
    tabsRef,
  };
};

export default useGetHiddenFlowTabs;
