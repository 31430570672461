import { createAsyncAction } from 'typesafe-actions';

import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { Tag } from 'models/Tag';

export type FetchTagsRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export type FetchTagsSuccessPayload = {
  list: Tag[];
};
export const fetchTags = createAsyncAction('project/fetchTags/request', 'project/fetchTags/success', 'project/fetchTags/failure')<
  FetchTagsRequestPayload,
  FetchTagsSuccessPayload,
  undefined
>();

export type CreateTagRequestPayload = {
  data: { data: Tag; project: Project['name']; sphere: Sphere['name'] };
};
export const createTag = createAsyncAction('project/createTag/request', 'project/createTag/success', 'project/createTag/failure')<
  CreateTagRequestPayload,
  undefined,
  undefined
>();

export type UpdateTagRequestPayload = {
  data: { data: Tag & { oldName: Tag['name'] }; project: Project['name']; sphere: Sphere['name'] };
};
export const updateTag = createAsyncAction('project/updateTag/request', 'project/updateTag/success', 'project/updateTag/failure')<
  UpdateTagRequestPayload,
  undefined,
  undefined
>();

export type DeleteTagRequestPayload = {
  data: Tag & { project: Project['name']; sphere: Sphere['name'] };
};
export const deleteTag = createAsyncAction('project/deleteTag/request', 'project/deleteTag/success', 'project/deleteTag/failure')<
  DeleteTagRequestPayload,
  undefined,
  undefined
>();
