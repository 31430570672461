import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'business-processes';

export const fetchInitiatives = (params: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ businessProcesses: Initiative[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const fetchInitiative = (params: {
  initiative: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ businessProcess: Initiative }>>> =>
  request({
    endpoint: `${endpoint}/${params.initiative}`,
    method: Methods.get,
    params: omit(params, 'initiative'),
  });

export const createInitiative = (data: {
  data: Initiative;
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const updateInitiative = (data: {
  data: Initiative & { oldName: Initiative['name'] };
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.put,
  });

export const deleteInitiative = (params: {
  initiative: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.initiative}`,
    method: Methods.delete,
    params: omit(params, 'initiative'),
  });
