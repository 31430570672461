import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import Button, { StyleTypes } from 'components/Button';

import { useSchemaCustomBehaviour } from 'hooks/xpi-components/useSchemaCustomBehaviour';
import { FormFields, formatBeforeSubmit, normalizeFields, schemaToFormConfig } from 'jsonSchema';

import FormFieldRenderer from '../../../../../FormFieldRenderer';

import { ButtonsContainer, FormFieldsContainer, Root, SubmitButton } from './styles';
import { Props } from './types';

const SchemaArrayRowForm: FC<Props> = ({ model, onCancel, onSubmit, schema, skipPropNames, submitting }) => {
  const rowSchema = useMemo(() => schema.items, [schema]);
  const initialFormConfig = useMemo(() => schemaToFormConfig(rowSchema, skipPropNames), [rowSchema, skipPropNames]);
  const formDefaultValues = useMemo(() => normalizeFields(model || {}, initialFormConfig), [initialFormConfig, model]);
  const methods = useForm<FormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const { control, formState, getValues, reset } = methods;
  const isDisabled = useMemo(() => !formState.isValid, [formState.isValid]);
  const values = useWatch({ control });
  const { model: updatedModel, schema: updatedSchema } = useSchemaCustomBehaviour(rowSchema, values);
  const memoFormConfig = useMemo(() => schemaToFormConfig(updatedSchema, skipPropNames), [updatedSchema, skipPropNames]);
  const handleSubmit = useCallback(() => {
    const formFields = getValues();
    const newValue = formatBeforeSubmit(formFields, memoFormConfig) as FormFields;
    onSubmit({ id: model?.id || '', ...newValue }, model);
  }, [getValues, memoFormConfig, model, onSubmit]);
  useEffect(() => {
    reset(updatedModel);
  }, [updatedModel, reset]);

  return (
    <Root>
      <FormFieldsContainer>
        <FormProvider {...methods}>
          {memoFormConfig.map((field) => {
            return <FormFieldRenderer key={field.name} field={field} />;
          })}
        </FormProvider>
      </FormFieldsContainer>
      <ButtonsContainer>
        <SubmitButton onClick={handleSubmit} disabled={isDisabled} label="Save" loading={submitting} />
        <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
      </ButtonsContainer>
    </Root>
  );
};

export default SchemaArrayRowForm;
