import styled, { css } from 'styled-components';

import { flexStyle, rgba, textStyle } from 'styles/mixins';

import Icon from '../Icon';
import Loader from '../Loader';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', wrap: 'nowrap' })};
`;
export const MenuItem = styled.div<{ active?: boolean; clickable?: boolean }>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  padding: 12px 16px;

  ${({ active, clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
      }

      &:hover {
        background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 10)};
      }

      ${active &&
      css`
        background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 10)};
      `}
    `}
`;
export const MenuItemIcon = styled(Icon)<{ backgroundColor?: string; color?: string }>`
  color: ${({ color, theme }) => theme.colors[color || 'textSecondary']};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme.colors[backgroundColor]};
      border-radius: 3px;
    `}

  margin-right: 16px;
`;
export const MenuItemLabel = styled.span<{ color?: string }>`
  ${({ color }) => textStyle({ color: color || 'textSecondary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
  white-space: nowrap;
`;
export const MenuItemLoading = styled(Loader)`
  border-top-color: inherit;
  border-bottom-color: inherit;
`;
export const DotsIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
`;
