import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Project } from 'models/Project';
import { Resource, ResourceWithFiles } from 'models/Resource';
import { Sphere } from 'models/Sphere';
import { requestDataToFormData } from 'utils/converters';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'resources';

export const fetchSphereResources = (params?: {
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ resources: Resource[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const createSphereResource = (
  data: ResourceWithFiles & { sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.post,
  });

export const validateSphereResource = (
  data: Resource & { oldName: Resource['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint: `${endpoint}/validate`,
    method: Methods.post,
  });

export const updateSphereResource = (
  data: ResourceWithFiles & { oldName: Resource['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.put,
  });

export const deleteSphereResource = (
  params: Pick<Resource, 'name' | 'type'> & { sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });

export const fetchProjectResources = (params?: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ resources: Resource[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const createProjectResource = (
  data: ResourceWithFiles & { project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.post,
  });

export const validateProjectResource = (
  data: Resource & { oldName: Resource['name']; project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint: `${endpoint}/validate`,
    method: Methods.post,
  });

export const updateProjectResource = (
  data: ResourceWithFiles & { oldName: Resource['name']; project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.put,
  });

export const deleteProjectResource = (
  params: Pick<Resource, 'name' | 'type'> & { project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });
