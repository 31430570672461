import styled from 'styled-components';

import { flexStyle } from 'styles/mixins';

import Icon from '../Icon';

export const Root = styled(Icon)`
  ${flexStyle({ align: 'center' })};
  cursor: pointer;
`;
