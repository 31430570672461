import React, { FC } from 'react';

const Time: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M17.5 10.2495C17.5502 8.74525 17.1468 7.26066 16.342 5.98878C15.5372 4.7169 14.3683 3.71658 12.9874 3.11789C11.6065 2.5192 10.0774 2.34985 8.59896 2.63184C7.12051 2.91384 5.7611 3.63414 4.6975 4.69909C3.6339 5.76403 2.91532 7.12435 2.6352 8.60316C2.35507 10.082 2.52636 11.6109 3.1268 12.991C3.72723 14.3712 4.72903 15.5387 6.00193 16.3419C7.27483 17.1451 8.75993 17.5467 10.2641 17.4945M9.99998 5.83285V9.99952L11.6666 11.6662M13.3333 15.8329C13.3333 16.4959 13.5967 17.1318 14.0655 17.6006C14.5344 18.0695 15.1703 18.3329 15.8333 18.3329C16.4964 18.3329 17.1322 18.0695 17.6011 17.6006C18.0699 17.1318 18.3333 16.4959 18.3333 15.8329C18.3333 15.1698 18.0699 14.5339 17.6011 14.0651C17.1322 13.5962 16.4964 13.3329 15.8333 13.3329C15.1703 13.3329 14.5344 13.5962 14.0655 14.0651C13.5967 14.5339 13.3333 15.1698 13.3333 15.8329Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Time;
