import React, { FC } from 'react';

const CloudDataConnection: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M11 11.9169V14.6669M11 14.6669C11.4862 14.6669 11.9525 14.86 12.2964 15.2039C12.6402 15.5477 12.8333 16.014 12.8333 16.5002M11 14.6669C10.5138 14.6669 10.0475 14.86 9.70364 15.2039C9.35982 15.5477 9.16667 16.014 9.16667 16.5002M9.16667 16.5002C9.16667 16.9865 9.35982 17.4528 9.70364 17.7966C10.0475 18.1404 10.5138 18.3336 11 18.3336C11.4862 18.3336 11.9525 18.1404 12.2964 17.7966C12.6402 17.4528 12.8333 16.9865 12.8333 16.5002M9.16667 16.5002H2.75M12.8333 16.5002H19.25M4.58333 9.07247C4.58333 7.50131 5.92167 6.22714 7.57167 6.22714C7.82375 5.10881 8.72208 4.19672 9.92933 3.83372C11.1366 3.46981 12.4685 3.71089 13.4227 4.46622C14.3779 5.21972 14.8106 6.37289 14.5585 7.49122H15.1938C15.7819 7.49001 16.3465 7.72243 16.7634 8.13739C17.1802 8.55235 17.4152 9.11588 17.4167 9.70406C17.4152 10.2922 17.1802 10.8558 16.7634 11.2707C16.3465 11.6857 15.7819 11.9181 15.1938 11.9169H7.57167C5.92167 11.9169 4.58333 10.6427 4.58333 9.07247Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloudDataConnection;
