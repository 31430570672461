import styled from 'styled-components';

import SchemaIcon from 'components/SchemaIcon';
import Select from 'components/Select';

import { flexStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
`;
export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 40px;
` as typeof Select;
export const OptionValueContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const OptionValueIcon = styled(SchemaIcon)`
  margin-right: 16px;
  width: 20px;
  height: 20px;
  font-size: 20px;
`;
