import { Branch } from 'models/Branch';
import { Step, StepTypes } from 'models/Step';

import { DEFAULT_STEP_NODE_ID, NODE_DEFAULT_POSITION } from '../constants';
import { NodeTypes } from '../enums';
import { StepNodeData, StepNodeDefaultData, StepNodeDefaultType, StepNodeType, StepWithPosition, XpiStudioNodes } from '../types';

import { generateStepDefaultNodeName } from './names';

export const flattenNodes = (steps: Step[]): XpiStudioNodes => {
  const result: XpiStudioNodes = [];

  const createNode = (data: StepNodeData): StepNodeType => ({
    data,
    id: data.step.name,
    position: NODE_DEFAULT_POSITION,
    type: NodeTypes.step,
  });
  const createDefaultStepNode = (data: StepNodeDefaultData): StepNodeDefaultType => ({
    data,
    id: generateStepDefaultNodeName(data?.parentStep?.name, data?.branch?.name),
    position: NODE_DEFAULT_POSITION,
    type: NodeTypes.stepDefault,
  });

  const processSteps = (steps: Step[]): void => {
    if (steps.length === 0) {
      result.push(createDefaultStepNode({}));
    } else {
      steps.forEach((step, sIndex) => {
        result.push(createNode({ parentStep: steps[sIndex - 1], step }));

        if (Array.isArray(step.branches) && step.branches.length !== 0) {
          step.branches.forEach((branch) => {
            processBranch(branch, step);
          });
        } else if (sIndex === steps.length - 1 && step.type !== StepTypes.goTo) {
          result.push(createDefaultStepNode({ parentStep: step }));
        }
      });
    }
  };

  const processBranch = (branch: Branch, parentStep: Step): void => {
    if (branch.steps.length === 0) {
      result.push(createDefaultStepNode({ branch, parentStep }));
    } else {
      branch.steps.forEach((step, sIndex) => {
        result.push(createNode({ branch, parentStep: branch.steps[sIndex - 1] || parentStep, step }));

        if (Array.isArray(step.branches) && step.branches.length !== 0) {
          step.branches.forEach((branch) => {
            processBranch(branch, step);
          });
        } else if (sIndex === branch.steps.length - 1 && step.type !== StepTypes.goTo) {
          result.push(createDefaultStepNode({ branch, parentStep: step }));
        }
      });
    }
  };

  processSteps(steps);
  return result;
};

export const flattenSteps = (steps: StepWithPosition[]): StepWithPosition[] => {
  const result: Step[] = [];
  const isBranch = (node: Step | Branch): node is Branch => 'steps' in node;
  const isStep = (node: Step | Branch): node is Step => !isBranch(node);

  const recurse =
    (parentStep?: Step) =>
    (node: Step | Branch): void => {
      if (isBranch(node)) {
        if (node.steps.length === 0) {
          result.push({
            name: `${parentStep?.name ? `${parentStep?.name}_` : ''}${node.name}_${DEFAULT_STEP_NODE_ID}`,
            type: DEFAULT_STEP_NODE_ID,
          });
        } else {
          node.steps.forEach((step) => {
            result.push(step);

            if (Array.isArray(step.branches)) {
              step.branches.forEach(recurse(step));
            }
          });
        }
      } else {
        result.push(node);
      }

      if (isStep(node) && Array.isArray(node.branches)) {
        node.branches.forEach(recurse(node));
      }
    };

  steps.forEach(recurse());

  return result;
};
