import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DevTestForm from 'components/DevTestForm';
import Modal from 'components/Modal';

import useDeepCallback from 'hooks/useDeepCallback';
import ProjectResourcesSection from 'pages/components/ProjectResourcesSection';
import ServicesSection from 'pages/components/ServicesSection';
import StepExplorer from 'pages/flow/components/StepExplorer';
import TriggerExplorer from 'pages/flow/components/TriggerExplorer';
import InitiativeFormUpdate from 'pages/initiative/components/InitiativeFormUpdate';
import ProjectExplorer from 'pages/project/components/ProjectExplorer';
import Repository from 'pages/project/components/Repository';
import { modalClose, modalOpen } from 'store/modal/actions';
import { buildDataCyValue } from 'utils/cy';

import { ICONS_TYPES } from '../Icon';
import { PLACEMENT_TYPES } from '../Tooltip';

import FlowNavSlider from './components/FlowNavSlider';
import {
  DEV_TEST_MODAL_ID,
  EDIT_INITIATIVE_MODAL_ID,
  PROJECT_EXPLORER_MODAL_ID,
  PROJECT_RESOURCES_MODAL_ID,
  REPOSITORY_MODAL_ID,
  SERVICES_MODAL_ID,
  STEP_EXPLORER_MODAL_ID,
  TRIGGER_EXPLORER_MODAL_ID,
} from './constant';
import { reset, slideClose, slideOpen } from './store/actions';
import { selectOpenedSlider } from './store/selectors';
import { ButtonTooltip, MenuItemIcon, ModalContainer, NavMenu, Root, StyledMenuItem } from './styles';
import { MenuItem, Props } from './types';

const FlowNavMenu: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const openedSlider = useSelector(selectOpenedSlider());

  const getDataCy = useCallback((elementName: string) => buildDataCyValue({ elementName, fieldName: 'flow' }), []);
  const handleCloseForm = useDeepCallback((id: string) => {
    dispatch(slideClose.request({ id }));
  });
  const handleOpenForm = useDeepCallback((id: string) => {
    const [openedSliderName] = Object.keys(openedSlider);
    dispatch(slideClose.request({ id: openedSliderName }));
    if (openedSliderName !== id) {
      dispatch(slideOpen({ id }));
    }
  });
  const handleOpenDevTestModal = useCallback(() => {
    dispatch(modalOpen({ id: DEV_TEST_MODAL_ID }));
  }, [dispatch]);
  const handleCloseDevTestModal = useCallback(() => {
    dispatch(modalClose.request({ id: DEV_TEST_MODAL_ID }));
  }, [dispatch]);

  const navMenuSettings: MenuItem[] = useMemo(
    () => [
      ((modalId: string = EDIT_INITIATIVE_MODAL_ID) => ({
        formComponent: <InitiativeFormUpdate onCancel={handleCloseForm(modalId)} />,
        formLabel: 'Initiative Properties',
        icon: ICONS_TYPES.Settings,
        label: 'Initiative Properties',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = PROJECT_EXPLORER_MODAL_ID) => ({
        formComponent: <ProjectExplorer />,
        formLabel: 'Project Explorer',
        icon: ICONS_TYPES.Files,
        label: 'Project Explorer',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = TRIGGER_EXPLORER_MODAL_ID) => ({
        formComponent: <TriggerExplorer />,
        formLabel: 'Triggers',
        icon: ICONS_TYPES.Bolt,
        label: 'Triggers',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = STEP_EXPLORER_MODAL_ID) => ({
        formComponent: <StepExplorer />,
        formLabel: 'Steps',
        icon: ICONS_TYPES.Pennant,
        label: 'Steps',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = PROJECT_RESOURCES_MODAL_ID) => ({
        formComponent: (
          <ProjectResourcesSection
            projectName={projectName!}
            sphereName={sphereName!}
            bpName={initiativeName}
            flowName={flowName}
          />
        ),
        formLabel: 'Resources',
        icon: ICONS_TYPES.CloudDataConnection,
        label: 'Resources',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = SERVICES_MODAL_ID) => ({
        formComponent: <ServicesSection projectName={projectName!} sphereName={sphereName!} />,
        formLabel: 'Services',
        icon: ICONS_TYPES.Stack,
        label: 'Services',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
      ((modalId: string = REPOSITORY_MODAL_ID) => ({
        formComponent: <Repository />,
        formLabel: 'Repository',
        icon: ICONS_TYPES.Variable,
        label: 'Repository',
        modalId,
        onClick: handleOpenForm(modalId),
      }))(),
    ],
    [flowName, handleCloseForm, handleOpenForm, initiativeName, projectName, sphereName]
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Root className={className}>
      <NavMenu>
        {navMenuSettings.map((menuItem: MenuItem, index) => (
          <ButtonTooltip key={index} placementType={PLACEMENT_TYPES.right} content={menuItem.label}>
            <StyledMenuItem
              onClick={menuItem.onClick}
              $active={openedSlider[menuItem.modalId]?.open && !openedSlider[menuItem.modalId]?.closingClass}
              data-cy={getDataCy(menuItem.label)}
            >
              <MenuItemIcon icon={menuItem.icon} size={22} />
            </StyledMenuItem>
          </ButtonTooltip>
        ))}
        <ButtonTooltip placementType={PLACEMENT_TYPES.right} content="DEV">
          <StyledMenuItem onClick={handleOpenDevTestModal}>
            <MenuItemIcon icon={ICONS_TYPES.SiteMap} size={22} />
          </StyledMenuItem>
        </ButtonTooltip>
      </NavMenu>
      <ModalContainer>
        {navMenuSettings.map((menuItem: MenuItem) => (
          <FlowNavSlider
            key={menuItem.modalId}
            sliderId={menuItem.modalId}
            title={menuItem.formLabel}
            onCancel={handleCloseForm(menuItem.modalId)}
          >
            {menuItem.formComponent}
          </FlowNavSlider>
        ))}
      </ModalContainer>
      <Modal
        id={DEV_TEST_MODAL_ID}
        renderTitle={() => 'Post DEV'}
        renderChildren={() => <DevTestForm onCancel={handleCloseDevTestModal} />}
      />
    </Root>
  );
};

export type { Props };

export default memo(FlowNavMenu);
