import { ActionType, getType } from 'typesafe-actions';

import * as logsActions from './actions';

type LogsActionType = ActionType<typeof logsActions>;

export type LogsState = {
  list: logsActions.AddLogPayload['log'][];
};

const initialState: LogsState = {
  list: [],
};

const logsReducer = (state: LogsState = initialState, action: LogsActionType): LogsState => {
  switch (action.type) {
    case getType(logsActions.addLog):
      return {
        ...state,
        list: state.list.filter((log) => log.type !== action.payload.log.type).concat(action.payload.log),
      };

    case getType(logsActions.markLogAsViewed):
      return {
        ...state,
        list: state.list.map((log) => {
          if (log.type === action.payload.type) {
            return {
              ...log,
              new: false,
            };
          }

          return log;
        }),
      };

    default:
      return state;
  }
};

export default logsReducer;
