import { AxiosResponse } from 'axios';

import { ResourceType } from 'models/ResourceType';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'resource-types';

export const fetchResourceTypes = (
  params?: RequestOptions['params']
): Promise<AxiosResponse<APIResponse<{ resources: ResourceType[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });
