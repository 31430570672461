import styled, { css } from 'styled-components';

import { flexStyle, scrollStyle } from 'styles/mixins';

import { PlacementTypes, Sizes } from './enums';

export const Root = styled.div<{ disabled?: boolean }>`
  position: relative;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;
export const PopoverContainer = styled.div<{
  placement: [PlacementTypes.left | PlacementTypes.right, PlacementTypes.top | PlacementTypes.bottom];
  size: Sizes;
}>`
  ${flexStyle({ direction: 'column', wrap: 'nowrap' })};
  ${scrollStyle()};
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 10;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ size }) => {
    switch (size) {
      case Sizes.small:
        return css`
          min-width: 20px;
          max-height: 200px;
        `;
      default:
        return css`
          min-width: 140px;
          max-height: 70vh;
        `;
    }
  }}
  ${({ placement }) => {
    const [horizontal, vertical] = placement;
    if (horizontal === PlacementTypes.left && vertical === PlacementTypes.top) {
      return css`
        bottom: calc(100% + 8px);
        left: 0;
      `;
    }

    if (horizontal === PlacementTypes.right && vertical === PlacementTypes.top) {
      return css`
        bottom: calc(100% + 8px);
        left: 0;
      `;
    }

    if (horizontal === PlacementTypes.left && vertical === PlacementTypes.bottom) {
      return css`
        top: calc(100% + 8px);
        left: 0;
      `;
    }

    if (horizontal === PlacementTypes.right && vertical === PlacementTypes.bottom) {
      return css`
        top: calc(100% + 8px);
        right: 0;
      `;
    }
  }}
`;
