import { ActionType, getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { ResourceWithUUID } from 'models/Resource';

import * as resourcesWizardActions from './actions';

type ResourcesWizardActionType = ActionType<typeof resourcesWizardActions>;

export type ResourcesWizardState = {
  resources: ResourceWithUUID[];
};

const initialState: ResourcesWizardState = {
  resources: [],
};

const resourcesWizardReducer = (
  state: ResourcesWizardState = initialState,
  action: ResourcesWizardActionType
): ResourcesWizardState => {
  switch (action.type) {
    case getType(resourcesWizardActions.addResource): {
      const uuid = uuidv4();
      const newResource: ResourceWithUUID = {
        data: {},
        isValidated: undefined,
        name: '',
        type: action.payload.type,
        uuid,
      };

      return {
        ...state,
        resources: state.resources.concat(newResource),
      };
    }

    case getType(resourcesWizardActions.changeResource):
      return {
        ...state,
        resources: state.resources.map((r) => {
          if (r.uuid === action.payload.uuid) {
            return {
              ...r,
              ...action.payload.data,
            };
          }

          return r;
        }),
      };

    case getType(resourcesWizardActions.removeResource):
      return {
        ...state,
        resources: state.resources.filter((r) => r.uuid !== action.payload.uuid),
      };

    case getType(resourcesWizardActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default resourcesWizardReducer;
