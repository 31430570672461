import React, { FC, useMemo } from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';

import { routes } from 'routes';

import {
  BreadCrumbLink,
  BreadCrumbText,
  BreadCrumbTextActive,
  BreadCrumbTextInactive,
  BreadCrumbsDelimiter,
  Root,
} from './styles';
import { BreadCrumb, Props } from './types';

const BreadCrumbs: FC<Props> = ({ className }) => {
  const location = useLocation();
  const locationParams = useParams();
  const pathNameOrigin = decodeURI(location.pathname);

  const breadCrumbsList: BreadCrumb[] = useMemo(() => {
    let result: BreadCrumb[] = [];
    const currentRoute = Object.values(routes).find((route) => {
      try {
        return generatePath(route.path, locationParams) === pathNameOrigin;
      } catch (e) {
        return undefined;
      }
    });

    if (currentRoute && currentRoute.breadCrumbs.length !== 0) {
      result = currentRoute.breadCrumbs.map((key) => {
        const breadCrumbRoute = routes[key];

        return {
          path: generatePath(breadCrumbRoute.path, locationParams),
          view: breadCrumbRoute.breadCrumbComponent,
        };
      });
    }

    return result;
  }, [pathNameOrigin, locationParams]);

  return (
    <Root className={className}>
      {breadCrumbsList.map((breadCrumb, i) => {
        return (
          <BreadCrumbText key={breadCrumb.path}>
            {i !== 0 && <BreadCrumbsDelimiter>/</BreadCrumbsDelimiter>}
            {i === breadCrumbsList.length - 1 ? (
              <BreadCrumbTextActive>{breadCrumb.view}</BreadCrumbTextActive>
            ) : (
              <BreadCrumbLink to={breadCrumb.path}>
                <BreadCrumbTextInactive>{breadCrumb.view}</BreadCrumbTextInactive>
              </BreadCrumbLink>
            )}
          </BreadCrumbText>
        );
      })}
    </Root>
  );
};

export type { Props };

export default BreadCrumbs;
