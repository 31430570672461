import React, { FC, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { routes } from 'routes';
import {
  fetchMapperTypes,
  fetchResourceTypes,
  fetchSchemas,
  fetchServiceTypes,
  fetchSpheres,
  fetchStepTypes,
  fetchTriggerTypes,
} from 'store/app/actions';
import { selectSpheres } from 'store/app/selectors';
import { ThemeModes } from 'styles/enums';
import { isDefAndNotNull } from 'utils/def';

import BasePageLayout from '../BasePageLayout';
import Loader from '../Loader';
import ToastCloseButton from '../ToastCloseButton';

import { LoaderContainer } from './styles';

const NotFoundPage = React.lazy(() => import('pages/notFound'));

const App: FC = () => {
  const dispatch = useDispatch();
  const spheres = useSelector(selectSpheres);
  const { list: spheresList } = spheres;
  const [firstSphere] = spheresList;

  useEffect(() => {
    dispatch(fetchSpheres.request());
    dispatch(fetchResourceTypes.request());
    dispatch(fetchServiceTypes.request());
    dispatch(fetchTriggerTypes.request());
    dispatch(fetchStepTypes.request());
    dispatch(fetchSchemas.request());
    dispatch(fetchMapperTypes.request());
  }, [dispatch]);

  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <Loader center />
        </LoaderContainer>
      }
    >
      <ToastContainer
        hideProgressBar
        position="bottom-right"
        theme={ThemeModes.dark}
        closeButton={({ closeToast }) => <ToastCloseButton closeToast={closeToast} />}
      />
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={isDefAndNotNull(firstSphere?.name) ? `/spheres/${firstSphere.name}` : '/start'} />}
        />
        {Object.entries(routes).map(([routeName, settings]) => (
          <Route
            key={routeName}
            path={settings.path}
            element={<BasePageLayout settings={settings}>{settings.component}</BasePageLayout>}
          />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default App;
