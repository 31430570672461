import { toast } from 'react-toastify';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import { slideClose } from 'components/FlowNavMenu/store/actions';

import API, { apiCallHandler } from 'api';
import { getServiceIdentifier } from 'models/Service/utils';
import { SERVICE_MODAL_ID } from 'pages/components/ServiceCard/constants';
import {
  CREATE_SERVICE_MODAL_ID,
  DELETE_SERVICE_CONFIRM_MODAL_ID,
  SERVICES_MODAL_ID,
} from 'pages/components/ServicesList/constants';
import { fetchFlow } from 'pages/flow/store/actions';
import { modalClose } from 'store/modal/actions';
import { isDefAndNotNull } from 'utils/def';

import { callServiceAction, createService, deleteService, fetchServices, updateService } from './actions';

function* onFetchProjectServices({ payload: { projectName, sphereName } }: ReturnType<typeof fetchServices.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchServices.failure());
    },
    errMessageFallback: 'Failed to fetch services',
    tryHandler: function* () {
      const {
        data: {
          data: { services },
        },
      } = yield* call(API.services.fetchServices, { project: projectName, sphere: sphereName });
      yield put(fetchServices.success({ list: services }));
    },
  });
}

function* onCreateProjectService({ payload: { data, onSuccess } }: ReturnType<typeof createService.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createService.failure());
    },
    errMessageFallback: 'Failed to create service',
    tryHandler: function* () {
      yield* call(API.services.createService, data);
      yield onFetchProjectServices({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchServices.request),
      });

      yield put(createService.success());
      yield put(modalClose.request({ id: CREATE_SERVICE_MODAL_ID }));
      yield call(toast.success, `The Service '${data.name}' was successfully created`);

      if (onSuccess) {
        onSuccess(data.name);
      }
    },
  });
}

function* onUpdateProjectService({ payload: { bp, data, flow } }: ReturnType<typeof updateService.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateService.failure());
    },
    errMessageFallback: 'Failed to update service',
    tryHandler: function* () {
      yield* call(API.services.updateService, data);
      yield onFetchProjectServices({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchServices.request),
      });

      yield put(updateService.success());
      const modalId = `${SERVICE_MODAL_ID}_${getServiceIdentifier({ name: data.oldName, type: data.type })}`;
      yield put(modalClose.request({ id: modalId }));

      if (isDefAndNotNull(bp) && isDefAndNotNull(flow)) {
        yield put(
          fetchFlow.request({
            bp: bp,
            name: flow,
            project: data.project,
            sphere: data.sphere,
          })
        );
      }

      yield call(toast.success, `The Service '${data.oldName}' was successfully updated`);
    },
  });
}

function* onDeleteProjectService({ payload: { data } }: ReturnType<typeof deleteService.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteService.failure());
    },
    errMessageFallback: 'Failed to delete service',
    tryHandler: function* () {
      yield* call(API.services.deleteService, data);
      yield onFetchProjectServices({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchServices.request),
      });

      yield put(deleteService.success());
      const modalId = `${SERVICE_MODAL_ID}_${getServiceIdentifier(data)}`;
      const deleteModalId = `${DELETE_SERVICE_CONFIRM_MODAL_ID}_${getServiceIdentifier(data)}`;
      yield put(modalClose.request({ id: modalId }));
      yield put(modalClose.request({ id: deleteModalId }));
      yield put(slideClose.request({ id: SERVICES_MODAL_ID }));
      yield call(toast.success, `The Service '${data.name}' was successfully deleted`);
    },
  });
}

function* onCallProjectServiceAction({ payload: { data } }: ReturnType<typeof callServiceAction.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(callServiceAction.failure());
    },
    errMessageFallback: 'Failed to call service action',
    tryHandler: function* () {
      yield* call(API.services.callServiceAction, data);
      yield onFetchProjectServices({
        payload: { projectName: data.project, sphereName: data.sphere },
        type: getType(fetchServices.request),
      });

      yield put(callServiceAction.success());
      yield put(modalClose.request({ id: CREATE_SERVICE_MODAL_ID }));
      yield call(toast.success, `The Service Action '${data.name}' was successfully completed`);
    },
  });
}

function* projectServicesSaga() {
  yield all([
    takeEvery(fetchServices.request, onFetchProjectServices),
    takeEvery(createService.request, onCreateProjectService),
    takeEvery(updateService.request, onUpdateProjectService),
    takeEvery(deleteService.request, onDeleteProjectService),
    takeEvery(callServiceAction.request, onCallProjectServiceAction),
  ]);
}

export default projectServicesSaga;
