import styled, { css } from 'styled-components';

import { textStyle } from 'styles/mixins';

export const Root = styled.div<{ bordered?: boolean }>`
  ${({ bordered }) =>
    bordered &&
    css`
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.divider};
    `}
`;
export const TableRoot = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;
export const Head = styled.thead``;
export const HeadRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.primaryLight};
`;
export const Body = styled.tbody``;
export const Row = styled.tr<{ isSelected?: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.secondaryBackground};
    `}

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
`;
export const Cell = styled.td<{ editable?: boolean }>`
  ${textStyle({ color: 'textSecondary', lineHeight: 1.5, size: '13px', textAlign: 'left', weight: 400 })};
  padding: 16px 16px 16px 16px;
  position: relative;
  vertical-align: top;
  word-break: break-word;
  ${({ editable }) =>
    editable &&
    css`
      padding: 4px;
    `}

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
export const HeadCell = styled.th`
  ${textStyle({ color: 'textSecondary', lineHeight: 1.2, size: '12px', textAlign: 'left', weight: 500 })};
  padding: 16px 16px 16px 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
