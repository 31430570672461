import { createGlobalStyle } from 'styled-components';

import { textStyle } from '../../mixins';

export const Toast = createGlobalStyle`
  :root {
    --toastify-toast-min-height: 48px;
    --toastify-toast-bd-radius: 4px;

    .Toastify__toast-body > div:last-child {
      ${textStyle({ size: '14px', textAlign: 'left' })}
    }
  }
`;

export default Toast;
