import { ActionType, getType } from 'typesafe-actions';

import { getClientID } from 'utils/clientID';

import * as authActions from './actions';

type AuthActionType = ActionType<typeof authActions>;

export type AuthState = {
  clientID: string;
  wsLoading: boolean;
};

const initialState: AuthState = {
  clientID: getClientID(),
  wsLoading: true,
};

const authReducer = (state: AuthState = initialState, action: AuthActionType): AuthState => {
  switch (action.type) {
    case getType(authActions.setWSLoading):
      return {
        ...state,
        wsLoading: action.payload.loading,
      };

    default:
      return state;
  }
};

export default authReducer;
