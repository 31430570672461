import { ActionType, getType } from 'typesafe-actions';

import * as devTestFormActions from './actions';

type DevTestFormActionType = ActionType<typeof devTestFormActions>;

export type DevTestFormState = {
  postLoading: boolean;
};

const initialState: DevTestFormState = {
  postLoading: false,
};

const devTestFormReducer = (state: DevTestFormState = initialState, action: DevTestFormActionType): DevTestFormState => {
  switch (action.type) {
    case getType(devTestFormActions.postDev.request):
      return {
        ...state,
        postLoading: true,
      };
    case getType(devTestFormActions.postDev.success):
    case getType(devTestFormActions.postDev.failure):
      return {
        ...state,
        postLoading: false,
      };

    default:
      return state;
  }
};

export default devTestFormReducer;
