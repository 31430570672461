import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OptionProps } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { FormFieldSelect, Option } from 'jsonSchema';
import { Resource } from 'models/Resource';
import { ResourceType } from 'models/ResourceType';
import CreateResourceForm from 'pages/components/CreateResourceForm';
import { CREATE_RESOURCE_MODAL_ID } from 'pages/components/ProjectResourcesList/constant';
import { selectResourceTypes } from 'store/app/selectors';
import { modalClose, modalOpen } from 'store/modal/actions';
import { createResource } from 'store/projectResources/actions';
import { selectProjectResources } from 'store/projectResources/selectors';
import { isDefAndNotNull } from 'utils/def';
import { getNameListFiltered } from 'utils/getters';

import { FormFieldsType } from '../../FormFieldRenderer/types';
import Modal, { Modes } from '../../Modal';

import { ResourceOptionValueContainer, ResourceOptionValueIcon } from '../styles';
import { SingleSelectOptionComponent, SingleSelectOptions, UseGetOptionsReturn } from '../types';

export const useGetResourcesOptions = (field: FormFieldSelect): UseGetOptionsReturn => {
  const { projectName, sphereName } = useParams();
  const dispatch = useDispatch();
  const { setValue, trigger } = useFormContext<FormFieldsType>();
  const { list: resourceTypes } = useSelector(selectResourceTypes);
  const { createLoading, list: resources } = useSelector(selectProjectResources);
  const options: SingleSelectOptions = resources.map((r) => {
    const resourceType = resourceTypes.find((rt) => rt.type === r.type);

    return {
      icon: resourceType?.icon,
      label: r.name,
      type: resourceType?.type,
      value: r.name,
    };
  });
  const filterOption = useCallback(
    ({ data }: FilterOptionOption<Option>) => {
      const subTypeProps = field.subTypeProps?.resourcesSelect;
      const isAddNewOption = 'addNewOption' in data && data.addNewOption;

      if (!isAddNewOption && isDefAndNotNull(subTypeProps) && subTypeProps.typeFilter) {
        return data.type === subTypeProps.typeFilter;
      }

      return true;
    },
    [field.subTypeProps?.resourcesSelect]
  );
  const resourceTypeFilterOption = useCallback(
    (option: ResourceType) => {
      const subTypeProps = field.subTypeProps?.resourcesSelect;

      if (isDefAndNotNull(subTypeProps) && subTypeProps.typeFilter) {
        return option.type === subTypeProps.typeFilter;
      }

      return true;
    },
    [field.subTypeProps?.resourcesSelect]
  );
  const Option: SingleSelectOptionComponent = (props: OptionProps<Option, false>) => {
    const { data } = props;
    return (
      <ResourceOptionValueContainer>
        <ResourceOptionValueIcon model={data.icon} />
        {data.label}
      </ResourceOptionValueContainer>
    );
  };
  const modalId = useMemo(() => `${field.name}_${CREATE_RESOURCE_MODAL_ID}`, [field.name]);
  const handleOpenCreateResourceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: modalId, mode: Modes.rightSlide }));
  }, [dispatch, modalId]);
  const handleCloseCreateResourceModal = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);
  const handleCreateResource = useCallback(
    (resource: Resource) => {
      if (isDefAndNotNull(sphereName) && isDefAndNotNull(projectName)) {
        dispatch(
          createResource.request({
            data: {
              ...resource,
              project: projectName,
              sphere: sphereName,
            },
            onSuccess: (newResourceName) => {
              setValue(field.name, { label: newResourceName, value: newResourceName }, { shouldDirty: true });
              trigger(field.name);
              handleCloseCreateResourceModal();
            },
          })
        );
      }
    },
    [sphereName, projectName, dispatch, setValue, field.name, trigger, handleCloseCreateResourceModal]
  );
  const getSelectedResourceNameList = useCallback((name: string) => getNameListFiltered<Resource>(resources, name), [resources]);

  const createResourceModal = useMemo(
    () => (
      <Modal
        id={modalId}
        renderTitle={() => 'Add Resource'}
        renderChildren={() => (
          <CreateResourceForm
            onSubmit={handleCreateResource}
            onCancel={handleCloseCreateResourceModal}
            existingResourcesNames={getSelectedResourceNameList('')}
            createLoading={createLoading}
            filterOptions={resourceTypeFilterOption}
          />
        )}
      />
    ),
    [
      createLoading,
      getSelectedResourceNameList,
      handleCloseCreateResourceModal,
      handleCreateResource,
      modalId,
      resourceTypeFilterOption,
    ]
  );

  return useMemo(
    () => ({
      OptionComponent: Option,
      filterOption,
      onAdd: handleOpenCreateResourceModal,
      onAddComponent: createResourceModal,
      options,
    }),
    [createResourceModal, filterOption, handleOpenCreateResourceModal, options]
  );
};
