import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import FlowTabImg from 'assets/images/flowTab.png';
import { buildDataCyValue } from 'utils/cy';

import { Modes } from './enums';
import { Root, RootButton, StyledBackground } from './styles';
import { Props } from './types';

const FlowTab: FC<Props> = ({ children, className, end = false, mode = Modes.white, onClick, to }) => {
  const tabContent = (
    <>
      <StyledBackground src={FlowTabImg} />
      {children}
    </>
  );

  if (to) {
    return (
      <Root
        as={NavLink}
        className={className}
        to={encodeURI(to)}
        end={end}
        mode={mode}
        data-cy={buildDataCyValue({ elementName: 'flow', fieldName: 'initiative' })}
      >
        {tabContent}
      </Root>
    );
  }

  return (
    <RootButton className={className} mode={mode} onClick={onClick}>
      {tabContent}
    </RootButton>
  );
};

export { Modes };
export type { Props };

export default memo(FlowTab);
