import { darken as polishedDarken, lighten as polishedLighten, rgba as polishedRgba } from 'polished';
import { css } from 'styled-components';

import { ThemeColors } from './types';

type TextStyle = {
  color?: ThemeColors;
  lineHeight?: number;
  size?: string;
  textAlign?: string;
  weight?: number;
};

type FlexStyle = {
  align?: string;
  direction?: string;
  justify?: string;
  wrap?: string;
};

type GridStyle = {
  columnGap?: number;
  columns?: number;
  rowGap?: number;
  rows?: number;
};

type IconStyle = {
  color?: string;
};

export const textStyle = ({
  color = 'darkestGrey',
  lineHeight,
  size = '14px',
  textAlign = 'center',
  weight = 400,
}: TextStyle) => css`
  line-height: ${lineHeight};
  font-size: ${size};
  color: ${(props) => props.theme.colors[color]};
  font-weight: ${weight};
  text-align: ${textAlign};
`;

export const flexStyle = ({ align = 'stretch', direction = 'row', justify = 'flex-start', wrap = 'wrap' }: FlexStyle) => css`
  display: flex;
  align-items: ${align};
  justify-content: ${justify};
  flex-direction: ${direction};
  flex-wrap: ${wrap};
`;

export const gridStyle = ({ columnGap = 0, columns = 1, rowGap = 0, rows = 1 }: GridStyle) => css`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  grid-template-rows: repeat(${rows}, 1fr);
  grid-column-gap: ${columnGap}px;
  grid-row-gap: ${rowGap}px;
`;

export const iconStyle = ({ color }: IconStyle) => css`
  svg {
    fill: ${color};

    g {
      fill: ${color};
    }
  }

  i {
    color: ${color};
  }
`;

export const scrollStyle = () => css`
  @-moz-document url-prefix() {
    scrollbar-color: ${(props) => `${props.theme.colors.divider} ${props.theme.colors.background}`};
    scrollbar-width: thin;
  }
  &::-webkit-scrollbar {
    height: 30px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    border: 2px solid ${(props) => props.theme.colors.background};
    background-color: ${(props) => props.theme.colors.divider};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.grey};
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

export const lighten = (color: string, percentage: number) => polishedLighten(percentage / 100, color);
export const darken = (color: string, percentage: number) => polishedDarken(percentage / 100, color);
export const rgba = (color: string, percentage: number) => polishedRgba(color, percentage / 100);
