import { createAction } from 'typesafe-actions';

import { Resource, ResourceWithUUID } from 'models/Resource';

export type AddResourcePayload = {
  type: Resource['type'];
};
export const addResource = createAction('resourcesWizard/addResource')<AddResourcePayload>();

export type ChangeResourcePayload = {
  data: Resource;
  uuid: ResourceWithUUID['uuid'];
};
export const changeResource = createAction('resourcesWizard/changeResource')<ChangeResourcePayload>();

export type RemoveResourcePayload = {
  uuid: ResourceWithUUID['uuid'];
};
export const removeResource = createAction('resourcesWizard/removeResource')<RemoveResourcePayload>();

export const reset = createAction('resourcesWizard/reset')();
