import React, { FC, createElement, useCallback } from 'react';

import { isDef } from 'utils/def';

import TreeNode from './components/TreeNode';
import { Root } from './styles';
import { Props, TreeItem } from './types';
import { getParentForNode } from './utils';

const Tree: FC<Props> = ({ className, nodeTypes, nodes }) => {
  const getParentNode = useCallback((node: TreeItem | undefined) => getParentForNode(node, nodes), [nodes]);
  const renderNode = useCallback(
    (node: TreeItem) => {
      if (isDef(nodeTypes[node.type])) {
        return createElement(nodeTypes[node.type], { getParentNode, node });
      }
    },
    [nodeTypes, getParentNode]
  );

  return (
    <Root className={className}>
      {nodes.map((node) => (
        <TreeNode level={0} key={node.index} node={node} renderNode={renderNode} />
      ))}
    </Root>
  );
};

export default Tree;
