import React, { FC, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormFieldSelectValueType, FormFieldUnion, Option, extractValue, getValidationRulesFromAField } from 'jsonSchema';
import { isDefAndNotNull } from 'utils/def';

import FormField from '../FormField';
import FormFieldRenderer from '../FormFieldRenderer';
import { FormFieldsType } from '../FormFieldRenderer/types';

import { useGetSelectOptions } from './hooks/useGetSelectOptions';
import { StyledSelect } from './styles';
import { Props, SingleSelectComponents } from './types';

const FormSelectRenderer: FC<Props> = ({ field, onlySelf }) => {
  const { control } = useFormContext<FormFieldsType>();
  const { OptionComponent, filterOption, onAdd, onAddComponent, optionFields, options } = useGetSelectOptions(field);
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);
  const reactSelectComponents = useMemo(() => {
    const result: SingleSelectComponents = {};

    if (isDefAndNotNull(OptionComponent)) {
      result.Option = OptionComponent;
    }

    return result;
  }, [OptionComponent]);

  const selectFieldValue = useWatch({ name: field.name });

  const dependantFields: FormFieldUnion[] = useMemo(() => {
    return optionFields.find((f) => f.value === extractValue(selectFieldValue))?.dependantFields || [];
  }, [selectFieldValue, optionFields]);

  return (
    <>
      <FormField
        {...validationRules}
        controllerProps={{
          control,
          name: field.name,
        }}
        defaultValue={field.defaultValue}
        render={(props) => (
          <StyledSelect<Option, false>
            {...props}
            components={reactSelectComponents}
            value={props.value as FormFieldSelectValueType}
            label={field.label}
            readonly={field.readonly}
            disabled={field.disabled}
            onChange={props.onChange}
            options={options}
            getOptionLabel={field.getOptionLabel}
            getOptionValue={field.getOptionValue}
            isSearchable={false}
            placeholder={field.placeholder}
            onAdd={onAdd}
            filterOption={filterOption}
          />
        )}
      />
      {!onlySelf && dependantFields.map((childField) => <FormFieldRenderer key={childField.name} field={childField} />)}
      {onAddComponent}
    </>
  );
};

export default FormSelectRenderer;
