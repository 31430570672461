import { createAction, createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

export type FetchInitiativeRequestPayload = {
  data: { initiative: Initiative['name']; project: Project['name']; sphere: Sphere['name'] };
};
export type FetchInitiativeSuccessPayload = {
  businessProcess: Initiative;
};
export const fetchInitiative = createAsyncAction(
  'initiative/fetchInitiative/request',
  'initiative/fetchInitiative/success',
  'initiative/fetchInitiative/failure'
)<FetchInitiativeRequestPayload, FetchInitiativeSuccessPayload, undefined>();

export type DeleteInitiativeRequestPayload = {
  initiative: Initiative['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const deleteInitiative = createAsyncAction(
  'initiative/deleteInitiative/request',
  'initiative/deleteInitiative/success',
  'initiative/deleteInitiative/failure'
)<DeleteInitiativeRequestPayload, undefined, undefined>();

export type UpdateInitiativeRequestPayload = {
  data: {
    data: Initiative & { oldName: Initiative['name'] };
    flow?: Flow['name'];
    project: Project['name'];
    sphere: Sphere['name'];
  };
};
export const updateInitiative = createAsyncAction(
  'initiative/updateInitiative/request',
  'initiative/updateInitiative/success',
  'initiative/updateInitiative/failure'
)<UpdateInitiativeRequestPayload, undefined, undefined>();

export const reset = createAction('initiative/reset')();
