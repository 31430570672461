import React, { FC } from 'react';

const InfoSquareRounded: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M10 2.5C16 2.5 17.5 4 17.5 10C17.5 16 16 17.5 10 17.5C4 17.5 2.5 16 2.5 10C2.5 4 4 2.5 10 2.5Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoSquareRounded;
