import { createSelector } from 'reselect';

import { DataMapperType } from 'models/DataMapperNode/enums';
import { Sphere } from 'models/Sphere';
import { SphereProject } from 'models/SphereProject';
import { VariableType } from 'models/Variable';
import { isDefAndNotNull } from 'utils/def';

import { RootState } from '../types';

import { AppState } from './reducer';

const selectAppState = (state: RootState) => state.app;

export const selectThemeMode = createSelector(selectAppState, (appState: AppState) => appState.themeMode);
export const selectSchemas = createSelector(selectAppState, (appState: AppState) => appState.schemas);
export const selectResourceTypes = createSelector(selectAppState, (appState: AppState) => appState.resourceTypes);
export const selectTriggerTypes = createSelector(selectAppState, (appState: AppState) => appState.triggerTypes);
export const selectStepTypes = createSelector(selectAppState, (appState: AppState) => appState.stepTypes);
export const selectSpheres = createSelector(selectAppState, (appState: AppState) => appState.spheres);
export const selectMapperTypes = createSelector(selectAppState, (appState: AppState) => appState.mapperTypes);
export const selectSphereSchema = createSelector(
  selectSchemas,
  (schemas: AppState['schemas']) => schemas.list.find((s) => s.schemaType === 'sphere')?.schema
);
export const selectServiceTypes = createSelector(selectAppState, (appState: AppState) => appState.serviceTypes);
export const selectSphereByName = (name: Sphere['name'] | undefined) =>
  createSelector(selectAppState, (appState: AppState) =>
    isDefAndNotNull(name) ? appState.spheres.list.find((sphere) => sphere.name === name) : undefined
  );
export const selectProjectInSphereByName = (
  sphereName: Sphere['name'] | undefined,
  projectName: SphereProject['name'] | undefined
) =>
  createSelector(selectSphereByName(sphereName), (sphere) => {
    if (sphere && sphere.projects) {
      return sphere.projects.find((p) => p === projectName);
    }

    return undefined;
  });
export const selectProjectSchema = createSelector(
  selectSchemas,
  (schemas: AppState['schemas']) => schemas.list.find((i) => i.schemaType === 'project')?.schema
);
export const selectInitiativeSchema = createSelector(
  selectSchemas,
  (schemas: AppState['schemas']) => schemas.list.find((i) => i.schemaType === 'business process')?.schema
);
export const selectTagSchema = createSelector(
  selectSchemas,
  (schemas: AppState['schemas']) => schemas.list.find((i) => i.schemaType === 'tag')?.schema
);
export const selectVariableSchemaByType = (variableType: VariableType) =>
  createSelector(selectSchemas, (schemas: AppState['schemas']) => {
    switch (variableType) {
      case VariableType.Global: {
        return schemas.list.find((s) => s.schemaType === 'globalVar')?.schema;
      }
      case VariableType.Context: {
        return schemas.list.find((s) => s.schemaType === 'contextVar')?.schema;
      }
      case VariableType.Initiative: {
        return schemas.list.find((s) => s.schemaType === 'bpVar')?.schema;
      }
      case VariableType.Flow: {
        return schemas.list.find((s) => s.schemaType === 'flowVar')?.schema;
      }
    }
  });
export const selectMapperSchemaByType = (type: string, dataMapperType: DataMapperType) =>
  createSelector(selectAppState, (appState: AppState) => {
    const schemasList =
      dataMapperType === DataMapperType.source ? appState.mapperTypes.list.source : appState.mapperTypes.list.destination;
    return schemasList.find((mapperType) => mapperType.type === type)!;
  });
