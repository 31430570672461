import React, { FC } from 'react';

import useDeepCallback from 'hooks/useDeepCallback';
import { buildDataCyValue } from 'utils/cy';

import { ICONS_TYPES } from '../Icon';

import { Colors } from './enums';
import {
  AttentionIcon,
  CheckIcon,
  ColorInnerBox,
  ColorOuterBox,
  ColorsContainer,
  ErrorMessage,
  ErrorMessageContainer,
  Label,
  RequiredMark,
  Root,
} from './styles';
import { Props } from './types';

const ColorPicker: FC<Props> = ({ className, disabled, error, label, name, onChange, required, touched, value }) => {
  const handleChangeColor = useDeepCallback((value: Colors) => onChange(value));

  return (
    <Root className={className}>
      {label && (
        <Label htmlFor={name}>
          {required && !disabled ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <ColorsContainer>
        {Object.values(Colors).map((color: Colors) => {
          const selected = color === value;

          return (
            <ColorOuterBox
              onClick={handleChangeColor(color)}
              key={color}
              $color={color}
              $selected={selected}
              data-cy={buildDataCyValue({ elementName: 'box', fieldName: color })}
            >
              {selected ? <CheckIcon icon={ICONS_TYPES.CheckMark} size={24} /> : <ColorInnerBox $color={color} />}
            </ColorOuterBox>
          );
        })}
      </ColorsContainer>
      {error && touched && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export { Colors };
export type { Props };

export default ColorPicker;
