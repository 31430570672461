import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import API, { apiCallHandler } from 'api';
import { onFetchSpheres } from 'store/app/saga';
import { modalClose } from 'store/modal/actions';

import { CREATE_INITIATIVE_MODAL_ID, DELETE_PROJECT_CONFIRM_MODAL_ID, UPDATE_PROJECT_MODAL_ID } from '../constants';

import { createInitiative, deleteProject, fetchInitiatives, fetchProject, fetchProjectTree, updateProject } from './actions';

function* onFetchProject({ payload: { data } }: ReturnType<typeof fetchProject.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchProject.failure());
    },
    errMessageFallback: 'Failed to fetch project',
    tryHandler: function* () {
      const {
        data: {
          data: { project },
        },
      } = yield* call(API.projects.fetchProject, data);

      yield put(fetchProject.success({ project }));
    },
  });
}

export function* onFetchProjectTree({ payload }: ReturnType<typeof fetchProjectTree.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchProjectTree.failure());
    },
    errMessageFallback: 'Failed to fetch project tree',
    tryHandler: function* () {
      const {
        data: {
          data: { project },
        },
      } = yield* call(API.projects.fetchProjectTree, payload);

      yield put(fetchProjectTree.success({ project }));
    },
  });
}

function* onUpdateProject({ payload: { data } }: ReturnType<typeof updateProject.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateProject.failure());
    },
    errMessageFallback: 'Failed to update project',
    tryHandler: function* () {
      yield* call(API.projects.updateProject, data);
      yield onFetchProject({
        payload: { data: { projectName: data.data.name, sphereName: data.sphere } },
        type: getType(fetchProject.request),
      });

      yield put(updateProject.success());
      yield put(modalClose.request({ id: UPDATE_PROJECT_MODAL_ID }));
      yield put(push(`/spheres/${data.sphere}/projects/${data.data.name}`));
      yield call(toast.success, `The Project '${data.data.name}' was successfully updated`);
    },
  });
}

function* onDeleteProject({ payload }: ReturnType<typeof deleteProject.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteProject.failure());
    },
    errMessageFallback: 'Failed to delete project',
    tryHandler: function* () {
      yield* call(API.projects.deleteProject, payload);
      yield onFetchSpheres();

      yield put(deleteProject.success());
      yield put(modalClose.request({ id: DELETE_PROJECT_CONFIRM_MODAL_ID }));
      yield put(push(`/spheres/${payload.sphere}`));
      yield call(toast.success, `The Project '${payload.project}' was successfully deleted`);
    },
  });
}

export function* onFetchInitiatives({ payload: { data } }: ReturnType<typeof fetchInitiatives.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchInitiatives.failure());
    },
    errMessageFallback: 'Failed to fetch initiatives',
    tryHandler: function* () {
      const {
        data: {
          data: { businessProcesses },
        },
      } = yield* call(API.businessProcesses.fetchInitiatives, data);

      yield put(fetchInitiatives.success({ businessProcesses }));
    },
  });
}

function* onCreateInitiative({ payload: { data } }: ReturnType<typeof createInitiative.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createInitiative.failure());
    },
    errMessageFallback: 'Failed to create initiative',
    tryHandler: function* () {
      yield* call(API.businessProcesses.createInitiative, data);
      yield onFetchInitiatives({
        payload: { data: { project: data.project, sphere: data.sphere } },
        type: getType(fetchInitiatives.request),
      });

      yield put(createInitiative.success());
      yield put(modalClose.request({ id: CREATE_INITIATIVE_MODAL_ID }));
      yield call(toast.success, `The Initiative '${data.data.name}' was successfully created`);
    },
  });
}

function* projectSaga() {
  yield all([
    takeEvery(fetchProject.request, onFetchProject),
    takeEvery(fetchProjectTree.request, onFetchProjectTree),
    takeEvery(updateProject.request, onUpdateProject),
    takeEvery(deleteProject.request, onDeleteProject),
    takeEvery(createInitiative.request, onCreateInitiative),
    takeEvery(fetchInitiatives.request, onFetchInitiatives),
  ]);
}

export default projectSaga;
