import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ICONS_TYPES } from 'components/Icon';
import Modal, { Sizes } from 'components/Modal';
import { TreeNodeProps } from 'components/Tree/types';

import { modalClose, modalOpen } from 'store/modal/actions';

import FlowVariableList from './components/FlowVariableList';
import { FLOW_VARIABLES_MODAL_ID } from './constants';
import { FolderIcon, FolderIconContainer, Root, TitleContainer } from './styles';

const FlowVariablesNode: FC<TreeNodeProps> = ({ className, getParentNode, node }) => {
  const dispatch = useDispatch();
  const flowNode = useMemo(() => getParentNode(node), [getParentNode, node]);
  const initiativeNode = useMemo(() => getParentNode(flowNode), [getParentNode, flowNode]);
  const modalId = useMemo(
    () => `${FLOW_VARIABLES_MODAL_ID}_${initiativeNode?.name}_${flowNode?.name}`,
    [initiativeNode, flowNode]
  );
  const handleClick = useCallback(() => {
    dispatch(modalOpen({ id: modalId }));
  }, [modalId, dispatch]);
  const handleCancel = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);

  return (
    <>
      <Root className={className} onClick={handleClick}>
        <FolderIconContainer>
          <FolderIcon icon={ICONS_TYPES.Files} size={14} />
        </FolderIconContainer>
        <TitleContainer>{node.name}</TitleContainer>
      </Root>
      <Modal
        id={modalId}
        size={Sizes.medium}
        renderTitle={() => `${flowNode?.name} variables`}
        renderChildren={() => (
          <FlowVariableList initiativeName={initiativeNode?.name || ''} flowName={flowNode?.name || ''} onCancel={handleCancel} />
        )}
      />
    </>
  );
};

export default FlowVariablesNode;
