import { ArraySchema, FormFieldSubTypes, Schema } from 'jsonSchema';
import { isDef } from 'utils/def';

export const buildVariableSchema = (schema: Schema | undefined): ArraySchema | undefined => {
  return isDef(schema)
    ? {
        behaviourHints: {
          subType: FormFieldSubTypes.array,
          subTypeProps: {
            [FormFieldSubTypes.array]: {
              columns: [
                {
                  name: 'name',
                  searchable: true,
                },
                {
                  name: 'description',
                  searchable: true,
                },
                {
                  name: 'type',
                  searchable: true,
                },
                {
                  name: 'length',
                },
                {
                  name: 'defaultValue',
                },
              ],
              entityName: 'variable',
            },
          },
        },
        items: schema,
        title: 'variables',
        type: 'array',
      }
    : undefined;
};
