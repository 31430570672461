export enum FormFieldTypes {
  array = 'ARRAY',
  checkBox = 'CHECKBOX',
  date = 'DATE',
  file = 'FILE',
  input = 'INPUT',
  option = 'OPTION',
  select = 'SELECT',
  time = 'TIME',
}
