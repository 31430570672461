import styled from 'styled-components';

import { flexStyle, rgba } from 'styles/mixins';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

import { Props } from './types';

export const Root = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'row', wrap: 'nowrap' })};
`;
export const NavMenu = styled.div<Props>`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  padding: 8px 8px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const ModalContainer = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  position: relative;
  height: 100%;
`;
export const ButtonTooltip = styled(Tooltip)`
  padding: 8px 0;
`;
export const StyledMenuItem = styled.div<{ $active?: boolean }>`
  background-color: ${({ $active, theme }) => ($active ? theme.colors.primary : rgba(theme.colors.primaryLight, 6))};
  color: ${({ $active, theme }) => ($active ? theme.colors.background : theme.colors.primary)};
  border-radius: 4px;
  padding: 10px;
  &:hover {
    background-color: ${({ $active, theme }) => ($active ? theme.colors.primary : rgba(theme.colors.primaryLight, 10))};
  }
`;
export const MenuItemIcon = styled(Icon)``;
export const EmptyContainer = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  padding: 15px;
`;
