import styled from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'space-between', wrap: 'nowrap' })};
  background-color: ${({ theme }) => theme.colors.surfacePrimary};
  padding: 40px 0 40px 0;
`;
export const StyledImg = styled.img`
  width: 200px;
  margin-bottom: 24px;
`;
export const Title = styled.span`
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '24px', textAlign: 'left', weight: 500 })};
  margin-bottom: 24px;
`;
export const Description = styled.span`
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '12x', textAlign: 'left', weight: 400 })};
  margin-bottom: 24px;
`;
