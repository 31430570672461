import styled, { css } from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import Icon from '../Icon';

import { CheckboxSizes } from './enums';
import { Props } from './types';

export const Root = styled.div``;
export const Container = styled.label`
  ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const InputWrapper = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  cursor: pointer;
  outline: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;
export const StyledInput = styled.input<{ showError: boolean; size: CheckboxSizes }>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  width: ${({ size }) => (size === CheckboxSizes.small ? '20px' : size === CheckboxSizes.medium ? '24px' : '30px')};
  height: ${({ size }) => (size === CheckboxSizes.small ? '20px' : size === CheckboxSizes.medium ? '24px' : '30px')};

  border-color: ${({ checked, disabled, showError, theme }) =>
    (showError && theme.colors.red) ||
    (disabled && theme.colors.surfaceSecondary) ||
    (!checked && theme.colors.grey) ||
    (checked && theme.colors.textPrimary)};
  background-color: ${({ checked, disabled, theme }) =>
    (disabled && theme.colors.surfaceSecondary) || (checked && theme.colors.textPrimary)};

  &::before {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    content: ' ';
    width: ${({ size }) => (size === CheckboxSizes.small ? '16px' : size === CheckboxSizes.medium ? '20px' : '26px')};
    height: ${({ size }) => (size === CheckboxSizes.small ? '16px' : size === CheckboxSizes.medium ? '20px' : '26px')};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M5 12L10 17L20 7' stroke='%23fff' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
  }
`;
export const CheckBox = styled.div<Partial<Props> & { checked: boolean; showError: boolean }>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
`;
export const Label = styled.span`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
  cursor: inherit;
  grid-gap: 8px;
`;
export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;
