import styled from 'styled-components';

import Input from 'components/Input';
import PaginatedContainer from 'components/PaginatedContainer';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between' })};
  width: 100%;
  padding: 16px;
`;
export const StepTypesContainer = styled(PaginatedContainer)`
  width: 100%;
` as typeof PaginatedContainer;
export const StyledSearch = styled(Input)`
  margin-bottom: 24px;
  width: 100%;
`;
export const NoMatch = styled.div`
  ${textStyle({ size: '12px', weight: 400 })};
  color: ${({ theme }) => theme.colors.textTertiary};
`;
