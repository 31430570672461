import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICONS_TYPES } from 'components/Icon';
import Modal, { Modes } from 'components/Modal';
import { PLACEMENT_TYPES } from 'components/Tooltip';

import { getResourceIdentifier } from 'models/Resource/utils';
import { selectResourceTypes } from 'store/app/selectors';
import { modalClose, modalOpen } from 'store/modal/actions';
import { buildDataCyValue } from 'utils/cy';
import { isDefAndNotNull } from 'utils/def';

import ResourceForm from '../ResourceForm';

import { DELETE_RESOURCE_CONFIRM_MODAL_ID, RESOURCE_MODAL_ID } from './constants';
import { ResourceValidationStatusType, ViewModes } from './enums';
import {
  AlertCircleIcon,
  EditIcon,
  RemoveIcon,
  RemoveIconTooltip,
  ResourceContent,
  ResourceIcon,
  ResourceName,
  RootCreate,
  RootEdit,
} from './styles';
import { Props } from './types';

const ResourceCard: FC<Props> = ({
  className,
  deleteLoading,
  model,
  onChange,
  onRemove,
  onValidate,
  resourceNameListToValidate,
  updateLoading,
  validateLoading,
  viewMode,
}) => {
  const dispatch = useDispatch();
  const { list: resourceTypes } = useSelector(selectResourceTypes);
  const modelIdentifier = useMemo(() => getResourceIdentifier(model), [model]);
  const modalId = useMemo(() => `${RESOURCE_MODAL_ID}_${modelIdentifier}`, [modelIdentifier]);
  const deleteModalId = useMemo(() => `${DELETE_RESOURCE_CONFIRM_MODAL_ID}_${modelIdentifier}`, [modelIdentifier]);
  const resourceType = useMemo(() => resourceTypes.find((resource) => resource.type === model.type), [model, resourceTypes])!;

  const handleOpenResourceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: modalId, mode: Modes.rightSlide }));
  }, [dispatch, modalId]);
  const handleCloseResourceModal = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);
  const handleOpenDeleteResourceConfirmModal = useCallback(() => {
    dispatch(modalOpen({ id: deleteModalId }));
  }, [deleteModalId, dispatch]);
  const handleRemove = useCallback(() => {
    if (viewMode === ViewModes.create) {
      handleCloseResourceModal();
    }
    onRemove(model);
  }, [handleCloseResourceModal, model, onRemove, viewMode]);

  return (
    <>
      {viewMode === ViewModes.create ? (
        <RootCreate className={className} onClick={handleOpenResourceModal}>
          <ResourceIcon model={resourceType.icon} />
          <ResourceName>{model.name || model.type}</ResourceName>
        </RootCreate>
      ) : (
        <RootEdit className={className} data-cy={buildDataCyValue({ elementName: 'card', fieldName: 'resource' })}>
          <ResourceContent>
            <AlertCircleIcon
              icon={ICONS_TYPES.AlertCircle}
              size={16}
              status={
                model.isValidated || !resourceType.hasValidation
                  ? ResourceValidationStatusType.Done
                  : !isDefAndNotNull(model.isValidated)
                    ? ResourceValidationStatusType.Required
                    : ResourceValidationStatusType.Failed
              }
            />
            <ResourceIcon model={resourceType.icon} />
            <ResourceName>{model.name}</ResourceName>
          </ResourceContent>
          <EditIcon icon={ICONS_TYPES.Pencil} size={24} onClick={handleOpenResourceModal} />
        </RootEdit>
      )}
      <Modal
        id={modalId}
        renderTitle={() => 'Define resource'}
        renderActions={() => (
          <RemoveIconTooltip
            placementType={PLACEMENT_TYPES.bottom}
            content={viewMode === ViewModes.create ? 'Remove from the list' : 'Delete'}
          >
            <RemoveIcon icon={ICONS_TYPES.TrashBin} size={20} onClick={handleOpenDeleteResourceConfirmModal} />
          </RemoveIconTooltip>
        )}
        renderChildren={() => (
          <ResourceForm
            model={model}
            resourceNameListToValidate={resourceNameListToValidate}
            type={resourceType}
            onSubmit={onChange}
            onCancel={handleCloseResourceModal}
            onValidate={onValidate}
            cancelOnSubmit={viewMode === ViewModes.create}
            updateLoading={updateLoading}
            validateLoading={validateLoading}
          />
        )}
      />
      <Modal
        id={deleteModalId}
        confirm
        renderTitle={() => `Delete Resource`}
        renderChildren={() => (
          <>
            Are you sure you want to delete <i>{model.name}</i>?
          </>
        )}
        confirmButtonProps={() => ({ label: 'Delete', loading: deleteLoading, onClick: handleRemove })}
      />
    </>
  );
};

export { ViewModes };

export default memo(ResourceCard);
