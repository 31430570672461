import React, { FC } from 'react';

const Blob: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M9.16667 8.33366V4.16699H8.33333M15 15.8337V11.667H14.1667M5 8.33366H5.00833M5 15.8337H5.00833M12.5 4.58366C12.5 4.47315 12.5439 4.36717 12.622 4.28903C12.7002 4.21089 12.8062 4.16699 12.9167 4.16699H14.5833C14.6938 4.16699 14.7998 4.21089 14.878 4.28903C14.9561 4.36717 15 4.47315 15 4.58366V7.91699C15 8.0275 14.9561 8.13348 14.878 8.21162C14.7998 8.28976 14.6938 8.33366 14.5833 8.33366H12.9167C12.8062 8.33366 12.7002 8.28976 12.622 8.21162C12.5439 8.13348 12.5 8.0275 12.5 7.91699V4.58366ZM8.33333 12.0837C8.33333 11.9732 8.37723 11.8672 8.45537 11.789C8.53351 11.7109 8.63949 11.667 8.75 11.667H10.4167C10.5272 11.667 10.6332 11.7109 10.7113 11.789C10.7894 11.8672 10.8333 11.9732 10.8333 12.0837V15.417C10.8333 15.5275 10.7894 15.6335 10.7113 15.7116C10.6332 15.7898 10.5272 15.8337 10.4167 15.8337H8.75C8.63949 15.8337 8.53351 15.7898 8.45537 15.7116C8.37723 15.6335 8.33333 15.5275 8.33333 15.417V12.0837Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Blob;
