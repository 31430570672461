import React, { FC } from 'react';

const Pennant: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M7.33398 19.25H11.0007M9.16732 19.25V2.75M9.16732 3.66667L17.4173 7.33333L9.16732 11"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pennant;
