import { ActionType, getType } from 'typesafe-actions';

import * as tabsActions from './actions';

type TabsActionType = ActionType<typeof tabsActions>;

export type TabsState = {
  [id: string]:
    | {
        activeTab: tabsActions.SetActiveTabPayload['key'];
      }
    | undefined;
};

const initialState: TabsState = {};

const tabsReducer = (state: TabsState = initialState, action: TabsActionType): TabsState => {
  switch (action.type) {
    case getType(tabsActions.setActiveTab):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          activeTab: action.payload.key,
        },
      };

    default:
      return state;
  }
};

export default tabsReducer;
