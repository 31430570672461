import { useDraggable } from '@dnd-kit/core';
import React, { FC, useEffect } from 'react';

import { Root } from './styles';
import { Props } from './types';

const DraggableItem: FC<Props> = ({ children, className, data, id, setIsDraggable, type }) => {
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    data: {
      item: data,
      type,
    },
    id,
  });
  useEffect(() => {
    setIsDraggable(isDragging, id);
  }, [setIsDraggable, id, isDragging]);

  return (
    <Root className={className} ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </Root>
  );
};

export default DraggableItem;
