import React, { FC } from 'react';

const Stack: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M3.66602 11.0003L10.9993 14.667L18.3327 11.0003M3.66602 14.667L10.9993 18.3337L18.3327 14.667M10.9993 3.66699L3.66602 7.33366L10.9993 11.0003L18.3327 7.33366L10.9993 3.66699Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Stack;
