import { ActionType, getType } from 'typesafe-actions';

import { CLOSING_CLASSNAME, Modes } from 'components/Modal';

import * as modalActions from './actions';

type ModalActionType = ActionType<typeof modalActions>;

export type ModalState = {
  [id: string]:
    | {
        animated?: boolean;
        closingClass?: string;
        data?: modalActions.ModalOpenPayload['data'];
        mode?: Modes;
        open?: boolean;
      }
    | undefined;
};

const initialState: ModalState = {};

const modalReducer = (state: ModalState = initialState, action: ModalActionType): ModalState => {
  switch (action.type) {
    case getType(modalActions.modalOpen):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          animated: action.payload.animated,
          closingClass: '',
          data: action.payload.data,
          mode: action.payload.mode || Modes.default,
          open: true,
        },
      };

    case getType(modalActions.setClosingClass):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          closingClass: CLOSING_CLASSNAME,
        },
      };
    case getType(modalActions.resetClosingClass):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          closingClass: '',
        },
      };

    case getType(modalActions.modalClose.success):
    case getType(modalActions.modalClose.failure):
      return {
        ...state,
        [action.payload.id]: undefined,
      };

    case getType(modalActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default modalReducer;
