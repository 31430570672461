import styled from 'styled-components';

import { flexStyle, scrollStyle, textStyle } from 'styles/mixins';

import Icon from '../../../Icon';

import { CLOSING_CLASSNAME, CLOSING_TIMEOUT_MS } from './constants';
import { Props } from './types';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  overflow-y: auto;
  overflow-x: hidden;
  left: 0;
  top: 0;
  z-index: 99;
  position: absolute;
  height: 100%;
`;
export const Slide = styled.div<Partial<Props>>`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};

  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 0;
  width: 296px;
  height: 100%;
  position: relative;
  padding: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};

  @keyframes openSlide {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes closeSlide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation: openSlide ${CLOSING_TIMEOUT_MS}ms ease-in;

  &.${CLOSING_CLASSNAME} {
    animation: closeSlide ${CLOSING_TIMEOUT_MS}ms ease-out;
  }
`;
export const Content = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  ${scrollStyle()};
  overflow-y: auto;
  height: 100%;
  position: relative;
`;
export const Header = styled.div`
  ${flexStyle({ align: 'center', justify: 'space-between', wrap: 'nowrap' })};

  height: 64px;
  padding: 21px;
  border-bottom: 1px solid gray;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const Title = styled.h1`
  ${textStyle({ color: 'textPrimary', lineHeight: 1.2, size: '18px', textAlign: 'left', weight: 500 })};
  letter-spacing: 0.15px;
  max-width: calc(100% - 20px);
  word-wrap: break-word;
`;
export const ActionsContainer = styled.div`
  ${flexStyle({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  cursor: pointer;
`;
