import { CLIENT_ID_SS_KEY } from 'mainConstants';

export const getClientID = (): string => {
  let clientID = sessionStorage.getItem(CLIENT_ID_SS_KEY);

  if (!clientID) {
    clientID = Date.now().toString() + Math.random().toString();
    sessionStorage.setItem(CLIENT_ID_SS_KEY, clientID);
  }

  return clientID;
};
