import { LogTypes } from './enums';

export type Log = {
  data: string[];
  name: string;
  new: boolean;
  type: LogTypes;
};

export * from './enums';
