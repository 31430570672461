import { createAction } from 'typesafe-actions';

import { Log, LogTypes } from 'models/Log';

export type AddLogPayload = {
  log: Log;
};
export const addLog = createAction('logs/addLog')<AddLogPayload>();

export type MarkLogAsViewedPayload = {
  type: LogTypes;
};
export const markLogAsViewed = createAction('logs/markLogAsViewed')<MarkLogAsViewedPayload>();
