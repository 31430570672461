import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDeepCallback from 'hooks/useDeepCallback';
import { setActiveTab } from 'store/tabs/actions';
import { selectActiveTab } from 'store/tabs/selectors';

import { ActiveTabBar, Root, Tab, TabsContainer } from './styles';
import { Props } from './types';

const Tabs: FC<Props> = ({ className, defaultKey, id, items, onTabChange, showCount = true }) => {
  const isAllHidden = useMemo(() => items.every((item) => item.hide), [items]);
  const dispatch = useDispatch();
  const activeTabKey = useSelector(selectActiveTab(id));
  const activeTab = useMemo(() => items.find((item) => item.key === activeTabKey), [activeTabKey, items]);
  const defaultTabKey = useMemo(() => {
    return defaultKey || items[0].key;
  }, [items, defaultKey]);

  const handleSetActiveTab = useDeepCallback((key: string) => {
    dispatch(setActiveTab({ id, key }));
    onTabChange?.(key);
  });

  useEffect(() => {
    dispatch(setActiveTab({ id, key: defaultTabKey }));
  }, [defaultTabKey, dispatch, id]);

  if (isAllHidden) {
    return null;
  }

  return (
    <Root className={className}>
      <TabsContainer>
        {items.map((tabItem) => {
          const isActive = tabItem.key === activeTabKey;

          if (tabItem.hide) {
            return null;
          }

          return (
            <Tab key={tabItem.key} active={isActive} disabled={tabItem.disabled} onClick={handleSetActiveTab(tabItem.key)}>
              {tabItem.label}
              {showCount && tabItem.view.props.list.length ? ` (${tabItem.view.props.list.length})` : ''}
              {isActive && <ActiveTabBar />}
            </Tab>
          );
        })}
      </TabsContainer>
      {activeTab?.view}
    </Root>
  );
};

export * from './types';

export default Tabs;
