import React, { FC } from 'react';

import TableAnimation from 'assets/images/tableAnimation.svg';

import { Description, Root, StyledImg, Title } from './styles';
import { Props } from './types';

const EmptyData: FC<Props> = ({ children, className }) => {
  return (
    <Root className={className}>
      <StyledImg src={TableAnimation} alt="table-image" />
      <Title>No Data yet</Title>
      <Description>Your table data will appear here after being populated</Description>
      {children}
    </Root>
  );
};

export default EmptyData;
