import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFieldTimeValueType, FormFields, getValidationRulesFromAField } from 'jsonSchema';

import FormField from '../FormField';

import { StyledTimePicker } from './styles';
import { Props } from './types';

const FormTimeRenderer: FC<Props> = ({ field }) => {
  const { control } = useFormContext<FormFields>();
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);

  return (
    <FormField
      {...validationRules}
      controllerProps={{
        control,
        name: field.name,
      }}
      defaultValue={field.defaultValue}
      required={field.required}
      render={(props) => (
        <StyledTimePicker
          {...props}
          value={props.value as FormFieldTimeValueType}
          placeholder={field.placeholder}
          label={field.label}
        />
      )}
    />
  );
};

export default FormTimeRenderer;
