import { all, call, put, takeEvery } from 'typed-redux-saga';

import API, { apiCallHandler } from 'api';
import { isDefAndNotNull } from 'utils/def';

import { FetchRecentsSuccessPayload, fetchRecents } from './actions';

function* onFetchRecents(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchRecents.failure());
    },
    errMessageFallback: 'Failed to fetch Recents',
    tryHandler: function* () {
      //TO-DO: uncomment and update the code after Api implementation on server side
      /*const {
        data: {
          data: { recents },
        },
      } = yield* call(API.recents.fetchRecents);
      yield put(fetchRecents.success({ businessProcesses: recents.businessProcesses, projects: recents.projects, spheres: recents.spheres }));
      */

      //TO-DO: remove next code after Api implementation
      // 1) get all spheres for Demo mode
      const {
        data: {
          data: { spheres },
        },
      } = yield* call(API.spheres.fetchSpheres);
      const spheresList = Object.values(spheres).map((sphere) => ({
        ...sphere,
        color: isDefAndNotNull(sphere.color) && !sphere.color.startsWith('#') ? `#${sphere.color}` : sphere.color,
      }));
      const temporaryDemoData: FetchRecentsSuccessPayload = { businessProcesses: [], projects: [], spheres: spheresList };

      // 2) get all projects for Demo mode by sphereName
      const sphereName = Object.values(spheres)[0] && Object.values(spheres)[0].name;
      if (sphereName) {
        const {
          data: {
            data: { sphere },
          },
        } = yield* call(API.spheres.fetchSphere, sphereName);
        temporaryDemoData.projects = sphere.projects.map((project) => ({ ...project, sphere: sphereName }));
      }

      // 3) get all initiatives for Demo mode by projectName
      const projectName = temporaryDemoData.projects.filter((project) => project.bpCount > 0)[0]?.name;
      if (projectName) {
        const {
          data: {
            data: { businessProcesses },
          },
        } = yield* call(API.businessProcesses.fetchInitiatives, {
          project: projectName,
          sphere: sphereName,
        });
        temporaryDemoData.businessProcesses = businessProcesses
          ? businessProcesses.map((bp) => ({ ...bp, project: projectName, sphere: sphereName }))
          : [];
      }

      yield put(fetchRecents.success(temporaryDemoData));
    },
  });
}

function* recentsSaga() {
  yield all([takeEvery(fetchRecents.request, onFetchRecents)]);
}

export default recentsSaga;
