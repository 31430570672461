import { isObject } from 'lodash';

import { FormFieldFileValueType } from 'jsonSchema';

export const base64UrlToFile = (base64Url: string, fileName: string): File => {
  const arr = base64Url.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bStr = atob(arr[1]);
  let u8Index = bStr.length;
  const u8Array = new Uint8Array(u8Index);

  while (u8Index--) {
    u8Array[u8Index] = bStr.charCodeAt(u8Index);
  }

  return new File([u8Array], fileName, { type: mime });
};

export const requestDataToFormData = <T extends (unknown & { files?: FormFieldFileValueType[] }) | string>(
  data: {
    key?: string;
    value: T;
  },
  files?: FormFieldFileValueType[]
): FormData | Omit<T, 'files'> | string => {
  let dataValue;
  if (isObject(data.value)) {
    const { files, ...otherData } = data.value;
    dataValue = otherData;
  } else {
    dataValue = data.value;
  }
  let formData: FormData;
  if (files?.length) {
    formData = new FormData();
    files?.forEach((file) => {
      if (file?.file && file.id) {
        formData.append('files', file?.file, file.id);
      }
    });
    formData.append(data.key || 'data', isObject(dataValue) ? JSON.stringify(dataValue) : dataValue);
    return formData;
  }
  return dataValue;
};
