import { AnyOfSchema, FormFieldSubTypes, ObjectSchema, SchemaFieldArray, SchemaFieldString, extractValue } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { SchemaCustomBehaviourContext } from './types';
import { getProperty, removeProperty } from './utils';

const INVOKE_BY_EXPRESSION = 'invoke by expression';
const FLOW = 'flow';
const BP = 'bp';
const DESTINATION = 'destination variable';
const SOURCE = 'source variable';
const ARGUMENTS = 'arguments';

export const invokeFlowConfiguration = ({ bp, flow, newValues, prevValues, schema }: SchemaCustomBehaviourContext) => {
  if (extractValue(newValues[INVOKE_BY_EXPRESSION]) === false) {
    const optionSchema = (schema as AnyOfSchema).anyOf[0] as ObjectSchema;
    if (isDef(newValues[BP])) {
      const flow = getProperty<SchemaFieldString>(optionSchema, FLOW);
      const subTypeProps = flow.behaviourHints?.subTypeProps[FormFieldSubTypes.selectDatasource];
      if (isDef(subTypeProps) && isDef(subTypeProps.dependencies)) {
        subTypeProps.dependencies[BP] = { value: newValues[BP] };
      }
    }
    if (isDefAndNotNull(newValues[FLOW]) && isDefAndNotNull(newValues[BP])) {
      const argumentList = getProperty<SchemaFieldArray>(optionSchema, ARGUMENTS);
      const destination = getProperty<SchemaFieldString>(argumentList.items, DESTINATION);
      let subTypeProps = destination.behaviourHints?.subTypeProps[FormFieldSubTypes.selectDatasource];
      if (isDef(subTypeProps) && isDef(subTypeProps.dependencies)) {
        subTypeProps.dependencies[FLOW] = { value: newValues[FLOW] };
        subTypeProps.dependencies[BP] = { value: newValues[BP] };
      }
      const source = getProperty<SchemaFieldString>(argumentList.items, SOURCE);
      subTypeProps = source.behaviourHints?.subTypeProps[FormFieldSubTypes.selectDatasource];
      if (isDef(subTypeProps) && isDef(subTypeProps.dependencies)) {
        subTypeProps.dependencies[FLOW] = { value: flow };
        subTypeProps.dependencies[BP] = { value: bp };
      }
    }

    if (newValues[BP] !== prevValues[BP] || newValues[FLOW] !== prevValues[FLOW]) {
      newValues[ARGUMENTS] = null;
    }
    if (newValues[BP] !== prevValues[BP]) {
      newValues[FLOW] = null;
    }
    if (newValues[FLOW] !== prevValues[FLOW]) {
      newValues[ARGUMENTS] = null;
    }
    if (!isDefAndNotNull(newValues[BP])) {
      removeProperty(optionSchema, FLOW);
    }
    if (!isDefAndNotNull(newValues[FLOW]) || !isDefAndNotNull(newValues[BP])) {
      removeProperty(optionSchema, ARGUMENTS);
    }
  }
  return { updatedModel: { ...newValues }, updatedSchema: { ...schema } };
};
