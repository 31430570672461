import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OptionProps } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { FormFieldSelect, Option } from 'jsonSchema';
import { Service } from 'models/Service';
import { ServiceType } from 'models/ServiceType';
import CreateServiceForm from 'pages/components/CreateServiceForm';
import { CREATE_SERVICE_MODAL_ID } from 'pages/components/ServicesList/constants';
import { selectServiceTypes } from 'store/app/selectors';
import { modalClose, modalOpen } from 'store/modal/actions';
import { createService } from 'store/projectServices/actions';
import { selectProjectServices } from 'store/projectServices/selectors';
import { isDefAndNotNull } from 'utils/def';
import { getNameListFiltered } from 'utils/getters';

import { FormFieldsType } from '../../FormFieldRenderer/types';
import Modal, { Modes } from '../../Modal';

import { ServiceOptionValueContainer, ServiceOptionValueIcon } from '../styles';
import { SingleSelectOptionComponent, SingleSelectOptions, UseGetOptionsReturn } from '../types';

export const useGetServicesOptions = (field: FormFieldSelect): UseGetOptionsReturn => {
  const { projectName, sphereName } = useParams();
  const dispatch = useDispatch();
  const { setValue, trigger } = useFormContext<FormFieldsType>();
  const { list: serviceTypes } = useSelector(selectServiceTypes);
  const { createLoading, list: services } = useSelector(selectProjectServices);
  const options: SingleSelectOptions = services.map((r) => {
    const serviceType = serviceTypes.find((rt) => rt.type === r.type);

    return {
      icon: serviceType?.icon,
      label: r.name,
      type: serviceType?.type,
      value: r.name,
    };
  });
  const filterOption = useCallback(
    ({ data }: FilterOptionOption<Option>) => {
      const subTypeProps = field.subTypeProps?.servicesSelect;
      const isAddNewOption = 'addNewOption' in data && data.addNewOption;

      if (!isAddNewOption && isDefAndNotNull(subTypeProps) && subTypeProps.typeFilter) {
        return data.type === subTypeProps.typeFilter;
      }

      return true;
    },
    [field.subTypeProps?.servicesSelect]
  );
  const serviceTypeFilterOption = useCallback(
    (option: ServiceType) => {
      const subTypeProps = field.subTypeProps?.servicesSelect;

      if (isDefAndNotNull(subTypeProps) && subTypeProps.typeFilter) {
        return option.type === subTypeProps.typeFilter;
      }

      return true;
    },
    [field.subTypeProps?.servicesSelect]
  );
  const Option: SingleSelectOptionComponent = (props: OptionProps<Option, false>) => {
    const { data } = props;
    return (
      <ServiceOptionValueContainer>
        <ServiceOptionValueIcon model={data.icon} />
        {data.label}
      </ServiceOptionValueContainer>
    );
  };
  const modalId = useMemo(() => `${field.name}_${CREATE_SERVICE_MODAL_ID}`, [field.name]);
  const handleOpenCreateServiceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: modalId, mode: Modes.rightSlide }));
  }, [dispatch, modalId]);
  const handleCloseCreateServiceModal = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);
  const handleCreateService = useCallback(
    (service: Service) => {
      if (isDefAndNotNull(sphereName) && isDefAndNotNull(projectName)) {
        dispatch(
          createService.request({
            data: {
              ...service,
              project: projectName,
              sphere: sphereName,
            },
            onSuccess: (newServiceName) => {
              setValue(field.name, { label: newServiceName, value: newServiceName }, { shouldDirty: true });
              trigger(field.name);
              handleCloseCreateServiceModal();
            },
          })
        );
      }
    },
    [sphereName, projectName, dispatch, setValue, field.name, trigger, handleCloseCreateServiceModal]
  );
  const getSelectedServiceNameList = useCallback((name: string) => getNameListFiltered<Service>(services, name), [services]);

  const createServiceModal = useMemo(
    () => (
      <Modal
        id={modalId}
        renderTitle={() => 'Add Service'}
        renderChildren={() => (
          <CreateServiceForm
            onSubmit={handleCreateService}
            onCancel={handleCloseCreateServiceModal}
            existingServicesNames={getSelectedServiceNameList('')}
            createLoading={createLoading}
            filterOptions={serviceTypeFilterOption}
          />
        )}
      />
    ),
    [
      createLoading,
      getSelectedServiceNameList,
      handleCloseCreateServiceModal,
      handleCreateService,
      modalId,
      serviceTypeFilterOption,
    ]
  );

  return useMemo(
    () => ({
      OptionComponent: Option,
      filterOption,
      onAdd: handleOpenCreateServiceModal,
      onAddComponent: createServiceModal,
      options,
    }),
    [createServiceModal, filterOption, handleOpenCreateServiceModal, options]
  );
};
