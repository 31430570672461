import { combineReducers } from 'redux';

import componentsReducer from './components/componentsReducer';
import flowReducer from './flow/store/reducer';
import initiativeReducer from './initiative/store/reducer';
import projectReducer from './project/store/reducer';
import recentsReducer from './recents/store/reducer';
import sphereReducer from './sphere/store/reducer';
import sphereCreateReducer from './sphereCreate/store/reducer';

const pagesReducer = combineReducers({
  components: componentsReducer,
  flow: flowReducer,
  initiative: initiativeReducer,
  project: projectReducer,
  recents: recentsReducer,
  sphere: sphereReducer,
  sphereCreate: sphereCreateReducer,
});

export default pagesReducer;
