import { createAction, createAsyncAction } from 'typesafe-actions';

import { Modes } from 'components/Modal';

export type ModalOpenPayload = {
  animated?: boolean;
  data?: unknown;
  id: string;
  mode?: Modes;
};
export const modalOpen = createAction('modal/open')<ModalOpenPayload>();

export type ModalClosePayload = {
  animated?: boolean;
  callBack?: () => void;
  id: string;
};
export const modalClose = createAsyncAction('modal/close/request', 'modal/close/success', 'modal/close/failure')<
  ModalClosePayload,
  ModalClosePayload,
  ModalClosePayload
>();

export type SetClosingClassPayload = {
  id: string;
};
export const setClosingClass = createAction('modal/setClosingClass')<SetClosingClassPayload>();

export type ResetClosingClassPayload = {
  id: string;
};
export const resetClosingClass = createAction('modal/resetClosingClass')<ResetClosingClassPayload>();

export const reset = createAction('modal/reset')();
