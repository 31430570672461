import styled from 'styled-components';

import Button from '../Button';
import Input from '../Input';

export const StyledInput = styled(Input)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export const StyledButton = styled(Button)`
  margin-top: -8px;
  margin-bottom: -8px;
  margin-right: -6px;
`;
