import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import StyledApp from './components/StyledApp';
import reportWebVitals from './reportWebVitals';
import { createReduxHistory, store } from './store';

const renderApp = () => {
  const history = createReduxHistory(store);
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <Provider store={store}>
      <Router history={history}>
        <StyledApp />
      </Router>
    </Provider>
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
