import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { VariableType } from 'models/Variable';
import VariableList from 'pages/components/VariableList';
import { selectContextVariables, selectContextVariablesLoading } from 'store/variables/selectors';

import { Root } from './styles';
import { Props } from './types';

const ContextVariableList: FC<Props> = ({ className, onCancel }) => {
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const contextVariableLoading = useSelector(selectContextVariablesLoading);
  const contextVariables = useSelector(selectContextVariables(sphereName!, projectName!));

  return (
    <Root className={className}>
      <VariableList
        variableLoading={contextVariableLoading}
        variables={contextVariables}
        variableType={VariableType.Context}
        onCancel={onCancel}
        initiativeName={initiativeName!}
        flowName={flowName!}
      />
    </Root>
  );
};

export default ContextVariableList;
