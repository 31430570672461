import React, { FC } from 'react';

import { buildDataCyValue } from 'utils/cy';

import BreadCrumbs from '../BreadCrumbs';

import { Root } from './styles';
import { Props } from './types';

const Header: FC<Props> = ({ className, settings }) => {
  return (
    <Root className={className} data-cy={buildDataCyValue({ elementName: 'header', fieldName: 'page' })}>
      {settings.breadCrumbs.length !== 0 && <BreadCrumbs />}
      {settings.actions && settings.actions}
    </Root>
  );
};

export type { Props };

export default Header;
