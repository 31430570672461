import { useCallback, useMemo } from 'react';

import { FormField, FormFieldSubTypes, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from '../../../../FormArrayRenderer/hooks/useGetArraySubTypeProps';

export const useGetTableRowId = (field: FormField) => {
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const key = useMemo(() => subTypeProps?.dataSource?.key, [subTypeProps]);
  return useCallback(
    (row: FormFields) => {
      if (isDef(key)) {
        const property = row[key];
        if (isDefAndNotNull(property)) {
          return property.toString();
        }
      }
      return JSON.stringify(row);
    },
    [key]
  );
};
