import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { slideClose } from 'components/FlowNavMenu/store/actions';
import { ICONS_TYPES } from 'components/Icon';
import { TreeNodeProps } from 'components/Tree/types';

import { PROJECT_EXPLORER_MODAL_ID } from '../../constants';

import { FolderIcon, FolderIconContainer, Root, TitleContainer } from './styles';

const ProjectNode: FC<TreeNodeProps> = ({ className, node }) => {
  const { sphereName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    dispatch(slideClose.request({ id: PROJECT_EXPLORER_MODAL_ID }));
    navigate(`/spheres/${sphereName}/projects/${node.name}`);
  }, [node, sphereName, navigate, dispatch]);
  return (
    <Root className={className} onClick={handleClick}>
      <FolderIconContainer>
        <FolderIcon icon={ICONS_TYPES.Folder} size={14} />
      </FolderIconContainer>
      <TitleContainer>{node.name}</TitleContainer>
    </Root>
  );
};

export default ProjectNode;
