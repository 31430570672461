import { all, call, put, takeEvery } from 'typed-redux-saga';

import API, { apiCallHandler } from 'api';
import { fetchFlow } from 'pages/flow/store/actions';

import { modalClose } from '../../../store/modal/actions';

import { DEV_TEST_MODAL_ID } from '../../FlowNavMenu/constant';

import { postDev } from './actions';

function* oonPostDevRequest({ payload }: ReturnType<typeof postDev.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(postDev.failure());
    },
    errMessageFallback: 'Failed to post dev',
    tryHandler: function* () {
      yield* call(API.dev.postDev, payload);
      yield put(postDev.success());
      yield put(modalClose.request({ id: DEV_TEST_MODAL_ID }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* devTestFormSaga() {
  yield all([takeEvery(postDev.request, oonPostDevRequest)]);
}

export default devTestFormSaga;
