import styled, { css } from 'styled-components';

import { flexStyle, rgba, textStyle } from 'styles/mixins';

import Icon from '../Icon';
import Input from '../Input';

import { Props } from './types';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const DropContainer = styled.label<Pick<Props, 'disabled' | 'error'> & { isDragActive?: boolean }>`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'center', wrap: 'nowrap' })};
  padding: 24px 16px;
  border-radius: 6px;
  border: 1px dashed ${({ error, theme }) => (error ? theme.colors.red : theme.colors.grey)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 6)};
  }

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 6)};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    `}
`;
export const UploadIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;
`;

export const InputLabel = styled.label`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-bottom: 8px;
  letter-spacing: 0.5px;
`;
export const DropLabelTitle = styled.span`
  ${textStyle({ color: 'primary', lineHeight: 1.2, size: '12px', textAlign: 'center', weight: 500 })};
  letter-spacing: 1.25px;
  text-transform: capitalize;
  margin-bottom: 8px;
`;
export const DropLabelDescription = styled.span`
  ${textStyle({ color: 'textTertiary', lineHeight: 1.2, size: '12px', textAlign: 'center', weight: 400 })};
  letter-spacing: 0.5px;
`;
export const FileItemsContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const FileItemInput = styled(Input)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
export const FileIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-right: 8px;
`;
export const RemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;

export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
