import { omit } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFieldCheckBoxValueType, FormFields, getValidationRulesFromAField } from 'jsonSchema';

import FormField from '../FormField';

import { StyledCheckbox } from './styles';
import { Props } from './types';

const FormCheckboxRenderer: FC<Props> = ({ field }) => {
  const { control } = useFormContext<FormFields>();
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);

  return (
    <FormField
      // no need to required/validation because it defaults to false always
      {...omit(validationRules, 'required')}
      controllerProps={{
        control,
        name: field.name,
      }}
      defaultValue={field.defaultValue}
      render={(props) => (
        <StyledCheckbox
          {...props}
          value={!!props.value as FormFieldCheckBoxValueType}
          label={field.label}
          required={field.required}
        />
      )}
    />
  );
};

export default FormCheckboxRenderer;
