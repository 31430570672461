import { ActionType, getType } from 'typesafe-actions';

import { Sphere } from 'models/Sphere';

import * as sphereActions from './actions';

type SphereActionType = ActionType<typeof sphereActions>;

export type SphereState = {
  createProjectLoading: boolean;
  deleteSphereLoading: boolean;
  importProjectLoading: boolean;
  resources: {
    createLoading: boolean;
    deleteLoading: boolean;
    list: sphereActions.FetchResourcesSuccessPayload['list'];
    loading: boolean;
    updateLoading: boolean;
    validateLoading: boolean;
  };
  sphere: Sphere<true> | undefined;
  sphereLoading: boolean;
  tags: {
    createLoading: boolean;
    deleteLoading: boolean;
    list: sphereActions.FetchTagsSuccessPayload['list'];
    loading: boolean;
    updateLoading: boolean;
  };
  updateSphereLoading: boolean;
};

const initialState: SphereState = {
  createProjectLoading: false,
  deleteSphereLoading: false,
  importProjectLoading: false,
  resources: {
    createLoading: false,
    deleteLoading: false,
    list: [],
    loading: false,
    updateLoading: false,
    validateLoading: false,
  },
  sphere: undefined,
  sphereLoading: true,
  tags: {
    createLoading: false,
    deleteLoading: false,
    list: [],
    loading: false,
    updateLoading: false,
  },
  updateSphereLoading: false,
};

const sphereReducer = (state: SphereState = initialState, action: SphereActionType): SphereState => {
  switch (action.type) {
    case getType(sphereActions.importProject.request):
      return {
        ...state,
        importProjectLoading: true,
      };
    case getType(sphereActions.importProject.success):
    case getType(sphereActions.importProject.failure):
      return {
        ...state,
        importProjectLoading: false,
      };

    case getType(sphereActions.createProject.request):
      return {
        ...state,
        createProjectLoading: true,
      };
    case getType(sphereActions.createProject.success):
    case getType(sphereActions.createProject.failure):
      return {
        ...state,
        createProjectLoading: false,
      };

    case getType(sphereActions.updateSphere.request):
      return {
        ...state,
        updateSphereLoading: true,
      };
    case getType(sphereActions.updateSphere.success):
    case getType(sphereActions.updateSphere.failure):
      return {
        ...state,
        updateSphereLoading: false,
      };

    case getType(sphereActions.deleteSphere.request):
      return {
        ...state,
        deleteSphereLoading: true,
      };
    case getType(sphereActions.deleteSphere.success):
    case getType(sphereActions.deleteSphere.failure):
      return {
        ...state,
        deleteSphereLoading: false,
      };

    case getType(sphereActions.fetchSphere.request):
      return {
        ...state,
        sphereLoading: true,
      };
    case getType(sphereActions.fetchSphere.success):
      return {
        ...state,
        sphere: action.payload.sphere,
        sphereLoading: false,
      };
    case getType(sphereActions.fetchSphere.failure):
      return {
        ...state,
        sphereLoading: false,
      };

    case getType(sphereActions.fetchResources.request):
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: true,
        },
      };
    case getType(sphereActions.fetchResources.success):
      return {
        ...state,
        resources: {
          ...state.resources,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(sphereActions.fetchResources.failure):
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
        },
      };

    case getType(sphereActions.createResource.request):
      return {
        ...state,
        resources: {
          ...state.resources,
          createLoading: true,
        },
      };
    case getType(sphereActions.createResource.success):
    case getType(sphereActions.createResource.failure):
      return {
        ...state,
        resources: {
          ...state.resources,
          createLoading: false,
        },
      };

    case getType(sphereActions.validateResource.request):
      return {
        ...state,
        resources: {
          ...state.resources,
          validateLoading: true,
        },
      };
    case getType(sphereActions.validateResource.success):
    case getType(sphereActions.validateResource.failure):
      return {
        ...state,
        resources: {
          ...state.resources,
          validateLoading: false,
        },
      };

    case getType(sphereActions.updateResource.request):
      return {
        ...state,
        resources: {
          ...state.resources,
          updateLoading: true,
        },
      };
    case getType(sphereActions.updateResource.success):
    case getType(sphereActions.updateResource.failure):
      return {
        ...state,
        resources: {
          ...state.resources,
          updateLoading: false,
        },
      };

    case getType(sphereActions.deleteResource.request):
      return {
        ...state,
        resources: {
          ...state.resources,
          deleteLoading: true,
        },
      };
    case getType(sphereActions.deleteResource.success):
    case getType(sphereActions.deleteResource.failure):
      return {
        ...state,
        resources: {
          ...state.resources,
          deleteLoading: false,
        },
      };

    case getType(sphereActions.fetchTags.request):
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
        },
      };
    case getType(sphereActions.fetchTags.success):
      return {
        ...state,
        tags: {
          ...state.tags,
          list: action.payload.list,
          loading: false,
        },
      };
    case getType(sphereActions.fetchTags.failure):
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
        },
      };

    case getType(sphereActions.createTag.request):
      return {
        ...state,
        tags: {
          ...state.tags,
          createLoading: true,
        },
      };
    case getType(sphereActions.createTag.success):
    case getType(sphereActions.createTag.failure):
      return {
        ...state,
        tags: {
          ...state.tags,
          createLoading: false,
        },
      };

    case getType(sphereActions.updateTag.request):
      return {
        ...state,
        tags: {
          ...state.tags,
          updateLoading: true,
        },
      };
    case getType(sphereActions.updateTag.success):
    case getType(sphereActions.updateTag.failure):
      return {
        ...state,
        tags: {
          ...state.tags,
          updateLoading: false,
        },
      };

    case getType(sphereActions.deleteTag.request):
      return {
        ...state,
        tags: {
          ...state.tags,
          deleteLoading: true,
        },
      };
    case getType(sphereActions.deleteTag.success):
    case getType(sphereActions.deleteTag.failure):
      return {
        ...state,
        tags: {
          ...state.tags,
          deleteLoading: false,
        },
      };

    case getType(sphereActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default sphereReducer;
