import { isDef, isDefAndNotNull } from 'utils/def';

import { FormArrayDatasourceNames } from './constants';
import { FormFieldSubTypes, FormFieldTypes } from './enums';
import {
  AnyOfSchema,
  ArraySchema,
  FieldSchema,
  FormConfig,
  FormField,
  FormFieldArray,
  FormFieldCheckBox,
  FormFieldCheckBoxValueType,
  FormFieldDate,
  FormFieldDateValueType,
  FormFieldFile,
  FormFieldFileValueType,
  FormFieldInput,
  FormFieldInputValueType,
  FormFieldNumberValueType,
  FormFieldOption,
  FormFieldSelect,
  FormFieldTime,
  FormFieldTimeValueType,
  FormFieldUnion,
  FormFieldValue,
  FormFields,
  ObjectSchema,
  OneOfProperty,
  Option,
  PrimitiveProperty,
  Property,
  Schema,
  SchemaFieldArray,
  SchemaFieldBoolean,
  SchemaFieldDate,
  SchemaFieldEnum,
  SchemaFieldFile,
  SchemaFieldInteger,
  SchemaFieldNumber,
  SchemaFieldObject,
  SchemaFieldString,
  SchemaFieldTime,
  SelectDatasourceSubTypeProps,
} from './types';

export const isObjectSchema = (schema: Schema): schema is ObjectSchema => 'properties' in schema;
export const isArraySchema = (schema: Schema): schema is ArraySchema => 'items' in schema;
export const isAnyOfSchema = (schema: Schema): schema is AnyOfSchema => 'anyOf' in schema;
export const isSchemaFieldString = (fieldSchema: FieldSchema): fieldSchema is SchemaFieldString =>
  'type' in fieldSchema && fieldSchema.type === 'string';
export const isSchemaFieldInteger = (fieldSchema: FieldSchema): fieldSchema is SchemaFieldInteger =>
  'type' in fieldSchema && fieldSchema.type === 'integer';
export const isSchemaFieldNumber = (fieldSchema: FieldSchema): fieldSchema is SchemaFieldNumber =>
  'type' in fieldSchema && fieldSchema.type === 'number';

export const isOneOfProperty = (property: Property): property is OneOfProperty => 'oneOf' in property;
export const isBooleanProperty = (property: Property): property is SchemaFieldBoolean =>
  'type' in property && property.type === 'boolean';
export const isEnumProperty = (property: Property): property is SchemaFieldEnum => 'enum' in property;
export const isStringProperty = (property: Property | SchemaFieldObject): property is SchemaFieldString =>
  'type' in property && property.type === 'string';
export const isIntegerProperty = (property: Property | SchemaFieldObject): property is SchemaFieldInteger =>
  'type' in property && property.type === 'integer';
export const isNumberProperty = (property: Property | SchemaFieldObject): property is SchemaFieldNumber =>
  'type' in property && property.type === 'number';
export const isArrayProperty = (property: Property | SchemaFieldObject): property is SchemaFieldArray =>
  'type' in property && property.type === 'array';
export const isConstProperty = (property: Property): property is PrimitiveProperty => 'const' in property;
export const isFileProperty = (property: Property | SchemaFieldFile): property is SchemaFieldFile =>
  'behaviourHints' in property && property.behaviourHints?.subType === FormFieldSubTypes.fileInput;
export const isDateProperty = (property: Property): property is SchemaFieldDate =>
  'behaviourHints' in property && property.behaviourHints?.subType === FormFieldSubTypes.dateInput;
export const isTimeProperty = (property: Property): property is SchemaFieldTime =>
  'behaviourHints' in property && property.behaviourHints?.subType === FormFieldSubTypes.timeInput;

export const isFormFieldHasDependantFields = (formField: FormFieldUnion): formField is FormFieldSelect =>
  'dependantFields' in formField && !!formField.dependantFields?.length;
export const isFormFieldSelect = (formField: FormFieldUnion): formField is FormFieldSelect =>
  'type' in formField && formField.type === FormFieldTypes.select;
export const isFormFieldCheckbox = (formField: FormFieldUnion): formField is FormFieldCheckBox =>
  'type' in formField && formField.type === FormFieldTypes.checkBox;
export const isFormFieldInput = (formField: FormFieldUnion): formField is FormFieldInput =>
  'type' in formField && formField.type === FormFieldTypes.input;
export const isFormFieldArray = (formField: FormFieldUnion): formField is FormFieldArray =>
  'type' in formField && formField.type === FormFieldTypes.array;
export const isFormFieldFile = (formField: FormFieldUnion): formField is FormFieldFile =>
  'type' in formField && formField.type === FormFieldTypes.file;
export const isFormFormFieldDate = (formField: FormFieldUnion): formField is FormFieldDate =>
  'type' in formField && formField.type === FormFieldTypes.date;
export const isFormFormFieldTime = (formField: FormFieldUnion): formField is FormFieldTime =>
  'type' in formField && formField.type === FormFieldTypes.time;
export const isFormFormFieldOption = (formField: FormFieldUnion): formField is FormFieldOption =>
  'type' in formField && formField.type === FormFieldTypes.option;

export const isValueFile = (
  value: FormFields[] | FormFieldValue | FormFieldValue[] | undefined
): value is FormFieldFileValueType => isDefAndNotNull(value) && typeof value === 'object' && 'file' in value && 'id' in value;
export const isValueOption = (value: FormFieldValue | undefined): value is Option =>
  isDefAndNotNull(value) && typeof value === 'object' && 'value' in value;

export const getFieldSubTypeProps = <T>(field: FormField, subType: FormFieldSubTypes): T | undefined => {
  return isDef(field.subTypeProps) && isDef(field.subTypeProps[subType]) ? (field.subTypeProps[subType] as T) : undefined;
};
export const getFieldDatasourceName = (field: FormField): string | undefined => {
  const subTypeProps = getFieldSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const dataSource = subTypeProps?.dataSource?.name;
  if (isDef(dataSource)) {
    return FormArrayDatasourceNames[dataSource];
  }
  return undefined;
};

export const extractValue = (
  value: FormFieldValue | FormFieldValue[] | FormFields[] | undefined
):
  | FormFieldInputValueType
  | FormFieldNumberValueType
  | FormFieldCheckBoxValueType
  | FormFieldFileValueType
  | FormFieldDateValueType
  | FormFieldTimeValueType
  | Option['value']
  | FormFields
  | FormFields[]
  | FormFieldValue[]
  | null =>
  Array.isArray(value) ? value : isValueOption(value) ? value.value : typeof value === 'string' ? value.trim() : value;

export const flattenFormConfig = (formConfig: FormConfig): FormConfig => {
  const result: FormConfig = [];

  const recurse = (field: FormField) => {
    if (isFormFormFieldOption(field)) {
      field.dependantFields?.forEach(recurse);
    } else {
      result.push(field);

      if (isFormFieldSelect(field)) {
        field.dependantFields?.forEach(recurse);
      }
    }
  };

  formConfig.forEach(recurse);

  return result;
};
