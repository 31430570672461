import { FormArrayDatasourceTypes, FormExpressionInputTypes } from './enums';

export const FormArrayDatasourceNames = {
  [FormArrayDatasourceTypes.selectVariable]: 'Select Variable',
  [FormArrayDatasourceTypes.selectFlow]: 'Select Flow',
  [FormArrayDatasourceTypes.selectInitiative]: 'Select Initiative',
  [FormArrayDatasourceTypes.selectPaths]: 'Select Path',
  [FormArrayDatasourceTypes.selectStore]: 'Select Data',
};

export const FormExpressionInputNames = {
  [FormExpressionInputTypes.expression]: 'Expression',
  [FormExpressionInputTypes.sql]: 'SQL',
};
