import React, { FC } from 'react';

const Upload: FC = () => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M9.33523 23.9996C7.6716 23.9996 6.0761 23.3675 4.89974 22.2422C3.72337 21.117 3.0625 19.5909 3.0625 17.9996C3.0625 16.4083 3.72337 14.8822 4.89974 13.757C6.0761 12.6318 7.6716 11.9996 9.33523 11.9996C9.72814 10.2492 10.8776 8.71092 12.5307 7.72323C13.3492 7.23417 14.2668 6.89501 15.2309 6.72509C16.1951 6.55518 17.187 6.55785 18.15 6.73294C19.1131 6.90804 20.0283 7.25213 20.8436 7.74558C21.6589 8.23903 22.3582 8.87217 22.9016 9.60885C23.445 10.3455 23.8218 11.1713 24.0106 12.0391C24.1994 12.9068 24.1964 13.7996 24.0019 14.6663H25.3352C26.5729 14.6663 27.7599 15.1579 28.6351 16.0331C29.5102 16.9083 30.0019 18.0953 30.0019 19.3329C30.0019 20.5706 29.5102 21.7576 28.6351 22.6328C27.7599 23.5079 26.5729 23.9996 25.3352 23.9996H24.0019M12.0019 19.9996L16.0019 15.9996M16.0019 15.9996L20.0019 19.9996M16.0019 15.9996V27.9996"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Upload;
