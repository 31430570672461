import { ActionType, getType } from 'typesafe-actions';

import * as expressionFormActions from './actions';
import { reset, validateExpression } from './actions';

type ExpressionFormActionType = ActionType<typeof expressionFormActions>;

export type ExpressionFormState = {
  validateLoading: boolean;
  validated: boolean;
};

const initialState: ExpressionFormState = {
  validateLoading: false,
  validated: false,
};

const expressionFormReducer = (
  state: ExpressionFormState = initialState,
  action: ExpressionFormActionType
): ExpressionFormState => {
  switch (action.type) {
    case getType(validateExpression.request): {
      return {
        ...state,
        validateLoading: true,
      };
    }
    case getType(validateExpression.failure): {
      return {
        ...state,
        validateLoading: false,
        validated: false,
      };
    }
    case getType(validateExpression.success): {
      return {
        ...state,
        validateLoading: false,
        validated: action.payload.validated,
      };
    }

    case getType(reset): {
      return initialState;
    }

    default:
      return state;
  }
};

export default expressionFormReducer;
