import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { upperFirst } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useDebouncedState } from 'hooks/useDebouncedState';
import { FormFieldSubTypes, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef } from 'utils/def';

import Button, { StyleTypes } from '../../../Button';
import Checkbox from '../../../Checkbox';
import { CheckboxSizes } from '../../../Checkbox/enums';
import { useGetSubTypeProps } from '../../../FormArrayRenderer/hooks/useGetArraySubTypeProps';
import Loader from '../../../Loader';
import Table from '../../../Table';

import { useFieldSubTypeDependencyValues } from '../../hooks/useFieldSubTypeDependencyValues';

import { useGetTableRowId } from './hooks/useGetTableRowId';
import { useGetTableSelection } from './hooks/useGetTableSelection';
import { fetchFormArrayDataSource, reset } from './store/actions';
import { selectFormArrayDataSource, selectFormArrayDataSourceLoading } from './store/selectors';
import { ButtonsContainer, Root, SubmitButton, TableContainer } from './styles';
import { Props } from './types';

const FormSourceArrayTable: FC<Props> = ({ field, onCancel, onSelect }) => {
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<FormFields>();
    const result = [];
    result.push(
      columnHelper.display({
        cell: ({ row }) => (
          <Checkbox
            name=""
            size={CheckboxSizes.small}
            value={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        header: ({ table }) =>
          subTypeProps?.dataSource?.multiple ? (
            <Checkbox
              size={CheckboxSizes.small}
              name=""
              value={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ) : undefined,
        id: 'select-col',
        size: 60,
      })
    );

    result.push(
      ...(subTypeProps?.dataSource?.columns || []).map((name: string) => {
        return columnHelper.display({
          cell: ({ row }) => {
            return <>{row.original[name]}</>;
          },
          header: () => upperFirst(name),
          id: name,
          size: undefined,
        });
      })
    );

    return result;
  }, [subTypeProps]);
  const { rowSelection, setRowSelection } = useGetTableSelection(field);
  const formArrayDataSource = useSelector(selectFormArrayDataSource);
  const isLoading = useSelector(selectFormArrayDataSourceLoading);
  const isLoadingDebounced = useDebouncedState(isLoading, false);
  const data = useMemo(() => formArrayDataSource || [], [formArrayDataSource]);
  const getRowId = useGetTableRowId(field);
  const dispatch = useDispatch();
  const contextTable = useReactTable<FormFields>({
    columns,
    data,
    enableMultiRowSelection: subTypeProps?.dataSource?.multiple === true,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });
  const handleSelect = useCallback(() => {
    const { rows } = contextTable.getSelectedRowModel();
    onSelect(rows.map((r) => r.original));
  }, [contextTable, onSelect]);
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);
  const isDisabled = useMemo(() => Object.keys(rowSelection).length === 0, [rowSelection]);
  const deps = useFieldSubTypeDependencyValues(field);
  useEffect(() => {
    if (isDef(subTypeProps) && isDef(subTypeProps.dataSource)) {
      dispatch(
        fetchFormArrayDataSource.request({
          bp: initiativeName,
          dataSourceName: subTypeProps.dataSource.name,
          dataSourceStoreKey: subTypeProps.dataSource.storeKey,
          flow: flowName,
          project: projectName!,
          sphere: sphereName!,
          ...deps,
        })
      );
    }
  }, [dispatch, sphereName, projectName, flowName, initiativeName, subTypeProps, deps]);
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Root>
      {isLoadingDebounced && <Loader />}
      {!isLoadingDebounced && !isLoading && (
        <>
          <TableContainer>
            <Table data={contextTable} bordered editable={false} />
          </TableContainer>
          <ButtonsContainer>
            <SubmitButton onClick={handleSelect} disabled={isDisabled} label="Select" />
            <Button label="Cancel" onClick={handleCancel} styleType={StyleTypes.link} />
          </ButtonsContainer>
        </>
      )}
    </Root>
  );
};

export default FormSourceArrayTable;
