import { AxiosResponse } from 'axios';

import { MapperTypes } from 'models/MapperType';

import { Methods } from '../enums';
import { RequestOptions, request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'mapper-types';

export const fetchMapperTypes = (
  params?: RequestOptions['params']
): Promise<AxiosResponse<APIResponse<{ mapTypes: MapperTypes }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });
