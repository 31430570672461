import { cloneDeep, debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormFields, Schema } from 'jsonSchema';

import { flowDataBehaviour } from './flowDataBehaviour';
import { invokeFlowArguments } from './invokeFlowArguments';
import { invokeFlowConfiguration } from './invokeFlowConfiguration';
import { SchemaCustomBehaviourContext, SchemaCustomBehaviourResultType } from './types';

const xpiComponentsMapping: {
  [key: string]: (context: SchemaCustomBehaviourContext) => SchemaCustomBehaviourResultType;
} = {
  'A flow data item': flowDataBehaviour,
  'Invoke Flow utility configurations': invokeFlowConfiguration,
  'Invoke flow arguments': invokeFlowArguments,
};

export const useSchemaCustomBehaviour = (initialSchema: Schema, formValues: FormFields) => {
  const [result, setResult] = useState<{ model: FormFields; schema: Schema }>({ model: formValues, schema: initialSchema });
  const [initialized, setInitialized] = useState(false);
  const lastModel = useRef<FormFields>(result.model);
  const { flowName, initiativeName, projectName, sphereName } = useParams();
  const handleChange = useCallback(
    (newValues: FormFields, prevValues: FormFields) => {
      if (xpiComponentsMapping[initialSchema.title]) {
        const clonedSchema = cloneDeep(initialSchema);
        const clonedValue = cloneDeep(newValues);
        const { updatedModel, updatedSchema } = xpiComponentsMapping[clonedSchema.title]({
          bp: initiativeName!,
          flow: flowName!,
          newValues: clonedValue,
          prevValues,
          project: projectName!,
          schema: clonedSchema,
          sphere: sphereName!,
        });
        lastModel.current = updatedModel;
        setResult({ model: updatedModel, schema: updatedSchema });
      } else {
        lastModel.current = newValues;
        setResult({ model: newValues, schema: initialSchema });
      }
    },
    [initialSchema, flowName, initiativeName, projectName, sphereName]
  );
  const handleChangeDebounced = useRef(
    debounce((newValues: FormFields, prevValues: FormFields) => handleChange(newValues, prevValues), 100)
  );

  useEffect(() => {
    if (!isEqual(lastModel.current, formValues) || !initialized) {
      setInitialized(true);
      if (!initialized) {
        handleChange(formValues, lastModel.current);
      } else {
        handleChangeDebounced.current(formValues, lastModel.current);
      }
    }
  }, [initialized, lastModel, formValues, handleChangeDebounced, handleChange]);

  return result;
};
