import { ActionType, Schema } from 'jsonSchema/types';
import { IconType } from 'models/Icon';

export type ServiceType = {
  actions?: ActionType[];
  hasValidation: boolean;
  icon: IconType;
  schema: Schema;
  type: string;
};

export * from './enums';
export * from './utils';
