import React, { FC } from 'react';

import {
  isFormFieldArray,
  isFormFieldCheckbox,
  isFormFieldFile,
  isFormFieldInput,
  isFormFieldSelect,
  isFormFormFieldDate,
  isFormFormFieldTime,
} from 'jsonSchema';

import FormArrayRenderer from '../FormArrayRenderer';
import FormCheckboxRenderer from '../FormCheckboxRenderer';
import FormDateRenderer from '../FormDateRenderer';
import FormFileRenderer from '../FormFileRenderer';
import FormInputRenderer from '../FormInputRenderer';
import FormSelectRenderer from '../FormSelectRenderer';
import FormTimeRenderer from '../FormTimeRenderer';

import { Props } from './types';

const FormFieldRenderer: FC<Props> = ({ field, onlySelf }) => {
  if (isFormFieldSelect(field)) {
    return <FormSelectRenderer field={field} onlySelf={onlySelf} />;
  }

  if (isFormFieldArray(field)) {
    return <FormArrayRenderer field={field} />;
  }

  if (isFormFieldInput(field)) {
    return <FormInputRenderer field={field} />;
  }

  if (isFormFieldFile(field)) {
    return <FormFileRenderer field={field} />;
  }

  if (isFormFieldCheckbox(field)) {
    return <FormCheckboxRenderer field={field} />;
  }

  if (isFormFormFieldDate(field)) {
    return <FormDateRenderer field={field} />;
  }

  if (isFormFormFieldTime(field)) {
    return <FormTimeRenderer field={field} />;
  }

  return null;
};

export default FormFieldRenderer;
