import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFieldDateValueType, FormFields, getValidationRulesFromAField } from 'jsonSchema';

import FormField from '../FormField';

import { StyledDatePicker } from './styles';
import { Props } from './types';

const FormDateRenderer: FC<Props> = ({ field }) => {
  const { control } = useFormContext<FormFields>();
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);

  return (
    <FormField
      {...validationRules}
      controllerProps={{
        control,
        name: field.name,
      }}
      defaultValue={field.defaultValue}
      required={field.required}
      render={(props) => (
        <StyledDatePicker
          {...props}
          value={props.value as FormFieldDateValueType}
          placeholder={field.placeholder}
          label={field.label}
        />
      )}
    />
  );
};

export default FormDateRenderer;
