import styled from 'styled-components';

import Button from 'components/Button';

import { flexStyle, rgba, scrollStyle } from 'styles/mixins';

import { ViewModes } from './enums';

export const Root = styled.div`
  ${flexStyle({ direction: 'row', wrap: 'nowrap' })};
  ${scrollStyle()};
  overflow-y: scroll;
  position: relative;
  padding-bottom: 64px;
`;
export const DataContainer = styled.div`
  ${flexStyle({ direction: 'row', wrap: 'nowrap' })};
  width: 100%;
`;
export const PathsView = styled.div<{ viewMode: ViewModes }>`
  ${scrollStyle()};
  overflow: hidden;
  overflow-y: scroll;
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.colors.primaryDark};
  padding-right: 8px;
  margin-top: ${({ viewMode }) => (viewMode === ViewModes.EDIT ? 86 : 22)}px;
`;
export const TitleContainer = styled.li`
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  text-overflow: ellipsis;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 10)};
  }

  &:before {
    counter-increment: item-counter;
    content: counter(item-counter) '. ';
  }
`;

export const ParametersView = styled.div`
  ${flexStyle({ direction: 'column', wrap: 'nowrap' })};
  ${scrollStyle()};
  align-items: flex-start;
  flex: 1;
  padding-left: 16px;
  width: 100%;
  overflow-y: scroll;
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ direction: 'row', justify: 'flex-end', wrap: 'column' })};
  margin-top: 24px;
`;
export const SubmitButtonsContainer = styled.div`
  position: absolute;
  ${flexStyle({ justify: 'flex-end', wrap: 'nowrap' })};
  margin-top: 16px;
  bottom: 0;
  right: 0;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
`;
