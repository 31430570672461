import { createSelector } from 'reselect';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

import { RootState } from '../types';

import { getContextVariablesKey, getFlowVariablesKey, getGlobalVariablesKey, getInitiativeVariablesKey } from './utils';

const selectVariablesState = (state: RootState) => state.variables;
export const selectGlobalVariables = (sphere: Sphere['name'], project: Project['name']) =>
  createSelector(selectVariablesState, (state) => state.globalVariables[getGlobalVariablesKey(sphere, project)]);
export const selectGlobalVariablesLoading = createSelector(selectVariablesState, (state) => state.globalVariablesLoading);
export const selectContextVariables = (sphere: Sphere['name'], project: Project['name']) =>
  createSelector(selectVariablesState, (state) => state.contextVariables[getContextVariablesKey(sphere, project)]);
export const selectContextVariablesLoading = createSelector(selectVariablesState, (state) => state.contextVariablesLoading);
export const selectVariablesLoading = createSelector(selectVariablesState, (state) => state.variablesLoading);
export const selectInitiativeVariables = (sphere: Sphere['name'], project: Project['name'], bp: Initiative['name']) =>
  createSelector(selectVariablesState, (state) => state.variables[getInitiativeVariablesKey(sphere, project, bp)]);
export const selectFlowVariables = (
  sphere: Sphere['name'],
  project: Project['name'],
  bp: Initiative['name'],
  flow: Flow['name']
) => createSelector(selectVariablesState, (state) => state.variables[getFlowVariablesKey(sphere, project, bp, flow)]);
export const selectVariableCreating = createSelector(selectVariablesState, (state) => state.variableCreating);
export const selectVariableUpdating = createSelector(selectVariablesState, (state) => state.variableUpdating);
export const selectVariableDeleting = createSelector(selectVariablesState, (state) => state.variableDeleting);
