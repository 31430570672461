import React, { FC } from 'react';

import { ICONS_TYPES } from '../Icon';

import { Root } from './styles';
import { Props } from './types';

const ToastCloseButton: FC<Props> = ({ closeToast }) => {
  return <Root icon={ICONS_TYPES.XMark} size={24} onClick={closeToast} />;
};

export default ToastCloseButton;
