import { omit } from 'lodash';

import {
  FormArrayDatasourceTypes,
  FormFieldSubTypes,
  FormFieldTypes,
  ObjectSchema,
  Property,
  Schema,
  SchemaFieldEnum,
  SchemaFieldString,
  extractValue,
} from 'jsonSchema';
import { VariableType } from 'models/Variable';
import { isDef, isDefAndNotNull } from 'utils/def';

import { SchemaCustomBehaviourContext } from './types';
import { addRequiredField, getProperty, removeProperty } from './utils';

const DATA_TYPE = 'data type';
const DYN = 'dyn';
const VARIABLE_NAME = 'variable name';
const VARIABLE_TYPE = 'variable type';
const UPDATE_EXPRESSION = 'update expression';
const ENCODING = 'encoding';
const ACTION = 'action';

const replaceProperty = (schema: Schema, propName: string, property: Property) => {
  const objectSchema = schema as ObjectSchema;
  objectSchema.properties[propName] = property;
};

export const flowDataBehaviour = ({ newValues, prevValues, schema }: SchemaCustomBehaviourContext) => {
  const dataType = getProperty<SchemaFieldString>(schema, DATA_TYPE);
  const encoding = getProperty<SchemaFieldEnum>(schema, ENCODING);
  const varName = getProperty<SchemaFieldString>(schema, VARIABLE_NAME);

  const actionValue = extractValue(newValues[ACTION]);
  switch (actionValue) {
    case 'Update': {
      addRequiredField(schema, UPDATE_EXPRESSION);
      break;
    }
    case 'Clear':
    case 'Reset': {
      removeProperty(schema, ENCODING);
      removeProperty(schema, UPDATE_EXPRESSION);
      break;
    }
  }

  if (!newValues[DYN]) {
    // varName
    varName.disabled = true;
    dataType.disabled = true;
    if (isDef(extractValue(newValues[VARIABLE_TYPE]))) {
      varName.disabled = false;
      dataType.disabled = false;
      if (extractValue(newValues[VARIABLE_TYPE]) !== extractValue(prevValues[VARIABLE_TYPE])) {
        newValues[VARIABLE_NAME] = null;
        newValues[DATA_TYPE] = null;
      }
      varName.behaviourHints = {
        ...varName.behaviourHints,
        subType: FormFieldSubTypes.selectDatasource,
        subTypeProps: {
          [FormFieldSubTypes.selectDatasource]: {
            dataSource: {
              columns: ['name', 'type'],
              name: FormArrayDatasourceTypes.selectVariable,
            },
            dependencies: {
              variableType: {
                value: extractValue(newValues[VARIABLE_TYPE]),
              },
            },
            outputMapping: {
              [DATA_TYPE]: {
                fieldName: 'type',
                type: FormFieldTypes.input,
              },
              [VARIABLE_NAME]: {
                fieldName: 'name',
                type: FormFieldTypes.input,
              },
            },
          },
        },
      };
    }

    // dataType
    replaceProperty(schema, DATA_TYPE, {
      ...omit(dataType, 'enum'),
      const: '',
      type: 'string',
    });
    newValues[DATA_TYPE] = extractValue(newValues[DATA_TYPE]) || null;
  } else {
    if (newValues[DYN] !== prevValues[DYN]) {
      newValues[DATA_TYPE] = null;
      newValues[VARIABLE_NAME] = null;
      newValues[ENCODING] = null;
      newValues[UPDATE_EXPRESSION] = null;
    }
  }

  //encoding
  if (isDefAndNotNull(newValues[DATA_TYPE])) {
    switch (extractValue(newValues[DATA_TYPE])) {
      case 'Date':
      case 'Time':
      case 'Logical':
      case 'Numeric': {
        newValues[ENCODING] = null;
        removeProperty(schema, ENCODING);
        break;
      }
      case 'Blob': {
        addRequiredField(schema, ENCODING);
        break;
      }
      case 'Alpha': {
        encoding.enum = encoding.enum.filter((x) => x !== 'Binary');
        addRequiredField(schema, ENCODING);
        break;
      }
    }
    if (extractValue(newValues[DATA_TYPE]) !== extractValue(prevValues[DATA_TYPE])) {
      newValues[ENCODING] = null;
    }
  } else {
    removeProperty(schema, ENCODING);
  }

  if (extractValue(newValues[VARIABLE_TYPE]) === VariableType.Environment) {
    removeProperty(schema, ENCODING);
  }

  return { updatedModel: { ...newValues }, updatedSchema: { ...schema } };
};
