import styled from 'styled-components';

import { flexStyle, rgba } from 'styles/mixins';

import Icon from '../Icon';

import { Props } from './types';

export const Root = styled.button<Partial<Props>>`
  ${flexStyle({ wrap: 'nowrap' })};
  padding: 4px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const StyledIcon = styled(Icon)<{ $active: boolean }>`
  padding: 5px 15px;
  color: ${({ $active, theme }) => ($active ? theme.colors.primary : theme.colors.textTertiary)};
  cursor: pointer;
  border-radius: 6px;
  background-color: ${({ $active, theme }) => ($active ? theme.colors.primaryLight : 'transparent')};

  &:hover {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 6)};
  }
`;
export const SunIcon = styled(StyledIcon)`
  margin-right: 4px;
`;
