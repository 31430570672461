import { combineReducers } from 'redux';

import componentsReducer from 'components/componentsReducer';

import pagesReducer from 'pages/pagesReducer';

import appReducer from './app/reducer';
import authReducer from './auth/reducer';
import logsReducer from './logs/reducer';
import modalReducer from './modal/reducer';
import projectResourcesReducer from './projectResources/reducer';
import projectServicesReducer from './projectServices/reducer';
import projectTagsReducer from './projectTags/reducer';
import rightPanelReducer from './rightPanel/reducer';
import { routerReducer } from './router';
import tabsReducer from './tabs/reducer';
import variablesReducer from './variables/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  components: componentsReducer,
  logs: logsReducer,
  modal: modalReducer,
  pages: pagesReducer,
  projectResources: projectResourcesReducer,
  projectServices: projectServicesReducer,
  projectTags: projectTagsReducer,
  rightPanel: rightPanelReducer,
  router: routerReducer,
  tabs: tabsReducer,
  variables: variablesReducer,
});

export default rootReducer;
