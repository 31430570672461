import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import API, { apiCallHandler } from 'api';
import { fetchProjectTree } from 'pages/project/store/actions';
import { modalClose } from 'store/modal/actions';

import { DELETE_FLOW_CONFIRM_MODAL_ID } from '../constant';

import { createFlow, deleteFlow, fetchFlows, tabClose } from './actions';
import { selectFlowTabs } from './selectors';

function* onFetchFlows({ payload: { bp, project, sphere } }: ReturnType<typeof fetchFlows.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchFlows.failure());
    },
    errMessageFallback: 'Failed to fetch flows',
    tryHandler: function* () {
      const {
        data: {
          data: { flows },
        },
      } = yield* call(API.flows.fetchFlows, { bp, project, sphere });
      yield put(fetchFlows.success({ flows }));
    },
  });
}

function* onCreateFlow({ payload }: ReturnType<typeof createFlow.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createFlow.failure());
    },
    errMessageFallback: 'Failed to create flow',
    tryHandler: function* () {
      yield* call(API.flows.createFlow, payload);
      yield put(createFlow.success());
      yield put(fetchProjectTree.request({ projectName: payload.project, sphereName: payload.sphere }));
      yield put(
        push(`/spheres/${payload.sphere}/projects/${payload.project}/initiative/${payload.bp}/flow/${payload.data.name}`)
      );
      yield call(toast.success, `The flow '${payload.data.name}' was successfully created`);
    },
  });
}

function* onDeleteFlow({ payload: { currentUrlFlowName, ...payload } }: ReturnType<typeof deleteFlow.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteFlow.failure());
    },
    errMessageFallback: 'Failed to delete flow',
    tryHandler: function* () {
      yield* call(API.flows.deleteFlow, payload);
      if (payload.name !== currentUrlFlowName) {
        yield onFetchFlows({
          payload: payload,
          type: getType(fetchFlows.request),
        });
      }
      yield put(deleteFlow.success());
      yield put(fetchProjectTree.request({ projectName: payload.project, sphereName: payload.sphere }));
      yield put(tabClose.request({ ...payload, currentUrlFlowName, flow: payload.name }));
      yield put(modalClose.request({ id: DELETE_FLOW_CONFIRM_MODAL_ID }));
      if (payload.name === currentUrlFlowName) {
        yield put(push(`/spheres/${payload.sphere}/projects/${payload.project}/initiative/${payload.bp}`));
      }
      yield call(toast.success, `The flow '${payload.name}' was successfully deleted`);
    },
  });
}

function* onTabClose({ payload }: ReturnType<typeof tabClose.request>): Generator {
  const flowTabs = yield* select(selectFlowTabs(payload.bp));
  yield put(tabClose.success({ ...payload }));
  if (payload.flow === payload.currentUrlFlowName) {
    if (flowTabs.length === 1) {
      yield put(push(`/spheres/${payload.sphere}/projects/${payload.project}/initiative/${payload.bp}`));
    } else {
      const prevFlowIndex = flowTabs.indexOf(payload.flow);
      yield put(
        push(
          `/spheres/${payload.sphere}/projects/${payload.project}/initiative/${payload.bp}/flow/${prevFlowIndex === 0 ? flowTabs[1] : flowTabs[prevFlowIndex - 1]}`
        )
      );
    }
  }
}

function* flowTabsSaga() {
  yield all([
    takeEvery(fetchFlows.request, onFetchFlows),
    takeEvery(createFlow.request, onCreateFlow),
    takeEvery(deleteFlow.request, onDeleteFlow),
    takeEvery(tabClose.request, onTabClose),
  ]);
}

export default flowTabsSaga;
