import { Props as RestSelectProps, SingleValue } from 'react-select';

import { IconType } from 'models/Icon';

import {
  FormArrayDatasourceTypes,
  FormExpressionInputTypes,
  FormFieldInputNumberTypes,
  FormFieldSubTypes,
  FormFieldTypes,
} from './enums';

export type Required = string[];

export type Option = { icon?: IconType; label: string; type?: string; value: PrimitiveProperty['const'] };

export type OneOfProperty = { behaviourHints?: BehaviorHints; oneOf: SchemaFieldObject[] };
export type PrimitiveProperty =
  | SchemaFieldString
  | SchemaFieldNumber
  | SchemaFieldInteger
  | SchemaFieldBoolean
  | SchemaFieldEnum
  | SchemaFieldFile
  | SchemaFieldDate
  | SchemaFieldTime;
export type Property = PrimitiveProperty | OneOfProperty | SchemaFieldArray;
export type FieldSchema = Property | SchemaFieldObject | AnyOfSchema | ArraySchema;
export type Properties = Record<string, Property>;
export type BehaviorHints = {
  readOnly?: boolean;
  secure?: boolean;
  subType: FormFieldSubTypes;
  subTypeProps: SubTypeProps;
  submitOnChange?: boolean;
};

export type SelectDatasourceSubTypeProps = {
  dataSource?: {
    columns?: string[];
    key?: string;
    multiple?: boolean;
    name: FormArrayDatasourceTypes;
    storeKey?: string;
  };
  dependencies?: {
    [key: string]: Partial<{
      fieldName: string;
      value: unknown;
    }>;
  };
  outputMapping?:
    | string
    | {
        [key: string]: {
          fieldName: string;
          type: FormFieldTypes;
        };
      };
};
export type ArraySubTypeColumnDefinition = {
  label?: string;
  name: string;
  searchable?: boolean;
  sortable?: boolean;
};
export type ArraySubTypeProps = {
  columns?: ArraySubTypeColumnDefinition[];
  displayField?: string;
  entityName?: string;
  hasRowAdd?: boolean;
  hasRowDelete?: boolean;
  hasRowEdit?: boolean;
};
export type ResourcesSelectSubTypeProps = {
  typeFilter: string;
};
export type ServicesSelectSubTypeProps = {
  typeFilter: string;
};
export type SubTypeProps = {
  [FormFieldSubTypes.fileInput]?: {
    accept: { [key: string]: string[] };
  };
  [FormFieldSubTypes.array]?: ArraySubTypeProps;
  [FormFieldSubTypes.dateInput]?: undefined;
  [FormFieldSubTypes.timeInput]?: undefined;
  [FormFieldSubTypes.expressionInput]?: FormExpressionInputTypes;
  [FormFieldSubTypes.resourcesSelect]?: ResourcesSelectSubTypeProps;
  [FormFieldSubTypes.servicesSelect]?: ServicesSelectSubTypeProps;
  [FormFieldSubTypes.selectDatasource]?: SelectDatasourceSubTypeProps;
};

export type FormFieldInputValueType = string;
export type FormFieldNumberValueType = number;
export type FormFieldCheckBoxValueType = boolean;
export type FormFieldDateValueType = string;
export type FormFieldTimeValueType = string;
export type FormFieldSelectValueType = SingleValue<Option>;
export type FormFieldFileValueType = {
  file?: File;
  id?: string; // value to link field with file in FormData during POST/PUT request
  name: string;
};
export type FormFieldUnion =
  | FormFieldInput
  | FormFieldCheckBox
  | FormFieldSelect
  | FormFieldOption
  | FormFieldArray
  | FormFieldFile
  | FormFieldDate
  | FormFieldTime;
export type FormConfig = FormFieldUnion[];
export type FormFieldValue =
  | FormFieldInputValueType
  | FormFieldCheckBoxValueType
  | FormFieldSelectValueType
  | FormFieldNumberValueType
  | FormFieldFileValueType
  | FormFieldDateValueType
  | FormFieldTimeValueType
  | FormFields
  | undefined;
export type FormFieldValidationRules = {
  max?: SchemaFieldNumber['maximum'];
  maxLength?: SchemaFieldString['maxLength'];
  min?: SchemaFieldNumber['minimum'];
  minLength?: SchemaFieldString['minLength'];
  pattern?: RegExp;
  required?: boolean;
};

export interface SchemaField<TypeLiteral, Type> {
  behaviourHints?: BehaviorHints;
  const?: Type;
  default?: Type;
  disabled?: boolean;
  title?: string;
  type: TypeLiteral;
}

export interface SchemaFieldString extends SchemaField<'string', string> {
  description?: string;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
  title?: string;
}

export interface SchemaFieldInteger extends SchemaField<'integer', number> {
  description?: string;
  maximum?: number;
  minimum?: number;
  title?: string;
}

export interface SchemaFieldNumber extends SchemaField<'number', number> {
  description?: string;
  maximum?: number;
  minimum?: number;
  title?: string;
}

export interface SchemaFieldEnum extends SchemaField<'string', string> {
  description: string;
  enum: string[];
  title?: string;
}

export interface SchemaFieldBoolean extends SchemaField<'boolean', boolean> {
  description: string;
  title?: string;
}

export interface SchemaFieldObject extends SchemaField<'object', object> {
  description?: string;
  properties: Properties;
  required?: Required;
}

export interface SchemaFieldArray extends SchemaField<'array', Array<FormFields>> {
  behaviourHints?: BehaviorHints;
  description: string;
  items: ObjectSchema | Schema;
}

export interface SchemaFieldFile extends SchemaField<'string', string> {
  behaviourHints: BehaviorHints;
  description?: string;
}

export interface SchemaFieldDate extends SchemaField<'date', string> {
  behaviourHints: BehaviorHints;
  description?: string;
  title?: string;
}

export interface SchemaFieldTime extends SchemaField<'time', string> {
  behaviourHints: BehaviorHints;
  description?: string;
  title?: string;
}

export interface AnyOfSchema extends Schema {
  anyOf: Schema[];
}

export interface ObjectSchema extends Schema {
  properties: Properties;
  required: Required;
}

export interface ArraySchema extends Schema {
  behaviourHints?: BehaviorHints;
  items: ObjectSchema | Schema;
}

export interface Schema {
  $schema?: string;
  additionalProperties?: boolean;
  description?: string;
  title: string;
  type: string;
}

export interface FormField {
  defaultValue?: FormFieldValue | FormFields[];
  dependantFields?: FormFieldUnion[];
  disabled?: boolean;
  fieldSchema: FieldSchema;
  label?: string;
  name: string;
  readonly?: boolean;
  required?: boolean;
  secure?: boolean;
  subType?: FormFieldSubTypes;
  subTypeProps?: SubTypeProps;
  submitOnChange?: boolean;
  type: FormFieldTypes;
}

export interface FormFieldInput extends FormField {
  numberType?: FormFieldInputNumberTypes;
  placeholder?: string;
}

export interface FormFieldOption extends FormField {
  value: PrimitiveProperty['const'];
}

export interface FormFieldCheckBox extends FormField {}

export interface FormFieldFile extends FormField {
  placeholder?: string;
}

export interface FormFieldArray extends FormField {
  items?: FormFieldUnion[];
}

export interface FormFieldSelect extends FormField {
  anyOfBehavior?: boolean;
  getOptionLabel: RestSelectProps<Option, false>['getOptionLabel'];
  getOptionValue: RestSelectProps<Option, false>['getOptionValue'];
  options?: RestSelectProps<Option, false>['options'];
  placeholder?: string;
}

export interface FormFieldDate extends FormField {
  placeholder?: string;
}

export interface FormFieldTime extends FormField {
  placeholder?: string;
}

export interface FormFields {
  [key: string]: FormFieldValue | FormFieldValue[] | FormFields[];
}

export type ActionType = {
  button?: boolean;
  icon?: string;
  menu?: boolean;
  name: string;
};
