import { ActionType, getType } from 'typesafe-actions';

import * as projectActions from './actions';

type ProjectActionType = ActionType<typeof projectActions>;

export type ProjectState = {
  createLoading: boolean;
  deleteLoading: boolean;
  list: projectActions.FetchTagsSuccessPayload['list'];
  loading: boolean;
  updateLoading: boolean;
};

const initialState: ProjectState = {
  createLoading: false,
  deleteLoading: false,
  list: [],
  loading: false,
  updateLoading: false,
};

const projectTagsReducer = (state: ProjectState = initialState, action: ProjectActionType): ProjectState => {
  switch (action.type) {
    case getType(projectActions.fetchTags.request):
      return {
        ...state,
        loading: true,
      };
    case getType(projectActions.fetchTags.success):
      return {
        ...state,
        list: action.payload.list,
        loading: false,
      };
    case getType(projectActions.fetchTags.failure):
      return {
        ...state,
        loading: false,
      };

    case getType(projectActions.createTag.request):
      return {
        ...state,
        createLoading: true,
      };
    case getType(projectActions.createTag.success):
    case getType(projectActions.createTag.failure):
      return {
        ...state,
        createLoading: false,
      };

    case getType(projectActions.updateTag.request):
      return {
        ...state,
        updateLoading: true,
      };
    case getType(projectActions.updateTag.success):
    case getType(projectActions.updateTag.failure):
      return {
        ...state,
        updateLoading: false,
      };

    case getType(projectActions.deleteTag.request):
      return {
        ...state,
        deleteLoading: true,
      };
    case getType(projectActions.deleteTag.success):
    case getType(projectActions.deleteTag.failure):
      return {
        ...state,
        deleteLoading: false,
      };

    default:
      return state;
  }
};

export default projectTagsReducer;
