import styled from 'styled-components';

import { flexStyle, scrollStyle } from 'styles/mixins';

import Button from '../../../Button';

export const Root = styled.div`
  min-height: 100px;
`;
export const SubmitButton = styled(Button)`
  margin-right: 16px;
`;
export const ButtonsContainer = styled.div`
  ${flexStyle({ justify: 'flex-end', wrap: 'nowrap' })};
  margin-top: 24px;
`;
export const TableContainer = styled.div`
  ${scrollStyle()};
  max-height: calc(70vh - 150px);
  overflow: auto;
  min-height: 30px;
`;
