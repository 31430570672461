import React, { FC } from 'react';

const Sphere: FC = () => (
  <svg viewBox="0 0 14 14" fill="none">
    <path
      d="M1.75 7C1.75 7.68944 1.8858 8.37213 2.14963 9.00909C2.41347 9.64605 2.80018 10.2248 3.28769 10.7123C3.7752 11.1998 4.35395 11.5865 4.99091 11.8504C5.62787 12.1142 6.31056 12.25 7 12.25M1.75 7C1.75 6.31056 1.8858 5.62787 2.14963 4.99091C2.41347 4.35395 2.80018 3.7752 3.28769 3.28769C3.7752 2.80018 4.35395 2.41347 4.99091 2.14963C5.62787 1.8858 6.31056 1.75 7 1.75M1.75 7C1.75 8.28917 4.10083 9.33333 7 9.33333C9.89917 9.33333 12.25 8.28917 12.25 7M1.75 7C1.75 5.71083 4.10083 4.66667 7 4.66667C9.89917 4.66667 12.25 5.71083 12.25 7M7 12.25C7.68944 12.25 8.37213 12.1142 9.00909 11.8504C9.64605 11.5865 10.2248 11.1998 10.7123 10.7123C11.1998 10.2248 11.5865 9.64605 11.8504 9.00909C12.1142 8.37213 12.25 7.68944 12.25 7M7 12.25C6.38116 12.25 5.78767 11.6969 5.35008 10.7123C4.9125 9.72774 4.66667 8.39239 4.66667 7C4.66667 5.60761 4.9125 4.27226 5.35008 3.28769C5.78767 2.30312 6.38116 1.75 7 1.75M7 12.25C7.61884 12.25 8.21233 11.6969 8.64992 10.7123C9.0875 9.72774 9.33333 8.39239 9.33333 7C9.33333 5.60761 9.0875 4.27226 8.64992 3.28769C8.21233 2.30312 7.61884 1.75 7 1.75M12.25 7C12.25 6.31056 12.1142 5.62787 11.8504 4.99091C11.5865 4.35395 11.1998 3.7752 10.7123 3.28769C10.2248 2.80018 9.64605 2.41347 9.00909 2.14963C8.37213 1.8858 7.68944 1.75 7 1.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Sphere;
