import moment from 'moment';
import React, { FC, MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import useDeepCallback from 'hooks/useDeepCallback';
import { stopMouseEvents } from 'utils/events';

import { ICONS_TYPES } from '../../../Icon';
import { PlacementTypes, Sizes } from '../../../Popover';
import PopoverMenu from '../../../PopoverMenu';

import { FORMAT_SCHEMA } from '../../constants';

import {
  ArrowIcon,
  CalendarContent,
  CalendarHeader,
  ControlButton,
  DayItem,
  HeaderButtons,
  HeaderDate,
  Month,
  Root,
  WeekItem,
  YearArrowIcon,
  YearButton,
  YearPicker,
} from './styles';
import { DayType, Props, YearType } from './types';
import { convertToDayType } from './utils';

const Calendar: FC<Props> = ({ className, onBlur, onChange, setIsActive, value }) => {
  const isValidDate: boolean = useMemo(() => moment(value, FORMAT_SCHEMA).isValid(), [value]);
  const selectedDate = useMemo(() => (isValidDate ? moment(value, FORMAT_SCHEMA) : moment()), [isValidDate, value]);
  const [selectedYear, setSelectedYear] = useState<number>(() => selectedDate.year());
  const [selectedMonth, setSelectedMonth] = useState<number>(() => selectedDate.month());
  const currentMonth = useMemo(() => moment([selectedYear, selectedMonth + 1, 1], FORMAT_SCHEMA), [selectedMonth, selectedYear]);
  const preMonth = useMemo(
    () => moment([selectedYear, selectedMonth + 1, 1], FORMAT_SCHEMA).add(-1, 'month'),
    [selectedMonth, selectedYear]
  );
  const nextMonth = useMemo(
    () => moment([selectedYear, selectedMonth + 1, 1], FORMAT_SCHEMA).add(1, 'month'),
    [selectedMonth, selectedYear]
  );
  const daysInCurrentMonth = useMemo(() => currentMonth.daysInMonth(), [currentMonth]);
  const daysInPreMonth = useMemo(() => preMonth.daysInMonth(), [preMonth]);
  const firstDayOfCurrentMonth = useMemo(() => currentMonth.startOf('month'), [currentMonth]);
  const firstDayMonthWeekNumber = useMemo(() => firstDayOfCurrentMonth.day(), [firstDayOfCurrentMonth]);
  const lastDayOfCurrentMonth = useMemo(() => currentMonth.endOf('month'), [currentMonth]);
  const lastDayCurrentMonthWeekNumber = useMemo(() => lastDayOfCurrentMonth.day(), [lastDayOfCurrentMonth]);
  const currentMonthName = useMemo(() => currentMonth.format('MMM'), [currentMonth]);
  const currentYear = useMemo(() => currentMonth.format('YYYY'), [currentMonth]);
  const currentYearNumber = useMemo(() => currentMonth.year(), [currentMonth]);

  const handleSelectYear = useCallback((value: number) => {
    setSelectedYear(value);
  }, []);

  const weekDaysNameList: Array<string> = useMemo(
    () =>
      Array(7)
        .fill(0)
        .map((el, i) => moment().isoWeekday(i).format('dd')),
    []
  );
  const daysList: DayType[] = useMemo(
    () => [
      ...Array(firstDayMonthWeekNumber)
        .fill(0)
        .map((el, i) => convertToDayType(daysInPreMonth - i, true, FORMAT_SCHEMA, selectedDate, preMonth, isValidDate))
        .reverse(),
      ...Array(daysInCurrentMonth)
        .fill(0)
        .map((el, i) => convertToDayType(i + 1, false, FORMAT_SCHEMA, selectedDate, currentMonth, isValidDate)),
      ...Array(6 - lastDayCurrentMonthWeekNumber)
        .fill(0)
        .map((el, i) => convertToDayType(i + 1, true, FORMAT_SCHEMA, selectedDate, nextMonth, isValidDate)),
    ],
    [
      currentMonth,
      daysInCurrentMonth,
      daysInPreMonth,
      firstDayMonthWeekNumber,
      isValidDate,
      lastDayCurrentMonthWeekNumber,
      nextMonth,
      preMonth,
      selectedDate,
    ]
  );
  const yearsList: YearType[] = useMemo(
    () => [
      ...Array(4)
        .fill(0)
        .map((el, i) => ({ label: `${currentYearNumber - 1 - i}`, onClick: () => handleSelectYear(currentYearNumber - 1 - i) }))
        .reverse(),
      { active: true, label: `${currentYearNumber}`, onClick: () => {} },
      ...Array(4)
        .fill(0)
        .map((el, i) => ({ label: `${currentYearNumber + 1 + i}`, onClick: () => handleSelectYear(currentYearNumber + 1 + i) })),
    ],
    [currentYearNumber, handleSelectYear]
  );

  const handleClickDay = useDeepCallback((value: DayType, e: ReactMouseEvent | MouseEvent) => {
    if (value.disabled) {
      stopMouseEvents(e);
    }
    !value.disabled && onChange(value.date);
  });
  const handleClickNextMonth = useCallback((e: ReactMouseEvent | MouseEvent) => {
    stopMouseEvents(e);
    setSelectedMonth((prevState) => {
      if (prevState === 11) {
        setSelectedYear((prevState1) => prevState1 + 1);
        return 0;
      }
      return prevState + 1;
    });
  }, []);
  const handleClickPrevMonth = useCallback((e: ReactMouseEvent | MouseEvent) => {
    stopMouseEvents(e);
    setSelectedMonth((prevState) => {
      if (prevState === 0) {
        setSelectedYear((prevState1) => prevState1 - 1);
        return 11;
      }
      return prevState - 1;
    });
  }, []);

  useEffect(() => {
    setIsActive(true);

    return () => {
      setSelectedYear(selectedDate.year());
      setSelectedMonth(selectedDate.month());
      setIsActive(false);
      onBlur?.();
    };
  }, [onBlur, selectedDate, setIsActive]);

  return (
    <Root className={className}>
      <CalendarHeader onClick={stopMouseEvents}>
        <HeaderDate>
          <Month>{currentMonthName}</Month>
          <YearPicker>
            <PopoverMenu
              items={yearsList}
              popoverProps={{
                closeOnRootClick: true,
                defaultPlacement: [PlacementTypes.right, PlacementTypes.bottom],
                preventStopMouseEvents: true,
                size: Sizes.small,
              }}
            >
              <YearButton>
                {currentYear}
                <YearArrowIcon icon={ICONS_TYPES.ChevronDown} size={24} />
              </YearButton>
            </PopoverMenu>
          </YearPicker>
        </HeaderDate>
        <HeaderButtons>
          <ControlButton onClick={handleClickPrevMonth}>
            <ArrowIcon icon={ICONS_TYPES.ChevronDown} size={24} rotate={-270} />
          </ControlButton>
          <ControlButton onClick={handleClickNextMonth}>
            <ArrowIcon icon={ICONS_TYPES.ChevronDown} size={24} rotate={-90} />
          </ControlButton>
        </HeaderButtons>
      </CalendarHeader>
      <CalendarContent>
        {weekDaysNameList.map((name) => (
          <WeekItem key={name} onClick={stopMouseEvents}>
            {name}
          </WeekItem>
        ))}
        {daysList.map((day, i) => (
          <DayItem
            key={`${day.value}_${i}`}
            disabled={day.disabled}
            selected={day.selected}
            isToday={day.isToday}
            onClick={handleClickDay(day)}
          >
            {day.value}
          </DayItem>
        ))}
      </CalendarContent>
    </Root>
  );
};

export type { Props };

export default Calendar;
