import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { isDefAndNotNull } from 'utils/def';

import {
  MAX_LENGTH_ERROR_TEXT,
  MAX_VALUE_ERROR_TEXT,
  MIN_LENGTH_ERROR_TEXT,
  MIN_VALUE_ERROR_TEXT,
  PATTERN_VALUE_ERROR_TEXT,
  REQUIRED_ERROR_TEXT,
} from './constants';
import { Props } from './types';

const FormField = <FormFields extends FieldValues, FieldName extends FieldPath<FormFields>>({
  controllerProps,
  defaultValue,
  max,
  maxLength,
  min,
  minLength,
  pattern,
  render,
  required,
}: Props<FormFields, FieldName>) => {
  return (
    <Controller
      {...controllerProps}
      rules={{
        max: isDefAndNotNull(max) ? { message: MAX_VALUE_ERROR_TEXT(max), value: max } : controllerProps.rules?.max,
        maxLength: isDefAndNotNull(maxLength)
          ? { message: MAX_LENGTH_ERROR_TEXT(maxLength), value: maxLength }
          : controllerProps.rules?.maxLength,
        min: isDefAndNotNull(min) ? { message: MIN_VALUE_ERROR_TEXT(min), value: min } : controllerProps.rules?.min,
        minLength: isDefAndNotNull(minLength)
          ? { message: MIN_LENGTH_ERROR_TEXT(minLength), value: minLength }
          : controllerProps.rules?.minLength,
        pattern: isDefAndNotNull(pattern)
          ? { message: PATTERN_VALUE_ERROR_TEXT(pattern), value: pattern }
          : controllerProps.rules?.pattern,
        required: isDefAndNotNull(required) && required ? REQUIRED_ERROR_TEXT : controllerProps.rules?.required,
        ...controllerProps.rules,
      }}
      defaultValue={defaultValue}
      render={({ field: { ref, ...rest }, fieldState }) =>
        render({ ...rest, error: fieldState?.error?.message, required, touched: fieldState.isTouched })
      }
    />
  );
};

export * from './constants';

export default FormField;
