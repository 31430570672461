import { all, delay, put, select, takeEvery } from 'typed-redux-saga';

import { Modes } from 'components/Modal';

import { isDefAndNotNull } from 'utils/def';

import { modalClose, resetClosingClass, setClosingClass } from './actions';
import { CLOSING_TIMEOUT_MS, CLOSING_TIMEOUT_OFFSET_MS } from './constants';
import { selectModalIsAnimated, selectModalMode } from './selectors';

function* onModalCloseRequest({ payload: { animated, callBack, id } }: ReturnType<typeof modalClose.request>): Generator {
  const mode = yield select(selectModalMode(id));
  const animatedFromStore = yield select(selectModalIsAnimated(id));

  if (mode === Modes.rightSlide && isDefAndNotNull(animated) ? animated : animatedFromStore) {
    yield put(setClosingClass({ id }));

    yield delay(CLOSING_TIMEOUT_MS - CLOSING_TIMEOUT_OFFSET_MS);
    yield put(resetClosingClass({ id }));
  }

  yield put(modalClose.success({ id }));

  callBack?.();
}

function* modalSaga() {
  yield all([takeEvery(modalClose.request, onModalCloseRequest)]);
}

export default modalSaga;
