import { AxiosResponse } from 'axios';

import { Log } from 'models/Log';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'commands';

export const runBuild = (data: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ logs: Log['data']; result: boolean }>>> =>
  request({
    data,
    endpoint: `${endpoint}/build`,
    method: Methods.post,
  });

export const runDeploy = (data: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ logs: Log['data']; result: boolean }>>> =>
  request({
    data,
    endpoint: `${endpoint}/deploy`,
    method: Methods.post,
  });
