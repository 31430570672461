import React, { FC, memo, useCallback } from 'react';

import { buildDataCyValue } from 'utils/cy';

import { ViewModes } from './enums';
import { RootFilled, RootOutlined, StepContainer, StepIcon, StepIconContainer, StepName } from './styles';
import { Props } from './types';

const StepTypeCard: FC<Props> = ({ className, model, onClick = () => {}, viewMode = ViewModes.outlined }) => {
  const handleClick = useCallback(() => onClick(model), [model, onClick]);

  return (
    <>
      {viewMode === ViewModes.outlined ? (
        <RootOutlined
          className={className}
          onClick={handleClick}
          data-cy={buildDataCyValue({ elementName: 'step', fieldName: model.type })}
        >
          <StepContainer>
            <StepIconContainer>
              <StepIcon model={model.icon} />
            </StepIconContainer>
            <StepName>{model.type}</StepName>
          </StepContainer>
        </RootOutlined>
      ) : (
        <RootFilled
          className={className}
          onClick={handleClick}
          data-cy={buildDataCyValue({ elementName: 'step', fieldName: model.type })}
        >
          <StepContainer>
            <StepIconContainer>
              <StepIcon model={model.icon} />
            </StepIconContainer>
            <StepName>{model.type}</StepName>
          </StepContainer>
        </RootFilled>
      )}
    </>
  );
};

export { ViewModes };

export default memo(StepTypeCard);
