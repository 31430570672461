import { createAction, createAsyncAction } from 'typesafe-actions';

import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { ProjectTree } from 'models/ProjectTree';
import { Sphere } from 'models/Sphere';

export type FetchProjectRequestPayload = {
  data: { projectName: Project['name']; sphereName: Sphere['name'] };
};
export type FetchProjectSuccessPayload = {
  project: Project;
};
export const fetchProject = createAsyncAction(
  'project/fetchProject/request',
  'project/fetchProject/success',
  'project/fetchProject/failure'
)<FetchProjectRequestPayload, FetchProjectSuccessPayload, undefined>();

export type UpdateProjectRequestPayload = {
  data: {
    data: Pick<Project, 'name' | 'description' | 'version'> & { oldName: Project['name'] };
    sphere: Sphere['name'];
  };
};
export const updateProject = createAsyncAction(
  'project/updateProject/request',
  'project/updateProject/success',
  'project/updateProject/failure'
)<UpdateProjectRequestPayload, undefined, undefined>();

export type DeleteProjectRequestPayload = {
  project: Project['name'];
  sphere: Sphere['name'];
};
export const deleteProject = createAsyncAction(
  'sphere/deleteProject/request',
  'sphere/deleteProject/success',
  'sphere/deleteProject/failure'
)<DeleteProjectRequestPayload, undefined, undefined>();

export type FetchInitiativesRequestPayload = {
  data: { project: Project['name']; sphere: Sphere['name'] };
};
export type FetchInitiativesSuccessPayload = {
  businessProcesses: Initiative[];
};
export const fetchInitiatives = createAsyncAction(
  'project/fetchInitiatives/request',
  'project/fetchInitiatives/success',
  'project/fetchInitiatives/failure'
)<FetchInitiativesRequestPayload, FetchInitiativesSuccessPayload, undefined>();

export type CreateInitiativeRequestPayload = {
  data: { data: Initiative; project: Project['name']; sphere: Sphere['name'] };
};
export const createInitiative = createAsyncAction(
  'project/createInitiative/request',
  'project/createInitiative/success',
  'project/createInitiative/failure'
)<CreateInitiativeRequestPayload, undefined, undefined>();

export const reset = createAction('project/reset')();

export type FetchProjectTreeRequestPayload = {
  projectName: Project['name'];
  sphereName: Sphere['name'];
};
export type FetchProjectTreeSuccessPayload = {
  project: ProjectTree;
};
export const fetchProjectTree = createAsyncAction(
  'project/fetchProjectTree/request',
  'project/fetchProjectTree/success',
  'project/fetchProjectTree/failure'
)<FetchProjectTreeRequestPayload, FetchProjectTreeSuccessPayload, undefined>();
