import React, { FC } from 'react';

import { buildDataCyValue } from 'utils/cy';

import { ICONS_TYPES, ICON_COMPS } from './constants';
import { Root, SvgWrapper } from './styles';
import { Props } from './types';

const Icon: FC<Props> = ({ className, disabled, icon, onClick, rotate = 0, size }) => (
  <Root
    className={className}
    onClick={disabled ? undefined : onClick}
    data-cy={buildDataCyValue({ elementName: 'icon', fieldName: icon })}
  >
    <SvgWrapper size={size} rotate={rotate}>
      {React.createElement(ICON_COMPS[icon])}
    </SvgWrapper>
  </Root>
);

export { ICONS_TYPES };
export type { Props };

export default Icon;
