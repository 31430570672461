import { isObject, isString } from 'lodash';
import { useCallback } from 'react';

import { FormField, FormFieldSubTypes, FormFieldValue, FormFields, SelectDatasourceSubTypeProps } from 'jsonSchema';
import { isDef, isDefAndNotNull } from 'utils/def';

import { useGetSubTypeProps } from './useGetArraySubTypeProps';

export const useTransformSubTypeSelection = <T extends FormFields>(field: FormField) => {
  const subTypeProps = useGetSubTypeProps<SelectDatasourceSubTypeProps>(field, FormFieldSubTypes.selectDatasource);
  const transformSelection = useCallback(
    (selection: T[]): FormFields[] | FormFieldValue[] => {
      if (isDef(subTypeProps) && isDef(subTypeProps?.outputMapping)) {
        const values: FormFieldValue[] = [];
        const formValues: FormFields[] = [];
        selection.forEach((item) => {
          if (isObject(subTypeProps.outputMapping)) {
            const transformedItem: FormFields = {};
            Object.keys(subTypeProps.outputMapping || {}).forEach((key) => {
              if (isDef(field.subTypeProps) && isDef(subTypeProps.outputMapping) && isObject(subTypeProps.outputMapping)) {
                const { fieldName } = subTypeProps.outputMapping[key];
                transformedItem[key] = item[fieldName];
              }
            });
            formValues.push(transformedItem);
          } else {
            if (isString(subTypeProps.outputMapping)) {
              const value = item[subTypeProps.outputMapping] as FormFieldValue;
              if (isDefAndNotNull(value)) {
                values.push(value);
              }
            }
          }
        });
        return isObject(subTypeProps.outputMapping) ? formValues : values;
      }
      return selection;
    },
    [subTypeProps, field]
  );

  return { transformSelection };
};
