import { Row, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { upperFirst } from 'lodash';
import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button, { Sizes, StyleTypes } from 'components/Button';
import { useGetSubTypeProps } from 'components/FormArrayRenderer/hooks/useGetArraySubTypeProps';
import { ICONS_TYPES } from 'components/Icon';
import Modal from 'components/Modal';
import Table from 'components/Table';

import useDeepCallback from 'hooks/useDeepCallback';
import { ArraySubTypeProps, FormFieldArray, FormFieldSubTypes, FormFields, extractValue, schemaToFormConfig } from 'jsonSchema';
import { modalClose, modalOpen } from 'store/modal/actions';
import { isDef, isDefAndNotNull } from 'utils/def';
import { stopMouseEvents } from 'utils/events';

import SchemaArrayRowForm from './components/SchemaArrayRowForm';
import { DELETE_ROW_MODAL_ID, SUBMIT_ROW_MODAL_ID } from './constants';
import { CellActions, CellValue, CellWrapper, Root, StyledButton, StyledSearch, TopContainer } from './styles';
import { EditRowModel, FormFieldsWithId, Props } from './types';
import { checkIfFormFieldsContainString } from './utils';

const SchemaArray: FC<Props> = ({
  getRowReadonlyState = () => false,
  hasRowAdd = true,
  hasRowDelete = true,
  hasRowEdit = true,
  hasSearch = true,
  onRowClick,
  onRowDelete,
  onRowSubmit,
  rowSubmitting,
  schema,
  skipPropNames,
}) => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<FormFieldsWithId>();
  const [searchStr, setSearchStr] = useState('');
  const handleSearchChanged = useCallback((value: string) => setSearchStr(value.toLowerCase()), [setSearchStr]);
  const formConfig = useMemo(() => schemaToFormConfig(schema), [schema]);
  const formArrayField = useMemo(() => formConfig[0] as FormFieldArray, [formConfig]);
  const subTypeProps = useGetSubTypeProps<ArraySubTypeProps>(formArrayField, FormFieldSubTypes.array);
  const { control } = useFormContext<FormFields>();
  const { append, fields, remove, update } = useFieldArray<FormFields>({
    control,
    name: schema.title,
  });
  const arrayItems: FormFieldsWithId[] = useMemo(() => fields as FormFieldsWithId[], [fields]);
  const submitRowModalId = useMemo(() => `${SUBMIT_ROW_MODAL_ID}_${schema.title}`, [schema]);
  const deleteRowModalId = useMemo(() => `${DELETE_ROW_MODAL_ID}_${schema.title}`, [schema]);
  const handleConfirmRowDelete = useDeepCallback((row: Row<FormFields>, event: MouseEvent) => {
    stopMouseEvents(event);
    dispatch(modalOpen({ data: row, id: deleteRowModalId }));
  });
  const handleRowEdit = useDeepCallback((row: Row<FormFields>, event: MouseEvent) => {
    stopMouseEvents(event);
    dispatch(modalOpen({ data: { row: row.original }, id: submitRowModalId }));
  });
  const handleRowDelete = useDeepCallback((row: Row<FormFields>) => {
    if (isDef(onRowDelete)) {
      onRowDelete(row.original);
    } else {
      const index = arrayItems.findIndex((item) => item.id === row.id);
      if (index >= 0) {
        remove(index);
      }
    }
    dispatch(modalClose.request({ id: deleteRowModalId }));
  });
  const columnsToRender = useMemo(
    () =>
      subTypeProps?.columns ||
      formArrayField.items
        ?.filter((item) => !skipPropNames?.includes(item.name))
        .map((x) => ({ label: x.label, name: x.name })) ||
      [],
    [subTypeProps, formArrayField, skipPropNames]
  );
  const hasAdd = useMemo(
    () => hasRowAdd && (!isDefAndNotNull(subTypeProps?.hasRowAdd) || subTypeProps?.hasRowAdd),
    [subTypeProps, hasRowAdd]
  );
  const hasEdit = useMemo(
    () => hasRowEdit && (!isDefAndNotNull(subTypeProps?.hasRowEdit) || subTypeProps?.hasRowEdit),
    [subTypeProps, hasRowEdit]
  );
  const hasDelete = useMemo(
    () => hasRowDelete && (!isDefAndNotNull(subTypeProps?.hasRowAdd) || subTypeProps?.hasRowDelete),
    [subTypeProps, hasRowDelete]
  );

  const columns = useMemo(
    () =>
      columnsToRender
        .map((colDef) =>
          columnHelper.accessor(colDef.name, {
            cell: ({ row }) => {
              const value = extractValue(row.original[colDef.name]);
              return (
                <CellWrapper>
                  <CellValue>
                    <>{isDefAndNotNull(value) ? `${value}` : ''}</>
                  </CellValue>
                </CellWrapper>
              );
            },
            header: () => upperFirst(colDef.label || colDef.name),
            size: undefined,
          })
        )
        .concat(
          hasEdit || hasDelete
            ? [
                columnHelper.accessor('rowActions', {
                  cell: ({ row }) => {
                    return getRowReadonlyState(row) ? null : (
                      <CellActions>
                        {hasEdit && (
                          <StyledButton
                            size={Sizes.small}
                            styleType={StyleTypes.link}
                            leftIcon={{ size: 16, type: ICONS_TYPES.Pencil }}
                            onClick={handleRowEdit(row)}
                          />
                        )}
                        {hasDelete && (
                          <StyledButton
                            size={Sizes.small}
                            styleType={StyleTypes.link}
                            leftIcon={{ size: 16, type: ICONS_TYPES.TrashBin }}
                            onClick={handleConfirmRowDelete(row)}
                          />
                        )}
                      </CellActions>
                    );
                  },
                  header: () => '',
                  size: 70,
                }),
              ]
            : []
        ),
    [columnsToRender, hasEdit, hasDelete, columnHelper, getRowReadonlyState, handleRowEdit, handleConfirmRowDelete]
  );
  const arrayItemsFiltered = useMemo(() => {
    return arrayItems?.filter((item) => checkIfFormFieldsContainString(item, searchStr, ['id'])) || [];
  }, [arrayItems, searchStr]);
  const contextTable = useReactTable({
    columns,
    data: arrayItemsFiltered,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });
  const handleRowAdd = useCallback(() => {
    dispatch(modalOpen({ data: {}, id: submitRowModalId }));
  }, [dispatch, submitRowModalId]);
  const handleRowSubmit = useCallback(
    (newValue: FormFieldsWithId, prevValue: FormFieldsWithId | undefined) => {
      // TODO: remove id earlier than in this function
      const { id: idNew, ...otherNewValues } = newValue;
      if (isDef(onRowSubmit)) {
        onRowSubmit(otherNewValues, prevValue, submitRowModalId);
      } else {
        if (!prevValue) {
          append([otherNewValues as never]);
        } else {
          const index = arrayItems.findIndex((item) => item.id === newValue.id);
          if (index >= 0) {
            update(index, otherNewValues as never);
          }
        }
        dispatch(modalClose.request({ id: submitRowModalId }));
      }
    },
    [onRowSubmit, submitRowModalId, dispatch, append, arrayItems, update]
  );
  const handleRowCancel = useCallback(() => {
    dispatch(modalClose.request({ id: submitRowModalId }));
  }, [submitRowModalId, dispatch]);
  const entityName = useMemo(() => subTypeProps?.entityName || 'item', [subTypeProps]);
  const addButton = useMemo(
    () => (
      <Button
        label={`Add ${entityName}`}
        onClick={handleRowAdd}
        styleType={StyleTypes.filled}
        leftIcon={{ size: 16, type: ICONS_TYPES.Plus }}
      />
    ),
    [entityName, handleRowAdd]
  );

  return (
    <Root>
      {(hasSearch || hasAdd) && (
        <TopContainer isEndAligned={!hasSearch && hasAdd}>
          {hasSearch && (
            <StyledSearch
              backgroundColor="surfacePrimary"
              name="triggerTypeSearch"
              isSearch
              placeholder="Search"
              value={searchStr}
              onChange={handleSearchChanged}
            />
          )}
          {hasAdd && addButton}
        </TopContainer>
      )}
      <Table data={contextTable} bordered editable={false} onRowClick={onRowClick} actionButton={hasAdd && addButton} />
      <Modal<EditRowModel>
        id={submitRowModalId}
        renderTitle={(data) => `${isDef(data.row) ? 'Edit' : 'Add'} ${entityName}`}
        renderChildren={({ row }) => (
          <SchemaArrayRowForm
            model={row}
            submitting={rowSubmitting}
            onCancel={handleRowCancel}
            onSubmit={handleRowSubmit}
            schema={schema}
            skipPropNames={skipPropNames}
          />
        )}
      />
      <Modal<Row<FormFieldsWithId>>
        id={deleteRowModalId}
        confirm
        renderTitle={() => `Delete ${entityName}`}
        renderChildren={() => (
          <>
            Are you sure you want to delete <i>{entityName}</i>?
          </>
        )}
        confirmButtonProps={(data) => ({ label: 'Delete', onClick: handleRowDelete(data) })}
      />
    </Root>
  );
};

export default SchemaArray;
