export const getNameListFiltered = <T extends { name: string }>(list: T[], name: string) =>
  list.reduce(
    (memo, curr) => {
      if (curr.name !== name) {
        return memo.concat(curr.name);
      }
      return memo;
    },
    [] as T['name'][]
  );
