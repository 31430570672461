import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { Store, applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { routerMiddleware } from './router';

const initState = {};

function initializeStore(initialState = initState): Store {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware];

  const store: Store = createStore(
    rootReducer,
    initialState,
    composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = initializeStore(initState);

export { createReduxHistory } from './router';
