import React, { FC, useCallback, useMemo } from 'react';

import { ICONS_TYPES } from '../Icon';

import { CheckboxSizes } from './enums';
import {
  AttentionIcon,
  CheckBox,
  Container,
  ErrorMessage,
  ErrorMessageContainer,
  InputWrapper,
  Label,
  RequiredMark,
  Root,
  StyledInput,
} from './styles';
import { Props } from './types';

const Checkbox: FC<Props> = ({
  className,
  disabled,
  error,
  label,
  name,
  onChange,
  required,
  showErrorText = true,
  size = CheckboxSizes.medium,
  touched,
  value = false,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e, e.target.checked);
    },
    [onChange]
  );
  const showError = useMemo(() => error && touched && showErrorText && !disabled, [error, touched, showErrorText, disabled]);

  return (
    <Root className={className}>
      <Container>
        <InputWrapper disabled={disabled} tabIndex={0}>
          <CheckBox checked={value} disabled={disabled} showError={!!showError}>
            <Label>
              <StyledInput
                size={size}
                showError={!!showError}
                {...{
                  checked: value,
                  disabled: disabled,
                  name,
                  onChange: handleChange,
                  type: 'checkbox',
                }}
              />
              {label && required && !disabled ? (
                <span>
                  {label}
                  <RequiredMark>*</RequiredMark>
                </span>
              ) : (
                label
              )}
            </Label>
          </CheckBox>
        </InputWrapper>
        {showError && (
          <ErrorMessageContainer>
            <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </Container>
    </Root>
  );
};

export type { Props };

export default Checkbox;
