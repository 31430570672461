import { createSelector } from 'reselect';

import { RootState } from 'store/types';

import { FlowNavState } from './reducer';

const selectFlowNavState = (state: RootState) => state.components.flowNav;

export const selectSlide = (id: keyof FlowNavState) =>
  createSelector(selectFlowNavState, (flowNavState): FlowNavState['id'] => flowNavState[id]);
export const selectOpenedSlider = () =>
  createSelector(selectFlowNavState, (flowNavState) =>
    Object.fromEntries(Object.entries(flowNavState).filter((slide) => slide[1]?.open && !slide[1]?.closingClass))
  );
export const selectSlideIsAnyOpen = createSelector(
  selectFlowNavState,
  (flowNavState) => Object.keys(flowNavState).filter((key) => flowNavState[key]).length > 0
);
export const selectSlideIsOpen = (id: keyof FlowNavState) =>
  createSelector(selectSlide(id), (flowNavState) => flowNavState?.open);
export const selectSlideClosingClass = (id: keyof FlowNavState) =>
  createSelector(selectSlide(id), (flowNavState) => flowNavState?.closingClass);
