import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { VariableType } from 'models/Variable';
import VariableList from 'pages/components/VariableList';
import { tryFetchInitiativeVariables } from 'store/variables/actions';
import { selectInitiativeVariables, selectVariablesLoading } from 'store/variables/selectors';

import { Root } from './styles';
import { Props } from './types';

const InitiativeVariableList: FC<Props> = ({ className, initiativeName, onCancel }) => {
  const { flowName, projectName, sphereName } = useParams();
  const variableLoading = useSelector(selectVariablesLoading);
  const variables = useSelector(selectInitiativeVariables(sphereName!, projectName!, initiativeName));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tryFetchInitiativeVariables({ bp: initiativeName, project: projectName!, sphere: sphereName! }));
  }, [dispatch, sphereName, projectName, initiativeName]);

  return (
    <Root className={className}>
      <VariableList
        variableLoading={variableLoading}
        variables={variables}
        variableType={VariableType.Initiative}
        initiativeName={initiativeName}
        flowName={flowName!}
        onCancel={onCancel}
      />
    </Root>
  );
};

export default InitiativeVariableList;
