import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { THEME_MODE_LS_KEY } from 'mainConstants';
import { setThemeMode } from 'store/app/actions';
import { selectThemeMode } from 'store/app/selectors';
import { ThemeModes } from 'styles/enums';

import { ICONS_TYPES } from '../Icon';

import { Root, StyledIcon, SunIcon } from './styles';
import { Props } from './types';

const ThemeSwitcher: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(selectThemeMode);
  const handleSwitchThemeMode = useCallback(
    (mode: ThemeModes) => () => {
      dispatch(setThemeMode({ mode }));
      window.localStorage.setItem(THEME_MODE_LS_KEY, mode);
    },
    [dispatch]
  );

  return (
    <Root className={className}>
      <SunIcon
        icon={ICONS_TYPES.Sun}
        size={24}
        onClick={handleSwitchThemeMode(ThemeModes.light)}
        $active={themeMode === ThemeModes.light}
      />
      <StyledIcon
        icon={ICONS_TYPES.Moon}
        size={24}
        onClick={handleSwitchThemeMode(ThemeModes.dark)}
        $active={themeMode === ThemeModes.dark}
      />
    </Root>
  );
};

export type { Props };

export default ThemeSwitcher;
