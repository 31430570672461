import { ActionType, getType } from 'typesafe-actions';

import { CLOSING_CLASSNAME } from '../components/FlowNavSlider/constants';

import * as flowNavActions from './actions';

type FlowNavActionType = ActionType<typeof flowNavActions>;

export type FlowNavState = {
  [id: string]:
    | {
        closingClass?: string;
        open?: boolean;
      }
    | undefined;
};

const initialState: FlowNavState = {};

const flowNavReducer = (state: FlowNavState = initialState, action: FlowNavActionType): FlowNavState => {
  switch (action.type) {
    case getType(flowNavActions.slideOpen):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          closingClass: '',
          open: true,
        },
      };

    case getType(flowNavActions.setClosingClass):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          closingClass: CLOSING_CLASSNAME,
        },
      };
    case getType(flowNavActions.resetClosingClass):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          closingClass: '',
        },
      };

    case getType(flowNavActions.slideClose.success):
    case getType(flowNavActions.slideClose.failure):
      return {
        ...state,
        [action.payload.id]: undefined,
      };

    case getType(flowNavActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default flowNavReducer;
