import React, { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  FormFieldInputValueType,
  FormFieldSubTypes,
  FormFields,
  getFieldDatasourceName,
  getValidationRulesFromAField,
} from 'jsonSchema';
import { modalClose, modalOpen } from 'store/modal/actions';
import { isDef } from 'utils/def';

import { Sizes as ButtonSizes, StyleTypes } from '../Button';
import FormField from '../FormField';
import { FormFieldsType } from '../FormFieldRenderer/types';
import { ICONS_TYPES } from '../Icon';
import Modal, { Sizes } from '../Modal';

import FormExpressionModal from './components/FormExpressionModal';
import FormSourceArrayTable from './components/FormSourceArrayTable';
import { useGetFormSubTypeProps } from './hooks/useGetFormSubTypeProps';
import { useSetValuesFromSubTypeSelection } from './hooks/useSetValuesFromSubTypeSelection';
import { StyledButton, StyledInput } from './styles';
import { Props } from './types';

const FormInputRenderer: FC<Props> = ({ field }) => {
  const { control, setValue } = useFormContext<FormFieldsType>();
  const validationRules = useMemo(() => getValidationRulesFromAField(field), [field]);
  const dispatch = useDispatch();
  const isSubTypeField = useMemo(() => isDef(field.subType), [field.subType]);
  const subTypeModalId = useMemo(() => `${field.name}_${field.subType}`, [field]);
  const { invalidFields } = useGetFormSubTypeProps(field);
  const isSubTypeClickable = useMemo(() => isSubTypeField && !field.disabled, [field.disabled, isSubTypeField]);
  const handleEdit = useCallback(() => {
    if (invalidFields.length > 0) {
      toast.warning('Please select ' + invalidFields[0]);
      return;
    }
    if (isDef(field.subType)) {
      dispatch(modalOpen({ id: subTypeModalId }));
    }
  }, [dispatch, field, invalidFields, subTypeModalId]);
  const { setFormValues } = useSetValuesFromSubTypeSelection(field);
  const handleCancel = useCallback(() => {
    dispatch(modalClose.request({ id: subTypeModalId }));
  }, [subTypeModalId, dispatch]);
  const handleSelect = useCallback(
    (selection: FormFields[]) => {
      if (selection.length) {
        const [item] = selection;
        setFormValues(item);
        dispatch(modalClose.request({ id: subTypeModalId }));
      }
    },
    [setFormValues, dispatch, subTypeModalId]
  );
  const handleSubmit = useCallback(
    (value: string) => {
      setValue(field.name, value);
      dispatch(modalClose.request({ id: subTypeModalId }));
    },
    [setValue, field, dispatch, subTypeModalId]
  );

  return (
    <>
      <FormField
        {...validationRules}
        defaultValue={field.defaultValue}
        controllerProps={{
          control,
          name: field.name,
        }}
        render={(props) => (
          <StyledInput
            {...props}
            value={props.value as FormFieldInputValueType}
            label={field.label}
            secure={field.secure}
            numberType={field.numberType}
            showClear={!field.readonly}
            readOnly={field.readonly || isSubTypeField}
            disabled={field.disabled}
            onClick={isSubTypeClickable ? handleEdit : undefined}
            postfix={
              isSubTypeClickable ? (
                <StyledButton
                  size={ButtonSizes.small}
                  styleType={StyleTypes.link}
                  leftIcon={{ size: 16, type: ICONS_TYPES.Dots }}
                />
              ) : undefined
            }
            placeholder={field.placeholder}
          />
        )}
      />
      {field.subType === FormFieldSubTypes.selectDatasource && (
        <Modal
          id={subTypeModalId}
          size={Sizes.medium}
          onClose={handleCancel}
          renderTitle={() => getFieldDatasourceName(field)}
          renderChildren={() => <FormSourceArrayTable field={field} onSelect={handleSelect} onCancel={handleCancel} />}
        />
      )}
      {field.subType === FormFieldSubTypes.expressionInput && (
        <FormExpressionModal field={field} onCancel={handleCancel} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default FormInputRenderer;
