import { ActionType, getType } from 'typesafe-actions';

import * as rightPanelActions from './actions';

type RightPanelActionType = ActionType<typeof rightPanelActions>;

export type RightPanelState = {
  open: boolean;
};

const initialState: RightPanelState = {
  open: true,
};

const rightPanelReducer = (state: RightPanelState = initialState, action: RightPanelActionType): RightPanelState => {
  switch (action.type) {
    case getType(rightPanelActions.rightPanelToggle):
      return {
        open: !state.open,
      };

    case getType(rightPanelActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default rightPanelReducer;
