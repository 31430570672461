import { all, delay, put, takeEvery } from 'typed-redux-saga';

import { resetClosingClass, setClosingClass, slideClose } from './actions';
import { CLOSING_TIMEOUT_MS, CLOSING_TIMEOUT_OFFSET_MS } from './constants';

function* onSlideCloseRequest({ payload: { callBack, id } }: ReturnType<typeof slideClose.request>): Generator {
  yield put(setClosingClass({ id }));

  yield delay(CLOSING_TIMEOUT_MS - CLOSING_TIMEOUT_OFFSET_MS);
  yield put(resetClosingClass({ id }));

  yield put(slideClose.success({ id }));

  callBack?.();
}

function* flowNavSaga() {
  yield all([takeEvery(slideClose.request, onSlideCloseRequest)]);
}

export default flowNavSaga;
