import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { flexStyle, rgba, scrollStyle, textStyle } from 'styles/mixins';

import ExpandableView from '../ExpandableView';
import Icon from '../Icon';
import Input from '../Input';
import ThemeSwitcher from '../ThemeSwitcher';

import { Props } from './types';

export const Root = styled.div<Props>`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  ${scrollStyle()};
  width: 223px;
  padding-top: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const NavMenuContentScroll = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  ${scrollStyle()};
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  width: 100%;
  padding: 3px 0;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const NavMenuContentTop = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  padding: 0 16px;
  width: 100%;
`;
export const NavMenuContentBottom = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 24px 0;
  width: 100%;
  margin-top: auto;
`;
export const LogoText = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1.4, size: '22px', textAlign: 'left', weight: 400 })};
  font-family: Poppins, sans-serif;
  margin-bottom: 24px;
`;
export const StyledSearch = styled(Input)`
  margin-bottom: 24px;
`;
export const StyledThemeSwitcher = styled(ThemeSwitcher)`
  margin-top: auto;
  align-self: center;
`;
export const LinksContainer = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', wrap: 'nowrap' })};
  width: 100%;
  margin-bottom: 24px;
  padding-left: 16px;
`;
export const StyledLink = styled(NavLink)`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
  padding: 8px 0;
  padding-left: 16px;
  width: calc(100% + 32px);
  margin-left: -16px;

  &:hover,
  &.active {
    background-color: ${({ theme }) => rgba(theme.colors.primaryLight, 6)};
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const LinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 16px;
`;
export const LinkIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 8px;
`;
export const StyledDummyText = styled.span`
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 500 })};
  letter-spacing: 0.1px;
  padding: 0 16px;
`;
export const Divider = styled.div`
  width: calc(100% + 32px);
  margin: 24px 0;
  margin-left: -16px;
  height: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;
export const ExpandableContentContainer = styled.div`
  width: 100%;
`;
export const SphereIcon = styled(Icon)<{ color?: string }>`
  ${flexStyle({ align: 'center', justify: 'center', wrap: 'nowrap' })};
  color: ${({ theme }) => theme.colors.background};
  background-color: ${({ color, theme }) => color || theme.colors.textPrimary};
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
`;
export const StyledExpandableView = styled(ExpandableView)`
  padding-left: 16px;
`;
