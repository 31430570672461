import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';

import { setFlowsPreviewUrl } from 'components/FlowTabs/store/actions';
import { Modes } from 'components/Modal';

import API, { apiCallHandler } from 'api';
import { Log, LogTypes } from 'models/Log';
import { Step, getStepIdentifier } from 'models/Step';
import { getTriggerIdentifier } from 'models/Trigger/utils';
import { addLog } from 'store/logs/actions';
import { modalClose, modalOpen } from 'store/modal/actions';
import { selectModalIsOpen } from 'store/modal/selectors';
import { base64UrlToFile } from 'utils/converters';
import { isDefAndNotNull } from 'utils/def';

import {
  CHANGE_BRANCH_TYPE_MODAL_ID,
  DELETE_BRANCH_CONFIRM_MODAL_ID,
  DELETE_BRANCH_WAIT_NODE_MODAL_ID,
  SET_BRANCH_WAIT_NODE_MODAL_ID,
} from '../components/BranchEdge/constants';
import {
  CONFIG_STEP_MODAL_ID,
  DELETE_STEP_CONFIRM_MODAL_ID,
  STEP_MODAL_ID,
} from '../components/StepNode/components/StepCard/components/Actions/constants';
import {
  DELETE_ROOT_NODE_CONFIRM_MODAL_ID,
  UPDATE_SCHEMA_MODAL_ID,
} from '../components/StepNode/components/StepCard/components/DataMapperForm/constants';
import { FLOW_ADD_STEP_MODAL_ID } from '../components/StepNode/constants';
import {
  CONFIG_TRIGGER_MODAL_ID,
  DELETE_TRIGGER_CONFIRM_MODAL_ID,
  TRIGGER_MODAL_ID,
} from '../components/TriggerNode/components/TriggerCard/components/Actions/constants';
import { FLOW_ADD_TRIGGER_MODAL_ID } from '../components/TriggerNode/constants';
import { generateBase64Url } from '../utils';

import {
  createBranch,
  createStep,
  createTrigger,
  deleteBranch,
  deleteBranchWaitNode,
  fetchFlow,
  finishGoTo,
  removeStep,
  removeTrigger,
  runBuild,
  runDeploy,
  setFlowPreviewUrl,
  updateBranchType,
  updateBranchWaitNode,
  updateStep,
  updateStepConfig,
  updateStepDataMapperConfig,
  updateTrigger,
  updateTriggerConfig,
  uploadFlowPreview,
} from './actions';
import { selectFlow, selectFlowPreview, selectGoTo } from './selectors';

const BRANCH_TYPE_GOTO_ERROR_CODE = 98;

function* onFetchFlow({ payload }: ReturnType<typeof fetchFlow.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(fetchFlow.failure());
    },
    errMessageFallback: 'Failed to fetch flow',
    tryHandler: function* () {
      const {
        data: { data },
      } = yield* call(API.flows.fetchFlow, payload);

      yield put(fetchFlow.success({ flow: data.flow }));
    },
  });
}

function* onCreateTrigger({ payload }: ReturnType<typeof createTrigger.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createTrigger.failure());
    },
    errMessageFallback: 'Failed to create trigger',
    tryHandler: function* () {
      yield* call(API.triggers.createTrigger, payload);
      yield put(createTrigger.success());
      yield put(modalClose.request({ id: FLOW_ADD_TRIGGER_MODAL_ID }));
      yield put(
        modalOpen({ animated: true, id: `${TRIGGER_MODAL_ID}_${getTriggerIdentifier(payload.data)}`, mode: Modes.rightSlide })
      );
      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.data.name} was created successfully.`);
    },
  });
}

function* onUpdateTrigger({ payload }: ReturnType<typeof updateTrigger.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateTrigger.failure());
    },
    errMessageFallback: 'Failed to update trigger',
    tryHandler: function* () {
      yield* call(API.triggers.updateTrigger, payload);
      yield put(updateTrigger.success());
      yield put(modalClose.request({ id: `${TRIGGER_MODAL_ID}_${getTriggerIdentifier(payload.data)}` }));
      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.trigger} properties was updated successfully.`);
    },
  });
}

function* onUpdateTriggerConfig({ payload }: ReturnType<typeof updateTriggerConfig.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateTrigger.failure());
    },
    errMessageFallback: 'Failed to update trigger',
    tryHandler: function* () {
      yield* call(API.triggers.updateTriggerConfig, { ...payload, data: { config: payload.data.config } });

      yield put(updateTriggerConfig.success());
      yield put(
        modalClose.request({
          id: `${CONFIG_TRIGGER_MODAL_ID}_${getTriggerIdentifier({ name: payload.trigger, type: payload.data.type })}`,
        })
      );
      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.trigger} configuration was updated successfully.`);
    },
  });
}

function* onRemoveTrigger({ payload }: ReturnType<typeof removeTrigger.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(removeTrigger.failure());
    },
    errMessageFallback: 'Failed to remove triggers',
    tryHandler: function* () {
      yield* call(API.triggers.removeTrigger, payload);
      yield put(removeTrigger.success());
      yield put(modalClose.request({ id: `${DELETE_TRIGGER_CONFIRM_MODAL_ID}_${getTriggerIdentifier(payload.trigger)}` }));
      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.trigger.name} was removed successfully.`);
    },
  });
}

function* onCreateStep({ payload }: ReturnType<typeof createStep.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createStep.failure());
    },
    errMessageFallback: 'Failed to create step',
    tryHandler: function* () {
      const isAddStepModalOpen = yield* select(selectModalIsOpen(FLOW_ADD_STEP_MODAL_ID));
      const { source } = yield* select(selectGoTo);
      const isGoToActive = isDefAndNotNull(source);

      yield* call(API.steps.createStep, payload);
      yield put(createStep.success());

      if (isAddStepModalOpen) {
        yield put(modalClose.request({ id: FLOW_ADD_STEP_MODAL_ID }));
      }
      if (isGoToActive) {
        yield put(finishGoTo());
      }
      yield put(modalOpen({ animated: true, id: `${STEP_MODAL_ID}_${getStepIdentifier(payload.data)}`, mode: Modes.rightSlide }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.data.name} was created successfully.`);
    },
  });
}

function* onUpdateStep({ payload }: ReturnType<typeof updateStep.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateStep.failure());
    },
    errMessageFallback: 'Failed to update step',
    tryHandler: function* () {
      yield* call(API.steps.updateStep, payload);
      yield put(updateStep.success());

      if (!payload.ignoreValidation) {
        yield put(modalClose.request({ id: `${STEP_MODAL_ID}_${getStepIdentifier(payload.data)}` }));
      }

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.step} properties was updated successfully.`);
    },
  });
}

function* onUpdateStepConfig({ payload }: ReturnType<typeof updateStepConfig.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateStepConfig.failure());
    },
    errMessageFallback: 'Failed to update step',
    tryHandler: function* () {
      yield* call(API.steps.updateStepConfig, { ...payload, data: { config: payload.data.config } });
      yield put(updateStepConfig.success());

      if (!payload.ignoreValidation) {
        yield put(
          modalClose.request({
            id: `${CONFIG_STEP_MODAL_ID}_${getStepIdentifier({ name: payload.step, type: payload.data.type })}`,
          })
        );
      }

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.step} configuration was updated successfully.`);
    },
  });
}

function* onUpdateStepDataMapper({ payload }: ReturnType<typeof updateStepDataMapperConfig.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateStepDataMapperConfig.failure());
    },
    errMessageFallback: 'Failed to update Data Mapper',
    tryHandler: function* () {
      yield* call(API.steps.updateStepDataMapperConfig, payload);
      yield put(updateStepDataMapperConfig.success());

      if (!payload.ignoreValidation) {
        yield put(
          modalClose.request({
            id: `${payload.path}_${UPDATE_SCHEMA_MODAL_ID}`,
          })
        );
      }
      yield put(
        modalClose.request({
          id: `${payload.path}_${DELETE_ROOT_NODE_CONFIRM_MODAL_ID}`,
        })
      );

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.step} was updated successfully.`);
    },
  });
}

function* onCreateBranch({ payload }: ReturnType<typeof createBranch.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(createBranch.failure());
    },
    errMessageFallback: 'Failed to create branch',
    tryHandler: function* () {
      yield* call(API.branches.createBranch, payload);
      yield put(createBranch.success());

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* onUpdateBranchType({ payload }: ReturnType<typeof updateBranchType.request>): Generator {
  yield apiCallHandler<{ invalidGoto?: Step['name'][] }>({
    catchHandler: function* () {
      yield put(updateBranchType.failure());
    },
    errMessageFallback: ({ data: { data, result } }) => {
      if (result.code === BRANCH_TYPE_GOTO_ERROR_CODE) {
        return `
          Failed to change branch type. The following GoTos cannot be used within non Linear branch:
          ${data.invalidGoto?.reduce((memo, curr) => (memo ? `${memo}, ${curr}` : curr), '')}
        `;
      }

      return 'Failed to change branch type';
    },
    tryHandler: function* () {
      yield* call(API.branches.updateBranch, payload);
      yield put(updateBranchType.success());
      yield put(modalClose.request({ id: CHANGE_BRANCH_TYPE_MODAL_ID }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* onUpdateBranchWaitNode({ payload }: ReturnType<typeof updateBranchWaitNode.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(updateBranchWaitNode.failure());
    },
    errMessageFallback: 'Failed to set branch wait node',
    tryHandler: function* () {
      yield* call(API.branches.updateBranch, payload);
      yield put(updateBranchWaitNode.success());
      yield put(modalClose.request({ id: SET_BRANCH_WAIT_NODE_MODAL_ID }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* onDeleteBranch({ payload }: ReturnType<typeof deleteBranch.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteBranch.failure());
    },
    errMessageFallback: 'Failed to delete branch',
    tryHandler: function* () {
      yield* call(API.branches.deleteBranch, payload);
      yield put(deleteBranch.success());
      yield put(modalClose.request({ id: DELETE_BRANCH_CONFIRM_MODAL_ID }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* onDeleteBranchWaitNode({ payload }: ReturnType<typeof deleteBranchWaitNode.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(deleteBranchWaitNode.failure());
    },
    errMessageFallback: 'Failed to delete branch wait node',
    tryHandler: function* () {
      yield* call(API.branches.updateBranch, payload);
      yield put(deleteBranchWaitNode.success());
      yield put(modalClose.request({ id: DELETE_BRANCH_WAIT_NODE_MODAL_ID }));

      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );
    },
  });
}

function* onRemoveStep({ payload }: ReturnType<typeof removeStep.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(removeTrigger.failure());
    },
    errMessageFallback: 'Failed to remove step',
    tryHandler: function* () {
      yield* call(API.steps.removeStep, payload);
      yield put(removeStep.success());
      yield put(modalClose.request({ id: `${DELETE_STEP_CONFIRM_MODAL_ID}_${getStepIdentifier(payload.step)}` }));
      yield put(
        fetchFlow.request({
          bp: payload.bp,
          name: payload.flow,
          project: payload.project,
          sphere: payload.sphere,
        })
      );

      yield call(toast.success, `${payload.step.name} was removed successfully.`);
    },
  });
}

function* onRunBuild({ payload }: ReturnType<typeof runBuild.request>): Generator {
  yield apiCallHandler<{ logs: Log['data'] }>({
    catchHandler: function* ({
      data: {
        data: { logs },
      },
    }) {
      yield put(runBuild.failure());
      yield put(
        addLog({
          log: {
            data: logs,
            name: `Project: ${payload.sphere} / ${payload.project} • failed to build`,
            new: true,
            type: LogTypes.projectBuild,
          },
        })
      );
    },
    errMessageFallback: 'Failed to run build',
    tryHandler: function* () {
      const {
        data: {
          data: { logs, result },
        },
      } = yield* call(API.commands.runBuild, payload);
      yield put(runBuild.success());

      if (result) {
        yield put(
          addLog({
            log: {
              data: logs,
              name: `Project: ${payload.sphere} / ${payload.project} • built`,
              new: true,
              type: LogTypes.projectBuild,
            },
          })
        );
        yield call(toast.success, `The Project '${payload.project}' has been successfully built`);
      } else {
        yield put(
          addLog({
            log: {
              data: logs,
              name: `Project: ${payload.sphere} / ${payload.project} • failed to build`,
              new: true,
              type: LogTypes.projectBuild,
            },
          })
        );
        yield call(toast.error, `The Project '${payload.project}' has been unsuccessfully built`);
      }
    },
  });
}

function* onRunDeploy({ payload }: ReturnType<typeof runDeploy.request>): Generator {
  yield apiCallHandler<{ logs: Log['data'] }>({
    catchHandler: function* ({
      data: {
        data: { logs },
      },
    }) {
      yield put(runDeploy.failure());
      yield put(
        addLog({
          log: {
            data: logs,
            name: `Project: ${payload.sphere} / ${payload.project} • failed to deploy`,
            new: true,
            type: LogTypes.projectDeploy,
          },
        })
      );
    },
    errMessageFallback: 'Failed to run deploy',
    tryHandler: function* () {
      const {
        data: {
          data: { logs, result },
        },
      } = yield* call(API.commands.runDeploy, payload);
      yield put(runDeploy.success());
      if (result) {
        yield call(toast.success, `The Project '${payload.project}' has been successfully deployed`);
        yield put(
          addLog({
            log: {
              data: logs,
              name: `Project: ${payload.sphere} / ${payload.project} • deployed`,
              new: true,
              type: LogTypes.projectDeploy,
            },
          })
        );
      } else {
        yield call(toast.error, `The Project '${payload.project}' has been unsuccessfully deployed`);
        yield put(
          addLog({
            log: {
              data: logs,
              name: `Project: ${payload.sphere} / ${payload.project} • failed to deploy`,
              new: true,
              type: LogTypes.projectDeploy,
            },
          })
        );
      }
    },
  });
}

function* onSetFlowPreviewUrl({ payload }: ReturnType<typeof setFlowPreviewUrl.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(setFlowPreviewUrl.failure());
    },
    errMessageFallback: 'Failed to generate flow preview',
    tryHandler: function* () {
      const flow = yield* select(selectFlow);
      const { objectURL: oldObjectUrl } = yield* select(selectFlowPreview);
      const base64Url = yield* call(generateBase64Url, payload.nodes);

      if (base64Url) {
        const file = base64UrlToFile(base64Url, `${flow.name}_preview.png`);

        if (oldObjectUrl) {
          URL.revokeObjectURL(oldObjectUrl);
        }

        const objectURL = URL.createObjectURL(file);

        yield put(setFlowsPreviewUrl([{ flow: flow.name, previewObjectUrl: objectURL }]));
        yield put(setFlowPreviewUrl.success({ objectURL }));
      }
    },
  });
}

function* onUploadFlowPreview({ payload }: ReturnType<typeof uploadFlowPreview.request>): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(uploadFlowPreview.failure());
    },
    errMessageFallback: 'Failed to upload flow preview',
    tryHandler: function* () {
      const flow = yield* select(selectFlow);
      const { objectURL } = yield* select(selectFlowPreview);

      if (objectURL) {
        const response: Response = yield* call(fetch, objectURL);
        const blob: Blob = yield* call(() => response.blob());
        const file: File = new File([blob], `${flow.name}_preview.png`, { type: blob.type });
        // a case for initiative name being change on the fly
        // we need to look at live data instead store because initiative name change
        // requires page reload, and we call upload on unmount
        const livePathNameSplit = window.location.pathname.split('/');
        const liveInitiativeNameIndex = livePathNameSplit.findIndex((path) => path === 'initiative');
        const liveInitiativeName = livePathNameSplit[liveInitiativeNameIndex + 1];

        yield* call(API.flows.uploadFlowPreview, { ...payload, bp: liveInitiativeName }, file);

        yield put(uploadFlowPreview.success());
      }
    },
  });
}

function* flowSaga() {
  yield all([
    takeEvery(deleteBranch.request, onDeleteBranch),
    takeEvery(deleteBranchWaitNode.request, onDeleteBranchWaitNode),
    takeEvery(createBranch.request, onCreateBranch),
    takeEvery(updateBranchType.request, onUpdateBranchType),
    takeEvery(updateBranchWaitNode.request, onUpdateBranchWaitNode),
    takeEvery(createTrigger.request, onCreateTrigger),
    takeEvery(updateTrigger.request, onUpdateTrigger),
    takeEvery(updateTriggerConfig.request, onUpdateTriggerConfig),
    takeEvery(removeTrigger.request, onRemoveTrigger),
    takeEvery(fetchFlow.request, onFetchFlow),
    takeEvery(createStep.request, onCreateStep),
    takeEvery(updateStep.request, onUpdateStep),
    takeEvery(updateStepConfig.request, onUpdateStepConfig),
    takeEvery(uploadFlowPreview.request, onUploadFlowPreview),
    takeEvery(removeStep.request, onRemoveStep),
    takeEvery(runBuild.request, onRunBuild),
    takeEvery(runDeploy.request, onRunDeploy),
    takeEvery(setFlowPreviewUrl.request, onSetFlowPreviewUrl),
    takeEvery(updateStepDataMapperConfig.request, onUpdateStepDataMapper),
  ]);
}

export default flowSaga;
