import { createSelector } from 'reselect';

import { RootState } from 'store/types';

import { DevTestFormState } from './reducer';

const selectDevTestFormState = (state: RootState) => state.components.devTestForm;

export const selectPostLoading = createSelector(
  selectDevTestFormState,
  (devTestFormState: DevTestFormState) => devTestFormState.postLoading
);
