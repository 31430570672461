import React, { FC } from 'react';

const Files: FC = () => (
  <svg viewBox="0 0 22 22" fill="none">
    <path
      d="M13.7507 2.75V6.41667C13.7507 6.65978 13.8472 6.89294 14.0191 7.06485C14.191 7.23676 14.4242 7.33333 14.6673 7.33333H18.334M13.7507 2.75H10.084C9.59775 2.75 9.13144 2.94315 8.78762 3.28697C8.44381 3.63079 8.25065 4.0971 8.25065 4.58333V13.75C8.25065 14.2362 8.44381 14.7025 8.78762 15.0464C9.13144 15.3902 9.59775 15.5833 10.084 15.5833H16.5007C16.9869 15.5833 17.4532 15.3902 17.797 15.0464C18.1408 14.7025 18.334 14.2362 18.334 13.75V7.33333M13.7507 2.75L18.334 7.33333M14.6673 15.5833V17.4167C14.6673 17.9029 14.4742 18.3692 14.1303 18.713C13.7865 19.0568 13.3202 19.25 12.834 19.25H6.41732C5.93109 19.25 5.46477 19.0568 5.12096 18.713C4.77714 18.3692 4.58398 17.9029 4.58398 17.4167V8.25C4.58398 7.76377 4.77714 7.29745 5.12096 6.95364C5.46477 6.60982 5.93109 6.41667 6.41732 6.41667H8.25065"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Files;
