import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Project } from 'models/Project';
import { Service, ServiceWithFiles } from 'models/Service';
import { Sphere } from 'models/Sphere';
import { requestDataToFormData } from 'utils/converters';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'services';

export const fetchServices = (params?: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ services: Service[] }>>> =>
  request({
    endpoint,
    method: Methods.get,
    params,
  });

export const createService = (
  data: ServiceWithFiles & { project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.post,
  });

export const updateService = (
  data: ServiceWithFiles & { oldName: Service['name']; project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint,
    method: Methods.put,
  });

export const deleteService = (
  params: Pick<Service, 'name' | 'type'> & { project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.name}`,
    method: Methods.delete,
    params: omit(params, 'name'),
  });

export const callServiceAction = (
  data: ServiceWithFiles & { action: string; project: Project['name']; sphere: Sphere['name'] }
): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data: requestDataToFormData({ value: data }, data.files),
    endpoint: `${endpoint}/action`,
    method: Methods.post,
  });
