import { FC } from 'react';

const context = require.context('./components', true, /\.tsx$/) as __WebpackModuleApi.RequireContext;

export const ICON_COMPS: Record<string, FC> = context.keys().reduce(
  (memo, key) => ({
    ...memo,
    [key.replace(/\.\/|\.tsx/g, '')]: context(key).default,
  }),
  {}
);

export const ICONS_TYPES: Record<keyof typeof ICON_COMPS, keyof typeof ICON_COMPS> = {};
Object.keys(ICON_COMPS).forEach((item) => {
  ICONS_TYPES[item] = item;
});
