import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader, { Modes as LoaderModes } from 'components/Loader';

import useDeepCallback from 'hooks/useDeepCallback';
import { Service } from 'models/Service';
import { getServiceIdentifier } from 'models/Service/utils';
import { ServiceTypes } from 'models/ServiceType';
import { modalClose } from 'store/modal/actions';
import { callServiceAction, deleteService, updateService } from 'store/projectServices/actions';
import { selectProjectServices } from 'store/projectServices/selectors';
import { getNameListFiltered } from 'utils/getters';

import CreateServiceForm from '../CreateServiceForm';

import { CREATE_SERVICE_MODAL_ID } from './constants';
import { Description, LoaderContainer, Root, SectionContent, ServicesContainer, StyledModal, StyledServiceCard } from './styles';
import { Props } from './types';

const ServicesList: FC<Props> = ({ className, onCreate, projectName, sphereName }) => {
  const dispatch = useDispatch();
  const { flowName, initiativeName } = useParams();
  const {
    actionLoading,
    createLoading,
    deleteLoading,
    list: services,
    loading,
    updateLoading,
  } = useSelector(selectProjectServices);

  const handleCloseServiceModal = useCallback(() => {
    dispatch(modalClose.request({ id: CREATE_SERVICE_MODAL_ID }));
  }, [dispatch]);
  const handleUpdateService = useDeepCallback((oldName: Service['name'], data: Service) => {
    dispatch(
      updateService.request({
        data: {
          ...data,
          oldName: oldName,
          project: projectName,
          sphere: sphereName,
        },
        ...(data.type === ServiceTypes.REST
          ? {
              bp: initiativeName,
              flow: flowName,
            }
          : {}),
      })
    );
  });
  const handleCallServiceAction = useDeepCallback((oldData: Service, actionName: string) => {
    dispatch(
      callServiceAction.request({
        data: {
          ...oldData,
          action: actionName,
          oldName: oldData.name,
          project: projectName,
          sphere: sphereName,
        },
      })
    );
  });
  const handleDeleteService = useDeepCallback((oldName: Service['name'], data: Service) => {
    dispatch(
      deleteService.request({
        data: {
          name: oldName,
          project: projectName,
          sphere: sphereName,
          type: data.type,
        },
      })
    );
  });
  const getSelectedServiceNameList = useCallback((name: string) => getNameListFiltered<Service>(services, name), [services]);

  return (
    <Root>
      {loading ? (
        <LoaderContainer>
          <Loader mode={LoaderModes.default} />
        </LoaderContainer>
      ) : (
        <SectionContent className={className}>
          {services.length === 0 ? (
            <Description>There are no services for this area</Description>
          ) : (
            <ServicesContainer>
              {services.map((service) => (
                <StyledServiceCard
                  key={getServiceIdentifier(service)}
                  onChange={handleUpdateService(service.name)}
                  onRemove={handleDeleteService(service.name)}
                  onCallAction={handleCallServiceAction(service)}
                  serviceNameListToValidate={getSelectedServiceNameList(service.name)}
                  model={service}
                  updateLoading={updateLoading}
                  deleteLoading={deleteLoading}
                  actionLoading={actionLoading}
                />
              ))}
            </ServicesContainer>
          )}
          <StyledModal
            id={CREATE_SERVICE_MODAL_ID}
            renderTitle={() => 'Add Service'}
            renderChildren={() => (
              <CreateServiceForm
                onSubmit={onCreate}
                onCancel={handleCloseServiceModal}
                existingServicesNames={getSelectedServiceNameList('')}
                createLoading={createLoading}
              />
            )}
          />
        </SectionContent>
      )}
    </Root>
  );
};

export default ServicesList;
