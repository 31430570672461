import { Props as RestSelectProps } from 'react-select';
import { DefaultTheme } from 'styled-components';

import { rgba } from 'styles/mixins';

import { Props } from './types';

const reactSelectStyles = <Option, Multi extends boolean = false>({
  disabled,
  error,
  studioUITheme,
  styles,
  touched,
}: Partial<Props> & { studioUITheme: DefaultTheme } & RestSelectProps<Option, Multi>): RestSelectProps<
  Option,
  Multi
>['styles'] => ({
  container: (baseStyles, state) => ({
    ...baseStyles,
    pointerEvents: 'auto',
    width: '100%',
    ...styles?.container?.(baseStyles, state),
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    '&:hover': {
      border: `1px solid ${error && touched ? studioUITheme.colors.red : state.menuIsOpen ? studioUITheme.colors.primary : disabled ? studioUITheme.colors.divider : studioUITheme.colors.grey}`,
    },
    backgroundColor: studioUITheme.colors.background,
    border: `1px solid ${error && touched ? studioUITheme.colors.red : state.menuIsOpen ? studioUITheme.colors.primary : studioUITheme.colors.divider}`,
    borderRadius: '4px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : baseStyles.opacity,

    padding: `${state.isMulti && state.hasValue ? 4 : 9}px 16px`,
    ...styles?.control?.(baseStyles, state),
  }),
  indicatorSeparator: (baseStyles, state) => ({
    display: 'none',
    ...styles?.indicatorSeparator?.(baseStyles, state),
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    display: !disabled ? baseStyles.display : 'none',
    ...styles?.indicatorsContainer?.(baseStyles, state),
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: studioUITheme.colors.textSecondary,
    fontSize: '12px',
    height: '20px',
    letterSpacing: '0.5px',
    lineHeight: 1,
    ...styles?.input?.(baseStyles, state),
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: studioUITheme.colors.background,
    borderRadius: '4px',
    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.10)',
    padding: '8px 0',
    ...styles?.menu?.(baseStyles, state),
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: studioUITheme.colors.background,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    ...styles?.menuList?.(baseStyles, state),
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: '9999',
    ...styles?.menuPortal?.(baseStyles, state),
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    "& > div[role='button']": {
      display: disabled ? 'none' : 'flex',
    },
    alignItems: 'center',
    backgroundColor: studioUITheme.colors.primary,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    margin: 2,

    padding: '5px 12px',
    ...styles?.multiValue?.(baseStyles, state),
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: studioUITheme.colors.background,
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    ...styles?.multiValueLabel?.(baseStyles, state),
  }),
  noOptionsMessage: (baseStyles, state) => ({
    ...baseStyles,
    color: studioUITheme.colors.textTertiary,
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1,
    padding: '12px 16px',
    ...styles?.noOptionsMessage?.(baseStyles, state),
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    '&:hover': {
      backgroundColor: rgba(studioUITheme.colors.primaryLight, state.isSelected ? 10 : 6),
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${studioUITheme.colors.divider}`,
    },
    backgroundColor: state.isSelected ? rgba(studioUITheme.colors.primaryLight, 10) : 'transparent',
    color: studioUITheme.colors.textSecondary,
    cursor: 'pointer',
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: 1,

    padding: '12px 16px',

    whiteSpace: 'pre-wrap',
    ...styles?.option?.(baseStyles, state),
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: studioUITheme.colors.textTertiary,
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: 1.2,
    marginLeft: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    ...styles?.placeholder?.(baseStyles, state),
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: studioUITheme.colors.textSecondary,
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: 1.4,
    ...styles?.singleValue?.(baseStyles, state),
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    paddingLeft: 0,
    ...styles?.valueContainer?.(baseStyles, state),
  }),
});

export default reactSelectStyles;
