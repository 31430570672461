import { cloneDeep } from 'lodash';

import { Branch } from 'models/Branch';
import { Step, StepTypes } from 'models/Step';
import { isDefAndNotNull } from 'utils/def';

import {
  BRANCH_MARGIN_X,
  DEFAULT_STEP_NODE_ID,
  EDGE_DEFAULT_LENGTH_PX,
  ROOT_NODE_DEFAULT_OFFSET,
  ROOT_NODE_ID,
  STEP_TYPE_FILLER,
} from '../constants';
import { NodeTypes } from '../enums';
import { NodeDimension, StepWithPosition, XpiStudioNodes } from '../types';

import { flattenSteps } from './flattern';
import { generateStepDefaultNodeName } from './names';
import { isNodeStepDefaultType, isNodeStepTriggerType } from './types';

const addDefaultSteps = (steps: Step[], parentName?: string, branchName?: string): void => {
  const lastStep = steps[steps.length - 1];
  const branches = lastStep?.branches;

  if (lastStep?.type === StepTypes.goTo) return;

  if (steps.length === 0) {
    steps.push({ name: generateStepDefaultNodeName(parentName, branchName), type: DEFAULT_STEP_NODE_ID });
    return;
  }

  if (branches?.length) {
    for (let i = 0; i < branches.length; i++) {
      addDefaultSteps(branches[i].steps, lastStep?.name, branches[i].name);
    }
  } else {
    steps.push({ name: generateStepDefaultNodeName(lastStep?.name, branchName), type: DEFAULT_STEP_NODE_ID });
  }
};

const getBranchesFromSteps = (steps: Step[]): Branch[] => steps[steps.length - 1]?.branches || []; // branches exist only in last step
const getTreeDepth = (steps: Step[], level: number): number => {
  let depth = level + steps.length;
  const branches = getBranchesFromSteps(steps);

  if (branches.length !== 0) {
    for (let i = 0; i < branches.length; i++) {
      depth = Math.max(depth, getTreeDepth(branches[i].steps, level + steps.length));
    }
  }

  return depth;
};

const createLevels = (steps: Step[]): Step[][] => [...Array(getTreeDepth(steps, 0))].map(() => []);
const fillLevels = (steps: Step[], stepLevelsMutable: Step[][], level: number): void => {
  const branches = getBranchesFromSteps(steps);

  for (let i = 0; i < steps.length; i++) {
    stepLevelsMutable[level + i].push(steps[i]);
  }

  if (branches.length !== 0) {
    for (let i = 0; i < branches.length; i++) {
      fillLevels(branches[i].steps, stepLevelsMutable, level + steps.length);
    }
  } else {
    for (let i = level + steps.length; i < stepLevelsMutable.length; i++) {
      stepLevelsMutable[i].push({ name: STEP_TYPE_FILLER, type: STEP_TYPE_FILLER });
    }
  }
};

export const doNodesPositioning = (
  nodes: XpiStudioNodes,
  steps: Step[],
  nodesDimensionsParsed: NodeDimension[],
  rootCenter: number
): XpiStudioNodes => {
  const { height: triggerNodeHeight, width: triggerNodeWidth } =
    nodesDimensionsParsed.find((nd) => nd.type === NodeTypes.trigger) || {};
  const { height: stepNodeHeight, width: stepNodeWidth } = nodesDimensionsParsed.find((nd) => nd.type === NodeTypes.step) || {};
  const { width: stepDefaultNodeWidth } = nodesDimensionsParsed.find((nd) => nd.type === NodeTypes.stepDefault) || {};

  const stepsCloned = cloneDeep(steps);
  addDefaultSteps(stepsCloned, ROOT_NODE_ID);
  const stepLevels: StepWithPosition[][] = createLevels(stepsCloned);
  const lastLevel = stepLevels.length - 1;
  const flatSteps = flattenSteps(stepsCloned);
  fillLevels(stepsCloned, stepLevels, 0);

  if (stepLevels.length < 1) {
    return nodes; // empty tree
  }

  for (let i = 0; i < stepLevels[lastLevel].length; i++) {
    stepLevels[lastLevel][i].y = lastLevel;
    stepLevels[lastLevel][i].x = i;
  }

  for (let l = lastLevel - 1; l >= 0; l--) {
    let nextLevelCounter = 0;

    for (let i = 0; i < stepLevels[l].length; i++) {
      const curStep = stepLevels[l][i];
      curStep.y = l;

      if (!curStep.branches?.length) {
        curStep.x = stepLevels[l + 1][nextLevelCounter].x;
        nextLevelCounter += 1;
      } else {
        curStep.x =
          (stepLevels[l + 1][nextLevelCounter].x! + stepLevels[l + 1][nextLevelCounter + curStep.branches.length - 1].x!) / 2;
        nextLevelCounter += curStep.branches.length;
      }
    }
  }

  const triggerNodeX = rootCenter - triggerNodeWidth! / 2;
  const triggerNodeY = ROOT_NODE_DEFAULT_OFFSET;
  let nodesPositioned = nodes.map((node) => {
    if (isNodeStepTriggerType(node)) {
      return {
        ...node,
        position: {
          ...node.position,
          x: triggerNodeX,
          y: triggerNodeY,
        },
      };
    }

    return node;
  });

  if (steps.length !== 0) {
    nodesPositioned = nodesPositioned.map((node) => {
      if (!isNodeStepTriggerType(node)) {
        const { x: stepX, y: stepY } = flatSteps.find((step) => step.name === node.id) || {};

        if (
          isDefAndNotNull(stepX) &&
          isDefAndNotNull(stepY) &&
          isDefAndNotNull(stepNodeWidth) &&
          isDefAndNotNull(stepNodeHeight)
        ) {
          const startX = stepX * (stepNodeWidth + BRANCH_MARGIN_X);

          return {
            ...node,
            position: {
              ...node.position,
              x:
                isNodeStepDefaultType(node) && isDefAndNotNull(stepDefaultNodeWidth)
                  ? startX + (stepNodeWidth / 2 - stepDefaultNodeWidth / 2)
                  : startX,
              y: stepY * (stepNodeHeight + EDGE_DEFAULT_LENGTH_PX),
            },
          };
        }
      }

      return node;
    });
  } else {
    // only two nodes: trigger and step default
    nodesPositioned = nodesPositioned.map((node) => {
      if (isNodeStepDefaultType(node)) {
        return {
          ...node,
          position: {
            ...node.position,
            x: triggerNodeX + triggerNodeWidth! / 2 - stepDefaultNodeWidth! / 2,
            y: triggerNodeY + triggerNodeHeight! + EDGE_DEFAULT_LENGTH_PX,
          },
        };
      }

      return node;
    });
  }

  if (isDefAndNotNull(triggerNodeHeight) && isDefAndNotNull(triggerNodeWidth) && steps.length !== 0) {
    const triggerNode = nodesPositioned.find((n) => n.type === NodeTypes.trigger)!;
    const triggerNodeChild = nodesPositioned.find((n) => n.id === stepsCloned[0].name)!;
    const triggerNodeXDistance = triggerNodeChild.position.x - triggerNode.position.x;
    const triggerNodeYDistance =
      triggerNodeChild.position.y - (triggerNode.position.y + triggerNodeHeight + EDGE_DEFAULT_LENGTH_PX);

    return nodesPositioned.map((node) => {
      if (!isNodeStepTriggerType(node)) {
        return {
          ...node,
          position: {
            ...node.position,
            x: node.position.x - triggerNodeXDistance,
            y: node.position.y - triggerNodeYDistance,
          },
        };
      }

      return node;
    });
  }

  return nodesPositioned;
};
