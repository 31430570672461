import React, { FC } from 'react';

import { ErrorMessageContainer, Root } from './styles';
import { Props } from './types';

const ErrorFallback: FC<Props> = ({ error }) => {
  return (
    <Root>
      <ErrorMessageContainer>
        <h1>{error.message}</h1>
        <br />
        <br />
        {error.stack}
      </ErrorMessageContainer>
    </Root>
  );
};

export type { Props };
export default ErrorFallback;
