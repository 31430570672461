import { chunk } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ICONS_TYPES } from '../Icon';

import { DEFAULT_ITEMS_PER_PAGE } from './constants';
import { Modes } from './enums';
import {
  ListContainer,
  ListItemWrapper,
  PaginationContainer,
  PaginationControlContainer,
  PaginationControlIcon,
  PaginationControlText,
  PaginationControlTextActive,
  PaginationControlTextSlash,
  PaginationTotalMessage,
  Root,
} from './styles';
import { Props } from './types';

const PaginatedContainer = <ListItem,>({
  className,
  getListItemId,
  gridColumns = 3,
  gridGap = 16,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  list,
  mode = Modes.grid,
  renderListItem,
}: Props<ListItem>) => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const currentPageNumber = useMemo(() => currentPageIndex + 1, [currentPageIndex]);
  const listTotal = useMemo(() => list.length, [list]);
  const listChunks = useMemo(() => chunk<ListItem>(list, itemsPerPage), [list, itemsPerPage]);
  const pagesTotal = useMemo(() => Math.ceil(listTotal / itemsPerPage), [listTotal, itemsPerPage]);
  const currentPageListItems = useMemo(() => listChunks[currentPageIndex] || [], [listChunks, currentPageIndex]);
  const paginationTotalMessage = useMemo(() => {
    const maxItemsPerPage = itemsPerPage * currentPageNumber;
    return `Showing ${itemsPerPage * currentPageIndex + 1} - ${maxItemsPerPage > listTotal ? listTotal : maxItemsPerPage} out of ${listTotal}`;
  }, [itemsPerPage, currentPageIndex, currentPageNumber, listTotal]);

  const handleNextPage = useCallback(() => {
    setCurrentPageIndex((state) => state + 1);
  }, []);
  const handlePrevPage = useCallback(() => {
    setCurrentPageIndex((state) => state - 1);
  }, []);

  useEffect(() => {
    if (currentPageIndex !== 0 && currentPageListItems.length === 0) {
      handlePrevPage();
    }
  }, [currentPageListItems, currentPageIndex, handlePrevPage]);

  return (
    <Root className={className}>
      <ListContainer gridColumns={gridColumns} mode={mode} gridGap={gridGap}>
        {currentPageListItems.map((item, index) => (
          <ListItemWrapper key={getListItemId(item)}>
            {renderListItem(item, currentPageIndex * itemsPerPage + index)}
          </ListItemWrapper>
        ))}
      </ListContainer>
      {listTotal > itemsPerPage && (
        <PaginationContainer>
          <PaginationTotalMessage>{paginationTotalMessage}</PaginationTotalMessage>
          <PaginationControlContainer>
            <PaginationControlIcon
              onClick={handlePrevPage}
              icon={ICONS_TYPES.ChevronDown}
              size={24}
              rotate={90}
              disabled={currentPageIndex === 0}
            />
            <PaginationControlText>
              <PaginationControlTextActive>{currentPageNumber}</PaginationControlTextActive>
              <PaginationControlTextSlash>/</PaginationControlTextSlash>
              {pagesTotal}
            </PaginationControlText>
            <PaginationControlIcon
              onClick={handleNextPage}
              icon={ICONS_TYPES.ChevronDown}
              size={24}
              rotate={-90}
              disabled={currentPageNumber === pagesTotal}
            />
          </PaginationControlContainer>
        </PaginationContainer>
      )}
    </Root>
  );
};

export { Modes };
export type { Props };

export default PaginatedContainer;
