import React, { FC } from 'react';

const SiteMap: FC = () => (
  <svg viewBox="0 0 14 14" fill="none">
    <path
      d="M3.5 8.75V8.16667C3.5 7.85725 3.62292 7.5605 3.84171 7.34171C4.0605 7.12292 4.35725 7 4.66667 7H9.33333C9.64275 7 9.9395 7.12292 10.1583 7.34171C10.3771 7.5605 10.5 7.85725 10.5 8.16667V8.75M7 5.25V7M1.75 9.91667C1.75 9.60725 1.87292 9.3105 2.09171 9.09171C2.3105 8.87292 2.60725 8.75 2.91667 8.75H4.08333C4.39275 8.75 4.6895 8.87292 4.90829 9.09171C5.12708 9.3105 5.25 9.60725 5.25 9.91667V11.0833C5.25 11.3928 5.12708 11.6895 4.90829 11.9083C4.6895 12.1271 4.39275 12.25 4.08333 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V9.91667ZM8.75 9.91667C8.75 9.60725 8.87292 9.3105 9.09171 9.09171C9.3105 8.87292 9.60725 8.75 9.91667 8.75H11.0833C11.3928 8.75 11.6895 8.87292 11.9083 9.09171C12.1271 9.3105 12.25 9.60725 12.25 9.91667V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H9.91667C9.60725 12.25 9.3105 12.1271 9.09171 11.9083C8.87292 11.6895 8.75 11.3928 8.75 11.0833V9.91667ZM5.25 2.91667C5.25 2.60725 5.37292 2.3105 5.59171 2.09171C5.8105 1.87292 6.10725 1.75 6.41667 1.75H7.58333C7.89275 1.75 8.1895 1.87292 8.40829 2.09171C8.62708 2.3105 8.75 2.60725 8.75 2.91667V4.08333C8.75 4.39275 8.62708 4.6895 8.40829 4.90829C8.1895 5.12708 7.89275 5.25 7.58333 5.25H6.41667C6.10725 5.25 5.8105 5.12708 5.59171 4.90829C5.37292 4.6895 5.25 4.39275 5.25 4.08333V2.91667Z"
      stroke="currentColor"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SiteMap;
