import React, { FC, useMemo } from 'react';

import { isDefAndNotNull } from 'utils/def';

import { ICONS_TYPES } from '../Icon';
import Popover from '../Popover';

import { DotsIcon, MenuItem, MenuItemIcon, MenuItemLabel, MenuItemLoading, Root } from './styles';
import { Props } from './types';

const PopoverMenu: FC<Props> = ({ children, className, items, popoverProps }) => {
  const isAllHidden = useMemo(() => items.length && items.every((item) => item.hide), [items]);

  if (isAllHidden) {
    return null;
  }

  return (
    <Popover
      className={className}
      {...popoverProps}
      content={
        <Root>
          {items.length === 0 ? (
            <MenuItem>
              <MenuItemLabel>Empty list</MenuItemLabel>
            </MenuItem>
          ) : (
            items.map((item, key) => {
              if (item.hide) {
                return null;
              }

              return (
                <MenuItem key={key} active={item.active} onClick={item.onClick} clickable={!!item.onClick}>
                  {item.icon && (
                    <MenuItemIcon
                      color={item.iconColor}
                      backgroundColor={item.iconBackgroundColor}
                      icon={item.icon}
                      size={item.iconSize ?? 24}
                    />
                  )}
                  <MenuItemLabel color={item.labelColor}>
                    {item.loading ? <MenuItemLoading size={20} /> : item.label}
                  </MenuItemLabel>
                </MenuItem>
              );
            })
          )}
        </Root>
      }
      closeOnRootClick={isDefAndNotNull(popoverProps?.closeOnRootClick) ? popoverProps?.closeOnRootClick : false}
    >
      {children || <DotsIcon icon={ICONS_TYPES.Dots} size={24} />}
    </Popover>
  );
};

export * from './types';

export default PopoverMenu;
