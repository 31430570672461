import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { VariableType } from 'models/Variable';
import VariableList from 'pages/components/VariableList';
import { tryFetchFlowVariables } from 'store/variables/actions';
import { selectFlowVariables, selectVariablesLoading } from 'store/variables/selectors';

import { Root } from './styles';
import { Props } from './types';

const FlowVariableList: FC<Props> = ({ className, flowName, initiativeName, onCancel }) => {
  const { projectName, sphereName } = useParams();
  const variableLoading = useSelector(selectVariablesLoading);
  const variables = useSelector(selectFlowVariables(sphereName!, projectName!, initiativeName, flowName));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tryFetchFlowVariables({ bp: initiativeName, flow: flowName, project: projectName!, sphere: sphereName! }));
  }, [dispatch, sphereName, projectName, initiativeName, flowName]);

  return (
    <Root className={className}>
      <VariableList
        variableLoading={variableLoading}
        variables={variables}
        variableType={VariableType.Flow}
        initiativeName={initiativeName}
        flowName={flowName}
        onCancel={onCancel}
      />
    </Root>
  );
};

export default FlowVariableList;
