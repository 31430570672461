import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormExpressionInputTypes } from 'jsonSchema';
import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

export type ValidateExpressionRequestPayload = {
  bp: Initiative['name'];
  data: {
    expression: string;
    expressionType: FormExpressionInputTypes;
  };
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export type ValidateExpressionSuccessPayload = {
  validated: boolean;
};
export const validateExpression = createAsyncAction(
  'expressionForm/validateExpression/request',
  'expressionForm/validateExpression/success',
  'expressionForm/validateExpression/failure'
)<ValidateExpressionRequestPayload, ValidateExpressionSuccessPayload, undefined>();

export const reset = createAction('expressionInput/reset')();
