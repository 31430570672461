import React, { FC } from 'react';

const Jdbc: FC = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 6v6a8 3 0 0 0 16 0v-6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 12v6a8 3 0 0 0 16 0v-6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Jdbc;
