import styled from 'styled-components';

import Modal from 'components/Modal';
import { Content } from 'components/Modal/styles';

import { flexStyle, textStyle } from 'styles/mixins';

import ResourceCard from '../ResourceCard';

export const Root = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  height: 100%;
`;
export const SectionContent = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
`;
export const ResourcesContainer = styled.div`
  ${flexStyle({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
  flex: 1;
  width: 100%;
`;
export const StyledResourceCard = styled(ResourceCard)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
export const Description = styled.h1`
  ${textStyle({ color: 'textSecondary', lineHeight: 1.2, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.5px;
`;
export const LoaderContainer = styled.div`
  ${flexStyle({ align: 'center', direction: 'column', justify: 'center' })};
  flex: 1;
`;
export const StyledModal = styled(Modal)`
  & > ${Content} {
    min-height: 300px;
  }
`;
