import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import DraggableItem from 'components/DraggableItem';
import { Modes } from 'components/PaginatedContainer';

import { TriggerType } from 'models/TriggerType';
import { selectTriggerTypes } from 'store/app/selectors';

import TriggerTypeCard, { ViewModes } from '../TriggerTypeCard';

import { TRIGGER_EXPLORER_DND_TYPE } from './constants';
import { NoMatch, Root, StyledDragOverlay, StyledSearch, TriggerTypesContainer } from './styles';
import { Props } from './types';

const TriggerExplorer: FC<Props> = ({ className }) => {
  const [searchStr, setSearchStr] = useState('');
  const { list: triggerTypes } = useSelector(selectTriggerTypes);
  const filteredTriggerTypes = useMemo(
    () => triggerTypes.filter((t) => t.type.toLowerCase().includes(searchStr)),
    [triggerTypes, searchStr]
  );
  const handleSearchChanged = useCallback((value: string) => setSearchStr(value.toLowerCase()), [setSearchStr]);
  const [draggableItem, setDraggableItem] = useState<TriggerType | undefined>(undefined);
  const setIsDraggable = useCallback(
    (isDraggable: boolean, id: string) => {
      setDraggableItem(filteredTriggerTypes.find((t) => isDraggable && t.type === id));
    },
    [filteredTriggerTypes, setDraggableItem]
  );

  return (
    <Root className={className}>
      <StyledSearch
        backgroundColor="surfacePrimary"
        name="triggerTypeSearch"
        isSearch
        placeholder="Search"
        value={searchStr}
        onChange={handleSearchChanged}
      />
      <TriggerTypesContainer
        gridGap={8}
        mode={Modes.grid}
        gridColumns={1}
        list={filteredTriggerTypes}
        getListItemId={(triggerType) => triggerType.type}
        renderListItem={(triggerType) => (
          <DraggableItem
            id={triggerType.type}
            setIsDraggable={setIsDraggable}
            type={TRIGGER_EXPLORER_DND_TYPE}
            data={triggerType}
          >
            <TriggerTypeCard model={triggerType} viewMode={ViewModes.filled} />
          </DraggableItem>
        )}
      />
      {!filteredTriggerTypes.length && <NoMatch>No match found</NoMatch>}
      {createPortal(
        <StyledDragOverlay dropAnimation={null}>
          {draggableItem && <TriggerTypeCard model={draggableItem} viewMode={ViewModes.filled} />}
        </StyledDragOverlay>,
        document.body
      )}
    </Root>
  );
};

export default memo(TriggerExplorer);
