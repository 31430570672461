import React, { useCallback, useMemo } from 'react';
import ReactSelect, {
  ActionMeta,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
  Props as RestSelectProps,
  components as reactSelectComponents,
} from 'react-select';
import { useTheme } from 'styled-components';

import { buildDataCyValue } from 'utils/cy';

import { ICONS_TYPES } from '../Icon';

import reactSelectStyles from './reactSelectStyles';
import {
  AddNewIcon,
  AttentionIcon,
  CheckMarkIcon,
  ClearIcon,
  DropdownIcon,
  ErrorMessage,
  ErrorMessageContainer,
  Label,
  MultiValueRemoveIcon,
  RequiredMark,
  Root,
  StyledAddNewOption,
  StyledAddNewOptionContainer,
  StyledOption,
} from './styles';
import { Props } from './types';

const Select = <Option extends object, Multi extends boolean = false>({
  className,
  components,
  dataCy = {},
  disabled,
  error,
  isMulti,
  label,
  loading,
  name,
  onAdd,
  onChange,
  options,
  placeholder,
  readonly,
  required,
  showErrorText = true,
  styles,
  touched,
  ...rest
}: Props & RestSelectProps<Option, Multi>) => {
  const theme = useTheme();
  const disabledOrLoading = useMemo(() => disabled || loading, [disabled, loading]);
  const handleOnChange = useCallback(
    (value: OnChangeValue<Option, Multi>, actionMeta: ActionMeta<Option>) => {
      if (value && 'addNewOption' in Object(value)) {
        return;
      }

      onChange?.(value, actionMeta);
    },
    [onChange]
  );
  const DropdownIndicator = (props: DropdownIndicatorProps<Option, Multi>) => {
    return (
      <reactSelectComponents.DropdownIndicator {...props}>
        <DropdownIcon icon={ICONS_TYPES.ChevronDown} size={20} />
      </reactSelectComponents.DropdownIndicator>
    );
  };
  const ClearIndicator = (props: ClearIndicatorProps<Option, Multi>) => {
    return (
      <reactSelectComponents.ClearIndicator {...props}>
        <ClearIcon icon={ICONS_TYPES.XMark} size={20} />
      </reactSelectComponents.ClearIndicator>
    );
  };
  const MultiValueRemove = (props: MultiValueRemoveProps<Option, Multi>) => {
    return (
      <reactSelectComponents.MultiValueRemove {...props}>
        <MultiValueRemoveIcon icon={ICONS_TYPES.XMark} size={12} />
      </reactSelectComponents.MultiValueRemove>
    );
  };
  const Option = (props: OptionProps<Option, Multi>) => {
    if ('addNewOption' in props.data && typeof onAdd === 'function') {
      return (
        <StyledAddNewOptionContainer
          data-cy={buildDataCyValue({ elementName: 'add-new-option', fieldName: 'select' })}
          onClick={onAdd}
        >
          <reactSelectComponents.Option {...props}>
            <StyledAddNewOption>
              <AddNewIcon icon={ICONS_TYPES.Plus} size={18} /> Add new
            </StyledAddNewOption>
          </reactSelectComponents.Option>
        </StyledAddNewOptionContainer>
      );
    }

    return (
      <reactSelectComponents.Option {...props}>
        <StyledOption data-cy={buildDataCyValue({ elementName: 'option', fieldName: 'select' })}>
          <>
            {components?.Option ? <components.Option {...props} /> : props.label}
            {props.isSelected && <CheckMarkIcon icon={ICONS_TYPES.CheckMark} size={18} />}
          </>
        </StyledOption>
      </reactSelectComponents.Option>
    );
  };
  const getDataCy = useCallback(
    (elementName: keyof typeof dataCy) => dataCy?.[elementName] || buildDataCyValue({ elementName, fieldName: name }),
    [dataCy, name]
  );
  const extendedOptions = useMemo(() => {
    if (typeof onAdd === 'function' && Array.isArray(options)) {
      return [{ addNewOption: true } as Option].concat(...options);
    }

    return options;
  }, [options, onAdd]);

  return (
    <Root className={className} data-cy={getDataCy('root')}>
      {label && (
        <Label htmlFor={name} data-cy={getDataCy('label')}>
          {required && !disabledOrLoading ? (
            <span>
              {label}
              <RequiredMark>*</RequiredMark>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <ReactSelect
        isDisabled={disabled}
        isLoading={loading}
        placeholder={placeholder}
        menuIsOpen={readonly ? false : undefined}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        noOptionsMessage={() => 'Empty List'}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        unstyled
        styles={reactSelectStyles<Option, Multi>({
          disabled: disabledOrLoading,
          error,
          studioUITheme: theme,
          styles,
          touched,
        })}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValueRemove,
          ...components,
          Option,
        }}
        isClearable={false}
        options={extendedOptions}
        onChange={handleOnChange}
        {...rest}
      />
      {error && touched && showErrorText && (
        <ErrorMessageContainer>
          <AttentionIcon icon={ICONS_TYPES.Attention} size={20} />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </Root>
  );
};

export type { Props };

export default Select;
