import { all, put, takeEvery } from 'typed-redux-saga';

import { apiCallHandler } from 'api';

import { validateExpression } from './actions';

function* onValidateExpression(): Generator {
  yield apiCallHandler({
    catchHandler: function* () {
      yield put(validateExpression.failure());
    },
    errMessageFallback: 'Failed to validate expression',
    tryHandler: function* () {
      //TODO use "expression" and "expressionType" props from payload to decide where it should go to be validated
      yield put(validateExpression.success({ validated: true }));
    },
  });
}

function* expressionFormSaga() {
  yield all([takeEvery(validateExpression.request, onValidateExpression)]);
}

export default expressionFormSaga;
