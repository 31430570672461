import { createSelector } from 'reselect';

import { RootState } from '../types';

import { ModalState } from './reducer';

const selectModalState = (state: RootState) => state.modal;

export const selectModal = (id: keyof ModalState) =>
  createSelector(selectModalState, (modalState): ModalState['id'] => modalState[id]);
export const selectModalIsAnyOpen = createSelector(
  selectModalState,
  (modalState) => Object.keys(modalState).filter((key) => modalState[key]).length > 0
);
export const selectModalIsAnimated = (id: keyof ModalState) =>
  createSelector(selectModal(id), (modalState) => modalState?.animated);
export const selectModalIsOpen = (id: keyof ModalState) => createSelector(selectModal(id), (modalState) => modalState?.open);
export const selectModalData = (id: keyof ModalState) => createSelector(selectModal(id), (modalState) => modalState?.data);
export const selectModalMode = (id: keyof ModalState) => createSelector(selectModal(id), (modalState) => modalState?.mode);
export const selectModalClosingClass = (id: keyof ModalState) =>
  createSelector(selectModal(id), (modalState) => modalState?.closingClass);
