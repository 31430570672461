import * as branches from './branches';
import * as businessProcessVariables from './businessProcessVariables';
import * as businessProcesses from './businessProcesses';
import * as commands from './commands';
import * as contextVariables from './contextVariables';
import * as dev from './dev';
import * as flowVariables from './flowVariables';
import * as flows from './flows';
import * as globalVariables from './globalVariables';
import * as mapperTypes from './mapperTypes';
import * as projects from './projects';
import * as recents from './recents';
import * as resourceTypes from './resourceTypes';
import * as resources from './resources';
import * as schemas from './schemas';
import * as serviceTypes from './serviceTypes';
import * as services from './services';
import * as spheres from './spheres';
import * as stepTypes from './stepTypes';
import * as steps from './steps';
import * as tags from './tags';
import * as triggerTypes from './triggerTypes';
import * as triggers from './triggers';
import { APIResponse, WSResponse } from './types';

const API = {
  branches,
  businessProcessVariables,
  businessProcesses,
  commands,
  contextVariables,
  dev,
  flowVariables,
  flows,
  globalVariables,
  mapperTypes,
  projects,
  recents,
  resourceTypes,
  resources,
  schemas,
  serviceTypes,
  services,
  spheres,
  stepTypes,
  steps,
  tags,
  triggerTypes,
  triggers,
};

export * from './constants';
export * from './enums';
export * from './utils';
export type { APIResponse, WSResponse };

export default API;
