import { ActionType, getType } from 'typesafe-actions';

import { Initiative } from 'models/Initiative';

import * as initiativeActions from './actions';

type InitiativeActionType = ActionType<typeof initiativeActions>;

export type InitiativeState = {
  deleteInitiativeLoading: boolean;
  initiative: Initiative | undefined;
  initiativeLoading: boolean;
  updateInitiativeLoading: boolean;
};

const initialState: InitiativeState = {
  deleteInitiativeLoading: false,
  initiative: undefined,
  initiativeLoading: true,
  updateInitiativeLoading: false,
};

const initiativeReducer = (state: InitiativeState = initialState, action: InitiativeActionType): InitiativeState => {
  switch (action.type) {
    case getType(initiativeActions.fetchInitiative.success):
      return {
        ...state,
        initiative: action.payload.businessProcess,
        initiativeLoading: false,
      };
    case getType(initiativeActions.fetchInitiative.request):
      return {
        ...state,
        initiativeLoading: true,
      };
    case getType(initiativeActions.fetchInitiative.failure):
      return {
        ...state,
        initiativeLoading: false,
      };

    case getType(initiativeActions.deleteInitiative.request):
      return {
        ...state,
        deleteInitiativeLoading: true,
      };
    case getType(initiativeActions.deleteInitiative.success):
    case getType(initiativeActions.deleteInitiative.failure):
      return {
        ...state,
        deleteInitiativeLoading: false,
      };

    case getType(initiativeActions.updateInitiative.request):
      return {
        ...state,
        updateInitiativeLoading: true,
      };
    case getType(initiativeActions.updateInitiative.success):
    case getType(initiativeActions.updateInitiative.failure):
      return {
        ...state,
        updateInitiativeLoading: false,
      };

    case getType(initiativeActions.reset):
      return initialState;

    default:
      return state;
  }
};

export default initiativeReducer;
