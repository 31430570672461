import { createSelector } from 'reselect';

import { RootState } from 'store/types';

import { InitiativeState } from './reducer';

const selectInitiativeState = (state: RootState) => state.pages.initiative;

export const selectInitiative = createSelector(
  selectInitiativeState,
  (initiativeState: InitiativeState) => initiativeState.initiative
);
export const selectInitiativeLoading = createSelector(
  selectInitiativeState,
  (initiativeState: InitiativeState) => initiativeState.initiativeLoading
);
export const selectDeleteInitiativeLoading = createSelector(
  selectInitiativeState,
  (initiativeState: InitiativeState) => initiativeState.deleteInitiativeLoading
);
export const selectUpdateInitiativeLoading = createSelector(
  selectInitiativeState,
  (initiativeState: InitiativeState) => initiativeState.updateInitiativeLoading
);
