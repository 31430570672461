import { push } from 'redux-first-history';
import { all, call, put, takeEvery } from 'typed-redux-saga';

import API, { apiCallHandler } from 'api';
import { Log, LogTypes } from 'models/Log';
import { onFetchSpheres } from 'store/app/saga';
import { addLog } from 'store/logs/actions';
import { modalOpen } from 'store/modal/actions';

import { CREATE_SUCCESS_MODAL_ID } from '../../sphere/constants';

import { createSphere } from './actions';

function* onCreateSphere({ payload: { data } }: ReturnType<typeof createSphere.request>): Generator {
  yield apiCallHandler<{ logs: Log['data'] }>({
    catchHandler: function* ({
      data: {
        data: { logs },
      },
    }) {
      yield put(createSphere.failure());
      yield put(
        addLog({ log: { data: logs, name: `Sphere: ${data.name} • failed to create`, new: true, type: LogTypes.sphereCreate } })
      );
    },
    errMessageFallback: 'Failed to create sphere',
    tryHandler: function* () {
      const {
        data: {
          data: { logs, sphere },
        },
      } = yield* call(API.spheres.createSphere, data);
      yield onFetchSpheres();

      yield put(createSphere.success());
      yield put(addLog({ log: { data: logs, name: `Sphere: ${data.name} • created`, new: true, type: LogTypes.sphereCreate } }));
      yield put(push(`/spheres/${sphere.name}`));
      yield put(modalOpen({ id: CREATE_SUCCESS_MODAL_ID }));
    },
  });
}

function* sphereCreateSaga() {
  yield all([takeEvery(createSphere.request, onCreateSphere)]);
}

export default sphereCreateSaga;
