import { AxiosResponse } from 'axios';
import { omit } from 'lodash';

import { Log } from 'models/Log';
import { Project } from 'models/Project';
import { ProjectTree } from 'models/ProjectTree';
import { Sphere } from 'models/Sphere';
import { SphereProject } from 'models/SphereProject';
import { requestDataToFormData } from 'utils/converters';

import { Methods } from '../enums';
import { request } from '../request';
import { APIResponse } from '../types';

const endpoint = 'projects';

export const importProject = ({
  file,
  name,
  sphere,
}: {
  file: File;
  name?: string;
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ logs: Log['data']; project: SphereProject }>>> => {
  return request({
    data: requestDataToFormData({ key: 'sphere', value: sphere }, [{ file, id: name, name: name || '' }]),
    endpoint: `${endpoint}/import`,
    method: Methods.post,
  });
};

export const createProject = (data: {
  data: Pick<Project, 'name' | 'description' | 'version'>;
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ logs: Log['data'] }>>> =>
  request({
    data,
    endpoint,
    method: Methods.post,
  });

export const fetchProject = ({
  projectName,
  sphereName,
}: {
  projectName: Project['name'];
  sphereName: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ project: Project }>>> =>
  request({
    endpoint: `${endpoint}/${projectName}`,
    method: Methods.get,
    params: { sphere: sphereName },
  });

export const fetchProjectTree = ({
  projectName,
  sphereName,
}: {
  projectName: Project['name'];
  sphereName: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<{ project: ProjectTree }>>> =>
  request({
    endpoint: `${endpoint}/${projectName}/tree`,
    method: Methods.get,
    params: { sphere: sphereName },
  });

export const updateProject = (data: {
  data: Pick<Project, 'name' | 'description' | 'version'> & { oldName: Project['name'] };
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    data,
    endpoint,
    method: Methods.put,
  });

export const deleteProject = (params: {
  project: Project['name'];
  sphere: Sphere['name'];
}): Promise<AxiosResponse<APIResponse<object>>> =>
  request({
    endpoint: `${endpoint}/${params.project}`,
    method: Methods.delete,
    params: omit(params, 'project'),
  });
