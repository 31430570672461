import { FormFieldSubTypes, SchemaFieldString, extractValue } from 'jsonSchema';
import { isDef } from 'utils/def';

import { SchemaCustomBehaviourContext } from './types';
import { addRequiredField, getProperty, removeProperty } from './utils';

const DESTINATION = 'destination variable';
const DESTINATION_TYPE = 'destination variable type';
const SOURCE = 'source variable';
const SOURCE_TYPE = 'source variable type';
const EXPRESSION = 'source variable expression';
const IN_OUT = 'in/out';
const VARIABLE_DATA_TYPE = 'variableDataType';

export const invokeFlowArguments = ({ newValues, prevValues, schema }: SchemaCustomBehaviourContext) => {
  const direction = extractValue(newValues[IN_OUT]) as string;
  const prevDirection = extractValue(prevValues[IN_OUT]) as string;
  switch (direction) {
    case 'in': {
      removeProperty(schema, SOURCE);
      addRequiredField(schema, EXPRESSION);
      break;
    }
    case 'out':
    case 'both': {
      removeProperty(schema, EXPRESSION);
      addRequiredField(schema, SOURCE);
      break;
    }
  }

  if (['out', 'both'].includes(direction)) {
    if (newValues[DESTINATION_TYPE] !== prevValues[DESTINATION_TYPE] || prevDirection !== direction) {
      if (newValues[DESTINATION_TYPE] !== newValues[SOURCE_TYPE]) {
        newValues[SOURCE] = null;
        newValues[SOURCE_TYPE] = null;
        const source = getProperty<SchemaFieldString>(schema, SOURCE);
        const subTypeProps = source.behaviourHints?.subTypeProps[FormFieldSubTypes.selectDatasource];
        if (isDef(subTypeProps) && isDef(subTypeProps.dependencies)) {
          subTypeProps.dependencies[VARIABLE_DATA_TYPE] = { value: newValues[DESTINATION_TYPE] };
        }
      }
    } else {
      if (newValues[SOURCE_TYPE] !== prevValues[SOURCE_TYPE]) {
        if (newValues[DESTINATION_TYPE] !== newValues[SOURCE_TYPE]) {
          newValues[DESTINATION] = null;
          newValues[DESTINATION_TYPE] = null;
          const source = getProperty<SchemaFieldString>(schema, DESTINATION);
          const subTypeProps = source.behaviourHints?.subTypeProps[FormFieldSubTypes.selectDatasource];
          if (isDef(subTypeProps) && isDef(subTypeProps.dependencies)) {
            subTypeProps.dependencies[VARIABLE_DATA_TYPE] = { value: newValues[SOURCE_TYPE] };
          }
        }
      }
    }
  }

  return { updatedModel: newValues, updatedSchema: schema };
};
