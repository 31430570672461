import React, { FC } from 'react';

const Import: FC = () => (
  <svg viewBox="0 0 21 20" fill="none">
    <path
      d="M3.83203 14.1663V15.833C3.83203 16.275 4.00763 16.699 4.32019 17.0115C4.63275 17.3241 5.05667 17.4997 5.4987 17.4997H15.4987C15.9407 17.4997 16.3646 17.3241 16.6772 17.0115C16.9898 16.699 17.1654 16.275 17.1654 15.833V14.1663M6.33203 9.16634L10.4987 13.333M10.4987 13.333L14.6654 9.16634M10.4987 13.333V3.33301"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Import;
