import { toInteger } from 'lodash';

import API, { API_VERSION } from 'api';
import { isDefAndNotNull } from 'utils/def';

import { Initiative } from '../Initiative';
import { Project } from '../Project';
import { Sphere } from '../Sphere';

import { Flow } from './index';

export const getLastFlowNumber = (flows: Flow[]): number =>
  toInteger(
    flows
      ?.filter((flow) => flow.name.toLowerCase().includes('flow '))
      .reduce((maxNumber, flow) => Math.max(maxNumber, toInteger(flow.name.split(' ').at(-1))), 0)
  );

export const getFlowPreviewLink = ({
  flowName,
  initiativeName,
  projectName,
  sphereName,
}: {
  flowName?: Flow['name'];
  initiativeName?: Initiative['name'];
  projectName?: Project['name'];
  sphereName?: Sphere['name'];
}): string => {
  if (
    isDefAndNotNull(flowName) &&
    isDefAndNotNull(initiativeName) &&
    isDefAndNotNull(projectName) &&
    isDefAndNotNull(sphereName)
  ) {
    return encodeURI(
      `/api/v${API_VERSION}/${API.flows.endpoint}/${flowName}/thumbnail?sphere=${sphereName}&project=${projectName}&bp=${initiativeName}`
    );
  }

  throw new Error('Missing something from flow path');
};
