import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ArraySchema, FormFieldSubTypes, FormFields, getFieldDatasourceName } from 'jsonSchema';
import { modalClose, modalOpen } from 'store/modal/actions';
import { isDef } from 'utils/def';

import Button, { Sizes as ButtonSizes, StyleTypes } from '../Button';
import FormSourceArrayTable from '../FormInputRenderer/components/FormSourceArrayTable';
import { ICONS_TYPES } from '../Icon';
import SchemaArrayRenderer from '../JsonSchemaRenderer/components/SchemaArrayRenderer';
import Modal, { Sizes } from '../Modal';

import { useFormArrayDisplayValue } from './hooks/useFormArrayDisplayValue';
import { useTransformSubTypeSelection } from './hooks/useTransformSubTypeSelection';
import { ButtonsContainer, Root, StyledButton, StyledInput, SubmitButton } from './styles';
import { Props } from './types';

const FormArrayRenderer: FC<Props> = ({ field }) => {
  const { getFieldState, getValues, setValue } = useFormContext<FormFields>();
  const dispatch = useDispatch();
  const [fieldValue, setFieldValue] = useState<FormFields[]>([]);
  const subTypeModalId = useMemo(() => `${field.name}_${field.subType}`, [field]);
  const handleEdit = useCallback(() => {
    if (field.subType === FormFieldSubTypes.selectDatasource) {
      dispatch(modalOpen({ id: subTypeModalId }));
    } else {
      dispatch(modalOpen({ id: field.name }));
    }
  }, [dispatch, subTypeModalId, field]);
  const handleCancel = useCallback(() => {
    if (field.subType === FormFieldSubTypes.selectDatasource) {
      dispatch(modalClose.request({ id: subTypeModalId }));
    } else {
      setValue(field.name, fieldValue);
      dispatch(modalClose.request({ id: field.name }));
    }
  }, [dispatch, setValue, fieldValue, field, subTypeModalId]);
  const handleSubmit = useCallback(() => {
    setFieldValue(getValues(field.name) as FormFields[]);
    dispatch(modalClose.request({ id: field.name }));
  }, [dispatch, setFieldValue, field, getValues]);
  const { error } = getFieldState(field.name);
  const isDisabled = useMemo(() => isDef(error), [error]);
  const displayValue = useFormArrayDisplayValue(field, fieldValue);
  const { transformSelection } = useTransformSubTypeSelection(field);
  const handleSelect = useCallback(
    (selection: FormFields[]) => {
      if (selection.length) {
        setValue(field.name, transformSelection(selection), { shouldDirty: true });
        dispatch(modalClose.request({ id: subTypeModalId }));
      }
    },
    [setValue, field.name, transformSelection, dispatch, subTypeModalId]
  );
  const value = useWatch({ name: field.name });
  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <Root>
      <StyledInput
        value={displayValue}
        label={field.label}
        secure={field.secure}
        showClear={false}
        readOnly
        onClick={handleEdit}
        postfix={
          <StyledButton size={ButtonSizes.small} styleType={StyleTypes.link} leftIcon={{ size: 16, type: ICONS_TYPES.Dots }} />
        }
        name={field.name}
      />
      <Modal
        id={field.name}
        size={Sizes.medium}
        onClose={handleCancel}
        renderTitle={() => field.label}
        renderChildren={() => (
          <>
            <SchemaArrayRenderer schema={field.fieldSchema as ArraySchema} />
            <ButtonsContainer>
              <SubmitButton onClick={handleSubmit} disabled={isDisabled} label="Save" />
              <Button label="Cancel" onClick={handleCancel} styleType={StyleTypes.link} />
            </ButtonsContainer>
          </>
        )}
      />
      {field.subType === FormFieldSubTypes.selectDatasource && (
        <Modal
          id={subTypeModalId}
          size={Sizes.medium}
          onClose={handleCancel}
          renderTitle={() => getFieldDatasourceName(field)}
          renderChildren={() => <FormSourceArrayTable field={field} onSelect={handleSelect} onCancel={handleCancel} />}
        />
      )}
    </Root>
  );
};

export default FormArrayRenderer;
