import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader, { Modes as LoaderModes } from 'components/Loader';

import useDeepCallback from 'hooks/useDeepCallback';
import { Resource } from 'models/Resource';
import { getResourceIdentifier } from 'models/Resource/utils';
import { modalClose } from 'store/modal/actions';
import { deleteResource, updateResource, validateResource } from 'store/projectResources/actions';
import { selectProjectResources } from 'store/projectResources/selectors';
import { getNameListFiltered } from 'utils/getters';

import CreateResourceForm from '../CreateResourceForm';
import { ViewModes } from '../ResourceCard';

import { CREATE_RESOURCE_MODAL_ID } from './constant';
import {
  Description,
  LoaderContainer,
  ResourcesContainer,
  Root,
  SectionContent,
  StyledModal,
  StyledResourceCard,
} from './styles';
import { Props } from './types';

const ProjectResourcesList: FC<Props> = ({ bpName, className, flowName, onCreate, projectName, sphereName }) => {
  const dispatch = useDispatch();
  const {
    createLoading,
    deleteLoading,
    list: resources,
    loading,
    updateLoading,
    validateLoading,
  } = useSelector(selectProjectResources);

  const handleCloseResourceModal = useCallback(() => {
    dispatch(modalClose.request({ id: CREATE_RESOURCE_MODAL_ID }));
  }, [dispatch]);
  const handleUpdateResource = useDeepCallback((oldName: Resource['name'], data: Resource) => {
    dispatch(
      updateResource.request({
        data: {
          ...data,
          oldName: oldName,
          project: projectName,
          sphere: sphereName,
        },
      })
    );
  });
  const handleValidateResource = useDeepCallback((oldName: Resource['name'], data: Resource) => {
    dispatch(
      validateResource.request({
        bp: bpName,
        data: {
          ...data,
          oldName: oldName,
          project: projectName,
          sphere: sphereName,
        },
        flow: flowName,
      })
    );
  });
  const handleDeleteResource = useDeepCallback((oldName: Resource['name'], data: Resource) => {
    dispatch(
      deleteResource.request({
        data: {
          name: oldName,
          project: projectName,
          sphere: sphereName,
          type: data.type,
        },
      })
    );
  });
  const getSelectedResourceNameList = useCallback((name: string) => getNameListFiltered<Resource>(resources, name), [resources]);

  return (
    <Root>
      {loading ? (
        <LoaderContainer>
          <Loader mode={LoaderModes.default} />
        </LoaderContainer>
      ) : (
        <SectionContent className={className}>
          {resources.length === 0 ? (
            <Description>There are no resources for this area</Description>
          ) : (
            <ResourcesContainer>
              {resources.map((resource) => (
                <StyledResourceCard
                  key={getResourceIdentifier(resource)}
                  onChange={handleUpdateResource(resource.name)}
                  onRemove={handleDeleteResource(resource.name)}
                  onValidate={handleValidateResource(resource.name)}
                  resourceNameListToValidate={getSelectedResourceNameList(resource.name)}
                  model={resource}
                  viewMode={ViewModes.edit}
                  updateLoading={updateLoading}
                  deleteLoading={deleteLoading}
                  validateLoading={validateLoading}
                />
              ))}
            </ResourcesContainer>
          )}
          <StyledModal
            id={CREATE_RESOURCE_MODAL_ID}
            renderTitle={() => 'Add Resource'}
            renderChildren={() => (
              <CreateResourceForm
                onSubmit={onCreate}
                onCancel={handleCloseResourceModal}
                existingResourcesNames={getSelectedResourceNameList('')}
                createLoading={createLoading}
              />
            )}
          />
        </SectionContent>
      )}
    </Root>
  );
};

export default ProjectResourcesList;
