import React, { FC, memo, useCallback } from 'react';

import { ViewModes } from './enums';
import { RootFilled, RootOutlined, TriggerContainer, TriggerIcon, TriggerIconContainer, TriggerName } from './styles';
import { Props } from './types';

const TriggerTypeCard: FC<Props> = ({ className, model, onClick = () => {}, viewMode = ViewModes.outlined }) => {
  const handleClick = useCallback(() => onClick(model), [model, onClick]);

  return (
    <>
      {viewMode === ViewModes.outlined ? (
        <RootOutlined className={className} onClick={handleClick}>
          <TriggerContainer>
            <TriggerIconContainer>
              <TriggerIcon model={model.icon} />
            </TriggerIconContainer>
            <TriggerName>{model.type}</TriggerName>
          </TriggerContainer>
        </RootOutlined>
      ) : (
        <RootFilled className={className} onClick={handleClick}>
          <TriggerContainer>
            <TriggerIconContainer>
              <TriggerIcon model={model.icon} />
            </TriggerIconContainer>
            <TriggerName>{model.type}</TriggerName>
          </TriggerContainer>
        </RootFilled>
      )}
    </>
  );
};

export { ViewModes };

export default memo(TriggerTypeCard);
