import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

export const Root = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const BreadCrumbTextActive = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
`;
export const BreadCrumbTextInactive = styled.span`
  color: ${({ theme }) => theme.colors.textTertiary};
  font-weight: 400;
`;
export const BreadCrumbText = styled.span`
  ${textStyle({ color: 'textPrimary', size: '12px', textAlign: 'left', weight: 500 })};
  letter-spacing: 0.1px;
  line-height: 1;
`;
export const BreadCrumbLink = styled(Link)`
  ${textStyle({ lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.1px;
`;
export const BreadCrumbsDelimiter = styled.span`
  ${textStyle({ color: 'textTertiary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.1px;
  padding-left: 8px;
  padding-right: 8px;
`;
