import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button, { StyleTypes } from 'components/Button';
import FormField from 'components/FormField';
import FormFieldRenderer from 'components/FormFieldRenderer';

import { FormFields, extractFiles, formatBeforeSubmit, normalizeFields, schemaToFormConfig } from 'jsonSchema';
import { isDefAndNotNull } from 'utils/def';

import { RESOURCE_NAME_UNIQ_ERROR_TEXT } from './constants';
import {
  ButtonsContainer,
  FormFieldsContainer,
  ResourceDescription,
  ResourceIcon,
  ResourceInfoContainer,
  ResourceName,
  ResourceTextContainer,
  Root,
  StyledInput,
  SubmitButton,
  SubmitButtonsContainer,
} from './styles';
import { Props, ResourceFormFields } from './types';

const ResourceForm: FC<Props> = ({
  cancelOnSubmit,
  className,
  createLoading,
  model,
  onCancel,
  onSubmit,
  onValidate,
  resourceNameListToValidate,
  type,
  updateLoading,
  validateLoading,
}) => {
  const memoResourceFormConfig = useMemo(() => schemaToFormConfig(type.schema), [type.schema]);
  const formDefaultValues = useMemo(
    () => ({
      name: model.name,
      ...normalizeFields(model.data, memoResourceFormConfig),
    }),
    [memoResourceFormConfig, model]
  );
  const methods = useForm<ResourceFormFields>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const { control, formState, getValues } = methods;
  const isDisabled = useMemo(() => !formState.isValid || !formState.isDirty, [formState.isValid, formState.isDirty]);
  const loading = useMemo(() => createLoading || updateLoading, [createLoading, updateLoading]);

  const handleSubmit = useCallback(() => {
    const { name, ...formFields } = getValues();
    const fields = formatBeforeSubmit(formFields, memoResourceFormConfig) as FormFields;
    const { fields: fieldsWithoutFiles, files } = extractFiles(fields);
    onSubmit({
      data: fieldsWithoutFiles,
      files,
      isValidated: undefined,
      name,
      type: model.type,
    });

    if (cancelOnSubmit) {
      onCancel();
    }
  }, [cancelOnSubmit, getValues, memoResourceFormConfig, model.type, onCancel, onSubmit]);
  const handleValidate = useCallback(() => {
    if (isDefAndNotNull(onValidate)) {
      const { name, ...formFields } = getValues();
      onValidate({
        data: formatBeforeSubmit(formFields, memoResourceFormConfig) as FormFields,
        isValidated: undefined,
        name,
        type: model.type,
      });
    }
  }, [getValues, memoResourceFormConfig, model.type, onValidate]);

  const handleNameValidate = useCallback(
    (value: ResourceFormFields['name']) =>
      resourceNameListToValidate.some((rName) => rName === value?.trim()) ? RESOURCE_NAME_UNIQ_ERROR_TEXT : undefined,
    [resourceNameListToValidate]
  );

  useEffect(() => {
    methods.reset(formDefaultValues);
  }, [type, methods, formDefaultValues]);

  return (
    <Root className={className}>
      <ResourceInfoContainer>
        <ResourceIcon model={type.icon} />
        <ResourceTextContainer>
          <ResourceName>{model.name || model.type}</ResourceName>
          <ResourceDescription>{type.schema.description}</ResourceDescription>
        </ResourceTextContainer>
      </ResourceInfoContainer>
      <FormFieldsContainer>
        <FormField<ResourceFormFields, 'name'>
          required
          maxLength={50}
          controllerProps={{
            control,
            name: 'name',
            rules: {
              validate: handleNameValidate,
            },
          }}
          render={(props) => <StyledInput {...props} label="Name" />}
        />
        <FormProvider {...methods}>
          {memoResourceFormConfig.map((field) => {
            return <FormFieldRenderer key={field.name} field={field} />;
          })}
        </FormProvider>
      </FormFieldsContainer>
      <ButtonsContainer>
        <SubmitButtonsContainer>
          <SubmitButton onClick={handleSubmit} disabled={isDisabled} loading={loading} label="Save Resource" />
          {type.hasValidation && isDefAndNotNull(onValidate) && (
            <Button label="Validate" onClick={handleValidate} loading={validateLoading} styleType={StyleTypes.outlined} />
          )}
        </SubmitButtonsContainer>
        <Button label="Cancel" onClick={onCancel} styleType={StyleTypes.link} />
      </ButtonsContainer>
    </Root>
  );
};

export default ResourceForm;
