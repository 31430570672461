import React, { FC, useState } from 'react';

import useDeepCallback from 'hooks/useDeepCallback';

import { ICONS_TYPES } from '../../../Icon';

import { ArrowContainer, ArrowIcon, ChildContainer, NodeContainer, Root, TitleContainer } from './styles';
import { Props } from './types';

const TreeNode: FC<Props> = ({ className, level, node, renderNode }) => {
  const [expanded, setExpanded] = useState(true);
  const handleArrowClick = useDeepCallback((value: boolean) => {
    setExpanded(value);
  });
  return (
    <Root className={className}>
      <TitleContainer level={level}>
        <ArrowContainer onClick={handleArrowClick(!expanded)}>
          {!!node.children?.length && <ArrowIcon icon={ICONS_TYPES.ChevronDown} size={14} rotate={expanded ? undefined : -90} />}
        </ArrowContainer>
        <NodeContainer>{renderNode(node)}</NodeContainer>
      </TitleContainer>
      {expanded && (
        <ChildContainer>
          {node.children?.map((n) => (
            <TreeNode className={className} key={n.index} node={n} renderNode={renderNode} level={level + 1} />
          ))}
        </ChildContainer>
      )}
    </Root>
  );
};

export default TreeNode;
