import { createAction, createAsyncAction } from 'typesafe-actions';

import { Project } from 'models/Project';
import { Resource } from 'models/Resource';
import { Sphere } from 'models/Sphere';
import { Tag } from 'models/Tag';

export type FetchSphereRequestPayload = {
  sphereName: Sphere['name'];
};
export type FetchSphereSuccessPayload = {
  sphere: Sphere<true>;
};
export const fetchSphere = createAsyncAction(
  'sphere/fetchSphere/request',
  'sphere/fetchSphere/success',
  'sphere/fetchSphere/failure'
)<FetchSphereRequestPayload, FetchSphereSuccessPayload, undefined>();

export type UpdateSphereRequestPayload = {
  data: Pick<Sphere, 'name' | 'color' | 'access'> & { oldName: Sphere['name'] };
};
export const updateSphere = createAsyncAction(
  'sphere/updateSphere/request',
  'sphere/updateSphere/success',
  'sphere/updateSphere/failure'
)<UpdateSphereRequestPayload, undefined, undefined>();

export type DeleteSphereRequestPayload = {
  sphereName: Sphere['name'];
};
export const deleteSphere = createAsyncAction(
  'sphere/deleteSphere/request',
  'sphere/deleteSphere/success',
  'sphere/deleteSphere/failure'
)<DeleteSphereRequestPayload, undefined, undefined>();

export type ImportProjectRequestPayload = {
  data: {
    file: File;
    name?: string;
    sphere: Sphere['name'];
  };
};
export const importProject = createAsyncAction(
  'sphere/importProject/request',
  'sphere/importProject/success',
  'sphere/importProject/failure'
)<ImportProjectRequestPayload, undefined, undefined>();

export type CreateProjectRequestPayload = {
  data: { data: Pick<Project, 'name' | 'description' | 'version'>; sphere: Sphere['name'] };
};
export const createProject = createAsyncAction(
  'sphere/createProject/request',
  'sphere/createProject/success',
  'sphere/createProject/failure'
)<CreateProjectRequestPayload, undefined, undefined>();

export type FetchResourcesRequestPayload = {
  sphereName: Sphere['name'];
};
export type FetchResourcesSuccessPayload = {
  list: Resource[];
};
export const fetchResources = createAsyncAction(
  'sphere/fetchResources/request',
  'sphere/fetchResources/success',
  'sphere/fetchResources/failure'
)<FetchResourcesRequestPayload, FetchResourcesSuccessPayload, undefined>();

export type CreateResourceRequestPayload = {
  data: Resource & { sphere: Sphere['name'] };
};
export const createResource = createAsyncAction(
  'sphere/createResource/request',
  'sphere/createResource/success',
  'sphere/createResource/failure'
)<CreateResourceRequestPayload, undefined, undefined>();

export type UpdateResourceRequestPayload = {
  data: Resource & { oldName: Resource['name']; sphere: Sphere['name'] };
};
export const updateResource = createAsyncAction(
  'sphere/updateResource/request',
  'sphere/updateResource/success',
  'sphere/updateResource/failure'
)<UpdateResourceRequestPayload, undefined, undefined>();

export type ValidateResourceRequestPayload = {
  data: Resource & { oldName: Resource['name']; sphere: Sphere['name'] };
};
export const validateResource = createAsyncAction(
  'sphere/validateResource/request',
  'sphere/validateResource/success',
  'sphere/validateResource/failure'
)<ValidateResourceRequestPayload, undefined, undefined>();

export type DeleteResourceRequestPayload = {
  data: Pick<Resource, 'name' | 'type'> & { sphere: Sphere['name'] };
};
export const deleteResource = createAsyncAction(
  'sphere/deleteResource/request',
  'sphere/deleteResource/success',
  'sphere/deleteResource/failure'
)<DeleteResourceRequestPayload, undefined, undefined>();

export type FetchTagsRequestPayload = {
  sphereName: Sphere['name'];
};
export type FetchTagsSuccessPayload = {
  list: Tag[];
};
export const fetchTags = createAsyncAction('sphere/fetchTags/request', 'sphere/fetchTags/success', 'sphere/fetchTags/failure')<
  FetchTagsRequestPayload,
  FetchTagsSuccessPayload,
  undefined
>();

export type CreateTagRequestPayload = {
  data: { data: Tag; sphere: Sphere['name'] };
};
export const createTag = createAsyncAction('sphere/createTag/request', 'sphere/createTag/success', 'sphere/createTag/failure')<
  CreateTagRequestPayload,
  undefined,
  undefined
>();

export type UpdateTagRequestPayload = {
  data: { data: Tag & { oldName: Tag['name'] }; sphere: Sphere['name'] };
};
export const updateTag = createAsyncAction('sphere/updateTag/request', 'sphere/updateTag/success', 'sphere/updateTag/failure')<
  UpdateTagRequestPayload,
  undefined,
  undefined
>();

export type DeleteTagRequestPayload = {
  data: Tag & { sphere: Sphere['name'] };
};
export const deleteTag = createAsyncAction('sphere/deleteTag/request', 'sphere/deleteTag/success', 'sphere/deleteTag/failure')<
  DeleteTagRequestPayload,
  undefined,
  undefined
>();

export const reset = createAction('sphere/reset')();
