import { createAsyncAction } from 'typesafe-actions';

import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';

export type PostDevPayload = {
  bp: Initiative['name'];
  data: {
    text: string;
  };
  flow: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
};
export const postDev = createAsyncAction(
  'devTestFrom/postDev/request',
  'devTestFrom/postDev/success',
  'devTestFrom/postDev/failure'
)<PostDevPayload, undefined, undefined>();
