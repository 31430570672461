import { ActionType, getType } from 'typesafe-actions';

import { Variable } from 'models/Variable';

import * as variablesActions from './actions';
import {
  createVariable,
  deleteVariable,
  fetchContextVariables,
  fetchFlowVariables,
  fetchGlobalVariables,
  fetchInitiativeVariables,
  updateVariable,
} from './actions';
import { getContextVariablesKey, getFlowVariablesKey, getGlobalVariablesKey, getInitiativeVariablesKey } from './utils';

type VariablesActionType = ActionType<typeof variablesActions>;

export type VariablesState = {
  contextVariables: Record<string, Variable[] | undefined>;
  contextVariablesLoading: boolean;
  globalVariables: Record<string, Variable[] | undefined>;
  globalVariablesLoading: boolean;
  variableCreating: boolean;
  variableDeleting: boolean;
  variableUpdating: boolean;
  variables: Record<string, Variable[] | undefined>;
  variablesLoading: boolean;
};

const initialState: VariablesState = {
  contextVariables: {},
  contextVariablesLoading: false,
  globalVariables: {},
  globalVariablesLoading: false,
  variableCreating: false,
  variableDeleting: false,
  variableUpdating: false,
  variables: {},
  variablesLoading: false,
};

const variablesReducer = (state: VariablesState = initialState, action: VariablesActionType): VariablesState => {
  switch (action.type) {
    case getType(fetchGlobalVariables.request): {
      return {
        ...state,
        globalVariablesLoading: true,
      };
    }
    case getType(fetchGlobalVariables.failure): {
      return {
        ...state,
        globalVariablesLoading: false,
      };
    }
    case getType(fetchGlobalVariables.success): {
      return {
        ...state,
        globalVariables: {
          ...state.globalVariables,
          [getGlobalVariablesKey(action.payload.sphere, action.payload.project)]: action.payload.variables,
        },
        globalVariablesLoading: false,
      };
    }

    case getType(fetchContextVariables.request): {
      return {
        ...state,
        contextVariablesLoading: true,
      };
    }
    case getType(fetchContextVariables.failure): {
      return {
        ...state,
        contextVariablesLoading: false,
      };
    }
    case getType(fetchContextVariables.success): {
      return {
        ...state,
        contextVariables: {
          ...state.contextVariables,
          [getContextVariablesKey(action.payload.sphere, action.payload.project)]: action.payload.variables,
        },
        contextVariablesLoading: false,
      };
    }

    case getType(fetchInitiativeVariables.request): {
      return {
        ...state,
        variablesLoading: true,
      };
    }
    case getType(fetchInitiativeVariables.failure): {
      return {
        ...state,
        variablesLoading: false,
      };
    }
    case getType(fetchInitiativeVariables.success): {
      return {
        ...state,
        variables: {
          ...state.variables,
          [getInitiativeVariablesKey(action.payload.sphere, action.payload.project, action.payload.bp)]: action.payload.variables,
        },
        variablesLoading: false,
      };
    }

    case getType(fetchFlowVariables.request): {
      return {
        ...state,
        variablesLoading: true,
      };
    }
    case getType(fetchFlowVariables.failure): {
      return {
        ...state,
        variablesLoading: false,
      };
    }
    case getType(fetchFlowVariables.success): {
      return {
        ...state,
        variables: {
          ...state.variables,
          [getFlowVariablesKey(action.payload.sphere, action.payload.project, action.payload.bp, action.payload.flow)]:
            action.payload.variables,
        },
        variablesLoading: false,
      };
    }

    case getType(createVariable.request): {
      return {
        ...state,
        variableCreating: true,
      };
    }
    case getType(createVariable.failure):
    case getType(createVariable.success): {
      return {
        ...state,
        variableCreating: false,
      };
    }

    case getType(deleteVariable.request): {
      return {
        ...state,
        variableDeleting: true,
      };
    }
    case getType(deleteVariable.failure):
    case getType(deleteVariable.success): {
      return {
        ...state,
        variableDeleting: false,
      };
    }

    case getType(updateVariable.request): {
      return {
        ...state,
        variableUpdating: true,
      };
    }
    case getType(updateVariable.failure):
    case getType(updateVariable.success): {
      return {
        ...state,
        variableUpdating: false,
      };
    }

    default:
      return state;
  }
};

export default variablesReducer;
