import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { StyleTypes } from 'components/Button';
import { ICONS_TYPES } from 'components/Icon';
import Modal, { Modes, Sizes } from 'components/Modal';
import { PLACEMENT_TYPES } from 'components/Tooltip';

import { FormFields } from 'jsonSchema';
import { ServiceWithFiles } from 'models/Service';
import { SERVICE_PATHS_FIELD_NAME } from 'models/Service/constants';
import { getServiceIdentifier } from 'models/Service/utils';
import {
  ServiceApiMethodTypes,
  ServiceTypes,
  getServiceApiMethodFieldValue,
  getServicePathsFieldValue,
} from 'models/ServiceType';
import { selectServiceTypes } from 'store/app/selectors';
import { modalClose, modalOpen } from 'store/modal/actions';
import { buildDataCyValue } from 'utils/cy';

import PathParametersList, { ViewModes } from '../PathParametersList';
import ServiceForm from '../ServiceForm';

import { DELETE_SERVICE_CONFIRM_MODAL_ID, PATHS_MODAL_ID, SERVICE_MODAL_ID } from './constants';
import { EditIcon, RemoveIcon, RemoveIconTooltip, Root, ServiceContent, ServiceIcon, ServiceName } from './styles';
import { Props } from './types';

const ServiceCard: FC<Props> = ({
  actionLoading,
  className,
  deleteLoading,
  model,
  onCallAction,
  onChange,
  onRemove,
  serviceNameListToValidate,
  updateLoading,
}) => {
  const dispatch = useDispatch();
  const { list: serviceTypes } = useSelector(selectServiceTypes);
  const modelIdentifier = useMemo(() => getServiceIdentifier(model), [model]);
  const modalId = useMemo(() => `${SERVICE_MODAL_ID}_${modelIdentifier}`, [modelIdentifier]);
  const deleteModalId = useMemo(() => `${DELETE_SERVICE_CONFIRM_MODAL_ID}_${modelIdentifier}`, [modelIdentifier]);
  const serviceType = useMemo(() => serviceTypes.find((service) => service.type === model.type), [model, serviceTypes])!;
  const pathsModalId = useMemo(() => `${PATHS_MODAL_ID}_${modelIdentifier}`, [modelIdentifier]);
  const pathsViewMode = useMemo(
    () => (getServiceApiMethodFieldValue(model) === ServiceApiMethodTypes.custom ? ViewModes.EDIT : ViewModes.VIEW),
    [model]
  );
  const [pathsData, setPathsData] = useState<FormFields[]>();
  const modelWithPaths = useMemo(() => {
    const currentPathsValue = getServicePathsFieldValue(model) || [];
    return { ...model, data: { ...model.data, ...{ [SERVICE_PATHS_FIELD_NAME]: pathsData ?? currentPathsValue } } };
  }, [pathsData, model]);

  const handleOpenServiceModal = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: modalId, mode: Modes.rightSlide }));
  }, [dispatch, modalId]);
  const handleCloseServiceModal = useCallback(() => {
    dispatch(modalClose.request({ id: modalId }));
  }, [dispatch, modalId]);
  const handleOpenDeleteServiceConfirmModal = useCallback(() => {
    dispatch(modalOpen({ id: deleteModalId }));
  }, [deleteModalId, dispatch]);
  const handleRemove = useCallback(() => {
    onRemove(model);
  }, [model, onRemove]);
  const handlePathsClick = useCallback(() => {
    dispatch(modalOpen({ animated: true, id: pathsModalId, mode: Modes.default }));
  }, [dispatch, pathsModalId]);
  const handleClosePathParametersListModal = useCallback(() => {
    dispatch(modalClose.request({ id: pathsModalId }));
  }, [dispatch, pathsModalId]);
  const renderActions = useMemo(
    () =>
      model.type === ServiceTypes.REST
        ? (isFormDirty: boolean) => {
            return (
              <>
                <Button onClick={handlePathsClick} label="Paths" styleType={StyleTypes.filled} />
                {serviceType.actions?.map((item) => (
                  <Button
                    key={item.name}
                    onClick={() => onCallAction(item.name)}
                    label={item.name}
                    styleType={StyleTypes.filled}
                    loading={actionLoading}
                    disabled={isFormDirty}
                  />
                ))}
              </>
            );
          }
        : undefined,
    [model.type, handlePathsClick, serviceType.actions, onCallAction, actionLoading]
  );
  const handleSubmit = useCallback(
    (data: ServiceWithFiles) => {
      onChange(data);
      setPathsData(undefined);
    },
    [onChange]
  );
  const handlePathSubmit = useCallback(
    (pathsFields: FormFields[]) => {
      setPathsData(pathsFields);
      handleClosePathParametersListModal();
    },
    [setPathsData, handleClosePathParametersListModal]
  );

  return (
    <>
      <Root className={className} data-cy={buildDataCyValue({ elementName: 'card', fieldName: 'service' })}>
        <ServiceContent>
          <ServiceIcon model={serviceType.icon} />
          <ServiceName>{model.name}</ServiceName>
        </ServiceContent>
        <EditIcon icon={ICONS_TYPES.Pencil} size={24} onClick={handleOpenServiceModal} />
      </Root>
      <Modal
        id={modalId}
        renderTitle={() => 'Define service'}
        renderActions={() => (
          <RemoveIconTooltip placementType={PLACEMENT_TYPES.bottom} content={'Delete'}>
            <RemoveIcon icon={ICONS_TYPES.TrashBin} size={20} onClick={handleOpenDeleteServiceConfirmModal} />
          </RemoveIconTooltip>
        )}
        renderChildren={() => (
          <ServiceForm
            model={model}
            serviceNameListToValidate={serviceNameListToValidate}
            type={serviceType}
            onSubmit={handleSubmit}
            onCancel={handleCloseServiceModal}
            cancelOnSubmit={false}
            updateLoading={updateLoading}
            renderActions={renderActions}
            pathsData={pathsData}
          />
        )}
      />
      <Modal
        id={deleteModalId}
        confirm
        renderTitle={() => `Delete Service`}
        renderChildren={() => (
          <>
            Are you sure you want to delete <i>{model.name}</i>?
          </>
        )}
        confirmButtonProps={() => ({ label: 'Delete', loading: deleteLoading, onClick: handleRemove })}
      />
      {model.type === ServiceTypes.REST && (
        <Modal
          id={pathsModalId}
          size={Sizes.large}
          renderTitle={() => 'Paths'}
          renderChildren={() => (
            <PathParametersList
              viewMode={pathsViewMode}
              schema={serviceType.schema}
              service={modelWithPaths}
              onCloseClick={handleClosePathParametersListModal}
              onSubmit={handlePathSubmit}
            />
          )}
        />
      )}
    </>
  );
};

export default memo(ServiceCard);
