import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { getType } from 'typesafe-actions';

import { FormArrayDatasourceTypes, FormFields } from 'jsonSchema';
import { ProjectTree } from 'models/ProjectTree';
import { getProjectFlowsFromTree } from 'models/ProjectTree/utils';
import { Step } from 'models/Step';
import { Tag } from 'models/Tag';
import { convertTagToVariable } from 'models/Tag/utils';
import { Trigger } from 'models/Trigger';
import { Variable, VariableType } from 'models/Variable';
import { selectFlowSelectedStep, selectFlowSelectedTrigger } from 'pages/flow/store/selectors';
import { fetchInitiatives, fetchProjectTree } from 'pages/project/store/actions';
import { onFetchInitiatives, onFetchProjectTree } from 'pages/project/store/saga';
import { selectInitiativeList, selectProjectTree } from 'pages/project/store/selectors';
import { selectProjectTags } from 'store/projectTags/selectors';
import { fetchFlowVariables, fetchInitiativeVariables } from 'store/variables/actions';
import { onFetchFlowVariables, onFetchInitiativeVariables } from 'store/variables/saga';
import {
  selectContextVariables,
  selectFlowVariables,
  selectGlobalVariables,
  selectInitiativeVariables,
} from 'store/variables/selectors';
import { isDef, isDefAndNotNull } from 'utils/def';

import { fetchFormArrayDataSource } from './actions';

function* onFetchFormArrayDataSource({ payload }: ReturnType<typeof fetchFormArrayDataSource.request>): Generator {
  switch (payload.dataSourceName) {
    case FormArrayDatasourceTypes.selectVariable: {
      let dataSource: Variable[] = [];
      const globalVariables = yield select(selectGlobalVariables(payload.sphere, payload.project));
      const contextVariables = yield select(selectContextVariables(payload.sphere, payload.project));
      const projectTags = yield select(selectProjectTags);

      if (isDef(globalVariables) && (!isDef(payload.variableType) || payload.variableType === VariableType.Global)) {
        dataSource.push(...(globalVariables as Variable[]));
      }
      if (isDef(contextVariables) && (!isDef(payload.variableType) || payload.variableType === VariableType.Context)) {
        dataSource.push(...(contextVariables as Variable[]));
      }
      if (isDef(contextVariables) && payload.variableType === VariableType.Environment) {
        dataSource.push(...(projectTags as Tag[]).map(convertTagToVariable));
      }

      if (isDef(payload.bp)) {
        yield* onFetchInitiativeVariables({
          payload: { bp: payload.bp!, project: payload.project, sphere: payload.sphere },
          type: getType(fetchInitiativeVariables.request),
        });
        const initiativeVariables = yield select(selectInitiativeVariables(payload.sphere, payload.project, payload.bp));
        if (isDef(initiativeVariables) && (!isDef(payload.variableType) || payload.variableType === VariableType.Initiative)) {
          dataSource.push(...(initiativeVariables as Variable[]));
        }

        if (isDef(payload.flow)) {
          yield* onFetchFlowVariables({
            payload: { bp: payload.bp!, flow: payload.flow!, project: payload.project, sphere: payload.sphere },
            type: getType(fetchFlowVariables.request),
          });
          const flowVariables = yield select(selectFlowVariables(payload.sphere, payload.project, payload.bp, payload.flow));
          if (isDef(flowVariables) && (!isDef(payload.variableType) || payload.variableType === VariableType.Flow)) {
            dataSource.push(...(flowVariables as Variable[]));
          }
        }
      }
      if (isDef(payload.variableDataType)) {
        dataSource = dataSource.filter((d) => d.type === payload.variableDataType);
      }
      yield put(fetchFormArrayDataSource.success({ dataSource: dataSource as FormFields[] }));
      break;
    }
    case FormArrayDatasourceTypes.selectFlow: {
      yield* onFetchProjectTree({
        payload: { projectName: payload.project, sphereName: payload.sphere },
        type: getType(fetchProjectTree.request),
      });
      const projectTree = yield select(selectProjectTree);
      const projectFlows = yield call(getProjectFlowsFromTree, projectTree as ProjectTree, payload.bp);
      yield put(fetchFormArrayDataSource.success({ dataSource: projectFlows as FormFields[] }));
      break;
    }
    case FormArrayDatasourceTypes.selectInitiative: {
      yield* onFetchInitiatives({
        payload: { data: { project: payload.project, sphere: payload.sphere } },
        type: getType(fetchInitiatives.request),
      });
      const initiatives = yield select(selectInitiativeList);
      yield put(fetchFormArrayDataSource.success({ dataSource: initiatives as FormFields[] }));
      break;
    }
    case FormArrayDatasourceTypes.selectPaths: {
      const selectedTrigger = (yield select(selectFlowSelectedTrigger)) as Trigger;
      const data = (selectedTrigger?.config?.data || []) as FormFields;
      const paths = Object.keys(data).map((key) => ({ name: key, paramsDetails: data[key] }));
      yield put(
        fetchFormArrayDataSource.success({
          dataSource: paths as FormFields[],
        })
      );
      break;
    }
    case FormArrayDatasourceTypes.selectStore: {
      const selectedStep = (yield select(selectFlowSelectedStep)) as Step;
      const stepStore = selectedStep?._store;
      const storeKey = payload.dataSourceStoreKey;

      if (isDefAndNotNull(stepStore) && isDefAndNotNull(storeKey)) {
        const dataSource = stepStore[storeKey].map((item) => ({ name: item }));
        yield put(
          fetchFormArrayDataSource.success({
            dataSource: dataSource as FormFields[],
          })
        );
      }
      break;
    }
  }
}

function* formSourceArraySaga() {
  yield all([takeEvery(fetchFormArrayDataSource.request, onFetchFormArrayDataSource)]);
}

export default formSourceArraySaga;
