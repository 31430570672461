import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormFields } from 'jsonSchema';
import { Flow } from 'models/Flow';
import { Initiative } from 'models/Initiative';
import { Project } from 'models/Project';
import { Sphere } from 'models/Sphere';
import { VariableDataType, VariableType } from 'models/Variable';

export type FetchFormArrayDataSourceRequestPayload = {
  bp?: Initiative['name'];
  dataSourceName: string;
  dataSourceStoreKey?: string;
  flow?: Flow['name'];
  project: Project['name'];
  sphere: Sphere['name'];
  variableDataType?: VariableDataType;
  variableType?: VariableType;
};
export type FetchFormArrayDataSourceSuccessPayload = {
  dataSource: Array<FormFields>;
};
export const fetchFormArrayDataSource = createAsyncAction(
  'formSourceControl/fetchFormArrayDataSource/request',
  'formSourceControl/fetchFormArrayDataSource/success',
  'formSourceControl/fetchFormArrayDataSource/failure'
)<FetchFormArrayDataSourceRequestPayload, FetchFormArrayDataSourceSuccessPayload, undefined>();

export const reset = createAction('formSourceControl/reset')();
