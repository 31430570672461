import styled from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';

import Icon from '../Icon';

export const Root = styled.div`
  ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const Label = styled.label`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-bottom: 8px;
  letter-spacing: 0.5px;
`;
export const StyledOption = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'space-between', wrap: 'nowrap' })};
`;
export const StyledAddNewOption = styled.div`
  ${flexStyle({ align: 'center', direction: 'row', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const StyledAddNewOptionContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
export const DropdownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
`;
export const ClearIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-right: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;
export const CheckMarkIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;
export const AddNewIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;
export const MultiValueRemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.background};
  margin-left: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;
