import { ActionType, getType } from 'typesafe-actions';

import { FormFields } from 'jsonSchema';

import * as formSourceArrayActions from './actions';
import { fetchFormArrayDataSource, reset } from './actions';

type FormSourceArrayActionType = ActionType<typeof formSourceArrayActions>;

export type FormSourceArrayState = {
  dataSource: Array<FormFields> | null;
  dataSourceLoading: boolean;
};

const initialState: FormSourceArrayState = {
  dataSource: null,
  dataSourceLoading: false,
};

const formSourceArrayReducer = (
  state: FormSourceArrayState = initialState,
  action: FormSourceArrayActionType
): FormSourceArrayState => {
  switch (action.type) {
    case getType(fetchFormArrayDataSource.request): {
      return {
        ...state,
        dataSourceLoading: true,
      };
    }
    case getType(fetchFormArrayDataSource.failure): {
      return {
        ...state,
        dataSourceLoading: false,
      };
    }
    case getType(fetchFormArrayDataSource.success): {
      return {
        ...state,
        dataSource: action.payload.dataSource,
        dataSourceLoading: false,
      };
    }
    case getType(reset): {
      return initialState;
    }

    default:
      return state;
  }
};

export default formSourceArrayReducer;
