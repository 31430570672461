import React, { FC } from 'react';

const Pencil: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M11.2487 5.41627L14.582 8.7496M3.33203 16.6663H6.66536L15.4154 7.91627C15.8574 7.47424 16.1057 6.87472 16.1057 6.2496C16.1057 5.62448 15.8574 5.02496 15.4154 4.58293C14.9733 4.14091 14.3738 3.89258 13.7487 3.89258C13.1236 3.89258 12.5241 4.14091 12.082 4.58293L3.33203 13.3329V16.6663Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pencil;
