import React, { FC } from 'react';

const ArrowSplit: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M5 7L1 11M1 11L5 15M1 11L5 11C6.32608 11 7.59785 10.4732 8.53553 9.53553C9.47322 8.59785 10 7.32608 10 6M15 7L19 11M19 11L15 15M19 11L15 11C14.3434 11 13.6932 10.8707 13.0866 10.6194C12.48 10.3681 11.9288 9.99983 11.4645 9.53553C11.0002 9.07124 10.6319 8.52005 10.3806 7.91342C10.1293 7.30679 10 6.65661 10 6M10 6L10 1"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowSplit;
