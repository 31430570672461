import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const useDebouncedState = <T>(state: T, defaultValue: T | null = null, interval = 300): T | null => {
  const [current, setCurrent] = useState(defaultValue);
  const handleLoading = useRef(debounce((state) => setCurrent(state), interval));
  useEffect(() => {
    handleLoading.current(state);
  }, [handleLoading, state]);
  return current;
};
