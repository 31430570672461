import React, { FC } from 'react';

const Date: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      d="M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M9.16659 12.5H9.99992V15M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Date;
