import styled, { css } from 'styled-components';

import { flexStyle, textStyle } from 'styles/mixins';
import { isDefAndNotNull } from 'utils/def';

import Icon from '../Icon';

import { Props } from './types';

export const Root = styled.div<Partial<Props>>`
  ${flexStyle({ align: 'flex-start', direction: 'column', justify: 'flex-start', wrap: 'nowrap' })};
`;
export const Label = styled.label`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-bottom: 8px;
  letter-spacing: 0.5px;
`;
export const RequiredMark = styled.span`
  ${textStyle({ color: 'textPrimary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  margin-left: 5px;
`;
export const InputContainer = styled.div<Partial<Props> & { $active: boolean; onClick: Props['onClick'] }>`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  width: 100%;
  padding: 9px 16px;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor || 'background']};
  border: 1px solid
    ${({ $active, error, readOnly, theme, touched }) =>
      error && touched ? theme.colors.red : $active && !readOnly ? theme.colors.primary : theme.colors.divider};
  border-radius: 4px;

  ${({ onClick }) =>
    isDefAndNotNull(onClick) &&
    css`
      cursor: pointer;

      & input,
      & textarea {
        cursor: pointer;
      }
    `}

  &:hover {
    border: 1px solid
      ${({ $active, error, readOnly, theme, touched }) =>
        error && touched ? theme.colors.red : $active && !readOnly ? theme.colors.primary : theme.colors.grey};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        border: 1px solid ${theme.colors.divider};
      }
    `}
`;
export const InputWrapper = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  width: 100%;
`;
export const TextAreaWrapper = styled.div`
  ${flexStyle({ align: 'flex-start', wrap: 'nowrap' })};
  width: 100%;
`;
const InputStyles = css<Pick<Props, 'backgroundColor'>>`
  ${textStyle({ color: 'textSecondary', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  flex: 1;
  width: 100%;
  height: 20px;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  resize: none;
  letter-spacing: 0.5px;

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textTertiary};
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  &:-webkit-autofill {
    background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor || 'background']};
  }
`;
export const StyledInput = styled.input<Pick<Props, 'backgroundColor'>>`
  ${InputStyles};
`;
export const StyledTextArea = styled.textarea<Pick<Props, 'backgroundColor'>>`
  ${InputStyles};
  line-height: 1.4;
  height: auto;
  margin-top: 10px;
  padding: 0;
`;
export const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-right: 8px;
`;
export const ClearIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.textTertiary};
  margin-left: 8px;
  cursor: pointer;
`;
export const ErrorMessageContainer = styled.div`
  ${flexStyle({ align: 'center', wrap: 'nowrap' })};
  margin-top: 8px;
`;
export const AttentionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 4px;
`;
export const ErrorMessage = styled.span`
  ${textStyle({ color: 'red', lineHeight: 1, size: '12px', textAlign: 'left', weight: 400 })};
  letter-spacing: 0.4px;
`;
