import React, { FC } from 'react';

const Logical: FC = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path d="M13.3334 13.333V6.66634Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.0001 16.6663C10.8841 16.6663 11.732 16.3152 12.3571 15.69C12.9822 15.0649 13.3334 14.2171 13.3334 13.333V6.66634C13.3334 5.78229 12.9822 4.93444 12.3571 4.30932C11.732 3.6842 10.8841 3.33301 10.0001 3.33301C9.11603 3.33301 8.26818 3.6842 7.64306 4.30932C7.01794 4.93444 6.66675 5.78229 6.66675 6.66634V13.333C6.66675 14.2171 7.01794 15.0649 7.64306 15.69C8.26818 16.3152 9.11603 16.6663 10.0001 16.6663Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Logical;
