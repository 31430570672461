import { createAction, createAsyncAction } from 'typesafe-actions';

export type SlideOpenPayload = {
  id: string;
};
export const slideOpen = createAction('flowNav/open')<SlideOpenPayload>();

export type SlideClosePayload = {
  callBack?: () => void;
  id: string;
};
export const slideClose = createAsyncAction('flowNav/close/request', 'flowNav/close/success', 'flowNav/close/failure')<
  SlideClosePayload,
  SlideClosePayload,
  SlideClosePayload
>();

export type SetClosingClassPayload = {
  id: string;
};
export const setClosingClass = createAction('flowNav/setClosingClass')<SetClosingClassPayload>();

export type ResetClosingClassPayload = {
  id: string;
};
export const resetClosingClass = createAction('flowNav/resetClosingClass')<ResetClosingClassPayload>();

export const reset = createAction('flowNav/reset')();
